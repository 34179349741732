(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        user:
        {
            is_logged_in: false,
            userdata: null,

            recover:
            {
                _mode: "unset",
                Init: function (stack)
                {
                    $("body").addClass("branded");
                    $("a").each(function StackLinkifier() { p.seamlessNavigation.MakeLink(this); });

                    var cStack = p.array.sieve(stack);

                    if (cStack.length > 1)
                    {
                        switch (cStack[1].toLowerCase())
                        {
                            case "user":
                                this._mode = "user";
                                break;
                            case "password":
                                this._mode = "pass";
                                if (cStack.length == 5 && cStack[2] == "do")
                                {
                                    this._mode = "passdo";
                                    this.onReset(cStack);
                                }
                                break;
                        }
                    }
                    if (this._mode == "user" || this._mode == "pass")
                        $("#recover-button").on("click", function () { p.user.recover.onRecover(); });
                },

                onReset: function (stack)
                {
                    p.common.showLoader($(".mag-panel"));
                    //$(".login-toast form").remove();

                    p.net.Request(["get", "verification", "resetcode"], { code: stack[3], user: stack[4] }, function (a, b) { p.user.recover.onResetVerify(a, b); });
                },

                onResetVerify: function (success, data)
                {
                    if (success && data && data.success)
                    {
                        var title = $(".login-toast h1");
                        var container = $(".login-toast");

                        var controlPass1 = $("#login-pass");
                        var controlPass2 = $("#login-repeat");

                        var lPass = $("<span>", { "class": "label label-danger", "html": LOCALE["REGISTER_HINT_NO_PASSWORD"] });
                        var lPassRepeat = $("<span>", { "class": "label label-danger", "html": LOCALE["REGISTER_HINT_BAD_PASSWORD"] });

                        var verify = function ()
                        {
                            lPass.detach();
                            lPassRepeat.detach();
                            $(".has-error").removeClass("has-error");

                            var p1 = controlPass1.val();
                            var p2 = controlPass2.val();

                            if (p1.length == 0)
                            {
                                lPass.html(LOCALE["REGISTER_HINT_NO_PASSSWORD"]);
                                lPass.insertAfter(controlPass1);
                                controlPass1.parent().addClass("has-error");
                                return false;
                            }

                            if (p1.length < 6)
                            {
                                lPass.html(LOCALE["REGISTER_HINT_SHORT_PASSWORD"]);
                                lPass.insertAfter(controlPass1);
                                controlPass1.parent().addClass("has-error");
                                return false;
                            }

                            if (p1 != p2)
                            {
                                lPassRepeat.insertAfter(controlPass2);
                                controlPass1.parent().addClass("has-error");
                                controlPass2.parent().addClass("has-error");
                                return false;
                            }

                            return true;
                        };

                        $("#recover-button").on("click", function ()
                        {
                            if (verify() == true)
                            {
                                controlPass1.attr("disabled", true);
                                controlPass2.attr("disabled", true);
                                $("#recover-button").button("loading");

                                p.common.showLoader($(".mag-panel"));

                                var stack = p.array.sieve(p.seamlessNavigation.GetStack(true));

                                p.net.Request(["do", "reset"],
                                    {
                                        hash: stack[3],
                                        user: stack[4],
                                        pass: p.md5(controlPass2.val())
                                    },
                                    function (a, b) { p.user.recover.onResetResult(a, b); });
                            }
                        });

                        p.common.hideLoader();
                    }
                    else
                    {
                        return p.seamlessNavigation.Push(null, null, "/");
                    }
                },

                onResetResult: function (success, data)
                {
                    //if (success && data && data.success)
                    //{
                    //    p.common.hideLoader();
                    //}
                    p.common.hideLoader();
                    return p.seamlessNavigation.Push(null, null, "/login/");

                },

                onRecover: function ()
                {
                    if (this._mode == "unset") return;

                    var field = $("#login-email");
                    var data = field.val().trim();

                    $("#group-email").removeClass("has-error");

                    if (data.length == 0)
                    {
                        $("#group-email").addClass("has-error");
                        return;
                    }

                    field.attr("disabled", true);
                    $("#recover-button").button("loading");

                    if (this._mode == "user")
                    {
                        p.net.Request(["do", "recover", "user"], { data: data }, function (a, b) { p.user.recover.onRecoverResult(a, b); });
                    }
                    else
                    {
                        p.net.Request(["do", "recover", "password"], { data: data }, function (a, b) { p.user.recover.onRecoverResult(a, b); });
                    }
                },

                onRecoverResult: function (success, data)
                {
                    if (success && data && data.success)
                    {
                        var message = $("<p>");
                        var loginLink = $("<a>", { "html": LOCALE["LOGIN"], "href": "/login/" });
                        p.seamlessNavigation.MakeLink(loginLink);

                        if (this._mode == "user")
                        {
                            message.html(LOCALE["RECOVER_USERNAME_HINT"].format(data.email));
                        }
                        else
                        {
                            message.html(LOCALE["RECOVER_PASSWORD_HINT"].format(data.email));
                        }

                        message.insertBefore($("form"));
                        loginLink.insertAfter(message);
                        loginLink.append("<br />");
                        $("form").remove();
                    }
                    else
                    {
                        $("#group-email").addClass("has-error");
                        $("#login-email").attr("disabled", false);
                        $("#recover-button").button("reset");
                    }
                }

            },

            getCustomFields: function ()
            {
                var customFields = {};

                if (CUSTOMDATA != false)
                {
                    var makeControl = function (f)
                    {
                        //console.log(f);
                        //f = {
                        //    settings:
                        //        {
                        //            caption: "",
                        //            type: "",
                        //            multiselect: false,
                        //            allow_custom: false,
                        //            required: false,
                        //            values: {},
                        //            min: -1,
                        //            max: -1,
                        //            selected: ""
                        //        },
                        //    label: null,
                        //    control: null
                        //};
                        var s = f.settings;
                        // var $decorator = $("<paper-input-decorator class=\"branded text\" floatingLabel cross-fade>");
                        var $decorator = $div({ "class": "input-field" });
                        var $control = null;

                        if (s.type == "select")
                        {
                            $control = $("<select>", { "class": "branded" });
                            // $control.attr("label", s.caption);
                            $control.prop("settings", s);
                            if (s.multiselect)
                                $control.attr("multiple", "");

                            for (var val in s.values)
                            {
                                var item = $("<option>");
                                item.attr("value", val);
                                item.html(s.values[val]);
                                $control.append(item);
                            }
                            // $decorator.append($coentrol);
                            if (p.sonderlocke("CLASSIC_SELECTS_ON_MOBILE") && p.browser.isMobile)
                            {
                                $control.addClass("browser-default");
                            }

                            $decorator.append($control);
                            $decorator.append($("<label>", { "html": s.caption }));

                            if (s.allow_custom)
                            {
                                var container = $div({ "class": "flex rows custom-select" });

                                var item = $("<option>");
                                item.attr("value", "__CUSTOM__");
                                item.html(LOCALE["REGISTER_CUSTOM"]);
                                $control.append(item);

                                var $newDecorator = $div({ "class": "input-field" });

                                var customInput = $("<input>", { "type": "text" });
                                // $decorator.attr("label", s.caption);
                                $newDecorator.addClass("hidden");

                                $newDecorator.append(customInput);
                                $newDecorator.append($("<label>", { "html": s.caption }));

                                // $decorator.append(customInput);
                                // container.append($control).append($decorator);

                                var onChange = function (e)
                                {
                                    // console.log(e);
                                    if ($(e.target).val() == "__CUSTOM__")
                                        $newDecorator.removeClass("hidden");
                                    else
                                        $newDecorator.addClass("hidden");
                                };
                                $control.on("change", onChange);

                                container.append($decorator).append($newDecorator);

                                // menu[0].addEventListener("core-select", onChange);

                                return container;
                            }

                            // $control.material_select();
                            return $decorator;
                        }
                        else
                        {
                            $control = $("<input>");
                            // $control.attr("is", "core-input");
                            $control.prop("settings", s);
                            //$control.addClass("form-control");

                            if (s.type == "date")
                            {
                                $control.attr("type", "text");
                                // $control.addClass("datepicker");
                                // $control
                            }
                            else
                            {
                                $control.attr("type", s.type);
                            }

                            // $decorator.attr("label", s.caption);

                            if (s.type == "number")
                            {
                                $control.on("keyup blur focus click tap change", function ()
                                {
                                    var v = parseInt($(this).val());
                                    var s = $(this).prop("settings");
                                    if (v < parseInt(s.min))
                                        $(this).val(s.min);
                                    else if (v > parseInt(s.max))
                                        $(this).val(s.max);
                                });
                            }
                        }
                        $decorator.append($control);
                        $decorator.append($("<label>", { "html": s.caption }));
                        return $decorator;
                    };

                    for (var key in CUSTOMDATA)
                    {
                        var field =
                        {
                            settings:
                            {
                                caption: "",
                                type: "",
                                multiselect: false,
                                allow_custom: false,
                                required: false,
                                values: {},
                                min: -1,
                                max: -1,
                                selected: ""
                            },
                            label: null,
                            control: null
                        };

                        $.extend(field.settings, CUSTOMDATA[key]);

                        field.control = makeControl(field);
                        //field.label = $("<span>", { "class": "label label-danger", "html": LOCALE["REGISTER_HINT_NO_DEFAULT"] });

                        //field.setError = function (error)
                        //{
                        //    if (error == true)
                        //    {
                        //        this.label.insertAfter(this.control);
                        //        this.control.parent().addClass("has-error");
                        //    }
                        //    else
                        //    {
                        //        this.label.detach();
                        //        this.control.parent().removeClass("has-error");
                        //    }
                        //};

                        customFields[key] = field;
                    }

                    //console.log(customFields);
                }

                return customFields;
            },

            account:
            {
                user: null,
                purchases: null,

                div: null,
                div_user: null,
                div_purchases: null,

                left_hero: null,
                right_hero: null,

                Init: function (stack)
                {
                    if (p.user.is_logged_in)
                    {
                        if (ENABLE_SHOP)
                            $("#menu-shelf, #menu-shop").parent()[0].selected = -1;

                        this.buildBase();

                        setTimeout(p.polymer.animateToTertiary, 100);

                        p.common.showLoader();
                        p.net.Request(["get", "purchase-summary"], {}, this.onPurchaseSummary);

                        p.common.showLoader();
                        p.net.Request(["get", "user", "status"], {}, this.onUserInformation);
                    }
                    else
                    {
                        setTimeout(function ()
                        {
                            p.seamlessNavigation.Push(p.seamlessNavigation.currentState.from_state);
                        }, 100);
                    }
                },

                buildBase: function ()
                {
                    this.div = $div({ "class": "details", "fit": "", "layout": "", "horizontal": "" });
                    this.div_user = $div({ "class": "account" });
                    this.div_purchases = $div({ "class": "purchases flex rows" });

                    var top = $div({ "class": "top", "layout": "", "horizontal": "" });
                    var left = $div({ "class": "left" });
                    var right = $div({ "class": "right" });

                    var $back = p.paper.icon_button("arrow-back");
                    $back.addClass("text flat transparent");
                    p.seamlessNavigation.Link_State($back, p.seamlessNavigation.currentState.from_state);

                    // p.paper.make_animateable(top, "cross-fade");
                    // p.paper.make_animateable(left, "cross-fade");
                    // p.paper.make_animateable(right, "cross-fade");
                    // p.paper.make_animateable(left, "slide-down");
                    // p.paper.make_animateable(right, "slide-down");
                    //p.paper.make_animateable(left, "slide-up");
                    //p.paper.make_animateable(right, "slide-up");

                    //#region Check for heroes

                    //var toasts = $(".book.toastify:not(.nomatch)");
                    //if (toasts.length >= 2)
                    //{
                    //    this.left_hero = $(toasts[0]).attr("id");
                    //    this.right_hero = $(toasts[1]).attr("id");
                    //}
                    //else if (toasts.length == 1)
                    //{
                    //    this.left_hero = $(toasts[0]).attr("id");
                    //}

                    if (this.left_hero)
                    {
                        p.paper.make_animateable(left, "hero", "cover" + this.left_hero);
                    }
                    if (this.right_hero)
                    {
                        p.paper.make_animateable(right, "hero", "cover" + this.right_hero);
                    }

                    //#endregion

                    top.append($back);
                    left.append(this.div_user);
                    right.append(this.div_purchases);

                    this.div.append(top).append(left).append(right);
                    $("#content-tertiary .content").html("").append(this.div);
                },

                buildUserInformation: function ()
                {
                    var $this = p.user.account;

                    var $title = $("<h1>", { "class": "display2", "html": LOCALE["MY_ACCOUNT"] });
                    var $title_more = $("<h1>", { "class": "display1", "html": LOCALE["MOREINFO"] });

                    var title_username = $div({ "class": "title", "html": LOCALE["USERNAME"] });
                    var value_username = $div({ "class": "subhead", "text": $this.user.username });

                    var title_email = $div({ "class": "title", "html": LOCALE["EMAIL"] });
                    var value_email = $div({ "class": "subhead", "text": $this.user.email });

                    var title_name = $div({ "class": "title", "html": LOCALE["NAME"] });
                    var value_name = $div({ "class": "subhead", "text": "{0} {1}".format($this.user.firstname, $this.user.lastname) });

                    $this.div_user
                        .append($title)
                        .append(title_username)
                        .append(value_username)
                        .append(title_email)
                        .append(value_email)
                        .append(title_name)
                        .append(value_name);

                    if (CUSTOMDATA != false)
                    {
                        $this.div_user.append($title_more);

                        var userCustom = p.jsonParse($this.user.custom);

                        if (userCustom == null)
                            userCustom = [];

                        for (var key in CUSTOMDATA)
                        {
                            var title = $div({ "class": "title", "text": CUSTOMDATA[key].caption });
                            var value = $div({ "class": "subhead", "text": userCustom[key] ? userCustom[key] : LOCALE["NO_INFORMATION"] });

                            $this.div_user
                                .append(title)
                                .append(value);
                        }
                    }
                },

                buildPurchases: function ()
                {
                    var $this = p.user.account;

                    var $title = $("<h1>", { "class": "display1", "html": LOCALE["PURCHASES"] });

                    $this.div_purchases.append($title);

                    var animator = $div({ "class": "animator" }); // $("<core-animated-pages selected=\"main\" transitions=\"hero-transition cross-fade\">");
                    var mainPage = $("<section name=\"main\" class=\"active\">");
                    var mainPageContent = $div({ "class": "flex rows" });

                    mainPage.append(mainPageContent);
                    animator.append(mainPage);
                    $this.div_purchases.append(animator);
                    var total_price = 0.0;

                    for (var i in $this.purchases)
                    {
                        var purchase = $this.purchases[i];
                        var name = "";
                        var udid = "";
                        p.books.each(function (e)
                        {
                            if (e.id == purchase.product_id)
                            {
                                name = e.name;
                                udid = e.udid;
                                return false;
                            }
                        }, false);

                        var coupon = purchase.coupon_data ? p.jsonParse(purchase.coupon_data) : null;
                        var discount = coupon ? coupon.calculated_discount : 0;

                        //#region Row
                        var row = $div({ "class": "row waves-effect" });
                        var row_content = $div({ "class": "inner flex cols" });

                        var p_name = $div({ "class": "body2", "text": name });
                        var p_price = $div({ "class": "body1 text-right", "html": "{0} {1}".format((parseFloat(purchase.price_net) - discount).toFixed(2), LOCALE["CURRENCY_SIGN"]) });

                        if (purchase.type == "sample")
                            p_price.html(LOCALE["FREESAMPLE"]);

                        if (purchase.cancelled == "1")
                            p_price.html(LOCALE["CANCELLED"]);

                        if (purchase.cancelled == "0" && purchase.validated == "0")
                            p_price.prepend($("<span>", { "class": "pending", html: LOCALE["PAYMENT_PENDING"] }));

                        // p.paper.make_animateable(row, "hero", "{0}-{1}".format(purchase.id, "container"));
                        // p.paper.make_animateable(p_name, "hero", "{0}-{1}".format(purchase.id, "name"));
                        // p.paper.make_animateable(p_price, "hero", "{0}-{1}".format(purchase.id, "price"));

                        // p.paper.make_animateable(row, "cross-fade");
                        // p.paper.make_animateable(p_name, "cross-fade");
                        // p.paper.make_animateable(p_price, "cross-fade");

                        row_content.append(p_name).append(p_price);
                        row.append(row_content); // .append(p.paper.ripplecontainer());

                        row.on("click", { id: purchase.id }, function (e)
                        {
                            setTimeout(function ()
                            {
                                // animator[0].selected = e.data.id;
                                p.polymer.AnimateTo(animator, e.data.id);
                            }, 100);
                        });

                        mainPageContent.append(row);
                        //#endregion

                        //#region Details

                        var page = $("<section name=\"{0}\">".format(purchase.id));
                        var pageContent = $div({ "class": "purchase-detail card flex rows" });

                        var topBar = $div();
                        var backButton = p.paper.icon_button("arrow-back");
                        backButton.addClass("text flat transparent");
                        backButton.on("click", function ()
                        {
                            setTimeout(function ()
                            {
                                // animator[0].selected = "main";
                                p.polymer.AnimateTo(animator, 0);
                            }, 100);
                        });
                        topBar.append(backButton);
                        page.append(topBar);

                        /*
                        10208: {id: "10208", public_id: "b74dfb960b71a7a8643914c3d17e2575", product_id: "93", customer_id: "31",…}
                        cancelled: "0"
                        coupon_code: "DICKETITTEN"
                        coupon_data: "{"id":"13","name":"Titten Discount","code":"DICKETITTEN","value":"5.00","timed_from":943916400,"timed_to":943916400,"multiuse_max":100,"multiuse_current":17,"is_user_unique":false,"is_fixed":false,"is_percentage":true,"is_timed":false,"is_limited":true,"is_singleuse":false,"calculated_discount":0.75}"
                        customer_id: "31"
                        external_customer_id: "LNK5ZQZM6NWU2"
                        external_transaction_id: "PAY-9RK25160WY599752WKSI2UPI"
                        id: "10208"
                        is_timed: "0"
                        origin: "web"
                        price_gross: "12.14"
                        price_net: "14.99"
                        price_vat: "2.85"
                        product_id: "93"
                        public_id: "b74dfb960b71a7a8643914c3d17e2575"
                        purchase_time_order: "2014-12-17 17:07:22"
                        purchase_time_payment: "2014-12-17 17:07:59"
                        timed_from: null
                        timed_to: null
                        type: "paypal"
                        validated: "1"

                        10209: {id: "10209", public_id: "reading-sample", product_id: "91", customer_id: "31", type: "sample",…}
                        cancelled: "0"
                        coupon_code: ""
                        coupon_data: ""
                        customer_id: "31"
                        external_customer_id: null
                        external_transaction_id: null
                        id: "10209"
                        is_timed: "0"
                        origin: "web"
                        price_gross: "0.00"
                        price_net: "0.00"
                        price_vat: "0.00"
                        product_id: "91"
                        public_id: "reading-sample"
                        purchase_time_order: "2014-12-19 16:31:54"
                        purchase_time_payment: null
                        timed_from: null
                        timed_to: null
                        type: "sample"
                        validated: "1"
                        */

                        var d_name = $div({ "class": "title", "text": name });
                        pageContent.append(d_name);

                        var d_internalId = $("<p cross-fade>");
                        d_internalId
                            .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_PUBLICID"] }))
                            .append($div({ "class": "body1", "html": purchase.id + "/" + purchase.public_id.toUpperCase() }));

                        pageContent.append(d_internalId);

                        if (purchase.type == "paypal")
                        {
                            var d_paypalId = $("<p cross-fade>");
                            d_paypalId
                                .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_PAYPALID"] }))
                                .append($div({ "class": "body1", "html": purchase.external_transaction_id }));

                            pageContent.append(d_paypalId);
                        }
                        else if (purchase.type == "sample")
                        {
                        }

                        var d_timeStart = $("<p cross-fade>");
                        d_timeStart
                            .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_TIME_PURCHASE"] }))
                            .append($div({ "class": "body1", "html": purchase.purchase_time_order }));

                        pageContent.append(d_timeStart);

                        if (purchase.cancelled == "1")
                        {
                            var d_storno = $("<p cross-fade>");
                            d_storno
                                .append($div({
                                    "class": "button branded-text error",
                                    "hero": "",
                                    "hero-id": "{0}-{1}".format(purchase.id, "price"),
                                    "html": LOCALE["CANCELLED"]
                                }));

                            pageContent.append(d_storno);
                        }
                        else
                        {
                            if (purchase.type != "sample")
                            {
                                var d_timeEnd = $("<p cross-fade>");
                                d_timeEnd
                                    .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_TIME_PAYMENT"] }));

                                if (purchase.validated == "0")
                                    d_timeEnd.append($div({ "class": "body1 pending", "html": LOCALE["PAYMENT_PENDING"] }));
                                else
                                    d_timeEnd.append($div({ "class": "body1", "html": purchase.purchase_time_payment }));

                                pageContent.append(d_timeEnd);
                            }

                            if (purchase.is_timed == "1")
                            {
                                var d_subscription = $("<p cross-fade>");
                                d_subscription
                                    .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_SUBSCRIPTION"] }));

                                pageContent.append(d_subscription);


                                var d_subStart = $("<p cross-fade class=\"indent\">");
                                d_subStart
                                    .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_SUB_FROM"] }))
                                    .append($div({ "class": "body1", "html": (moment(purchase.timed_from)).format("dddd, MMMM Do YYYY, HH:mm:ss") }));

                                pageContent.append(d_subStart);

                                var d_subEnd = $("<p cross-fade class=\"indent\">");
                                d_subEnd
                                    .append($div({ "class": "button", "html": LOCALE["PURCHASE_DETAIL_SUB_TO"] }))
                                    .append($div({ "class": "body1", "html": (moment(purchase.timed_to)).format("dddd, MMMM Do YYYY, HH:mm:ss") }));

                                pageContent.append(d_subEnd);
                            }

                            if (coupon)
                            {
                                var d_priceBase = $("<p cross-fade class=\"flex cols inner\">");
                                d_priceBase
                                    .append($div({
                                        "class": "button flex grow",
                                        "html": LOCALE["PURCHASE_DETAIL_PRICE_NET"]
                                    }))
                                    .append($div({
                                        "class": "body1 text-right",
                                        "html": "{0} {1}".format(parseFloat(purchase.price_net).toFixed(2), LOCALE["CURRENCY_SIGN"])
                                    }));

                                pageContent.append(d_priceBase);


                                var d_priceCoupon = $("<p cross-fade class=\"flex cols inner\">");
                                d_priceCoupon
                                    .append($div({
                                        "class": "button flex grow",
                                        "html": LOCALE["PURCHASE_DETAIL_DISCOUNT"]
                                    }))
                                    .append($div({
                                        "class": "body1 branded-text success text-right",
                                        "html": "-{0} {1}".format(discount.toFixed(2), LOCALE["CURRENCY_SIGN"])
                                    }));

                                pageContent.append(d_priceCoupon);
                            }

                            var d_price = $("<p cross-fade class=\"flex cols foot\">");
                            d_price
                                .append($div({
                                    "class": "button flex grow",
                                    "html": LOCALE["PURCHASE_DETAIL_PRICE_TOTAL"]
                                }))
                                .append($div({
                                    "class": "body1 text-right",
                                    "html": "{0} {1}".format((parseFloat(purchase.price_net) - discount).toFixed(2), LOCALE["CURRENCY_SIGN"])
                                }));

                            pageContent.append(d_price);
                        }

                        p.paper.make_animateable(pageContent, "hero", "{0}-{1}".format(purchase.id, "container"));
                        p.paper.make_animateable(d_name, "hero", "{0}-{1}".format(purchase.id, "name"));

                        p.paper.make_animateable(pageContent, "cross-fade");
                        p.paper.make_animateable(d_name, "cross-fade");

                        page.append(pageContent);
                        animator.append(page);

                        //#endregion

                        if (purchase.type != "sample" && purchase.cancelled == "0" && purchase.validated == "1")
                            total_price += (parseFloat(purchase.price_net) - discount);
                    }

                    var summary = $div({ "class": "caption foot", "layout": "", "horizontal": "", });
                    summary.append($div({ "html": LOCALE["PURCHASE_TOTAL_LABEL"], "flex": "", "two": "" }));
                    summary.append($div({ "class": "text-right", "flex": "", "html": "{0} {1}".format(total_price.toFixed(2), LOCALE["CURRENCY_SIGN"]) }));

                    p.paper.make_animateable(summary, "cross-fade");

                    mainPageContent.append(summary);
                },

                onUserInformation: function (success, data)
                {
                    if (success && data && data.success && data["user-all"])
                    {
                        p.user.account.user = data["user-all"];
                        p.user.account.buildUserInformation();
                    }
                    p.common.hideLoader();
                },

                onPurchaseSummary: function (success, data)
                {
                    if (success && data && data.success && data.purchases)
                    {
                        p.user.account.purchases = data.purchases;
                        p.user.account.buildPurchases();
                        p.common.transitionLoader.hide();
                    }
                    p.common.hideLoader();
                }
            },

            manage:
            {
                Init: function (stack)
                {
                    p.common.showLoader();

                    p.polymer.animateToTertiary();


                    p.net.Request(["get", "purchase-summary"], {}, this.onPurchaseSummary);

                    $("#acc-edit-button").on("click", this.onEditStart);
                    p.material.ripple($("#acc-edit-button"));
                },
                onEditStart: function ()
                {
                    p.common.showLoader();
                    //<input id="register-firstname" type="text" class="form-control" placeholder="%%FIRST_NAME%%" />
                    // <div id="acc-edit-button" class="btn btn-default">%%EDIT%%</div>

                    var iEmail = $("<input>",
                        {
                            "type": "text",
                            "class": "form-control",
                            "placeholder": LOCALE["EMAIL"],
                            "value": $("#cell-email").attr("data-mail")
                        });

                    var iFName = $("<input>",
                        {
                            "type": "text",
                            "class": "form-control",
                            "placeholder": LOCALE["FIRST_NAME"],
                            "value": p.user.userdata.firstname
                        });

                    var iLName = $("<input>",
                        {
                            "type": "text",
                            "class": "form-control",
                            "placeholder": LOCALE["LAST_NAME"],
                            "value": p.user.userdata.lastname
                        });

                    var iLName = $("<input>",
                        {
                            "type": "text",
                            "class": "form-control",
                            "placeholder": LOCALE["LAST_NAME"],
                            "value": p.user.userdata.lastname
                        });

                    //var iStreet = $("<input>",
                    //    {
                    //        "type": "text",
                    //        "class": "form-control",
                    //        "placeholder": LOCALE["STREET"],
                    //        "value": p.user.userdata.addr_street
                    //    });
                    //var iZip = $("<input>",
                    //    {
                    //        "type": "text",
                    //        "class": "form-control",
                    //        "placeholder": LOCALE["ZIPCODE"],
                    //        "value": p.user.userdata.addr_zip
                    //    });
                    //var iCity = $("<input>",
                    //    {
                    //        "type": "text",
                    //        "class": "form-control",
                    //        "placeholder": LOCALE["CITY"],
                    //        "value": p.user.userdata.addr_city
                    //    });
                    //var iCountry = $("<input>",
                    //    {
                    //        "type": "text",
                    //        "class": "form-control",
                    //        "placeholder": LOCALE["COUNTRY"],
                    //        "value": p.user.userdata.addr_country
                    //    });

                    //$("#cell-email").html("").append(iEmail);
                    $("#cell-name").html("").append(iFName).append(iLName);
                    //$("#cell-street").html("").append(iStreet);
                    //$("#cell-zip").html("").append(iZip);
                    //$("#cell-city").html("").append(iCity);
                    //$("#cell-country").html("").append(iCountry);

                    var cancelBtn = $div({ "class": "btn btn-default", "href": "/account/", "html": LOCALE["CANCEL"] });
                    p.seamlessNavigation.MakeLink(cancelBtn);
                    p.material.ripple(cancelBtn);

                    cancelBtn.insertAfter($("#acc-edit-button"));

                    $("#acc-edit-button").off("click", p.user.manage.onEditStart);
                    $("#acc-edit-button").on("click", function ()
                    {
                        p.user.manage.onEditSave(iFName, iLName); //, iStreet, iZip, iCity, iCountry);
                    });
                    $("#acc-edit-button").html(LOCALE["SAVE"]);
                    $("#acc-edit-button").removeClass("btn-default").addClass("btn-primary");

                    p.common.hideLoader();
                },
                onEditSave: function (iFName, iLName) //, iStreet, iZipCode, iCity, iCountry)
                {
                    var validate = function ()
                    {
                        var firstname = iFName.val().trim();
                        var lastname = iLName.val().trim();
                        //var street = iStreet.val().trim();
                        //var zipcode = iZipCode.val().trim();
                        //var city = iCity.val().trim();
                        //var country = iCountry.val().trim();

                        var hasError = false;

                        if (firstname.length == 0)
                        {
                            iFName.parent().addClass("has-error");
                            hasError = true;
                        }
                        if (lastname.length == 0)
                        {
                            iLName.parent().addClass("has-error");
                            hasError = true;
                        }


                        //if (street.length == 0)
                        //{
                        //    iStreet.parent().addClass("has-error");
                        //    hasError = true;
                        //}
                        //if (zipcode.length == 0)
                        //{
                        //    iZipCode.parent().addClass("has-error");
                        //    hasError = true;
                        //}
                        //if (city.length == 0)
                        //{
                        //    iCity.parent().addClass("has-error");
                        //    hasError = true;
                        //}
                        //if (country.length == 0)
                        //{
                        //    iCountry.parent().addClass("has-error");
                        //    hasError = true;
                        //}

                        return !hasError;
                    };

                    if (validate())
                    {
                        var controlsArray = [iFName, iLName]; //, iStreet, iZipCode, iCity, iCountry];
                        for (var i = 0; i < controlsArray.length; i++)
                            $(controlsArray[i]).attr("disabled", true);

                        p.common.showLoader();

                        p.net.Request(["do", "edit-user"],
                            {
                                "user": p.user.userdata.username,
                                "firstname": iFName.val().trim(),
                                "lastname": iLName.val().trim()//,
                                //"street": iStreet.val().trim(),
                                //"zipcode": iZipCode.val().trim(),
                                //"city": iCity.val().trim(),
                                //"country": iCountry.val().trim()
                            }, function (success, data)
                        {
                            if (success && data && data.success)
                            {
                                p.seamlessNavigation.Reload();
                            }
                            else
                            {
                                for (var i = 0; i < controlsArray.length; i++)
                                    $(controlsArray[i]).attr("disabled", false);
                            }

                            p.common.hideLoader();
                        });
                    }
                },
                onPurchaseSummary: function (success, data)
                {
                    if (success && data && data.success && data.purchases)
                    {
                        //var expandRow = function (e, d)
                        //{
                        //    var $e = $(e);
                        //    $e.toggleClass("expand");
                        //};
                        var makeRow = function (name, price, data)
                        {
                            var $tr = $("<tr>", { "class": "subhead" });
                            var $tdName = $("<td>", { "colspan": 4, html: name });
                            var $tdPrice = $("<td>", { "colspan": 1, html: price });
                            //p.material.ripple($tr);
                            /*
                            {
                               "id":"10035",
                               "public_id":"reading-sample",
                               "product_id":"2",
                               "customer_id":"2",
                               "type":"sample",
                               "origin":"web",
                               "price_gross":"0.00",
                               "price_vat":"0.00",
                               "price_net":"0.00",
                               "external_customer_id":null,
                               "external_transaction_id":null,
                               "purchase_time_order":"2014-05-02 14:10:45",
                               "purchase_time_payment":null,
                               "validated":"1",
                               "cancelled":"0",
                               "is_timed":"0",
                               "timed_from":null,
                               "timed_to":null
                            }
                            */

                            var $deeds = $div({ "class": "purchase-details" });
                            //p.material.ripple($deeds);

                            var addDeed = function (title, txt)
                            {
                                var d = $div({ "class": "body2" });
                                d.append($("<span>", { "class": "key", html: title }));
                                d.append($("<span>", { "class": "value", html: txt }));
                                $deeds.append(d);
                            };

                            var isSample = data.type == "sample";
                            var isPaypal = data.type == "paypal";
                            var isSubscription = data.is_timed == "1" && data.cancelled != "1";

                            !isSample && addDeed(LOCALE["PURCHASE_DETAIL_PUBLICID"], data.public_id.toUpperCase());
                            isPaypal && addDeed(LOCALE["PURCHASE_DETAIL_PAYPALID"], data.external_transaction_id.toUpperCase());

                            addDeed("", "");

                            addDeed(LOCALE["PURCHASE_DETAIL_TIME_PURCHASE"], (moment(data.purchase_time_order)).format());
                            !isSample && addDeed(LOCALE["PURCHASE_DETAIL_TIME_PAYMENT"], (data.cancelled != "1" ? (moment(data.purchase_time_payment)).format() : LOCALE["CANCELLED"]));

                            addDeed("", "");

                            !isSample && addDeed(LOCALE["PURCHASE_DETAIL_PRICE_GROSS"], "{0}&nbsp;{1}".format(parseFloat(data.price_gross).toFixed(2), LOCALE["CURRENCY_SIGN"]));
                            !isSample && addDeed(LOCALE["PURCHASE_DETAIL_PRICE_VAT"], "{0}&nbsp;{1}".format(parseFloat(data.price_vat).toFixed(2), LOCALE["CURRENCY_SIGN"]));
                            !isSample && addDeed(LOCALE["PURCHASE_DETAIL_PRICE_NET"], "{0}&nbsp;{1}".format(parseFloat(data.price_net).toFixed(2), LOCALE["CURRENCY_SIGN"]));

                            if (!isSample && data.coupon_data)
                            {
                                var coupon = p.jsonParse(data.coupon_data);
                                if (coupon)
                                {
                                    var couponTitle = "{0} {1}";
                                    var couponValue = "- {0}{1}".format(parseFloat(coupon.calculated_discount).toFixed(2), LOCALE["CURRENCY_SIGN"]);

                                    if (coupon.is_fixed)
                                    {
                                        couponTitle = "";
                                    }
                                    else
                                    {
                                        couponTitle =
                                            "(" + LOCALE["PURCHASE_DETAIL_DISCOUNT_LABEL"].format(
                                                parseFloat(coupon.value).toFixed(2),
                                                "%",
                                                parseFloat(data.price_net).toFixed(2)
                                            ) + ")";
                                    }

                                    addDeed(LOCALE["PURCHASE_DETAIL_DISCOUNT"] + "<br>({0})".format(coupon.name), "{0}&nbsp;{1}".format(couponValue, couponTitle));
                                    addDeed(LOCALE["PURCHASE_DETAIL_PRICE_TOTAL"],
                                        (parseFloat(data.price_net) - parseFloat(coupon.calculated_discount)).toFixed(2)
                                        + " " + LOCALE["CURRENCY_SIGN"]);
                                }
                                //console.log(coupon);
                            }

                            isSubscription && addDeed("", "");
                            isSubscription && addDeed(LOCALE["PURCHASE_DETAIL_SUBSCRIPTION"], "");
                            isSubscription && addDeed(LOCALE["PURCHASE_DETAIL_SUB_FROM"], (moment(data.timed_from)).format());
                            isSubscription && addDeed(LOCALE["PURCHASE_DETAIL_SUB_TO"], (moment(data.timed_to)).format());

                            $tdName.append($deeds);

                            $tr.append($tdName).append($tdPrice);
                            $tr.on("click", function () { $(this).toggleClass("expand"); });
                            return $tr;
                        };
                        var total_net = 0;
                        for (var i in data.purchases)
                        {
                            var purchase = data.purchases[i];
                            var name = "";
                            p.books.each(function (e)
                            {
                                if (e.id == purchase.product_id)
                                {
                                    name = e.name;
                                    return false;
                                }
                            }, false);

                            if (purchase.cancelled == "1")
                            {
                                $("#table-body").append(makeRow(name, LOCALE["CANCELLED"], purchase));
                                //total_net += purchase.price_net;
                            }
                            else
                            {
                                var price = 0;

                                if (purchase.coupon_data)
                                {
                                    var coupon = p.jsonParse(purchase.coupon_data);
                                    price = (parseFloat(purchase.price_net) - parseFloat(coupon.calculated_discount));
                                }
                                else
                                {
                                    price = parseFloat(purchase.price_net);
                                }



                                $("#table-body").append(makeRow(purchase.type == "sample" ? "{0} ({1})".format(name, LOCALE["SAMPLE"]) : name, purchase.price_net == 0.0 ? LOCALE["FREE"] : price.toFixed(2) + "&nbsp;" + LOCALE["CURRENCY_SIGN"], purchase));

                                total_net += price;
                            }
                        }

                        $("#payment-total").html("{0}&nbsp;{1}".format(parseFloat(total_net).toFixed(2), LOCALE["CURRENCY_SIGN"]));
                    }
                    else
                    {
                        if (data && data.success === false)
                            p.seamlessNavigation.Push(null, null, "/login/");
                    }

                    p.material.raiseOnHover(".account");
                    p.material.raiseOnHover(".purchases");

                    p.common.hideLoader();
                }
            },

            Init: function ()
            {
                p.net.Request(["get", "user", "status"], {}, this.onGetInfo);
            },

            formInitProp: "form-inited",

            isInited: function (e)
            {
                return e.prop(p.user.formInitProp);
            },

            setInited: function (e)
            {
                e.prop(p.user.formInitProp, true);
            },

            forms:
            {
                goPage: function (name, title)
                {
                    var state = p.seamlessNavigation.stateTemplate;
                    state.cmd = p.seamlessNavigation.currentState.cmd;
                    state.stack = name;
                    state.animator = "login-animator";
                    state.selection = name;
                    state.branded_body = true;
                    state.title = title;

                    p.seamlessNavigation.Push(state);

                    if (p.sonderlocke("SPM_LOGIN"))
                    {
                        p.user.forms.login.onLoginError();

                        if (name == "/register/confirm/")
                            p.user.forms.register_confirm.startResendTimer();
                        else
                            p.user.forms.register_confirm.stopResendTimer();
                    }

                    p.user.forms.login.free();
                    p.user.forms.register.free();
                    p.user.forms.register_confirm.free();
                    p.user.forms.recover_password.free();
                    p.user.forms.recover_username.free();
                },

                setSPMError: function (visible, msg)
                {
                    if (!p.sonderlocke("SPM_LOGIN"))
                        return;

                    var $container = $("#spm-login-error");
                    var $text = $("#spm-error-text");

                    if (visible)
                    {
                        $text.html(msg);
                        $container.removeClass("hidden");
                    }
                    else
                    {
                        $container.addClass("hidden");
                    }
                },
                setError: function (e, msg)
                {
                    return p.form_helper.setError(e, msg);
                },
                setDisabled: function (e, disabled)
                {
                    return p.form_helper.setDisabled(e, disabled);
                },

                setDisabledButton: function (e, disabled, spinner)
                {
                    return p.form_helper.setDisabledButton(e, disabled, spinner);
                },

                value: function (e)
                {
                    return p.form_helper.value(e);
                },

                login:
                {
                    init: function ()
                    {
                        $("#button-login").on("click", p.user.forms.login.delayedValidation);
                        $("#login-password, #login-username").on("keyup", function (e)
                        {
                            if (e.keyCode == 13)
                            {
                                p.user.forms.login.validate();
                            }
                        });

                    },
                    delayedValidation: function ()
                    {
                        setTimeout(p.user.forms.login.validate, 100);
                    },
                    free: function ()
                    {
                        var $this = p.user.forms;
                        var fieldUsername = $("#login-username");
                        var fieldPassword = $("#login-password");
                        $this.setDisabled(fieldUsername, false);
                        $this.setDisabled(fieldPassword, false);
                        $this.setDisabledButton($("#button-login"), false);
                    },
                    validate: function ()
                    {
                        var $this = p.user.forms;

                        var fieldUsername = $("#login-username");
                        var fieldPassword = $("#login-password");

                        $this.setDisabled(fieldUsername, true);
                        $this.setDisabled(fieldPassword, true);
                        $this.setDisabledButton($("#button-login"), true, true);
                        p.user.forms.setSPMError(false);

                        var hasErrors = false;

                        if ($this.value(fieldUsername).length == 0)
                        {
                            $this.setError(fieldUsername, LOCALE["ERROR_EMPTY_FIELD"]);
                            hasErrors = true;
                        }

                        if ($this.value(fieldPassword).length == 0)
                        {
                            $this.setError(fieldPassword, LOCALE["ERROR_EMPTY_FIELD"]);
                            hasErrors = true;
                        }

                        if (hasErrors)
                        {
                            $this.setDisabled(fieldUsername, false);
                            $this.setDisabled(fieldPassword, false);
                            $this.setDisabledButton($("#button-login"), false);
                            return;
                        }

                        $this.setError(fieldUsername, null);
                        $this.setError(fieldPassword, null);

                        p.user.Login($this.value(fieldUsername), $this.value(fieldPassword));
                    },
                    onLoginError: function (e)
                    {
                        var $this = p.user.forms;
                        var fieldUsername = $("#login-username");
                        var fieldPassword = $("#login-password");
                        $this.setError(fieldUsername, e);
                        $this.setError(fieldPassword, e);

                        $this.setDisabled(fieldUsername, false);
                        $this.setDisabled(fieldPassword, false);
                        $this.setDisabledButton($("#button-login"), false);
                    }
                },
                register:
                {
                    fields: [],
                    light_fields: [],
                    custom_fields: [],
                    all_fields: [],
                    do_custom: false,
                    spm:
                    {
                        is_light_registration: false,
                        spm_abonummer: null,
                        spm_kundennummer: null
                    },

                    regex:
                    {
                        username: /[a-zA-Z0-9_\-\.]*/,
                        email: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i
                    },

                    init: function ()
                    {
                        console.log("init register");

                        this.fields["username"] = $("#register-username");
                        this.fields["password"] = $("#register-password");
                        this.fields["password-repeat"] = $("#register-password-repeat");
                        this.fields["email"] = $("#register-email");
                        this.fields["firstname"] = $("#register-firstname");
                        this.fields["lastname"] = $("#register-lastname");
                        this.fields["agree-terms"] = $("#register-agree-terms");
                        this.fields["agree-privacy"] = $("#register-agree-privacy");

                        for (var i in this.fields)
                            this.all_fields.push(this.fields[i]);

                        if (p.sonderlocke("SPM_LOGIN") && SONDERLOCKE["SPM_LOGIN"] == true)
                        {
                            this.light_fields["password"] = $("#register-light-password");
                            this.light_fields["password-repeat"] = $("#register-light-password-repeat");
                            this.light_fields["email"] = $("#register-light-email");

                            for (var i in this.light_fields)
                                this.all_fields.push(this.light_fields[i]);
                        }

                        this.do_custom = $("#register-require-custom").length > 0;

                        console.log(CUSTOMDATA, this.do_custom);
                        if (CUSTOMDATA && this.do_custom)
                        {
                            this.custom_fields = p.user.getCustomFields();

                            console.log(this.custom_fields);

                            for (var $key in this.custom_fields)
                            {
                                this.all_fields.push(this.custom_fields[$key].control);
                                // this.custom_fields[$key].control.attr("cross-fade", "");
                                this.custom_fields[$key].control.insertBefore("#legal-fields");
                            }
                            $('select').formSelect();
                            // $("input.datepicker").pickadate({
                            //     // selectMonths: true, // Creates a dropdown to control month
                            //     // selectYears: 15, // Creates a dropdown of 15 years to control year,
                            //     today: 'Today',
                            //     clear: 'Clear',
                            //     close: 'Ok',
                            //     editable: true,
                            //     closeOnSelect: false // Close upon selecting a date,
                            //   });
                        }

                        $("#button-register").on("click", p.user.forms.register.delayedValidation);

                        if (p.sonderlocke("SPM_LOGIN") && SONDERLOCKE["SPM_LOGIN"] == true)
                            $("#button-register-light").on("click", p.user.forms.register.delayedValidationLight);
                    },
                    free: function ()
                    {
                        var $this = p.user.forms;
                        $this.register.disableAll(false);
                    },
                    delayedValidation: function ()
                    {
                        setTimeout(p.user.forms.register.validate, 100);
                    },
                    delayedValidationLight: function ()
                    {
                        if (p.sonderlocke("SPM_LOGIN") && SONDERLOCKE["SPM_LOGIN"] == true)
                        {
                            setTimeout(p.user.forms.register.validateLight, 100);
                        }
                    },
                    disableAll: function (disabled)
                    {
                        for (var i in p.user.forms.register.all_fields)
                        {
                            p.user.forms.setDisabled(p.user.forms.register.all_fields[i], disabled);
                        }
                    },
                    unerrorAll: function ()
                    {
                        p.user.forms.setSPMError(false);
                        for (var i in p.user.forms.register.all_fields)
                        {
                            p.user.forms.setError(p.user.forms.register.all_fields[i], null);
                        }
                    },
                    validate: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.register;
                        $this.register.disableAll(true);
                        $this.register.unerrorAll();

                        var hasError = false;

                        //#region Validate static fields

                        var valUsername = $this.value($$this.fields["username"]);
                        var valPassword = $this.value($$this.fields["password"]);
                        var valPassword_Repeat = $this.value($$this.fields["password-repeat"]);
                        var valEmail = $this.value($$this.fields["email"]);
                        var valFirstName = $this.value($$this.fields["firstname"]);
                        var valLastName = $this.value($$this.fields["lastname"]);

                        // console.log($this.value($$this.fields["agree-terms"]));

                        //#region Username
                        if (valUsername.length == 0)
                        {
                            $this.setError($$this.fields["username"], LOCALE["REGISTER_HINT_NO_USERNAME"]);
                            hasError = true;
                        }
                        else if (valUsername.length < 4)
                        {
                            $this.setError($$this.fields["username"], LOCALE["REGISTER_HINT_SHORT_USERNAME"]);
                            hasError = true;
                        }
                        else if ($$this.regex.username.exec(valUsername) != valUsername)
                        {
                            $this.setError($$this.fields["username"], LOCALE["REGISTER_HINT_BAD_USERNAME"]);
                            hasError = true;
                        }
                        //#endregion

                        //#region Password
                        if (valPassword.length == 0)
                        {
                            $this.setError($$this.fields["password"], LOCALE["REGISTER_HINT_NO_PASSWORD"]);
                            hasError = true;
                        }
                        else if (valPassword.length < 6)
                        {
                            $this.setError($$this.fields["password"], LOCALE["REGISTER_HINT_SHORT_PASSWORD"]);
                            hasError = true;
                        }
                        else if (valPassword != valPassword_Repeat)
                        {
                            $this.setError($$this.fields["password-repeat"], LOCALE["REGISTER_HINT_BAD_PASSWORD"]);
                            $this.setError($$this.fields["password"], LOCALE["REGISTER_HINT_BAD_PASSWORD"]);
                            hasError = true;
                        }
                        //#endregion

                        if (valFirstName.length == 0)
                        {
                            $this.setError($$this.fields["firstname"], LOCALE["REGISTER_HINT_NO_FIRSTNAME"]);
                            hasError = true;
                        }
                        if (valLastName.length == 0)
                        {
                            $this.setError($$this.fields["lastname"], LOCALE["REGISTER_HINT_NO_LASTNAME"]);
                            hasError = true;
                        }

                        //#region eMail

                        if (valEmail.length == 0)
                        {
                            $this.setError($$this.fields["email"], LOCALE["REGISTER_HINT_NO_EMAIL"]);
                            hasError = true;
                        }
                        else if ($$this.regex.email.exec(valEmail) != valEmail)
                        {
                            $this.setError($$this.fields["email"], LOCALE["REGISTER_HINT_BAD_EMAIL"]);
                            hasError = true;
                        }

                        //#endregion

                        //#region Custom fields

                        if (CUSTOMDATA && $$this.do_custom)
                        {
                            for (var $key in $$this.custom_fields)
                            {
                                var f = $$this.custom_fields[$key];

                                if (f.settings.required)
                                {
                                    //console.log($this.value(f.control));
                                    if ($this.value(f.control).length == 0)
                                    {
                                        $this.setError(f.control, LOCALE["REGISTER_HINT_NO_DEFAULT"]);
                                        hasError = true;
                                    }
                                }
                            }
                        }

                        //#endregion

                        //#region Legal

                        if (!$this.value($$this.fields["agree-terms"]))
                        {
                            $this.setError($$this.fields["agree-terms"], LOCALE["REGISTER_HINT_BAD_AGREEMENT"]);
                            hasError = true;
                        }
                        if (!$this.value($$this.fields["agree-privacy"]))
                        {
                            $this.setError($$this.fields["agree-privacy"], LOCALE["REGISTER_HINT_BAD_AGREEMENT"]);
                            hasError = true;
                        }

                        //#endregion

                        if (hasError)
                            return $this.register.disableAll(false);


                        $this.setDisabledButton($("#button-register"), true, true);
                        $$this.submit();

                        //#endregion
                    },

                    validateLight: function ()
                    {
                        if (p.sonderlocke("SPM_LOGIN") && SONDERLOCKE["SPM_LOGIN"] == true)
                        {
                            var $this = p.user.forms;
                            var $$this = $this.register;
                            $this.register.disableAll(true);
                            $this.register.unerrorAll();

                            var hasError = false;

                            var valPassword = $this.value($$this.light_fields["password"]);
                            var valPassword_Repeat = $this.value($$this.light_fields["password-repeat"]);
                            var valEmail = $this.value($$this.light_fields["email"]);

                            //#region Password
                            if (valPassword.length == 0)
                            {
                                $this.setError($$this.fields["password"], LOCALE["REGISTER_HINT_NO_PASSWORD"]);
                                hasError = true;
                            }
                            else if (valPassword.length < 6)
                            {
                                $this.setError($$this.fields["password"], LOCALE["REGISTER_HINT_SHORT_PASSWORD"]);
                                hasError = true;
                            }
                            else if (valPassword != valPassword_Repeat)
                            {
                                $this.setError($$this.fields["password-repeat"], LOCALE["REGISTER_HINT_BAD_PASSWORD"]);
                                $this.setError($$this.fields["password"], LOCALE["REGISTER_HINT_BAD_PASSWORD"]);
                                hasError = true;
                            }
                            //#endregion

                            //#region eMail

                            if (valEmail.length == 0)
                            {
                                $this.setError($$this.fields["email"], LOCALE["REGISTER_HINT_NO_EMAIL"]);
                                hasError = true;
                            }
                            else if ($$this.regex.email.exec(valEmail) != valEmail)
                            {
                                $this.setError($$this.fields["email"], LOCALE["REGISTER_HINT_BAD_EMAIL"]);
                                hasError = true;
                            }

                            //#endregion

                            if (hasError)
                                return $this.register.disableAll(false);


                            $this.setDisabledButton($("#button-register-light"), true, true);
                            $$this.submitLight();
                        }
                    },

                    submit: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.register;

                        var valUsername = $this.value($$this.fields["username"]);
                        var valPassword = $this.value($$this.fields["password"]);
                        var valEmail = $this.value($$this.fields["email"]);
                        var valFirstName = $this.value($$this.fields["firstname"]);
                        var valLastName = $this.value($$this.fields["lastname"]);

                        p.user.forms.register.spm.is_light_registration = false;
                        p.user.forms.register_confirm.email = valEmail;

                        var payload =
                        {
                            "user": valUsername,
                            "pass": p.md5(valPassword),
                            "firstname": valFirstName,
                            "lastname": valLastName,
                            "email": valEmail
                        };

                        if (CUSTOMDATA && $$this.do_custom)
                        {
                            var custom = {};
                            for (var $key in $$this.custom_fields)
                            {
                                var f = $$this.custom_fields[$key];
                                custom[$key] = $this.value(f.control);
                            }

                            payload["custom"] = custom;
                        }

                        p.net.Request(["do", "register"], payload, p.user.forms.register.onRegister);
                    },
                    submitLight: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.register;

                        var valPassword = $this.value($$this.light_fields["password"]);
                        var valEmail = $this.value($$this.light_fields["email"]);

                        p.user.forms.register.spm.is_light_registration = true;
                        p.user.forms.register_confirm.email = valEmail;

                        var payload =
                        {
                            "user": $this.value($("#login-username")),
                            "pass": p.md5(valPassword),
                            "email": valEmail
                        };

                        if (CUSTOMDATA && $$this.do_custom)
                        {
                            var custom = {};
                            for (var $key in $$this.custom_fields)
                            {
                                var f = $$this.custom_fields[$key];
                                custom[$key] = $this.value(f.control);
                            }

                            payload["custom"] = custom;
                        }

                        p.net.Request(["do", "register-light"], payload, p.user.forms.register.onRegister);
                    },

                    onRegister: function (success, data)
                    {
                        var $this = p.user.forms;
                        var $$this = $this.register;

                        if (success && data && data.success)
                        {
                            p.user.forms.goPage("/register/confirm/", LOCALE["REGISTER_CONFIRM"]);
                        }
                        else
                        {
                            if (data && data.error && data.error == "EXISTS")
                            {
                                if (p.user.forms.register.spm.is_light_registration)
                                {
                                    $this.setError($$this.light_fields["email"], LOCALE["REGISTER_HINT_EXISTS"]);
                                }
                                else
                                {
                                    $this.setError($$this.fields["username"], LOCALE["REGISTER_HINT_EXISTS"]);
                                    $this.setError($$this.fields["email"], LOCALE["REGISTER_HINT_EXISTS"]);
                                }
                            }

                            $this.register.disableAll(false);
                            $this.setDisabledButton($("#button-register"), false);
                            $this.setDisabledButton($("#button-register-light"), false);
                        }
                    }
                },
                register_confirm:
                {
                    email: null,
                    init: function ()
                    {
                        if ($("#button-confirm").length > 0)
                        {
                            // Keine "no confirm" sonderlocke
                            $("#button-confirm").on("click", p.user.forms.register_confirm.delayedValidation);
                        }

                        if ($("#link-resend").length > 0)
                        {
                            $("#link-resend").on("click", p.user.forms.register_confirm.resend);
                        }
                    },

                    startResendTimer: function ()
                    {
                        var $this = p.user.forms.register_confirm;
                        $this.stopResendTimer();
                        $this.resendTimer = setTimeout($this.showResend, 10000);
                    },
                    stopResendTimer: function ()
                    {
                        var $this = p.user.forms.register_confirm;
                        clearTimeout($this.resendTimer);
                    },

                    showResend: function ()
                    {
                        var $this = p.user.forms.register_confirm;
                        $("#spm-resend-confirmation").addClass("visible");
                        $this.stopResendTimer();
                    },
                    hideResend: function ()
                    {
                        var $this = p.user.forms.register_confirm;
                        $("#spm-resend-confirmation").removeClass("visible");
                        $this.startResendTimer();
                    },

                    resend: function ()
                    {
                        var $this = p.user.forms.register_confirm;
                        $this.hideResend();

                        p.net.Request(["do", "resend"], { email: $this.email });
                    },

                    free: function ()
                    {
                        var $this = p.user.forms;
                        var field = $("#register-confirm-code");
                        $this.setDisabled(field, false);
                        $this.setDisabledButton($("#button-confirm"), false);
                    },
                    delayedValidation: function ()
                    {
                        setTimeout(p.user.forms.register_confirm.validate, 100);
                    },
                    validate: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.register_confirm;

                        var field = $("#register-confirm-code");
                        var val = $this.value(field);

                        $this.setDisabled(field, true);

                        var hasError = false;

                        if (val.length == 0)
                        {
                            $this.setError(field, LOCALE["REGISTER_HINT_NO_DEFAULT"]);
                            hasError = true;
                        }
                        else if (val.length < 6)
                        {
                            $this.setError(field, LOCALE["REGISTER_CONFIRM_HINT_SHORT"]);
                            hasError = true;
                        }

                        if (hasError)
                        {
                            return $this.setDisabled(field, false);
                        }

                        $this.setDisabledButton($("#button-confirm"), true, true);

                        p.net.Request(["do", "confirm"], { user: $$this.email, code: val }, p.user.forms.register_confirm.onConfirm);

                    },
                    onConfirm: function (success, data)
                    {
                        var $this = p.user.forms;
                        var $$this = $this.register_confirm;

                        if (success && data && data.success)
                        {
                            p.user.forms.goPage("/register/finished/", LOCALE["REGISTER_DONE"]);
                        }
                        else
                        {
                            var field = $("#register-confirm-code");
                            $this.setError(field, LOCALE["REGISTER_CONFIRM_HINT_WRONG"]);
                            $this.setDisabled(field, false);
                            $this.setDisabledButton($("#button-confirm"), false);
                        }
                    }
                },
                register_finished:
                {
                    init: function ()
                    {
                        if (p.seamlessNavigation.FromApp)
                        {
                            $("#button-finish").html(LOCALE["REGISTER_GOTO_CLOSE"]);
                            //p.seamlessNavigation.Link_State($("#button-finish"), { cmd: ["close"], stack: "/close/" });
                            $("#button-finish").on("click", function ()
                            {
                                location.assign("close");
                            });
                        }
                        else
                        {
                            var state = p.seamlessNavigation.stateTemplate;
                            state.cmd = p.seamlessNavigation.currentState.cmd;
                            state.stack = "/login/";
                            state.animator = "login-animator";
                            state.selection = "/login/";
                            state.branded_body = true;
                            state.title = LOCALE["LOGIN"];

                            p.seamlessNavigation.Link_State($("#button-finish"), state);
                        }
                    }
                },
                recover_username:
                {
                    init: function ()
                    {
                        $("#button-recover-username").on("click", p.user.forms.recover_username.delayedValidation);

                        var state = p.seamlessNavigation.stateTemplate;
                        state.cmd = p.seamlessNavigation.currentState.cmd;
                        state.stack = "/login/";
                        state.animator = "login-animator";
                        state.selection = "/login/";
                        state.branded_body = true;
                        state.title = LOCALE["LOGIN"];
                        p.seamlessNavigation.Link_State($("#button-recover-username-sent"), state);
                    },
                    free: function ()
                    {
                        var $this = p.user.forms;
                        var field = $("#recover-username-email");
                        $this.setDisabledButton($("#button-recover-username"), false);
                        $this.setDisabled(field, false);
                    },
                    delayedValidation: function ()
                    {
                        setTimeout(p.user.forms.recover_username.validate, 100);
                    },
                    validate: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.recover_username;
                        var hasError = false;

                        var field = $("#recover-username-email");
                        var val = $this.value(field);

                        $this.setDisabled(field, true);

                        if (val.length == 0)
                        {
                            $this.setError(field, LOCALE["REGISTER_HINT_NO_EMAIL"]);
                            hasError = true;
                        }

                        if (hasError)
                            return $this.setDisabled(field, false);

                        $this.setDisabledButton($("#button-recover-username"), true, true);

                        p.net.Request(["do", "recover", "user"], { data: val }, p.user.forms.recover_username.onRequest);
                    },
                    onRequest: function (success, data)
                    {
                        var $this = p.user.forms;
                        var $$this = $this.recover_username;
                        var field = $("#recover-username-email");

                        if (success && data && data.success)
                        {
                            p.user.forms.goPage("/recover/username/sent/", LOCALE["LOST_USER"]);
                        }
                        else
                        {
                            $this.setError(field, LOCALE["RECOVER_USERNAME_HINT_BAD"]);
                            $this.setDisabledButton($("#button-recover-username"), false);
                            $this.setDisabled(field, false);
                        }
                    }
                },
                recover_password:
                {
                    init: function ()
                    {
                        $("#button-recover-password").on("click", p.user.forms.recover_password.delayedValidation);

                        if (p.seamlessNavigation.FromApp)
                        {
                            $("#button-recover-password-sent").html(LOCALE["REGISTER_GOTO_CLOSE"]);
                            //p.seamlessNavigation.Link_State($("#button-recover-password-sent"), { cmd: ["close"], stack: "/close/" });
                            $("#button-recover-password-sent").on("click", function ()
                            {
                                location.assign("close");
                            });
                        }
                        else
                        {
                            var state = p.seamlessNavigation.stateTemplate;
                            state.cmd = p.seamlessNavigation.currentState.cmd;
                            state.stack = "/login/";
                            state.animator = "login-animator";
                            state.selection = "/login/";
                            state.branded_body = true;
                            state.title = LOCALE["LOGIN"];
                            p.seamlessNavigation.Link_State($("#button-recover-password-sent"), state);
                        }
                    },
                    free: function ()
                    {
                        var $this = p.user.forms;
                        var field = $("#recover-password-email");
                        $this.setDisabledButton($("#button-recover-password"), false);
                        $this.setDisabled(field, false);
                    },
                    delayedValidation: function ()
                    {
                        setTimeout(p.user.forms.recover_password.validate, 100);
                    },
                    validate: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.recover_password;
                        var hasError = false;

                        var field = $("#recover-password-email");
                        var val = $this.value(field);

                        $this.setDisabled(field, true);

                        if (val.length == 0)
                        {
                            $this.setError(field, LOCALE["REGISTER_HINT_NO_EMAIL"]);
                            hasError = true;
                        }

                        if (hasError)
                            return $this.setDisabled(field, false);

                        $this.setDisabledButton($("#button-recover-password"), true, true);

                        p.net.Request(["do", "recover", "password"], { data: val }, p.user.forms.recover_password.onRequest);
                    },
                    onRequest: function (success, data)
                    {
                        var $this = p.user.forms;
                        var $$this = $this.recover_password;
                        var field = $("#recover-password-email");

                        if (success && data && data.success)
                        {
                            p.user.forms.goPage("/recover/password/sent/", LOCALE["LOST_PASS"]);
                        }
                        else
                        {
                            $this.setError(field, LOCALE["RECOVER_PASSWORD_HINT_BAD"]);
                            $this.setDisabledButton($("#button-recover-password"), false);
                            $this.setDisabled(field, false);
                        }
                    }
                },
                reset_password:
                {
                    hash: null,
                    user: null,
                    init: function ()
                    {
                        $("#button-reset-password").on("click", p.user.forms.reset_password.delayedValidation);
                    },
                    delayedValidation: function ()
                    {
                        setTimeout(p.user.forms.reset_password.validate, 100);
                    },
                    validate: function ()
                    {
                        var $this = p.user.forms;
                        var $$this = $this.reset_password;
                        var hasError = false;

                        var p1 = $("#reset-password");
                        var p2 = $("#reset-password-repeat");

                        var v1 = $this.value(p1);
                        var v2 = $this.value(p2);

                        $this.setDisabled(p1, true);
                        $this.setDisabled(p2, true);
                        $this.setDisabledButton($("#button-reset-password"), true, true);

                        if (v1.length == 0)
                        {
                            $this.setError(p1, LOCALE["REGISTER_HINT_NO_PASSWORD"]);
                            hasError = true;
                        }
                        else if (v1.length < 6)
                        {
                            $this.setError(p1, LOCALE["REGISTER_HINT_SHORT_PASSWORD"]);
                            hasError = true;
                        }
                        else if (v1 != v2)
                        {
                            $this.setError(p2, LOCALE["REGISTER_HINT_BAD_PASSWORD"]);
                            $this.setError(p1, LOCALE["REGISTER_HINT_BAD_PASSWORD"]);
                            hasError = true;
                        }

                        if (hasError)
                        {
                            $this.setDisabled(p1, false);
                            $this.setDisabled(p2, false);
                            $this.setDisabledButton($("#button-reset-password"), false);
                            return;
                        }

                        p.net.Request(["do", "reset"],
                            {
                                hash: $$this.hash,
                                user: $$this.user,
                                pass: p.md5(v1)
                            },
                            p.user.forms.reset_password.onReset);
                    },
                    onReset: function (success, data)
                    {
                        var $this = p.user.forms;
                        var $$this = $this.reset_password;

                        var p1 = $("#reset-password");
                        var p2 = $("#reset-password-repeat");

                        if (success && data && data.success)
                        {
                            p.user.forms.goPage("/login/", LOCALE["LOGIN"]);
                        }
                        else
                        {
                            $this.setError(p2, LOCALE["RECOVER_PASSWORD_RESET_BAD"]);
                            $this.setError(p1, null);
                            $this.setDisabled(p1, false);
                            $this.setDisabled(p2, false);
                            $this.setDisabledButton($("#button-reset-password"), false);
                        }
                    }
                }

            },

            PrepareForms: function (stack)
            {
                var selectorLogin = $("#page-login");
                var selectorRegister = $("#page-register-main");
                var selectorRegisterConfirm = $("#page-register-confirm");
                var selectorRegisterFinished = $("#page-register-finished");
                var selectorRecoverUsername = $("#page-recover-username");
                var selectorRecoverPassword = $("#page-recover-password");
                var selectorResetPassword = $("#page-reset-password");

                var hasLogin = !!selectorLogin.length;
                var hasRegistration = !!selectorRegister.length;
                var hasRegistrationConfirmation = !!selectorRegisterConfirm.length;
                var hasRegistrationFinished = !!selectorRegisterFinished.length;
                var hasRecoverUsername = !!selectorRecoverUsername.length;
                var hasRecoverPassword = !!selectorRecoverPassword.length;
                var hasResetPassword = !!selectorResetPassword.length;

                //#region Inits
                if (hasLogin && !this.isInited(selectorLogin))
                {
                    p.user.forms.login.init();
                    this.setInited(selectorLogin);
                }

                if (hasRegistration && !this.isInited(selectorRegister))
                {
                    p.user.forms.register.init();
                    this.setInited(selectorRegister);
                }

                if (hasRegistrationConfirmation && !this.isInited(selectorRegisterConfirm))
                {
                    p.user.forms.register_confirm.init();
                    this.setInited(selectorRegisterConfirm);
                }

                if (hasRegistrationFinished && !this.isInited(selectorRegisterFinished))
                {
                    p.user.forms.register_finished.init();
                    this.setInited(selectorRegisterFinished);
                }

                if (hasRecoverUsername && !this.isInited(selectorRecoverUsername))
                {
                    p.user.forms.recover_username.init();
                    this.setInited(selectorRecoverUsername);
                }

                if (hasRecoverPassword && !this.isInited(selectorRecoverPassword))
                {
                    p.user.forms.recover_password.init();
                    this.setInited(selectorRecoverPassword);
                }

                if (hasResetPassword && !this.isInited(selectorResetPassword))
                {
                    p.user.forms.reset_password.init();
                    this.setInited(selectorResetPassword);
                }
                //#endregion

                if (stack.length >= 3)
                {
                    if (stack[0] == "register" && stack[1] == "confirm")
                    {
                        if (stack[2].length > 0)
                        {
                            // We're coming back to confirm our account
                            p.user.forms.register_confirm.email = stack[2];
                        }
                    }

                    if (stack.length >= 5)
                    {
                        if (stack[0] == "recover")
                        {
                            if (stack[1] == "password" && stack[2] == "reset")
                            {
                                if (stack[3].length > 0 && stack[4].length > 0)
                                {
                                    p.user.forms.reset_password.hash = stack[3];
                                    p.user.forms.reset_password.user = stack[4];
                                }
                            }
                        }
                    }
                }

                p.common.transitionLoader.hide();
            },

            //testcounter: 0,
            RequestExtraInfo: function (onFinished)
            {
                //p.debugpanel(++p.user.testcounter);
                var infoDone = false;
                var onModal = function (success, data)
                {
                    if (!!success && !!data.success)
                    {
                        $("body").append(data["modal-html"].trim());

                        setTimeout(function ()
                        {
                            $("#userinfo-modal").modal();
                            $("#userinfo-modal").on("hidden.bs.modal", function ()
                            {
                                if (infoDone == false)
                                {
                                    if (p.seamlessNavigation.FromApp)
                                        p.seamlessNavigation.Push(null, null, "/cancel/", true);

                                    location.reload();
                                }

                                $("#userinfo-modal").remove();
                            });

                            var fields = p.user.getCustomFields();
                            $("#userinfo-form").html("");
                            for (var key in fields)
                            {
                                var container = $div({ "class": "form-group" });
                                container.append(fields[key].control);
                                $("#userinfo-form").append(container);
                            }

                            var setLocked = function (locked)
                            {
                                for (var key in fields)
                                {
                                    var field = fields[key];

                                    if (field.settings.allow_custom)
                                    {
                                        field.control.children().attr("disabled", locked);
                                    }
                                    else
                                    {
                                        field.control.attr("disabled", locked);
                                    }
                                }
                            };

                            var sendUserinfo = function (info)
                            {
                                p.net.Request(["do", "edit-user"], {
                                    user: p.user.userdata.username,
                                    custom: info
                                }, function (success, data)
                                {
                                    if (success && data && data.success && !!data.success)
                                    {
                                        infoDone = true;
                                        $("#userinfo-modal").modal("hide");

                                        if (onFinished && p.is.function(onFinished))
                                            onFinished();
                                    }
                                });
                            };

                            var doSave = function ()
                            {
                                setLocked(true);
                                var hasErrors = false;

                                for (var key in fields)
                                {
                                    var field = fields[key];
                                    var thisError = false;

                                    if (field.settings.required)
                                    {
                                        if (field.settings.allow_custom)
                                        {
                                            var selectBox = field.control.children("select");
                                            var customField = field.control.children("input");

                                            if (selectBox.val().trim().length == 0)
                                            {
                                                field.setError(true);
                                                hasErrors = true;
                                                thisError = true;
                                            }
                                            else if (selectBox.val().trim() == "__CUSTOM__" && customField.val().trim().length == 0)
                                            {
                                                field.setError(true);
                                                hasErrors = true;
                                                thisError = true;
                                            }
                                        }
                                        else
                                        {
                                            if (field.control.val().trim().length == 0)
                                            {
                                                field.setError(true);
                                                hasErrors = true;
                                                thisError = true;
                                            }
                                        }
                                    }

                                    if (thisError == false) field.setError(false);

                                }

                                if (hasErrors)
                                {
                                    setLocked(false);
                                }
                                else
                                {
                                    var values = {};
                                    for (var key in fields)
                                    {
                                        var field = fields[key];

                                        if (field.settings.allow_custom)
                                        {
                                            var selectBox = field.control.children("select");
                                            var customField = field.control.children("input");

                                            if (selectBox.val().trim() == "__CUSTOM__")
                                            {
                                                values[key] = customField.val().trim();
                                            }
                                            else
                                            {
                                                values[key] = selectBox.val().trim();
                                            }
                                        }
                                        else
                                        {
                                            values[key] = field.control.val().trim();
                                        }
                                    }

                                    // console.log(values);
                                    $("#userinfo-do").button("loading");
                                    sendUserinfo(values);
                                }
                            };

                            $("#userinfo-do").on("click", function ()
                            {
                                doSave();

                                //$("#userinfo-modal").modal("hide");

                                //if (onFinished && p.is.function(onFinished))
                                //    onFinished();
                            });
                        }, 100);
                    }
                };

                p.net.Request(["usermodal"], {}, onModal);
            },

            Login: function (user, pass)
            {
                p.common.showLoader();
                p.net.Request(["do", "login"], { "user": user, "pass": p.md5(pass) }, this.onLogin);
            },

            Logout: function ()
            {
                p.common.showLoader();
                p.net.Request(["do", "logout"], {}, this.onLogout);
            },

            onLogout: function (success, data)
            {
                p.common.hideLoader();
                if (success)
                {
                    if (data.success == true)
                    {
                        p.user.is_logged_in = false;
                        p.user.Init();

                        p.books.invalidateLoadState();
                        p.books.load();
                        p.seamlessNavigation.Reload();
                        p.tracking.sessionEnd();
                        p.tracking.sessionStart();
                    }
                }
                else
                {
                    p.trigger("logout_error");
                }
            },

            onLogin: function (success, data)
            {
                p.common.hideLoader();
                if (success)
                {
                    if (data.success == true)
                    {
                        p.books.invalidateLoadState();
                        p.books.load();
                        p.user.Init();

                        //$("body").removeClass("branded");
                        //if (location.hash)
                        //{
                        //    var goto = location.hash.substring(1);
                        //    return location.assign(goto);
                        //}

                        //if (!p.seamlessNavigation.lastBase || p.seamlessNavigation.lastBase.length == 0)
                        //    p.seamlessNavigation.Push(null, null, "/");
                        //else
                        //    p.seamlessNavigation.Push(null, null, p.seamlessNavigation.lastBase);

                        var fromStack = p.seamlessNavigation.currentState && p.seamlessNavigation.currentState.from_state && p.seamlessNavigation.currentState.from_state.stack_array;
                        var fromLoginLoop = true;

                        if (fromStack)
                            fromLoginLoop = fromStack[0] == "login" || fromStack[0] == "register" || fromStack[0] == "recover";

                        if (fromLoginLoop)
                        {
                            p.seamlessNavigation.Push({ cmd: null });
                        }
                        else
                        {
                            p.seamlessNavigation.Push(p.seamlessNavigation.currentState.from_state);
                        }
                    }
                    else
                    {
                        if (data.error && data.error == "NEEDS_CONFIRMATION")
                        {
                            //p.user.userdata = { username: data.user, email: data.email };
                            p.user.forms.register_confirm.email = data.email;
                            p.user.forms.goPage("/register/confirm/", LOCALE["REGISTER_CONFIRM"]);
                        }
                        else if (data.error && data.error == "SPM_COMPLETION_REQUIRED")
                        {
                            p.user.forms.goPage("/register/light/", LOCALE["REGISTER"]);
                            var fieldUsername = $("#login-username");
                            var fieldPassword = $("#login-password");
                            p.user.forms.setDisabled(fieldUsername, false);
                            p.user.forms.setDisabled(fieldPassword, false);
                            p.user.forms.setDisabledButton($("#button-login"), false);
                        }
                        else if (data.error && data.error == "SPM_ALREADY_CONVERTED")
                        {
                            p.user.forms.login.onLoginError("");
                            p.user.forms.setSPMError(true, LOCALE["SPM_ALREADY_CONVERTED"]);
                        }
                        else
                        {
                            //p.user.FormError(LOCALE["ERROR_BAD_USER"]);
                            p.user.forms.login.onLoginError(LOCALE["ERROR_BAD_USER"]);
                        }
                    }
                }
                else
                {
                    p.trigger("login_error");
                }
            },

            onGetInfo: function (success, data)
            {
                if (data.is_logged_in)
                {
                    p.user.is_logged_in = true;
                    p.user.userdata = data.user;
                    p.tracking.queue("USER_LOGIN", {});
                }
                else
                {
                    p.user.is_logged_in = false;
                    p.user.userdata = { username: "anonymous" };
                }

                if (p.books)
                    p.books.load();

                p.trigger("user_status_get", data);
            },

            renderStatus: function ()
            {
                //var $box = $("#user");
                //$box.html("");

                //if (!$box.prop("materialized"))
                //{
                //    p.material.raiseOnHover($box);
                //    $box.prop("materialized", true);
                //}

                // var newButton = p.paper.button("", false, false, false, "branded text");
                // newButton.attr("self-center", "");
                // newButton.attr("id", "menu-login");
                // $("#menu-login").replaceWith(newButton);

                var newButton = $("<a>", {
                    "id": "menu-login",
                    "class": "btn text waves-effect"
                });
                $("#menu-login").replaceWith(newButton);

                if (p.user.is_logged_in)
                {
                    if (p.sonderlocke("SPM_SUB_INFO_BOX")) $("#spm_aboinfo").addClass("hidden");
                    if (p.sonderlocke("HIDE_LOGIN_BUTTON")) $("#menu-login").removeClass("hidden"); // && $(document.querySelector("#menu-login + div.separator")).removeClass("hidden");

                    $("#menu-login").html(LOCALE["SIGNOUT"]);
                    $("#menu-login").on("click", function () { p.user.Logout(); });

                    $("#menu-account").removeClass("hidden");

                    p.seamlessNavigation.Link_State($("#menu-account"),
                        {
                            cmd: ["account"],
                            stack: "/account/",
                            //target: "#content-tertiary .content",
                            //animator: "animator-content",
                            //selection: "2",
                            title: LOCALE["MY_ACCOUNT"]
                        });
                }
                else
                {
                    if (p.sonderlocke("SPM_SUB_INFO_BOX")) $("#spm_aboinfo").removeClass("hidden");

                    $("#menu-login").html(LOCALE["SIGNIN"]);
                    $("#menu-account").addClass("hidden");
                    p.seamlessNavigation.Link_State($("#menu-login"),
                        {
                            cmd: ["login"],
                            stack: "/login/",
                            branded_body: true,
                            title: LOCALE["LOGIN"],
                            animator: "login-animator",
                            selection: "/login/"
                        });

                    if (p.sonderlocke("HIDE_LOGIN_BUTTON")) $("#menu-login").addClass("hidden");// && $(document.querySelector("#menu-login + div.separator")).addClass("hidden");
                }

                if (p.sonderlocke("SPM_SUB_INFO_BOX"))
                {
                    var abocontainer = $("#spm_aboinfo");
                    if (!abocontainer.prop("attached"))
                    {
                        $("#abo_handle_button, #spm_aboclose, #spm_aboinfo_background").on("click", function ()
                        {
                            $("#abo_bubble").toggleClass("expanded");
                        });

                        p.seamlessNavigation.Link_State($("#spm_abobutton"),
                            {
                                cmd: ["login"],
                                stack: "/login/",
                                branded_body: true,
                                title: LOCALE["LOGIN"],
                                animator: "login-animator",
                                selection: "/login/"
                            });

                        abocontainer.prop("attached", true);

                    }
                }

                if (p.sonderlocke("APP_BAR"))
                {
                    var appbarcontainer = $("#app-bar");
                    if (!appbarcontainer.prop("attached"))
                    {
                        $("#appbar-btn-start").on("click", function ()
                        {
                            p.polymer.AnimateTo("#appbar-animator", 1);
                        });

                        $("#appbar-btn-android").on("click", function ()
                        {
                            window.open("https://play.google.com/store/apps/details?id=" + SONDERLOCKE.APP_BAR_DATA.android.id, "_blank");
                            p.polymer.AnimateTo("#appbar-animator", 0);
                        });

                        $("#appbar-btn-iphone").on("click", function ()
                        {
                            window.open(SONDERLOCKE.APP_BAR_DATA.ios.phone.url, "_blank");
                            p.polymer.AnimateTo("#appbar-animator", 0);
                        });
                        $("#appbar-btn-ipad").on("click", function ()
                        {
                            window.open(SONDERLOCKE.APP_BAR_DATA.ios.tablet.url, "_blank");
                            p.polymer.AnimateTo("#appbar-animator", 0);
                        });

                        $("#appbar-btn-winphone").on("click", function ()
                        {
                            window.open(SONDERLOCKE.APP_BAR_DATA.windows.phone.url, "_blank");
                            p.polymer.AnimateTo("#appbar-animator", 0);
                        });
                        $("#appbar-btn-winapp").on("click", function ()
                        {
                            window.open(SONDERLOCKE.APP_BAR_DATA.windows.tablet.url, "_blank");
                            p.polymer.AnimateTo("#appbar-animator", 0);
                        });

                        appbarcontainer.prop("attached", true);
                    }
                }

                if (p.sonderlocke("LANGUAGE_SWITCH"))
                {
                    // #menu-language
                    $("#menu-language").dropdown();
                }
            }
        }
    };

    exHelp.extend(module);
})(window);