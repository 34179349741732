(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let MaglifyReaderInit =
    {
        AdMan:
        {
            _ads: {},
            debug_tag: "ADMAN",

            AdObject: function (e, pageNo)
            {
                //console.log("Init", e);
                this.id = e.id;
                this.type = e.type;
                this.file = e.transitionAdItem;
                this.skippable = false;
                this.clickable = false;
                this.url = e.urlToLoadWhenClicked;
                this.time = 0;

                if (this.type == "VIDEO")
                {
                    this.skippable = e.allowSkipTransition;
                    this.time = parseInt(e.skipAfterSecs, 10);
                }
                else if (this.type == "IMAGE")
                {
                    this.skippable = true;
                    this.time = parseInt(e.imageDuration, 10);

                    //console.log(e.urlToLoadWhenClicked);
                    if (e.urlToLoadWhenClicked !== undefined)
                    {
                        this.clickable = true;
                        this.url = e.urlToLoadWhenClicked;
                        //console.log(this);
                    }
                }

                this.ad_time_started = -1;
                this.ad_expired = false;
                this.ad_running = false;
                this.ad_timeout = null;
                this.ad_interval = null;

                this.get_html = function ()
                {
                    var $ad_back = $div({ "class": "ad" });
                    var $ad_container = $div({ "class": "adcontainer" });
                    var $ad_wrapper = $div({ "class": "wrapper" });

                    $ad_back.append($ad_container);
                    $ad_container.append($ad_wrapper);

                    var $ad_bar = $div({ "class": "bar" });
                    var $ad_title = $div({ "class": "title", "html": LOCALE["AD_TITLE"] });
                    var $ad_info = $div({ "class": "info", "html": LOCALE["AD_TIMER"] });
                    var $ad_skip = p.paper.button(LOCALE["AD_CLOSE"], true, null, null, "branded fill invisible"); //$("<button>", { "class": "btn btn-primary invisible" });


                    //$ad_skip.html(LOCALE["AD_CLOSE"]);

                    $ad_wrapper.append($ad_title)
                        .append($ad_info)
                        .append($ad_bar)
                        .append($ad_skip);

                    var $ad_object = null;

                    if (this.type == "VIDEO")
                    {
                        /*
                            content: "<video src=\"{0}/api/web/?cmd=get/file/media&udid={1}&file={2}\" {3} {4} {5} />".format(
                            BASEPATH, p.reader.book_id, encodeURIComponent(overlay.content),
                            overlay.autoplay ? "autoplay" : "",
                            overlay.controls ? "controls" : "",
                            overlay.loop ? "loop" : "")
                        */

                        try
                        {
                            $ad_object = $("<video>", {
                                "class": "adobject",
                                //"src": "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}".format(BASEPATH, p.reader.book_id, encodeURIComponent(this.file)),
                                // "autoplay": "autoplay"
                                "preload": "none"
                            });

                            for (var mime in this.file)
                            {
                                var $source = $("<source>", {
                                    src: "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web&mime={3}".format(BASEPATH, p.reader.book_id, encodeURIComponent(this.file[mime]), mime),
                                    type: mime
                                });

                                $ad_object.append($source);
                                // break;
                                // $ad_object[0].play();
                            }
                        }
                        catch (x)
                        {
                            // IE + jQuery + <video> don't like each other, so create the thing with a full HTML string
                            // instead of relying on jQuery (something about .attr() not being supported
                            var workaround_string = "<video class=\"adobject\" autoplay=\"autoplay\" ><source src=\"{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web\" type=\"video/mp4\"></source></video>".format(BASEPATH, p.reader.book_id, encodeURIComponent(this.file));
                            $ad_object = $(workaround_string);
                        }

                        $ad_object.on("timeupdate",
                            function (event)
                            {
                                var perc = p.math.Percentage.XofY(this.currentTime, this.duration);
                                $ad_bar.css("width", perc + "%");
                            });
                    }
                    else if (this.type == "IMAGE")
                    {
                        $ad_object = $("<img>", {
                            "class": "adobject dynamic loading",
                            "src": "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web".format(BASEPATH, p.reader.book_id, encodeURIComponent(this.file)),
                        });

                        if (this.clickable)
                        {
                            $ad_object.addClass("clickable");
                        }
                    }

                    $ad_wrapper.append($ad_object).append(p.paper.spinner());
                    return $ad_back;
                };

                this.start = function (on_expire)
                {
                    //return;
                    if (!this.ad_running && !this.ad_expired)
                    {
                        //console.log("Start", this, e);
                        var $adObj = this.get_html();
                        $("body").append($adObj);

                        var $bar = $adObj.find(".bar");
                        var $title = $adObj.find(".title");
                        var $timer = $adObj.find(".info");
                        var $adObject = $adObj.find(".adobject");
                        var $adContainer = $adObj.find(".adcontainer");
                        var $skip = $adObj.find(".btn");

                        var naturalWidth = -1;
                        var naturalHeight = -1;

                        var $this = this;

                        var closeFnc = function ()
                        {
                            $this.ad_expired = true;
                            clearInterval($this.ad_interval);
                            clearTimeout($this.ad_timeout);

                            $adObj.removeClass("show");
                            $adObj.on("transitionend moztransitionend", function (e)
                            {
                                //console.log(e);
                                $adObj.remove();
                                p.AdMan.advertising = false;
                            });
                        };

                        var rescaleFnc = function ()
                        {
                            if (naturalWidth <= 0 || naturalHeight <= 0 || (naturalWidth == 320 && naturalHeight == 240))
                            {
                                if ($this.type == "VIDEO")
                                {

                                    naturalHeight = $adObject[0].videoHeight;
                                    naturalWidth = $adObject[0].videoWidth;
                                    if (naturalWidth <= 0 || naturalHeight <= 0)
                                    {
                                        naturalHeight = 240;
                                        naturalWidth = 320;
                                    }
                                }
                                else if ($this.type == "IMAGE")
                                {
                                    naturalHeight = $adObject[0].naturalHeight;
                                    naturalWidth = $adObject[0].naturalWidth;
                                }
                            }

                            // 40px margin on both sides
                            var availableWidth = $(window).innerWidth() - (40 * 2);
                            var currentWidth = naturalWidth; //$adObject.outerWidth();
                            var availableHeight = $(window).innerHeight() - (112 + (40 * 2));
                            var currentHeight = naturalHeight; //$adObject.outerHeight();

                            if (currentWidth > availableWidth || currentHeight > availableHeight)
                            {
                                var factor = 1.0;
                                var newWidth = currentWidth;
                                var newHeight = currentHeight;

                                while (newWidth > availableWidth || newHeight > availableHeight)
                                {
                                    factor = factor - 0.01;
                                    newWidth = currentWidth * factor;
                                    newHeight = currentHeight * factor;
                                }


                                $adObject.css("width", newWidth);
                                $adObject.css("height", newHeight);

                            }
                            else
                            {
                                $adObject.css("width", naturalWidth);
                                $adObject.css("height", naturalHeight);

                                //$adObject.css("width", "");
                                //$adObject.css("height", "");
                            }

                            //if (currentWidth > currentHeight)
                            //{
                            //    if (currentWidth > availableWidth)
                            //    {
                            //        // downscale
                            //        $adObject.css("width", availableWidth);
                            //    }
                            //    else if (availableWidth > currentWidth)
                            //    {
                            //        // Don't upscale
                            //        $adObject.css("width", "");
                            //    }
                            //}
                            //else
                            //{
                            //    if (currentHeight > availableHeight)
                            //    {
                            //        // downscale
                            //        $adObject.css("height", availableHeight);
                            //    }
                            //    else if (availableHeight > currentHeight)
                            //    {
                            //        // Don't upscale
                            //        $adObject.css("height", "");
                            //    }
                            //}
                        };

                        requestAnimationFrame(function ()
                        {
                            $adObj.addClass("show");
                        });

                        this.ad_running = true;
                        this.ad_time_started = moment().valueOf();
                        this.ad_expired = false;
                        p.AdMan.advertising = true;

                        var startFnc = function ()
                        {
                            $this.ad_time_started = moment().valueOf();
                            $adContainer.addClass("z-depth-3");
                            rescaleFnc();

                            p.tracking.queue("AD_OPEN", {
                                adId: $this.id,
                                folderId: p.reader.book_id,
                                type: $this.type,
                                pageNo: pageNo
                            });

                            clearInterval($this.ad_interval);
                            $this.ad_interval = setInterval(function ()
                            {
                                if (!jQuery.contains(document.documentElement, $adObj[0]))
                                    $("body").append($adObj);

                                rescaleFnc();

                                var timeExpired = moment().valueOf(); - $this.ad_time_started;
                                var timeLeft = ($this.time * 1000) - timeExpired;
                                timeLeft = parseInt((timeLeft / 1000).toFixed(0));


                                if (timeLeft >= 0)
                                {
                                    $timer.html(LOCALE["AD_TIMER"].format(Math.abs(timeLeft)));
                                    if ($this.type == "IMAGE")
                                    {
                                        var perc = p.math.Percentage.XofY(timeExpired / 1000, $this.time);
                                        if (perc > 100)
                                            perc = 100;
                                        $bar.css("width", perc + "%");
                                    }
                                }
                                else
                                {
                                    //if ($this.type == "VIDEO")
                                    //{
                                    if (!$adObj.prop("closelnkd"))
                                    {
                                        $title.addClass("down");
                                        $timer.addClass("invisible");
                                        $skip.removeClass("invisible");
                                        $skip.on("click", function ()
                                        {
                                            if ($this.type == "IMAGE")
                                            {
                                                p.tracking.queue("AD_CLOSE", {
                                                    adId: $this.id
                                                });
                                            }
                                            else
                                            {
                                                p.tracking.queue("AD_SKIP", {
                                                    adId: $this.id
                                                });
                                            }
                                            closeFnc();
                                        });
                                        $adObj.prop("closelnkd", true);
                                    }
                                    //}
                                }
                            }, 100);

                            clearTimeout($this.ad_timeout);
                            $this.ad_timeout = setTimeout(function ()
                            {
                                $this.ad_expired = true;
                                //if ($this.type == "IMAGE")
                                //{
                                //    closeFnc();
                                //}
                                if (on_expire && p.is.function(on_expire))
                                {
                                    on_expire();
                                }
                            }, $this.time * 1000);
                        };


                        if (this.type == "VIDEO")
                        {
                            $adObject.on("timeupdate",
                                function (event)
                                {
                                    if (this.currentTime == this.duration)
                                    {
                                        p.tracking.queue("AD_CLOSE", {
                                            adId: $this.id
                                        });
                                        closeFnc();
                                    }
                                });
                            $adObject[0].play();
                            var width = Math.max(320, $adObject[0].videoWidth);
                            var height = Math.max(240, $adObject[0].videoHeight);
                            $adObject.css("width", width + "px");
                            $adObject.css("height", height + "px");
                            startFnc();

                            $adObject.on("loadedmetadata", function ()
                            {
                                // console.log("ONMETA", $adObject[0]);
                                var width = Math.max(320, $adObject[0].videoWidth);
                                var height = Math.max(240, $adObject[0].videoHeight);
                                $adObject.css("width", width + "px");
                                $adObject.css("height", height + "px");
                            });

                        }
                        else if (this.type == "IMAGE")
                        {
                            if (this.clickable)
                            {
                                var $url = this.url;
                                //console.log($url);
                                $adObject.on("click", function ()
                                {
                                    window.open($url, "_blank");
                                });
                            }
                            $timer.html(LOCALE["AD_TIMER"].format(Math.abs($this.time)));
                            $adObject.on("load", function ()
                            {
                                $(this).removeClass("loading");
                                startFnc();
                            });
                        }
                    }
                };
            },

            feed: function (e)
            {
                // debugger;
                if (e)
                {
                    var max = Object.keys(e).length;
                    var now = 1;
                    for (; now <= max; now++)
                    {
                        //console.log(this.debug_tag, "Parsing page", now, "of", max);
                        var page = e[now];
                        if (page["ad"])
                        {
                            var ad = page.ad;
                            //console.log(this.debug_tag, "Ad found", ad);
                            this._ads[now] = new this.AdObject(ad, now);
                        }
                    }
                }
            },
            getAd: function (e)
            {
                if (e == "loading")
                    e = 1;

                return this._ads[e];
            },

            advertising: false
        },


        reader:
        {
            book_id: null,
            container: null,
            path: null,
            render_canvas: null,
            sound: null,

            dbg_log: [],
            dbg: function (a)
            {
                /*
                var $this = p.reader;
                $this.dbg_log.push(a);

                if ($this.dbg_log.length > 5)
                    $this.dbg_log.splice(0, 1);

                var ele = p.e("#dbgcontainer");
                if (!ele || ele.length == 0)
                {
                    ele = p.e("<div>", { "id": "dbgcontainer" }).appendTo($this.container);
                }

                ele.setHtml("<p>" + $this.dbg_log.join("</p><p>") + "</p>");
                */
            },

            settings:
            {
                language: "en",

                show_go_to_page: false,

                preload: false,

                custom_background: null,
                custom_backgound_mode: null,

                start_page: 1,
                no_pdf: false,

                slideshow_mode: false,
                slideshow_timing: 5,
                slideshow_controls: false,
                slideshow_pdf: false,

                page_mode: "auto",
                page_sound: 1,
                page_sound_volume: 1.0,

                show_zoom: true,
                enable_pinching: false,
                enable_double_tapping: false,
                enable_mousewheel_zoom: false,
                zoom_max: 4.0,
                zoom_min: "auto",
                zoom_doubleclick: 1.0,
                zoom_wheel_step: 0.1,
                zoom_button_step: 0.1,

                enable_dragging: true,
                enable_scrollbars: false,

                show_fullscreen: true,
                hide_in_fullscreen: true,

                show_invert: false,
                show_download: false,

                show_overview: true,
                overview_direction: "bottom",
                overview_overlay: false,
                overview_size: 150,
                overview_start_open: false,

                enable_keyboard_navigation: false,
                enable_swiping: false,
                enable_arrows: false,

                social_twitter: null,
                social_facebook: null,
                social_google: null,
                social_email: false,

                enable_printing: false,

                small_controls: false
            },

            control_assignments:
            {
                "bookshelf": "topleft-hor",
                "search": "topright-hor",
                "showarea": "topright-hor",
                "overview": "bottomright-ver",
                "fullscreen": "bottomright-ver",
                "invert": "topright-hor",
                "zoom-in": "topright-hor",
                "zoom-out": "topright-hor",
                "go-back": "left",
                "go-fwd": "right",
                "download": "bottomleft-hor",
                "page": "top",
                // "goto": "bottomleft-hor",
                "ss-back": "bottom",
                "ss-play": "bottom",
                "ss-pause": "bottom",
                "ss-fwd": "bottom"
            },

            elements:
                [
                    {
                        selector: ".content",
                        construct: "<div>",
                        options: { "class": "content", tabindex: 0 },

                        children:
                            [
                                {
                                    selector: ".pages",
                                    options: { "class": "pages" },
                                    children:
                                        [
                                            {
                                                selector: ".overlayer",
                                                options: { "class": "overlayer" }
                                            }
                                        ]
                                }
                            ]
                    },

                    {
                        selector: ".controls",
                        options: { "class": "controls" },
                        children:
                            [
                                {
                                    selector: ".topleft",
                                    options: { "class": "topleft" },
                                    children:
                                        [
                                            { selector: ".horizontal", options: { "class": "horizontal flex" } },
                                            { selector: ".vertical", options: { "class": "vertical flex" } }
                                        ]
                                },
                                {
                                    selector: ".topright",
                                    options: { "class": "topright" },
                                    children:
                                        [
                                            { selector: ".horizontal", options: { "class": "horizontal flex" } },
                                            { selector: ".vertical", options: { "class": "vertical flex" } }
                                        ]
                                },
                                {
                                    selector: ".bottomleft",
                                    options: { "class": "bottomleft" },
                                    children:
                                        [
                                            { selector: ".horizontal", options: { "class": "horizontal flex" } },
                                            { selector: ".vertical", options: { "class": "vertical flex" } }
                                        ]
                                },
                                {
                                    selector: ".bottomright",
                                    options: { "class": "bottomright" },
                                    children:
                                        [
                                            { selector: ".horizontal", options: { "class": "horizontal flex" } },
                                            { selector: ".vertical", options: { "class": "vertical flex" } }
                                        ]
                                },

                                {
                                    selector: ".top",
                                    options: { "class": "top flex horizontal center-content" }
                                },

                                {
                                    selector: ".left",
                                    options: { "class": "left flex vertical center-content" }
                                },

                                {
                                    selector: ".bottom",
                                    options: { "class": "bottom flex horizontal center-content" }
                                },

                                {
                                    selector: ".right",
                                    options: { "class": "right flex vertical center-content" }
                                }
                            ]
                    },

                    {
                        selector: ".overviews",
                        options: { "class": "overviews" }
                    },

                    {
                        selector: ".main-loader",
                        options: { "class": "main-loader loader" }
                    },

                    {
                        selector: ".status",
                        options: { "class": "status flex horizontal" },
                        children:
                            [
                                {
                                    selector: ".loader",
                                    options: { "class": "loader" },
                                    children: [{ selector: ".spinner", options: { "class": "spinner" } }]
                                },
                                {
                                    selector: ".text",
                                    options: { "class": "text", html: "__LOCALE__:LOADING_READER_PDF" }
                                },
                                {
                                    selector: ".finished-overlay",
                                    options: { "class": "finished-overlay hidden", html: "__LOCALE__:LOADED_READER_PDF" }
                                },
                                {
                                    selector: ".error-overlay",
                                    options: { "class": "error-overlay hidden", html: "__LOCALE__:ERROR" }
                                }
                            ]
                    },
                    {
                        selector: ".render-canvas",
                        construct: "<canvas>",
                        options: { "class": "render-canvas" }
                    }
                ],



            makeMessage: function (title, text, onButton)
            {
                var box = p.e("<div>", { "class": "message" });
                var eTitle = p.e("<div>", { "class": "title", html: title });
                var eText = p.e("<div>", { "class": "text", html: text });
                var eButton = p.e("<div>", { "class": "button", html: "OK" });
                eTitle.appendTo(box);
                eText.appendTo(box);
                eButton.appendTo(box);

                eButton.on("click", function ()
                {
                    box.remove();

                    if (onButton && p.is.function(onButton))
                    {
                        onButton();
                    }
                });

                return box;
            },

            loadAudioPath: function ()
            {
                return BASEPATH + "/sound/page-flip-";
                // var scriptTags = p.e("script");
                // var currentScript = null;
                // scriptTags.each(function ()
                // {
                //     if (this.src.contains("magalone."))
                //     {
                //         currentScript = this.src;
                //         return false;
                //     }
                // });

                // if (currentScript != null)
                // {
                //     p.pager.current_script = currentScript;
                //     var currentPath = p.pager.current_script.substr(0, p.pager.current_script.lastIndexOf("/") - 3) + "/sound/page-flip-";
                //     return currentPath;
                // }

                // return "./";
            },

            svgLoaded: false,
            loadSvgFilters: function ()
            {
                var invertFilter = '<svg version="1.1" xmlns:svg="http://www.w3.org/2000/svg" height="0"><filter id="invert"><feComponentTransfer><feFuncR type="table" tableValues="1 0"></feFuncR><feFuncG type="table" tableValues="1 0"></feFuncG><feFuncB type="table" tableValues="1 0"></feFuncB></feComponentTransfer></filter></svg>';
                p.e(invertFilter, { "style": "display:none;" }).appendTo("body");
                this.svgLoaded = true;
            },

            playPageSound: function ()
            {
                if (p.reader.sound != null)
                {
                    p.reader.sound[0].pause();
                    if (!isNaN(p.reader.sound[0].duration))
                    {
                        p.reader.sound[0].currentTime = 0;
                    }
                    p.reader.sound[0].play();
                }
            },

            get: function (e, defaultValue)
            {
                return defaultValue;
                var v = p.reader.container.getAttr("data-" + e);
                var d = defaultValue === undefined ? null : defaultValue;
                return v == null ? d : v;
            },

            getBoolean: function (e, defaultValue)
            {
                return defaultValue;
                var v = this.get(e);
                var d = defaultValue === undefined ? false : defaultValue;
                return v == null ? d : v == "true";
            },

            lastFullscreenElement: null,
            onFullscreenChange: function (evt)
            {
                var fullscreenElement = document.fullScreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
                var type = "";
                if (p.reader.lastFullscreenElement == null)
                {
                    type = "enter";
                }
                else
                {
                    type = "exit";
                }

                p.trigger("fullscreen", [type, fullscreenElement || p.reader.lastFullscreenElement]);
                p.reader.lastFullscreenElement = fullscreenElement;
            },

            init: function ()
            {
                var $this = p.reader, temp;
                var stack = window.location.href.split("/?/");
                var queryStack = stack[1].split("/");
                //console.log(queryStack);
                $this.book_id = queryStack[0];

                if (queryStack.length > 2)
                {
                    var argPage = parseInt(queryStack[2]);
                    if (!isNaN(argPage) && argPage > 0)
                        this.bookPage = argPage;
                    // console.log("PAGE", this.bookPage);
                }
                //console.log(queryStack);

                p.tracking.init();

                p.subscribe("ondata", this.onData);

                // Load all book data
                p.common.showLoader();
                var langKey = p.e("html").getAttr("lang");
                p.locale.set(langKey);
                p.storage("locale")[langKey] = LOCALE;





















                // var $this = this, temp;
                // debugger;
                this.container = p.e("#reader-container");

                if (this.container.length > 0)
                {
                    this.path = BASEPATH + "/data/" + this.book_id;
                }

                if (this.container.length > 0 && this.path != null && this.path.length > 0)
                {
                    // Get Config
                    // this.settings.language = this.get("language", "en");
                    // p.locale.set(this.settings.language);
                    var lastPage = parseInt(p.ls.get(this.book_id + "_page"));
                    if (queryStack.length > 1)
                    {
                        var argPage = parseInt(queryStack[1]);
                        if (argPage > 0)
                            lastPage = argPage;
                    }

                    this.settings.start_page = lastPage || parseInt(this.get("start-page", "1"));
                    this.settings.no_pdf = this.getBoolean("no-pdf", false);

                    this.settings.show_fullscreen = this.getBoolean("show-fullscreen", true);
                    this.settings.hide_in_fullscreen = true; // this.getBoolean("immersive-fullscreen", false);
                    this.settings.show_invert = true; //this.getBoolean("show-invert");

                    this.settings.show_overview = true; //this.getBoolean("show-thumbnails");
                    this.settings.overview_overlay = this.getBoolean("thumbnails-overlay");
                    this.settings.overview_direction = this.get("thumbnails-direction", "bottom");
                    this.settings.overview_size = parseInt(this.get("thumbnails-size", "150"));
                    this.settings.overview_start_open = this.getBoolean("thumbnails-start-open");

                    this.settings.show_zoom = true; //this.getBoolean("show-zoom");
                    this.settings.enable_pinching = true; //this.getBoolean("enable-pinching");
                    this.settings.enable_double_tapping = true; //this.getBoolean("enable-double-click");
                    this.settings.enable_mousewheel_zoom = true; //this.getBoolean("enable-mouse-wheel");
                    this.settings.zoom_wheel_step = parseInt(this.get("zoom-wheel", "10")) / 100;
                    this.settings.zoom_max = parseInt(this.get("zoom-max", "400")) / 100;
                    this.settings.zoom_min = (temp = this.get("zoom-min", "auto")) == "auto" ? "auto" : parseInt(temp) / 100;
                    this.settings.zoom_doubleclick = parseInt(this.get("zoom-doubleclick", "100")) / 100;

                    this.settings.enable_keyboard_navigation = this.getBoolean("enable-keyboard", true);
                    this.settings.enable_swiping = this.getBoolean("enable-swiping", true);
                    this.settings.enable_arrows = this.getBoolean("enable-arrows", true);

                    this.settings.preload = this.getBoolean("preload", true);//!
                    this.settings.show_download = this.getBoolean("show-download", false);
                    this.settings.show_go_to_page = this.getBoolean("show-go-to-page", false);//!

                    this.settings.slideshow_mode = this.getBoolean("slideshow", false);
                    this.settings.slideshow_timing = parseFloat(this.get("slideshow-time", "5"));
                    this.settings.slideshow_controls = this.getBoolean("slideshow-show-controls", false);
                    this.settings.slideshow_pdf = this.getBoolean("slideshow-use-pdf", false);

                    this.settings.small_controls = this.getBoolean("small-controls", false);

                    this.settings.page_sound = parseInt(this.get("page-sound", "1"));
                    this.settings.page_sound_volume = parseInt(this.get("page-sound-volume", "100")) / 100;

                    this.settings.page_mode = this.get("page-mode", "auto");
                    if (this.settings.page_mode != "auto")
                    {
                        if (this.settings.page_mode == "single")
                        {
                            p.pager.current_mode_manual = true;
                            p.pager.current_mode = p.pager.constant.SINGLE;
                        }
                        else if (this.settings.page_mode == "double")
                        {
                            p.pager.current_mode_manual = true;
                            p.pager.current_mode = p.pager.constant.DOUBLE;
                        }
                    }

                    //this.settings.enable_dragging = this.getBoolean("enable-dragging", true);//!
                    //this.settings.enable_scrollbars = this.getBoolean("enable-scrollbars");//!
                    /*

                    custom_background: null,
                    custom_backgound_mode: null,
                    
                    social_twitter: null,
                    social_facebook: null,
                    social_google: null,
                    social_email: false,

                    enable_printing: false,
                    */

                    // Do inits
                    // this.loadLocale(function ()
                    // {
                    $this.prepareElements();
                    document.addEventListener("fullscreenchange", p.reader.onFullscreenChange);
                    document.addEventListener("mozfullscreenchange", p.reader.onFullscreenChange);
                    document.addEventListener("webkitfullscreenchange", p.reader.onFullscreenChange);
                    document.addEventListener("MSFullscreenChange", p.reader.onFullscreenChange); // CASE SENSITIVE, yes I am serious

                    $this.render_canvas = $this.container.find(".render-canvas");

                    if ($this.settings.enable_scrollbars)
                    {
                        $this.container.find(".content").addClass("scrollbars");
                    }
                    if ($this.settings.small_controls)
                        $this.container.find(".controls").addClass("small");

                    if ($this.settings.slideshow_mode)
                    {
                        p.pager.current_mode_manual = true;
                        p.pager.current_mode = p.pager.constant.SINGLE;
                    }
                    if ($this.settings.page_sound > 0 && $this.settings.page_sound <= 20)
                    {
                        try
                        {
                            $this.sound = p.e("<audio>", { "preload": "auto" });
                        } catch (ex)
                        {
                            $this.sound = p.e("<audio>");
                        }
                        if ($this.sound != null)
                        {
                            var path = $this.loadAudioPath();

                            if ($this.sound[0].canPlayType("audio/mp3"))
                                var mp3 = p.e("<source>", { "src": path + $this.settings.page_sound + ".mp3", "type": "audio/mp3" }).appendTo($this.sound);
                            if ($this.sound[0].canPlayType("audio/aac"))
                                var aac = p.e("<source>", { "src": path + $this.settings.page_sound + ".aac", "type": "audio/aac" }).appendTo($this.sound);
                            if ($this.sound[0].canPlayType("audio/ogg"))
                                var ogg = p.e("<source>", { "src": path + $this.settings.page_sound + ".ogg", "type": "audio/ogg" }).appendTo($this.sound);
                            if ($this.sound[0].canPlayType("audio/wav"))
                                var wav = p.e("<source>", { "src": path + $this.settings.page_sound + ".wav", "type": "audio/wav" }).appendTo($this.sound);

                            $this.sound.appendTo($this.container);
                            try
                            {
                                $this.sound[0].volume = $this.settings.page_sound_volume;
                            }
                            catch (ex)
                            {
                                console.error("AUDIO is not supported by browser");
                                $this.sound = null;
                            }
                        }
                    }

                    // $this.checkFileExists($this.path + "/cover_small.jpg",
                    //     function (success)
                    //     {
                    //         if (success)
                    //         {

                    $this.prepareCover();
                    $this.prepareData();
                    $this.prepareControls();
                    //     }
                    // });




                    p.net.Request(["get", "book", $this.book_id], {}, function P5ReaderInit_RequestBookdata(success, data)
                    {
                        if (success)
                        {
                            if (data) // && data.hasOwnProperty("is_logged_in"))
                            {
                                //if (!data.is_logged_in)
                                //{
                                //    return p.reader.onNoUser();
                                //}

                                if (data.book.custom_data && p.is.string(data.book.custom_data) && data.book.custom_data.length > 0)
                                {
                                    data.book.custom_data = p.jsonParse(data.book.custom_data);
                                }

                                p.reader.bookData = data.book;
                            }
                        }

                        p.trigger("ondata");

                        p.common.hideLoader();
                    });

                    p.common.showLoader();
                    p.net.Request(["get", "web", $this.book_id], {}, function P5ReaderInit_RequestBookJSON(success, data)
                    {
                        if (success && data && data.success === true)
                        {
                            if (data) // && data.hasOwnProperty("is_logged_in"))
                            {
                                //if (!data.is_logged_in)
                                //{
                                //    return p.reader.onNoUser();
                                //}

                                p.reader.pageData = data.book[$this.book_id].pages;
                                p.AdMan.feed(p.reader.pageData);
                                p.pager.pages = p.reader.pageData;
                                p.overlays.init();
                                p.pager.init();
                                // p.Pager.refSize = p.reader.pageData[1].ref_size;

                                if (data.book[$this.book_id]["contents"])
                                    p.reader.contentData = data.book[$this.book_id].contents;
                            }
                        }
                        else
                        {
                            if (data)
                            {
                                if (data.error == "NOT_ALLOWED")
                                {
                                    return p.reader.onShelfError();
                                }
                            }

                            return p.reader.onFatalError();
                        }

                        p.trigger("ondata");

                        p.common.hideLoader();
                    });
                    var statusGetCb = function (e)
                    {
                        //if (!e)
                        //    return p.reader.onNoUser();
                        //else
                        p.reader.InitAfterLogin($this.book_id);

                        p.unsubscribe("user_status_get", statusGetCb);
                    };

                    p.subscribe("user_status_get", statusGetCb);
                    p.user.Init();
                    // });

                    p.common.transitionLoader.hide();
                }
            },
            checkFileExists: function (path, callback)
            {
                p.net.Request(path,
                    {
                        method: "HEAD",
                        finished: function (success, data, xhr)
                        {
                            if (callback && p.is.function(callback))
                                callback(success && xhr.status == 200);
                        }
                    });
            },

            InitAfterLogin: function (bookId)
            {
                // Local session data retrieval
                var localData = p.ls.get(p.user.userdata.username + "_" + bookId + "_data");

                this.sessionData =
                {
                    currentPage: 0,
                    zoom: 1.0,
                    bookmarks: [],
                    autoFitHeight: false,
                    autoFitWidth: false,
                    autoFitBoth: true,
                    showAreas: false
                };

                if (!localData)
                {
                }
                else
                {
                    //this.sessionData = p.jsonParse(localData);
                    $.extend(this.sessionData, p.jsonParse(localData));
                    this.applyLocalData();
                }
                this.saveLocalData();
                p.trigger("ondata");

                // P5 Reader Settings
                // localData = null;
                // localData = p.ls.get(p.user.userdata.username + "_" + "maglify_reader_settings");
                // this.readerData =
                //     {
                //         animation: 0,
                //         mode: p.Pager.CONST.MODE_DOUBLE,
                //         invert: false
                //     };
                // if (!localData)
                // {
                // }
                // else
                // {
                //     $.extend(this.readerData, p.jsonParse(localData));
                // }
                this.saveSettings();
                p.trigger("ondata");

                p.common.hideLoader();
            },

            saveLocalData: function ()
            {
                p.ls.set(p.user.userdata.username + "_" + this.bookId + "_data", p.jsonStringify(this.sessionData));
            },
            applyLocalData: function ()
            {
                if (this.sessionData)
                {
                    this.scale = this.sessionData.zoom;
                }
            },

            saveSettings: function ()
            {
                p.ls.set(p.user.userdata.username + "_" + "maglify_reader_settings", p.jsonStringify(this.readerData));
            },


            dataInterrupt: false,
            dataLoaded: false,
            dataLoadCount: 0,
            onData: function ()
            {
                p.reader.dataLoadCount++;

                if (p.reader.dataInterrupt) return;

                // if (p.reader.dataLoadCount >= 4 && (!p.reader.bookData || !p.reader.pageData || !p.reader.sessionData || !p.reader.readerData))
                // {
                //     p.reader.dataInterrupt = true;
                //     p.reader.onFatalError();
                //     return;
                // }

                if (!p.reader.bookData || !p.reader.pageData || !p.reader.sessionData || !p.reader.readerData)
                    return;

                if (p.reader.dataLoaded) return;
                p.reader.dataLoaded = true;

                var $this = p.reader;

                //console.log("All data loaded!");

                if ($this.bookPage != null)
                {
                    $this.sessionData.currentPage = $this.bookPage;
                    //console.log("Going to page", $this.bookPage);
                }

                p.reader.scale = $this.sessionData.zoom;

                $("body").addClass($this.bookData.color);
                //$("#book-name").html($this.bookData.name);

                document.title = p.reader.bookData.name + " - " + SITENAME;

                if ($this.bookData.is_purchased === false)
                {
                    // Is this a preview in the bookshelf?
                    if ($this.bookData.is_preview === true)
                    {
                        // Limit page count to preview pages
                        $this.bookData.pagecount.web = parseInt($this.bookData.preview.pagecount.web);
                    }
                    else
                    {
                        p.reader.dataInterrupt = true;
                        p.reader.onShelfError();
                        return;
                    }
                }

                // text content
                $($this.toolbar.page_max).html($this.bookData.pagecount.web);

                $($this.toolbar.zoom_label).html(($this.sessionData.zoom * 100).toFixed(0) + "%");
                $($this.toolbar.page_current).on("keyup", function (e) { p.reader.goToPage(e); });

                // Pagination
                $($this.toolbar.page_previous + ", .reader-flipper.previous").on("click", function (e)
                {
                    e.preventDefault();
                    //console.log(e);
                    p.reader.prevPage(function (e)
                    {
                        p.reader.fixPageNumDisplay(e);
                    });
                });
                $($this.toolbar.page_next + ", .reader-flipper.next").on("click", function (e)
                {
                    e.preventDefault();
                    p.Pager.nextPage(function (e)
                    {
                        p.reader.fixPageNumDisplay(e);
                    });
                });
                $(window).on("keyup", function (e)
                {
                    if (p.AdMan.advertising)
                        return;

                    if (e.keyCode == 37) //left
                    {
                        p.reader.prevPage();
                    }

                    if (e.keyCode == 39) // right
                    {
                        p.reader.nextPage();
                    }

                    if (e.keyCode == 107) // Plus +
                    {
                        p.reader.zoomIn();
                    }

                    if (e.keyCode == 109) // Minus -
                    {
                        p.reader.zoomOut();
                    }
                });

                //#region Zoom Tools

                $($this.toolbar.zoom_in).on("click", function (e) { e.preventDefault(); p.reader.zoomIn(); });
                $($this.toolbar.zoom_out).on("click", function (e) { e.preventDefault(); p.reader.zoomOut(); });

                $("a[value=\"zoom:fit\"]").on("click", function (e) { e.preventDefault(); p.reader.toggleZoomToFitAuto(true); });

                $("a[value=\"zoom:25\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(.25); });
                $("a[value=\"zoom:50\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(.50); });
                $("a[value=\"zoom:75\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(.75); });
                $("a[value=\"zoom:100\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(1.00); });
                $("a[value=\"zoom:125\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(1.25); });
                $("a[value=\"zoom:150\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(1.50); });
                $("a[value=\"zoom:200\"]").on("click", function (e) { e.preventDefault(); p.reader.zoomTo(2.00); });

                //#endregion

                //#region DisplayMode Tools
                $("a[value=\"display:single\"]").on("click", function (e)
                {
                    e.preventDefault();
                    p.reader.readerData.mode = p.Pager.settings.mode = p.Pager.CONST.MODE_SINGLE;
                    p.Pager.saveSession();
                    location.reload();
                });
                $("a[value=\"display:double\"]").on("click", function (e)
                {
                    e.preventDefault();
                    p.reader.readerData.mode = p.Pager.settings.mode = p.Pager.CONST.MODE_DOUBLE;
                    p.Pager.saveSession();
                    location.reload();
                });
                //#endregion

                //#region Accessibility
                $("a[value=\"accessibility:toggle\"]").on("click", function (e)
                {
                    e.preventDefault();
                    //p.reader.pages.setAccessibility(!p.reader.pages.accessibility_invert);

                    if (p.Pager.settings.accessibility == p.Pager.CONST.ACCESSIBILITY_INVERT)
                        p.Pager.settings.accessibility = p.Pager.CONST.ACCESSIBILITY_NONE;
                    else
                        p.Pager.settings.accessibility = p.Pager.CONST.ACCESSIBILITY_INVERT;

                    p.Pager.setAccessibility(p.Pager.settings.accessibility);

                    try
                    {
                        if ($("#tool-settings-dropdown")[0].$)
                        {
                            $("#tool-settings-dropdown")[0].$.scroller.style.height = "";
                            $("#tool-settings-dropdown")[0].$.scroller.style.width = "";
                        }
                    }
                    catch (x)
                    {
                    }

                    p.Pager.saveSession();
                });
                //#endregion

                $("#menu-back, #tool-back").on("click", function (e)
                {
                    e.preventDefault();
                    //p.common.showLoader();
                    p.common.transitionLoader.show(function () { window.location = BASEPATH; });
                });

                $($this.toolbar.previews).on("click", function (e)
                {
                    e.preventDefault();
                    var originTemplate = "right: {0}px;";

                    $("#previews-background").attr("style",
                        originTemplate.format(
                            (window.innerWidth - $(p.reader.toolbar.previews).offset().left)
                        )
                    );

                    $("#previews, #previews-background").toggleClass("show");
                    $($this.toolbar.previews).toggleClass("branded fill");
                });

                // $($this.toolbar.overlay_hint).on("click", function (e)
                // {
                //     e.preventDefault();
                //     p.reader.sessionData.showAreas = !p.reader.sessionData.showAreas;
                //     if (p.reader.sessionData.showAreas == true)
                //     {
                //         $("#content").addClass("areahint");
                //         $($this.toolbar.overlay_hint + " .material-icons").html("crop_din");
                //     }
                //     else
                //     {
                //         $("#content").removeClass("areahint");
                //         $($this.toolbar.overlay_hint + " .material-icons").html("crop_free");
                //     }
                //     p.reader.saveLocalData();
                // });

                // if (p.reader.sessionData.showAreas == true)
                // {
                //     $("#content").addClass("areahint");
                //     $($this.toolbar.overlay_hint + " .material-icons").html("crop_din");
                // }

                //p.reader.pages.Init();
                p.reader.InitPreviews();
                p.Pager.Init(p.reader.pathBuilder("full"));
                p.reader.InitBookmarks();
                p.reader.InitOverlays();
                p.reader.InitTouch();

                p.reader.fixPageNumDisplay(p.reader.sessionData.currentPage);
                //$(window).on("resize", p.reader.ToolbarWatcher);

                //#region Additionals (Contents & More)

                if (p.reader.contentData)
                {
                    p.reader.InitContents();
                }

                //#endregion
            },

            setProgress: function (e)
            {
                var status = p.e("div.status .text");
                if (e < 0)
                {
                    status.setHtml(p.locale.getString("LOADING"));
                }
                else if (e >= 0 && e < 100)
                {
                    status.setHtml(e.toFixed(0) + "%");
                }
                else if (e == "error")
                {
                    p.e(".error-overlay").removeClass("hidden");
                    setTimeout(function ()
                    {
                        p.e(".status").addClass("gone");
                    }, 1000);
                }
                else
                {
                    p.e(".finished-overlay").removeClass("hidden");
                    setTimeout(function ()
                    {
                        p.e(".status").addClass("gone");
                    }, 1000);
                }
            },

            prepareControls: function ()
            {
                // Make Controls
                var ctrl_initializers =
                {
                    "bookshelf": {
                        selector: ".ctrl.btn.bookshelf",
                        options: { "class": "ctrl btn bookshelf" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "arrow_back" } }
                            ],
                        tooltip: "__LOCALE__:READER_BACK"
                    },
                    "search": {
                        selector: ".ctrl.btn.search",
                        options: { "class": "ctrl btn search" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "search" } }
                            ],
                        tooltip: "__LOCALE__:SEARCH"
                    },
                    "fullscreen": {
                        selector: ".ctrl.btn.fullscreen",
                        options: { "class": "ctrl btn fullscreen" },
                        children:
                            [
                                { selector: ".icon.on", options: { "class": "icon on", html: "fullscreen_exit" } },
                                { selector: ".icon.off", options: { "class": "icon off", html: "fullscreen" } }
                            ]
                    },
                    "showarea": {
                        selector: ".ctrl.btn.showarea",
                        options: { "class": "ctrl btn showarea off" },
                        children:
                            [
                                { selector: ".icon.on", options: { "class": "icon on", html: "border_outer" } },
                                { selector: ".icon.off", options: { "class": "icon off", html: "border_clear" } }
                            ]
                    },
                    "overview": {
                        selector: ".ctrl.btn.overview",
                        options: { "class": "ctrl btn overview" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "view_module" } }
                            ],
                        tooltip: "__LOCALE__:TOOLTIP_PREVIEWS"
                    },
                    "invert": {
                        selector: ".ctrl.btn.invert",
                        options: { "class": "ctrl btn invert" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "invert_colors" } }
                            ],
                        tooltip: "__LOCALE__:READER_ACCESS_INVERT"
                    },
                    "zoom-in": {
                        selector: ".ctrl.btn.zoom-in",
                        options: { "class": "ctrl btn zoom-in" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "zoom_in" } }
                            ],
                        tooltip: "__LOCALE__:TOOLTIP_ZOOM_IN"
                    },
                    "zoom-out": {
                        selector: ".ctrl.btn.zoom-out",
                        options: { "class": "ctrl btn zoom-out" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "zoom_out" } }
                            ],
                        tooltip: "__LOCALE__:TOOLTIP_ZOOM_OUT"
                    },
                    "go-back": {
                        selector: ".ctrl.btn.go-back",
                        options: { "class": "ctrl btn go-back" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "keyboard_arrow_left" } }
                            ]
                    },
                    "go-fwd": {
                        selector: ".ctrl.btn.go-fwd",
                        options: { "class": "ctrl btn go-fwd" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "keyboard_arrow_right" } }
                            ]
                    },
                    "download": {
                        construct: "<a>",
                        selector: ".ctrl.btn.download",
                        options: { "class": "ctrl btn download", href: p.pager.getPDFPath(), "target": "_blank" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "file_download" } }
                            ]
                    },
                    "page": {
                        selector: ".ctrl.input.page",
                        options: { "class": "ctrl input page flex cols" },
                        children:
                            [
                                { construct: "<input>", selector: "input", options: { "class": "text-center", html: "" } },
                                { selector: ".spacer", options: { "class": "spacer text-center padded", html: "__LOCALE__:READER_OF" } },
                                { selector: ".max", options: { "class": "max text-center padded", html: "" } }
                            ]
                    },


                    /// SLIDESHOW

                    "ss-back": {
                        selector: ".ctrl.btn.ss-back",
                        options: { "class": "ctrl btn ss-back" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "skip_previous" } }
                            ]
                    },
                    "ss-play": {
                        selector: ".ctrl.btn.ss-play",
                        options: { "class": "ctrl btn ss-play" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "play_arrow" } }
                            ]
                    },
                    "ss-pause": {
                        selector: ".ctrl.btn.ss-pause",
                        options: { "class": "ctrl btn ss-pause" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "pause" } }
                            ]
                    },
                    "ss-fwd": {
                        selector: ".ctrl.btn.ss-fwd",
                        options: { "class": "ctrl btn ss-fwd" },
                        children:
                            [
                                { selector: ".icon", options: { "class": "icon", html: "skip_next" } }
                            ]
                    }
                };

                var containers =
                {
                    "topleft-hor": p.e(".controls .topleft .horizontal"),
                    "topright-hor": p.e(".controls .topright .horizontal"),
                    "bottomleft-hor": p.e(".controls .bottomleft .horizontal"),
                    "bottomright-hor": p.e(".controls .bottomright .horizontal"),
                    "topleft-ver": p.e(".controls .topleft .vertical"),
                    "topright-ver": p.e(".controls .topright .vertical"),
                    "bottomleft-ver": p.e(".controls .bottomleft .vertical"),
                    "bottomright-ver": p.e(".controls .bottomright .vertical"),

                    "top": p.e(".controls .top"),
                    "left": p.e(".controls .left"),
                    "right": p.e(".controls .right"),
                    "bottom": p.e(".controls .bottom")
                };

                var tabindex = 1;

                for (var ctrl in ctrl_initializers)
                {
                    var target = containers[this.control_assignments[ctrl]] || containers["topleft-hor"];
                    var ele = this._prepareElement(ctrl_initializers[ctrl], target).setAttr("tabindex", tabindex++);
                    if (ctrl_initializers[ctrl].tooltip)
                    {
                        switch (this.control_assignments[ctrl] || "topleft-hor")
                        {
                            case "top":
                            case "topleft-hor":
                            case "topright-hor":
                            case "topleft-ver":
                            case "topright-ver":
                                ele.setAttr("data-position", "bottom");
                                break;
                            case "bottom":
                            case "bottomleft-hor":
                            case "bottomright-hor":
                            case "bottomleft-ver":
                            case "bottomright-ver":
                                ele.setAttr("data-position", "top");
                                break;
                            case "left":
                                ele.setAttr("data-position", "right");
                                break;
                            case "right":
                                ele.setAttr("data-position", "left");
                                break;
                        }
                    }
                }

                $(".tooltipped").tooltip();

                // FULLLSCREEN BUTTON
                if (this.settings.show_fullscreen)
                {
                    p.e(".ctrl.btn.fullscreen").on("click", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        if (p.is.keyboard_event(e))
                        {
                            if (e.keyCode != 13 && e.keyCode != 32)
                            {
                                return;
                            }
                        }

                        if (p.fullscreen.isFullscreen)
                        {

                            p.fullscreen.exit();
                        }
                        else
                        {

                            p.fullscreen.enter(p.reader.container[0]);
                        }
                    });

                    if (p.fullscreen.isFullscreen)
                    {
                        p.e(".ctrl.btn.fullscreen").addClass("on").removeClass("off");
                    }
                    else
                    {
                        p.e(".ctrl.btn.fullscreen").addClass("off").removeClass("on");
                    }

                    var readerFullscreenChange = function (type, element)
                    {
                        //console.log(type, element);
                        if (p.e(element).getAttr("id") == "reader-container")
                        {
                            if (type == "exit")
                            {
                                p.e(".ctrl.btn:not(.fullscreen)").removeClass("immersive");
                                p.reader.container.removeClass("fullscreen");
                                p.pager.adjust({ reload: true });
                                p.e(".ctrl.btn.fullscreen").addClass("off").removeClass("on");
                            }
                            else
                            {
                                if (p.reader.settings.hide_in_fullscreen)
                                    p.e(".ctrl.btn:not(.fullscreen)").addClass("immersive");
                                p.reader.container.addClass("fullscreen");
                                p.pager.adjust({ reload: true });
                                p.e(".ctrl.btn.fullscreen").addClass("on").removeClass("off");
                            }

                            p.pager.calculateAutozoom();
                        }
                    };

                    p.subscribe("fullscreen", readerFullscreenChange);
                }
                else
                {
                    p.e(".ctrl.btn.fullscreen").addClass("gone");
                }

                // OVERVIEW
                if (this.settings.show_overview)
                {
                    p.e(".ctrl.btn.overview").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.reader.overview.toggle();
                    });
                }
                else
                {
                    p.e(".ctrl.btn.overview").addClass("gone");
                }

                // INVERT

                if (this.settings.show_invert && !p.browser.isMSIE)
                {
                    p.e(".ctrl.btn.invert").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.reader.toggleInvert();
                    });
                }
                else
                {
                    p.e(".ctrl.btn.invert").addClass("gone");
                }

                // ZOOM

                if (this.settings.show_zoom)
                {
                    p.e(".ctrl.btn.zoom-in").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.pager.setZoom(p.pager.current_zoom + p.reader.settings.zoom_button_step);
                        p.pager.adjust({ reload: true });
                    });
                    p.e(".ctrl.btn.zoom-out").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.pager.setZoom(p.pager.current_zoom - p.reader.settings.zoom_button_step);
                        p.pager.adjust({ reload: true });
                    });
                }
                else
                {
                    p.e([".ctrl.btn.zoom-in", ".ctrl.btn.zoom-out"]).addClass("gone");
                }

                if (this.settings.enable_arrows)
                {
                    //var tapLock = false;
                    p.e(".ctrl.btn.go-fwd").on("click tap", function (e)
                    {
                        //if (!tapLock)
                        //{
                        //    tapLock = true;

                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        //p.reader.dbg("fwd-tap");

                        if (p.pager.current_animator == null)
                            p.pager.goForward();
                        else
                            p.pager.current_animator.doFullAnimationNext();

                        //    setTimeout(function () { tapLock = false; }, 50);
                        //}
                    });
                    p.e(".ctrl.btn.go-back").on("click tap", function (e)
                    {
                        //if (!tapLock)
                        //{
                        //    tapLock = true;

                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        //p.reader.dbg("back-tap");

                        if (p.pager.current_animator == null)
                            p.pager.goBack();
                        else
                            p.pager.current_animator.doFullAnimationPrev();

                        //    setTimeout(function () { tapLock = false; }, 50);
                        //}
                    });
                }
                else
                {
                    p.e([".ctrl.btn.go-fwd", ".ctrl.btn.go-back"]).addClass("gone");
                }

                if (this.settings.show_download)
                {
                    p.e(".ctrl.btn.download").on("click tap", function (e)
                    {
                        //if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        //window.location.assign(p.pager.getPDFPath());
                    });
                }
                else
                {
                    p.e(".ctrl.btn.download").addClass("gone");
                }

                if (this.settings.show_go_to_page)
                {
                    p.e(".ctrl.btn.goto").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                    });
                }
                else
                {
                    p.e(".ctrl.btn.goto").addClass("gone");
                }

                if (this.settings.slideshow_mode && this.settings.slideshow_controls)
                {
                    p.e(".ctrl.btn.ss-back").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.reader.slideshow.prev_manual();
                    });
                    p.e(".ctrl.btn.ss-fwd").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.reader.slideshow.next_manual();
                    });


                    p.e(".ctrl.btn.ss-pause").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.reader.slideshow.pause();
                    });
                    p.e(".ctrl.btn.ss-play").on("click tap", function (e)
                    {
                        if (e && e.preventDefault) e.preventDefault();
                        if (e && e.stopPropagation) e.stopPropagation();

                        p.reader.slideshow.play();
                    });

                    p.e(".ctrl.btn.ss-play").addClass("gone");
                }
                else
                {
                    p.e([".ctrl.btn.ss-back", ".ctrl.btn.ss-pause", ".ctrl.btn.ss-play", ".ctrl.btn.ss-fwd"]).addClass("gone");
                }


                p.e(".ctrl.btn.bookshelf").on("click tap", function (e)
                {
                    if (e && e.preventDefault) e.preventDefault();
                    if (e && e.stopPropagation) e.stopPropagation();
                    location.assign(BASEPATH);
                });

                // SEARCH BUTTON
                p.e(".ctrl.btn.search").on("click tap", function (e)
                {
                    if (e && e.preventDefault) e.preventDefault();
                    if (e && e.stopPropagation) e.stopPropagation();

                    p.reader.search.open();
                });

                // Page input
                p.e(".ctrl.input.page input").on("keyup", function (e)
                {
                    if (e && e.preventDefault) e.preventDefault();
                    if (e && e.stopPropagation) e.stopPropagation();

                    if (e.keyCode == 13)
                    {
                        p.pager.goToPage(p.pager.current_page = parseInt($(this).val()));
                    }
                });
                p.e(".ctrl.input.page input").on("click", function (e)
                {
                    if (e && e.preventDefault) e.preventDefault();
                    if (e && e.stopPropagation) e.stopPropagation();

                    this.setSelectionRange(0, this.value.length)
                });

                // Show overlay area
                p.e(".ctrl.btn.showarea").on("click tap", function (e)
                {
                    //if (e && e.preventDefault) e.preventDefault();
                    if (e && e.stopPropagation) e.stopPropagation();
                    p.reader.sessionData.showAreas = !p.reader.sessionData.showAreas;

                    if (p.reader.sessionData.showAreas)
                    {
                        $(this).addClass("on").removeClass("off");
                        $("body").addClass("show-overlays");
                    }
                    else
                    {
                        $(this).addClass("off").removeClass("on");
                        $("body").removeClass("show-overlays");
                    }

                    //window.location.assign(p.pager.getPDFPath());
                });
            },

            search: {
                _elements: null,
                _lastQuery: "",

                makeModal: function ()
                {
                    var elements = {
                        modal: null,

                        content_wrapper: null,
                        header: null,
                        header_text: null,
                        search_field_wrapper: null,
                        search_field_icon: null,
                        search_field: null,
                        search_field_label: null,
                        search_button: null,
                        content: null,

                        footer: null,
                        cancel_button: null
                    };
                    elements.modal = p.e("<div>", { "id": "search-modal", "class": "modal modal-fixed-footer" });

                    elements.header = p.e("<div>", { "class": "modal-fixed-header" }).appendTo(elements.modal);
                    elements.header_text = p.e("<h4>", { "html": p.locale.getString("SEARCH") }).appendTo(elements.header);
                    elements.search_field_wrapper = p.e("<div>", { "class": "input-field" }).appendTo(elements.header);
                    elements.search_field_icon = p.e("<i>", { "class": "material-icons prefix", html: "search" }).appendTo(elements.search_field_wrapper);
                    elements.search_field = p.e("<input>", { "id": "search-modal-field", "type": "text", "placeholder": p.locale.getString("SEARCH") }).appendTo(elements.search_field_wrapper);
                    elements.search_field_label = p.e("<label>", { "for": "search-modal-field" }).appendTo(elements.search_field_wrapper);

                    elements.content_wrapper = p.e("<div>", { "class": "modal-content" }).appendTo(elements.modal);

                    elements.footer = p.e("<div>", { "class": "modal-footer" }).appendTo(elements.modal);
                    elements.cancel_button = p.e("<a>", { "href": "#!", "class": "modal-close waves-effect btn-flat", html: p.locale.getString("CLOSE") }).appendTo(elements.footer);

                    return elements;
                },

                open: function ()
                {
                    var $this = p.reader.search;
                    if ($this._elements == null)
                    {
                        $this._elements = $this.makeModal();
                        $this._elements.modal.appendTo("#reader-container");
                        $($this._elements.modal).modal({
                            ready: function ()
                            {
                                p.e($(".modal-overlay").detach()[0]).appendTo("#reader-container");
                            }
                        });
                        $this._elements.search_field.on("change input", $this.onSearch);
                    }

                    $($this._elements.modal).modal("open");
                },

                onSearch: function ()
                {
                    var $this = p.reader.search;
                    if ($this._elements != null)
                    {

                        var needle = $this._elements.search_field[0].value.toLowerCase();
                        // console.log(needle, $this._lastQuery);
                        if (needle && needle.length > 0)
                        {
                            if (needle == $this._lastQuery)
                                return;

                            $this._elements.content_wrapper.setHtml("");

                            $this._lastQuery = needle;
                            var results = [];
                            for (var page in p.pager.pages)
                            {
                                var $page = p.pager.pages[page];
                                var idx = $page.page_content.toLowerCase().indexOf(needle);
                                if (idx >= 0)
                                {
                                    results.push({ page_num: page, page: $page, idx: idx });
                                }
                            }

                            if (results.length == 0)
                            {
                                p.e("<div>", { "class": "result empty", "html": "__LOCALE__:SEARCH_NONE" }).appendTo($this._elements.content_wrapper);
                            }
                            else
                            {
                                for (var i in results)
                                {
                                    var result = results[i];
                                    var el = p.e("<div>", { "class": "result flex cols", "data-page": result.page_num });
                                    var thumb = p.e("<div>", { "class": "thumb" }).appendTo(el);
                                    var loader = p.reader.stageLoader();
                                    loader.load([p.pager.getThumbPath(result.page_num)]);
                                    loader.appendTo(thumb);
                                    var text = p.e("<div>", { "class": "preview" }).appendTo(el);

                                    var textLen = 500;
                                    var textLeft = textLen / 2;
                                    var textRight = textLen / 2;
                                    var endIdx = result.idx + needle.length;
                                    if (result.idx < textLeft)
                                    {
                                        textRight += (textLeft - result.idx);
                                        textLeft = result.idx;
                                    }
                                    if (endIdx + textRight > result.page.page_content.length)
                                    {
                                        textLeft = Math.max(0, textLeft - ((endIdx + textRight) - result.page.page_content.length));
                                        textRight = result.page.page_content.length - endIdx;
                                    }
                                    var snippet = result.page.page_content.substring(result.idx - textLeft, endIdx + textRight);
                                    if (result.idx - textLeft != 0)
                                    {
                                        snippet = "&hellip;" + snippet;
                                    }
                                    else if (endIdx + textRight < result.page.page_content.length)
                                    {
                                        snippet += "&hellip;";
                                    }

                                    var expression = needle.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                                    var regexp = new RegExp(expression, 'ig');
                                    snippet = snippet.replace(regexp, "<span class='highlight'>$&</span>");

                                    text.setHtml(snippet);
                                    el.appendTo($this._elements.content_wrapper);

                                    el.on("click tap", function ()
                                    {
                                        // debugger;
                                        p.pager.goToPage(p.pager.current_page = parseInt(p.e(this).getAttr("data-page")));
                                        $($this._elements.modal).modal("close");
                                    });

                                }
                            }
                        }
                        else
                        {
                            p.e("<div>", { "class": "result empty", "html": "__LOCALE__:SEARCH_NONE" }).appendTo($this._elements.content_wrapper);
                        }
                    }
                }
            },

            slideshow:
            {
                timeout: null,
                play_timeout: function ()
                {
                    p.pager.goForward(function ()
                    {
                        p.reader.slideshow.timeout = setTimeout(p.reader.slideshow.play_timeout, p.reader.settings.slideshow_timing * 1000);
                        if (p.pager.current_page == p.pager.page_count)
                            p.pager.current_page = 0;
                    });
                },
                play: function ()
                {
                    p.e(".ctrl.btn.ss-play").addClass("gone");
                    p.e(".ctrl.btn.ss-pause").removeClass("gone");
                    p.reader.slideshow.timeout = setTimeout(p.reader.slideshow.play_timeout, p.reader.settings.slideshow_timing * 1000);
                },
                pause: function ()
                {
                    p.e(".ctrl.btn.ss-pause").addClass("gone");
                    p.e(".ctrl.btn.ss-play").removeClass("gone");
                    clearTimeout(p.reader.slideshow.timeout);
                },
                next_manual: function ()
                {
                    p.reader.slideshow.pause();
                    p.pager.goForward();
                },
                prev_manual: function ()
                {
                    p.reader.slideshow.pause();
                    p.pager.goBack();
                }
            },

            overview:
            {
                isInited: false,
                isFirstOpen: true,
                isOpen: false,
                container: null,
                thumbs: [],
                delta: 0,
                interval: null,

                ensureInit: function ()
                {
                    if (!this.isInited)
                    {
                        var $this = this,
                            requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                            cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

                        this.container = p.e("#reader-container .overviews");
                        this.container.addClass(p.reader.settings.overview_direction + (p.reader.settings.overview_overlay ? " float" : " solid"));
                        this.container.addClass("hidden");

                        switch (p.reader.settings.overview_direction)
                        {
                            case "left":
                            case "right":
                                this.container.setAttr("style", "width: {0}px".format(p.reader.settings.overview_size));
                                break;
                            case "top":
                            case "bottom":
                                this.container.setAttr("style", "height: {0}px".format(p.reader.settings.overview_size));
                                break;
                        }

                        this.container.on("wheel", function (e)
                        {
                            if (e && e.preventDefault) e.preventDefault();
                            if (e && e.stopPropagation) e.stopPropagation();

                            switch (p.reader.settings.overview_direction)
                            {
                                case "left":
                                case "right":
                                    $this.container[0].scrollTop += e.deltaY;
                                    break;
                                case "top":
                                case "bottom":
                                    $this.container[0].scrollLeft += e.deltaY;
                                    break;
                            }
                        });

                        var isScrolling = false;
                        var lastPos = null;
                        var currentPos = null;
                        var momentumStep = 1;

                        var fncGetPos = function (event)
                        {
                            if (event.type.startsWith("touch"))
                            {
                                if (event.touches.length == 1)
                                {
                                    return { x: event.touches[0].pageX, y: event.touches[0].pageY };
                                }
                                else if (event.touches.length == 2)
                                {
                                    var a = { x: event.touches[0].pageX, y: event.touches[0].pageY },
                                        b = { x: event.touches[1].pageX, y: event.touches[1].pageY },
                                        midpoint = p.math.Midpoint2D(a, b);

                                    return { x: midpoint.x, y: midpoint.y, dist: p.math.Dist2D(a, b) };
                                }
                            }
                            else
                            {
                                return { x: event.pageX, y: event.pageY };
                            }
                        };

                        this.container.on("touchstart mousedown", function (e)
                        {
                            if (e && e.preventDefault) e.preventDefault();
                            if (e && e.stopPropagation) e.stopPropagation();

                            p.reader.overview.delta = 0;
                            isScrolling = true;
                            lastPos = fncGetPos(e);
                            clearInterval(p.reader.overview.interval);
                            clearTimeout(p.reader.overview.scrollTimeout);
                        });
                        this.container.on("touchmove mousemove", function (e)
                        {
                            if (e && e.preventDefault) e.preventDefault();
                            if (e && e.stopPropagation) e.stopPropagation();

                            //clearInterval(p.reader.overview.interval);
                            //clearTimeout(p.reader.overview.scrollTimeout);
                            if (isScrolling)
                            {
                                currentPos = fncGetPos(e);

                                switch (p.reader.settings.overview_direction)
                                {
                                    case "left":
                                    case "right":
                                        p.reader.overview.delta = lastPos.y - currentPos.y;
                                        $this.container[0].scrollTop += p.reader.overview.delta;
                                        break;

                                    case "top":
                                    case "bottom":
                                        p.reader.overview.delta = lastPos.x - currentPos.x;
                                        $this.container[0].scrollLeft += p.reader.overview.delta;
                                        break;
                                }

                                //console.log(delta);

                                //console.log(lastPos, currentPos);
                                lastPos = currentPos;
                                //console.log(lastPos, currentPos);
                            }
                        });
                        this.container.on("touchend mouseup mouseleave", function (e)
                        {
                            if (e && e.preventDefault) e.preventDefault();
                            if (e && e.stopPropagation) e.stopPropagation();
                            //console.log("end");

                            isScrolling = false;
                            if (Math.abs(p.reader.overview.delta) >= 10)
                            {
                                momentumStep = Math.abs(p.reader.overview.delta) / (p.pager.is_mobile ? 30 : 60);

                                var animationCallback = function ()
                                {
                                    if (Math.abs(p.reader.overview.delta) > 1)
                                    {
                                        switch (p.reader.settings.overview_direction)
                                        {
                                            case "left":
                                            case "right":
                                                $this.container[0].scrollTop += p.reader.overview.delta;
                                                break;

                                            case "top":
                                            case "bottom":
                                                $this.container[0].scrollLeft += p.reader.overview.delta;
                                                break;
                                        }

                                        if (p.reader.overview.delta > 0)
                                            p.reader.overview.delta -= momentumStep;
                                        else
                                            p.reader.overview.delta += momentumStep;

                                        //console.log(delta); 
                                        //delta = delta / 4;
                                        p.reader.overview.interval = requestFrame(animationCallback);
                                    }
                                    else
                                    {
                                        //clearInterval(p.reader.overview.interval);
                                        cancelFrame(p.reader.overview.interval);
                                    }
                                };

                                p.reader.overview.interval = requestFrame(animationCallback);

                                /*clearInterval(p.reader.overview.interval);
                                clearTimeout(p.reader.overview.scrollTimeout);
                                p.reader.overview.interval = setInterval(function ()
                                {
                                    if (Math.abs(p.reader.overview.delta) > 1)
                                    {
                                        switch (p.reader.settings.overview_direction)
                                        {
                                            case "left":
                                            case "right":
                                                $this.container[0].scrollTop += p.reader.overview.delta;
                                                break;

                                            case "top":
                                            case "bottom":
                                                $this.container[0].scrollLeft += p.reader.overview.delta;
                                                break;
                                        }

                                        if (p.reader.overview.delta > 0)
                                            p.reader.overview.delta -= momentumStep;
                                        else
                                            p.reader.overview.delta += momentumStep;

                                        //console.log(delta); 
                                        //delta = delta / 4;
                                    }
                                    else
                                    {
                                        clearInterval(p.reader.overview.interval);
                                    }
                                }, 1000 / (p.pager.is_mobile ? 30 : 60));
                                */
                            }
                        });
                    }
                },

                open: function ()
                {
                    this.ensureInit();

                    if (this.isFirstOpen)
                    {
                        this.isFirstOpen = false;
                        var pageSize = p.pager.getPageSize(1, true);
                        var overviewMargin = 8;
                        var scaleFactor;
                        switch (p.reader.settings.overview_direction)
                        {
                            case "left":
                            case "right":
                                scaleFactor = (p.reader.settings.overview_size - overviewMargin * 2) / pageSize.width;
                                break;
                            case "top":
                            case "bottom":
                                scaleFactor = (p.reader.settings.overview_size - overviewMargin * 2) / pageSize.height;
                                break;
                        }
                        var thumbSize = { width: pageSize.width * scaleFactor, height: pageSize.height * scaleFactor };

                        var thumbFnc = function ()
                        {
                            if (Math.abs(p.reader.overview.delta) < 10)
                            {
                                p.pager.current_page = parseInt(p.e(this).getAttr("data-page"));
                                p.pager.goToPage(p.pager.current_page);
                            }
                        };

                        for (var i = 0; i < p.pager.page_count; i++)
                        {
                            var thumb = p.e("<div>", { "class": "thumb", "data-page": i + 1 });
                            var loader = p.reader.stageLoader();
                            loader.load([p.pager.getThumbPath(i + 1)]);

                            loader.appendTo(thumb);

                            thumb.setAttr("style", "width: {0}px; height: {1}px;".format(thumbSize.width, thumbSize.height));
                            thumb.appendTo(this.container);

                            var waitingForClick = false;

                            var thumbOnDown = function ()
                            {
                                waitingForClick = true;
                            };
                            var thumbOnUp = function ()
                            {
                                if (waitingForClick)
                                {
                                    p.pager.current_page = parseInt(p.e(this).getAttr("data-page"));
                                    p.pager.goToPage(p.pager.current_page);
                                    waitingForClick = false;
                                }
                            };
                            var thumbOnCancel = function ()
                            {
                                waitingForClick = false;
                            };

                            //thumb.on("click tap", thumbFnc);
                            thumb.on("mousedown touchstart", thumbOnDown);
                            thumb.on("mouseup touchend", thumbOnUp);
                            thumb.on("mousemove mousecancel touchmove touchcancel", thumbOnCancel);
                        }
                    }

                    this.container.removeClass("hidden");
                    p.e("#reader-container .controls").setAttr("style", "{0}: {1}px;".format(p.reader.settings.overview_direction, p.reader.settings.overview_size));
                    if (!p.reader.settings.overview_overlay)
                        p.pager.content_container.setAttr("style", "{0}: {1}px;".format(p.reader.settings.overview_direction, p.reader.settings.overview_size));

                    this.scrollToCurrentPage();

                    this.isOpen = true;
                },
                scrollTimeout: null,
                scrollToCurrentPage: function ()
                {
                    var currentThumb = p.e(".thumb[data-page='{0}']".format(p.pager.current_page_firstvalid)),
                        requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                        cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
                    if (currentThumb && currentThumb.length > 0)
                    {
                        p.e(".thumb").removeClass("active");

                        if (p.pager.current_mode == p.pager.constant.SINGLE || p.pager.current_mode == p.pager.constant.SINGLE_REVERSE)
                            currentThumb.addClass("active");
                        else
                            p.e(".thumb[data-page='{0}'], .thumb[data-page='{1}']".format(p.pager.current_page_left, p.pager.current_page_right)).addClass("active");

                        if (p.reader.settings.overview_direction == "left" || p.reader.settings.overview_direction == "right")
                            var target = currentThumb[0].offsetTop - ((currentThumb[0].offsetHeight + this.container[0].offsetHeight) / 2) + currentThumb[0].offsetHeight;
                        else
                            var target = currentThumb[0].offsetLeft - ((currentThumb[0].offsetWidth + this.container[0].offsetWidth) / 2) + currentThumb[0].offsetWidth;

                        if (p.pager.current_mode == p.pager.constant.DOUBLE || p.pager.current_mode == p.pager.constant.DOUBLE_REVERSE)
                            target += ((p.reader.settings.overview_direction == "left" || p.reader.settings.overview_direction == "right") ? currentThumb[0].offsetHeight / 2 : currentThumb[0].offsetWidth / 2) + 16;

                        var now = 0,
                            max = 60,
                            step = Math.abs(this.container[0].scrollTop - target) / max,
                            $this = this,
                            dist = target - this.container[0].scrollTop,
                            start = this.container[0].scrollTop;


                        if (p.reader.settings.overview_direction == "top" || p.reader.settings.overview_direction == "bottom")
                        {
                            dist = target - this.container[0].scrollLeft;
                            start = this.container[0].scrollLeft;
                        }

                        var easeInOutQuad = function (tCurrent, start, change, duration)
                        {
                            tCurrent /= duration / 2;
                            if (tCurrent < 1) return change / 2 * tCurrent * tCurrent + start;
                            tCurrent--;
                            return -change / 2 * (tCurrent * (tCurrent - 2) - 1) + start;
                        };

                        //console.log(dist, start, this.container[0].scrollLeft);

                        var stepFnc = function ()
                        {
                            if (p.reader.settings.overview_direction == "left" || p.reader.settings.overview_direction == "right")
                            {
                                $this.container[0].scrollTop = easeInOutQuad(now, start, dist, max);
                            }
                            else
                            {
                                $this.container[0].scrollLeft = easeInOutQuad(now, start, dist, max);
                            }

                            now++;
                            if (now < max)
                                $this.scrollTimeout = requestFrame(stepFnc);
                            else
                                cancelFrame($this.scrollTimeout);
                        };

                        cancelFrame(this.scrollTimeout);
                        this.scrollTimeout = requestFrame(stepFnc);
                    }
                },
                close: function ()
                {
                    this.ensureInit();

                    this.container.addClass("hidden");
                    p.pager.content_container.setAttr("style", "");
                    p.e("#reader-container .controls").setAttr("style", "");

                    this.isOpen = false;
                },
                toggle: function ()
                {
                    if (this.isOpen)
                        this.close();
                    else
                        this.open();
                }
            },

            // getApiDownloadPath: function (type)
            // {
            //     return "{0}/api/web/?cmd=get/file/{2}&udid={1}&class=web&platform=web".format(BASEPATH, p.reader.book_id, type);
            // },

            getApiCoverPath: function (size)
            {
                return "{0}/api/web/?cmd=get/file/cover&udid={1}&class=web&platform=web&size={2}".format(BASEPATH, p.reader.book_id, size);
            },

            prepareData: function ()
            {
                // p.net.request(this.path + "/data.json", {
                //     finished: function (success, data)
                //     {
                //         if (success)
                //         {
                //             p.pager.pages = p.jsonParse(data);
                //             //p.e(".finished-overlay").removeClass("hidden");
                //             p.pager.init();
                //         }
                //     }
                // });
            },
            prepareCover: function ()
            {
                var coverLoader = this.stageLoader();
                coverLoader.addClass("cover-loader");
                coverLoader.appendTo(".main-loader");
                coverLoader.load([
                    this.getApiCoverPath("small"),
                    this.getApiCoverPath("medium"),
                    this.getApiCoverPath("retina"),
                    this.getApiCoverPath("big")
                ]);
            },
            prepareElements: function ()
            {
                for (var key in this.elements)
                {
                    this._prepareElement(this.elements[key], this.container);
                }
            },
            _prepareElement: function (e, parent)
            {
                var ele, key, $this = this;
                ele = parent.find(e.selector);
                if (ele.length == 0)
                {
                    ele = p.e(e.construct || "<div>", e.options);
                    ele.appendTo(parent);

                    if (e.tooltip)
                    {
                        //data-position="bottom" data-tooltip="I am a tooltip"
                        ele.addClass("tooltipped");
                        ele.setAttr("data-position", "bottom");
                        ele.setAttr("data-tooltip", e.tooltip.startsWith("__LOCALE__") ? p.locale.getString(e.tooltip.split(":")[1]) : e.tooltip);
                    }
                }

                if (e["children"] !== void 0)
                {
                    for (key in e.children)
                        this._prepareElement(e.children[key], ele);
                }

                return ele;
            },


            make_slider: function (options, fullscreenCheckId)
            {
                var slider = p.e("<div>", { "class": "slider", "value": 50 });
                var track = p.e("<div>", { "class": "track" }).appendTo(slider);
                var fill = p.e("<div>", { "class": "fill" }).appendTo(track);
                var thumb = p.e("<div>", { "class": "thumb" }).appendTo(slider);
                var buffer = p.e("<div>", { "class": "buffer" });

                var callback = null;
                var displayCallback = null;
                var vertical = false;

                var fncs =
                {
                    slider:
                    {
                        max: options.max || 100,
                        min: options.min || 0,
                        value: options.value || 50,
                        sigfigs: options.sigfigs != undefined ? options.sigfigs : -1,
                        dragging: false,
                        update: function (nocallback, fromUser)
                        {
                            var $this = fncs.slider;
                            var percent = p.math.Percentage.XofY($this.value, $this.max);
                            if (vertical)
                            {
                                fill.setStyle("height", percent + "%");
                                thumb.setStyle("bottom", percent + "%");
                            }
                            else
                            {
                                fill.setStyle("width", percent + "%");
                                thumb.setStyle("left", percent + "%");
                            }

                            var displayValue = $this.value + 0;
                            if (displayCallback && p.is.function(displayCallback))
                                displayValue = displayCallback(displayValue);

                            thumb.setAttr("value", $this.value);

                            slider.setAttr("value", $this.value);
                            slider.setAttr("max", $this.max);
                            slider.setAttr("min", $this.min);

                            if (!nocallback && callback && p.is.function(callback))
                                callback($this.value, !!fromUser);
                        },
                        set: function (val)
                        {
                            var $this = fncs.slider;
                            $this.value = val;
                            $this.update;
                        },
                        setBuffer: function (perc)
                        {
                            buffer.setStyle("width", (100 - perc) + "%");
                        },
                        onStart: function ()
                        {
                            var $this = fncs.slider;
                            $this.dragging = true;
                            thumb.addClass("pressed");
                        },
                        onEnd: function ()
                        {
                            var $this = fncs.slider;
                            $this.dragging = false;
                            thumb.removeClass("pressed");
                        },
                        onDrag: function (e)
                        {
                            var $this = fncs.slider;
                            if ($this.dragging)
                            {
                                var pos = $this.getPos(e);
                                if (vertical)
                                {
                                    var height = slider.getHeight();
                                    $this.value = ((p.math.Percentage.XofY(height - pos.y, height) / 100) * $this.max) + $this.min;
                                }
                                else
                                {
                                    var width = slider.getWidth();
                                    $this.value = ((p.math.Percentage.XofY(pos.x, width) / 100) * $this.max) + $this.min;
                                }

                                if ($this.sigfigs != -1)
                                {
                                    $this.value = $this.value.toFixed($this.sigfigs);
                                }

                                $this.value = Math.min($this.max, Math.max($this.min, $this.value));
                                $this.update(undefined, true);
                            }
                        },
                        getPos: function (event)
                        {
                            //debugger;
                            var offset = slider.getPageXY();
                            if (event.type.startsWith("touch"))
                            {
                                if (event.touches.length == 1)
                                {
                                    return { x: event.touches[0].pageX - offset.x, y: event.touches[0].pageY - offset.y };
                                }
                            }
                            else
                            {
                                return { x: event.pageX - offset.x, y: event.pageY - offset.y };
                            }
                        }
                    }
                };


                if (options)
                {
                    if (options.dark)
                        slider.addClass("dark");
                    if (options.discrete)
                        slider.addClass("discrete");
                    if (options.callback)
                        callback = options.callback;
                    if (options.displayCallback)
                        displayCallback = options.displayCallback;
                    if (options.vertical)
                        vertical = true;
                    if (options.buffering)
                        buffer.appendTo(track);
                    if (options.buffer)
                        fncs.setBuffer(options.buffer);
                }

                if (vertical)
                    slider.addClass("vertical");

                slider.extend(fncs);
                slider.slider.update();
                thumb.on("mousedown touchstart", fncs.slider.onStart);
                slider.on("mousemove touchmove", fncs.slider.onDrag);
                p.e("body").on("mouseup touchend", fncs.slider.onEnd);
                return slider;
            },

            stageLoaderId: 0,
            stageLoader: function ()
            {
                var ele = p.e("<div>", { "class": "loader" }),
                    spinner = p.e("<div>", { "class": "spinner" }).appendTo(ele);
                var thisId = ++p.reader.stageLoaderId;

                var c =
                {
                    currentStage: -1,
                    instanceId: 0,
                    sources: [],
                    elements: [],
                    spinner: null,
                    callback: null,
                    loadInstance: null,

                    load: function (sources, callback, suppressLoading)
                    {
                        //CallTrace(arguments, thisId + "/SL" + (c.instanceId + 1));
                        //console.log("stageLoader", "load");
                        if (sources)
                        {
                            var allsame = true;

                            for (var i = 0; i < sources.length; i++)
                                if (sources[i] != c.sources[i])
                                {
                                    allsame = false;
                                    //console.log("stageLoader", "new sources");
                                    //console.log("not all same");
                                    //if (c.callback && p.is.function(c.callback))
                                    //{
                                    //    c.callback();
                                    //    c.callback = null;
                                    //}
                                    break;
                                }

                            if (allsame)
                            {
                                //Trace(["done (same sources)"], "SL");
                                //console.log("stageLoader", "same sources");
                                //console.log("all same");
                                if (callback && p.is.function(callback))
                                {
                                    callback();
                                }
                                return;
                            }

                            c.sources = sources;
                            c.spinner.removeClass("hidden");
                            for (var i = 0; i < c.elements.length; i++)
                                c.elements[i].remove();

                            c.elements = [];

                            for (var i = 0; i < c.sources.length; i++)
                                c.elements[i] = p.e("<img>", { "class": suppressLoading && i == 0 ? "" : "loading" }).appendTo(ele);
                        }
                        c.callback = callback;
                        //Trace([c.callback, "assigned", callback], thisId + "/SL" + (c.instanceId + 1));
                        //c.onLoad();

                        c.loadInstance = new c.loadEvent(++c.instanceId);
                        c.currentStage = -1;
                        c.loadInstance.onLoad();
                    },
                    loadEvent: function (iid)
                    {
                        var $this = this;
                        this.id = iid;
                        this.onLoad = function ()
                        {
                            //Trace([$this.id, "onload"], thisId + "/SL");
                            //Trace([$this.id, "onLoad"], "SL");
                            if ($this.id != c.instanceId)
                            {
                                return;
                            }
                            if (c.currentStage >= 0)
                            {
                                c.spinner.addClass("hidden");
                                if (c.elements[c.currentStage])
                                    c.elements[c.currentStage].removeClass("loading");
                            }

                            c.currentStage++;

                            if (c.currentStage < c.sources.length)
                            {
                                c.elements[c.currentStage].on("load", $this.onLoad);
                                c.elements[c.currentStage].setAttr("src", c.sources[c.currentStage]);
                            }
                            else
                            {
                                //Trace([$this.id, "done", c.callback], thisId + "/SL");
                                if (c.callback && p.is.function(c.callback))
                                {
                                    if ($this.id != c.instanceId)
                                    {
                                        return;
                                    }
                                    c.callback();
                                    c.callback = null;
                                    //Trace([$this.id, "unassigned callback", c.callback], thisId + "/SL");
                                }
                            }
                        };

                    }//,
                    //onLoad: function (iid)
                    //{
                    //    if (c.currentStage >= 0)
                    //    {
                    //        c.spinner.addClass("hidden");
                    //        if (c.elements[c.currentStage])
                    //            c.elements[c.currentStage].removeClass("loading");
                    //    }

                    //    c.currentStage++;

                    //    if (c.currentStage < c.sources.length)
                    //    {
                    //        c.elements[c.currentStage].setAttr("src", c.sources[c.currentStage]);
                    //        c.elements[c.currentStage].on("load", c.onLoad);
                    //    }
                    //    else
                    //    {
                    //        if (c.callback && p.is.function(c.callback))
                    //        {
                    //            c.callback();
                    //            c.callback = null;
                    //        }
                    //    }
                    //}
                };
                c.spinner = spinner;
                exHelp.extend(ele, c);
                return ele;
            },

            isInvertOn: false,
            toggleInvert: function ()
            {
                if (this.isInvertOn)
                {
                    //p.reader.container.setStyle("filter", "");
                    p.reader.container.removeClass("invert");
                    this.isInvertOn = false;
                }
                else
                {
                    //if (p.browser.isMSIE)
                    //{
                    //    if (!this.svgLoaded)
                    //        this.loadSvgFilters();

                    //    p.reader.container.setStyle("filter", "url(#invert)");
                    //}
                    //else
                    //{
                    //p.reader.container.setStyle("filter", "invert(100%)");
                    //}
                    p.reader.container.addClass("invert");
                    this.isInvertOn = true;
                }
            },
        },

        swipe_animator:
        {
            listen: true,
            dragging: false,
            dragged: null,
            revealed: null,
            moveStart: null,
            size: null,
            last: null,
            target: null,
            direction: "none",
            animationTimeout: null,

            init: function ()
            {
                p.e(document).on("mousedown touchstart", this.listenerStart);
                p.e(document).on("mousemove touchmove", this.listenerMove);
                p.e(document).on("mouseup touchend mouseleave touchleave", this.listenerEnd);
            },

            destruct: function ()
            {
                p.e(document).off("mousedown touchstart", this.listenerStart);
                p.e(document).off("mousemove touchmove", this.listenerMove);
                p.e(document).off("mouseup touchend mouseleave touchleave", this.listenerEnd);
            },

            intersects: function (pos, rect)
            {
                return (pos.x > rect.left && pos.x < rect.right && pos.y > rect.top && pos.y < rect.bottom);
            },

            posFromEvent: function (event)
            {
                if (event.type.startsWith("touch"))
                {
                    if (event.touches.length == 1)
                    {
                        return { x: event.touches[0].pageX, y: event.touches[0].pageY };
                    }
                    else if (event.touches.length == 2)
                    {
                        var a = { x: event.touches[0].pageX, y: event.touches[0].pageY },
                            b = { x: event.touches[1].pageX, y: event.touches[1].pageY },
                            midpoint = p.math.Midpoint2D(a, b);

                        return { x: midpoint.x, y: midpoint.y, dist: p.math.Dist2D(a, b) };
                    }
                }
                else
                {
                    return { x: event.pageX, y: event.pageY };
                }
            },

            animateRight: function (dist)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant;

                // Calculations
                var fullHidden = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;
                var fullVisible = 0;
                var current = fullHidden - dist;

                var proz = Math.min(1.0, dist / fullHidden);
                var eased = p.math.easing.easeOutQuad(proz * 100, 0, 100, 100) / 100;

                // Ensure style
                clearTimeout($this.animationTimeout);
                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.revealed = p.e([p.pager.holders[CONST.CUR_LEFT], p.pager.holders[CONST.CUR_RIGHT]]),
                        $this.dragged = p.e([p.pager.holders[CONST.NEXT_LEFT], p.pager.holders[CONST.NEXT_RIGHT]]);
                    p.e([p.pager.holders[CONST.PREV_LEFT], p.pager.holders[CONST.PREV_LEFT]]).addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_left + 2))
                        p.e(p.pager.holders[CONST.NEXT_LEFT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.NEXT_LEFT]).addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_right + 2))
                        p.e(p.pager.holders[CONST.NEXT_RIGHT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.NEXT_RIGHT]).addClass("hidden");


                    if (p.pager.showPage(p.pager.current_page_left))
                        p.e(p.pager.holders[CONST.CUR_LEFT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.CUR_LEFT]).addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_right))
                        p.e(p.pager.holders[CONST.CUR_RIGHT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.CUR_RIGHT]).addClass("hidden");
                }
                else
                {
                    $this.revealed = p.pager.holders[CONST.CUR],
                        $this.dragged = p.pager.holders[CONST.NEXT];
                    p.pager.holders[CONST.PREV].addClass("hidden");
                    p.pager.holders[CONST.NEXT].removeClass("hidden");
                }

                $this.dragged.addClass("animate");
                $this.revealed.addClass("wait");
                p.overlays.setHidden(true);

                // Animate
                $this.dragged.setStyle("transform", "translate({0}px, 0)".format(fullHidden - (fullHidden * eased)));
                $this.revealed.setStyle("transform", "translate(-{0}%, 0)".format(20 * eased));

                $this.dragged.setStyle("opacity", 1 * p.math.easing.easeOutExpo(proz * 1000, 0, 1, 100));
                $this.revealed.setStyle("opacity", 10 - p.math.easing.easeOutExpo(proz * 100, 0, 10, 100));
            },
            animateLeft: function (dist)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant;

                // Calculations
                var fullHidden = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;
                var fullVisible = 0;
                var current = fullHidden - dist;

                var proz = Math.min(1.0, dist / fullHidden);
                var eased = p.math.easing.easeOutQuad(proz * 100, 0, 100, 100) / 100;

                // Ensure style
                clearTimeout($this.animationTimeout);
                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.revealed = p.e([p.pager.holders[CONST.CUR_LEFT], p.pager.holders[CONST.CUR_RIGHT]]),
                        $this.dragged = p.e([p.pager.holders[CONST.PREV_LEFT], p.pager.holders[CONST.PREV_RIGHT]]);
                    p.e([p.pager.holders[CONST.NEXT_LEFT], p.pager.holders[CONST.NEXT_RIGHT]]).addClass("hidden");
                }
                else
                {
                    $this.dragged = p.pager.holders[CONST.PREV],
                        $this.revealed = p.pager.holders[CONST.CUR];
                    p.pager.holders[CONST.PREV].removeClass("hidden");
                    p.pager.holders[CONST.NEXT].addClass("hidden");
                }

                $this.dragged.addClass("animate").removeClass("hidden");
                $this.revealed.addClass("wait").removeClass("hidden");
                p.overlays.setHidden(true);

                // Animate
                $this.dragged.setStyle("transform", "translate(-{0}px, 0)".format(fullHidden - (fullHidden * eased)));
                $this.revealed.setStyle("transform", "translate({0}%, 0)".format(20 * eased));

                $this.dragged.setStyle("opacity", 1 * p.math.easing.easeOutExpo(proz * 1000, 0, 1, 100));
                $this.revealed.setStyle("opacity", 10 - p.math.easing.easeOutExpo(proz * 100, 0, 10, 100));
            },
            animateRightTo: function (from, to, callback)
            {
                var $this = p.swipe_animator,
                    CONST = p.pager.constant,
                    t = 0,
                    max = 30,
                    requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
                var fnc = function ()
                {
                    $this.animateRight(p.math.easing.easeInOutQuad(t++, from, to - from, max));
                    if (t < max)
                        $this.animationTimeout = requestFrame(fnc);
                    else if (p.is.function(callback))
                    {
                        callback();
                        cancelFrame($this.animationTimeout);
                    }
                };

                cancelFrame($this.animationTimeout);
                $this.animationTimeout = requestFrame(fnc);
            },
            animateLeftTo: function (from, to, callback)
            {
                var $this = p.swipe_animator,
                    CONST = p.pager.constant,
                    t = 0,
                    max = 30,
                    requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
                var fnc = function ()
                {
                    $this.animateLeft(p.math.easing.easeInOutQuad(t++, from, to - from, max));
                    if (t < max)
                        $this.animationTimeout = requestFrame(fnc);
                    else if (p.is.function(callback))
                    {
                        callback();
                        cancelFrame($this.animationTimeout);
                    }
                };

                cancelFrame($this.animationTimeout);
                $this.animationTimeout = requestFrame(fnc);
            },

            listenerStart: function (e)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant;

                if (!$this.lockAnimation && !p.pager.touchBlocked)
                {
                    if (p.pager.current_animation == CONST.SWIPE)
                    {
                        if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                        {
                            var rectLeft = p.pager.holders[CONST.CUR_LEFT][0].getBoundingClientRect(),
                                rectRight = p.pager.holders[CONST.CUR_RIGHT][0].getBoundingClientRect(),
                                targetRectLeft = { top: rectLeft.top, left: rectLeft.left, right: rectLeft.left + rectLeft.width * 0.1, bottom: rectLeft.bottom },
                                targetRectRight = { top: rectRight.top, left: rectRight.right - rectRight.width * 0.1, right: rectRight.right, bottom: rectRight.bottom },
                                pos = $this.posFromEvent(e);

                            if ($this.intersects(pos, targetRectRight))
                            {
                                // One time calculation
                                $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                                $this.target = p.pager.page_container[0].getBoundingClientRect();
                                $this.target = {
                                    top: $this.target.top + p.pager.current_padding,
                                    left: $this.target.left + p.pager.current_padding,
                                    right: $this.target.right - p.pager.current_padding,
                                    bottom: $this.target.bottom - p.pager.current_padding
                                };
                                $this.moveStart = pos;

                                p.pager.paging_handled_by_animator = true;

                                $this.startDragRight();
                            }
                            else if ($this.intersects(pos, targetRectLeft))
                            {
                                // One time calculation
                                $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                                $this.target = p.pager.page_container[0].getBoundingClientRect();
                                $this.target = {
                                    top: $this.target.top + p.pager.current_padding,
                                    left: $this.target.left + p.pager.current_padding,
                                    right: $this.target.right - p.pager.current_padding,
                                    bottom: $this.target.bottom - p.pager.current_padding
                                };
                                $this.moveStart = pos;

                                p.pager.paging_handled_by_animator = true;

                                $this.startDragLeft();
                            }
                        }
                        else
                        {
                            var rect = p.pager.holders[CONST.CUR][0].getBoundingClientRect(),
                                targetRectLeft = { top: rect.top, left: rect.left, right: rect.left + rect.width * 0.1, bottom: rect.bottom },
                                targetRectRight = { top: rect.top, left: rect.right - rect.width * 0.1, right: rect.right, bottom: rect.bottom },
                                pos = $this.posFromEvent(e);

                            // One time calculation
                            $this.size = { width: rect.width, height: rect.height };
                            $this.target = p.pager.page_container[0].getBoundingClientRect();
                            $this.target = {
                                top: $this.target.top + p.pager.current_padding,
                                left: $this.target.left + p.pager.current_padding,
                                right: $this.target.right - p.pager.current_padding,
                                bottom: $this.target.bottom - p.pager.current_padding
                            };
                            $this.moveStart = pos;

                            if ($this.intersects(pos, targetRectRight))
                            {
                                p.pager.paging_handled_by_animator = true;
                                $this.startDragRight();
                            }
                            else if ($this.intersects(pos, targetRectLeft))
                            {
                                p.pager.paging_handled_by_animator = true;
                                $this.startDragLeft();
                            }
                        }
                    }
                }
            },
            listenerMove: function (e)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant;
                if (!$this.lockAnimation && $this.dragging)
                {
                    var pos = $this.posFromEvent(e);
                    $this.last = pos;
                    if ($this.direction == "right")
                    {
                        var distX = Math.abs($this.target.right - pos.x);

                        $this.animateRight(distX);
                    }
                    else if ($this.direction == "left")
                    {
                        var distX = Math.abs($this.target.left - pos.x);

                        $this.animateLeft(distX);
                    }
                }
            },
            listenerEnd: function (e)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant;

                if (!$this.lockAnimation && $this.dragging)
                {
                    p.reader.playPageSound();
                    var pos = $this.last,
                        finish = function ()
                        {
                            $this.dragged.removeClass("animate");
                            $this.revealed.removeClass("wait");
                            $this.dragged.setStyle("transform", "");
                            $this.revealed.setStyle("transform", "");
                            p.pager.paging_handled_by_animator = false;
                            $this.last = null;
                            $this.lockAnimation = false;
                            p.overlays.setHidden(false);
                        };

                    if ($this.direction == "right")
                    {
                        var distX = pos != null ? Math.abs($this.target.right - pos.x) : 0;
                        var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;
                        var triggerDistance = finalDistance / 10;

                        if (distX < finalDistance)
                        {
                            if (distX >= triggerDistance)
                            {
                                $this.animateRightTo(distX, finalDistance, function ()
                                {
                                    p.pager.goForward(function ()
                                    {
                                        finish();
                                    });
                                });
                            }
                            else
                            {
                                $this.animateRightTo(distX, 0, function ()
                                {
                                    finish();
                                });
                            }
                        }
                        else
                        {
                            p.pager.goForward(function ()
                            {
                                finish();
                            });
                        }
                    }
                    else if ($this.direction == "left")
                    {
                        var distX = pos != null ? Math.abs($this.target.left - pos.x) : 0;
                        var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;
                        var triggerDistance = finalDistance / 8;

                        if (distX < finalDistance)
                        {
                            if (distX >= triggerDistance)
                            {
                                $this.animateLeftTo(distX, finalDistance, function ()
                                {
                                    p.pager.goBack(function ()
                                    {
                                        finish();
                                    });
                                });
                            }
                            else
                            {
                                $this.animateLeftTo(distX, 0, function ()
                                {
                                    finish();
                                });
                            }
                        }
                        else
                        {
                            p.pager.goBack(function ()
                            {
                                finish();
                            });
                        }
                    }
                }

                $this.dragging = false;
            },


            lockAnimation: false,

            doFullAnimationNext: function (callback)
            {
                var $this = p.swipe_animator,
                    CONST = p.pager.constant;

                if (!$this.lockAnimation && p.pager.can_go_forward)
                {
                    $this.lockAnimation = true;

                    p.reader.playPageSound();

                    var pos = $this.last,
                        finish = function ()
                        {
                            $this.dragged.removeClass("animate");
                            $this.revealed.removeClass("wait");
                            $this.dragged.setStyle("transform", "");
                            $this.revealed.setStyle("transform", "");

                            if (callback && p.is.function(callback))
                                callback();

                            $this.lockAnimation = false;
                            p.pager.paging_handled_by_animator = false;
                            $this.last = null;
                            p.overlays.setHidden(false);
                        },
                        rectLeft = p.pager.holders[CONST.CUR_LEFT][0].getBoundingClientRect(),
                        rectRight = p.pager.holders[CONST.CUR_RIGHT][0].getBoundingClientRect();

                    $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                    $this.target = p.pager.page_container[0].getBoundingClientRect();
                    $this.target = {
                        top: $this.target.top + p.pager.current_padding,
                        left: $this.target.left + p.pager.current_padding,
                        right: $this.target.right - p.pager.current_padding,
                        bottom: $this.target.bottom - p.pager.current_padding
                    };

                    var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;

                    $this.direction = "right";

                    $this.animateRightTo(0, finalDistance, function ()
                    {
                        p.pager.goForward(null, function ()
                        {
                            finish();
                        });
                    });
                }
            },
            doFullAnimationPrev: function (callback)
            {
                var $this = p.swipe_animator,
                    CONST = p.pager.constant;

                if (!$this.lockAnimation && p.pager.can_go_back)
                {
                    $this.lockAnimation = true;

                    p.reader.playPageSound();

                    var pos = $this.last,
                        finish = function ()
                        {
                            $this.dragged.removeClass("animate");
                            $this.revealed.removeClass("wait");
                            $this.dragged.setStyle("transform", "");
                            $this.revealed.setStyle("transform", "");

                            if (callback && p.is.function(callback))
                                callback();

                            $this.lockAnimation = false;
                            p.pager.paging_handled_by_animator = false;
                            $this.last = null;
                            p.overlays.setHidden(false);
                        },
                        rectLeft = p.pager.holders[CONST.CUR_LEFT][0].getBoundingClientRect(),
                        rectRight = p.pager.holders[CONST.CUR_RIGHT][0].getBoundingClientRect();

                    $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                    $this.target = p.pager.page_container[0].getBoundingClientRect();
                    $this.target = {
                        top: $this.target.top + p.pager.current_padding,
                        left: $this.target.left + p.pager.current_padding,
                        right: $this.target.right - p.pager.current_padding,
                        bottom: $this.target.bottom - p.pager.current_padding
                    };

                    var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;

                    $this.direction = "left";

                    $this.animateLeftTo(0, finalDistance, function ()
                    {
                        p.pager.goBack(null, function ()
                        {
                            finish();
                        });
                    });
                }
            },

            startDragRight: function (e)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

                $this.dragging = true;
                cancelFrame($this.animationTimeout);

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.revealed = p.e([p.pager.holders[CONST.CUR_LEFT], p.pager.holders[CONST.CUR_RIGHT]]),
                        $this.dragged = p.e([p.pager.holders[CONST.NEXT_LEFT], p.pager.holders[CONST.NEXT_RIGHT]]);
                    p.e([p.pager.holders[CONST.PREV_LEFT], p.pager.holders[CONST.PREV_LEFT]]).addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_left + 2))
                        p.e(p.pager.holders[CONST.NEXT_LEFT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.NEXT_LEFT]).addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_right + 2))
                        p.e(p.pager.holders[CONST.NEXT_RIGHT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.NEXT_RIGHT]).addClass("hidden");


                    if (p.pager.showPage(p.pager.current_page_left))
                        p.e(p.pager.holders[CONST.CUR_LEFT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.CUR_LEFT]).addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_right))
                        p.e(p.pager.holders[CONST.CUR_RIGHT]).removeClass("hidden");
                    else
                        p.e(p.pager.holders[CONST.CUR_RIGHT]).addClass("hidden");
                }
                else
                {
                    $this.revealed = p.pager.holders[CONST.CUR],
                        $this.dragged = p.pager.holders[CONST.NEXT];
                    p.pager.holders[CONST.PREV].addClass("hidden");
                }

                $this.dragged.addClass("animate");
                $this.revealed.addClass("wait");

                //$this.dragged.setStyle("opacity", "0");
                $this.dragged.setStyle("transform", "translate(200%, 0)");
                $this.revealed.setStyle("transform", "translate(0%, 0)");

                $this.direction = "right";
            },
            startDragLeft: function (e)
            {
                var $this = p.swipe_animator, CONST = p.pager.constant,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

                $this.dragging = true;
                cancelFrame($this.animationTimeout);

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.revealed = p.e([p.pager.holders[CONST.CUR_LEFT], p.pager.holders[CONST.CUR_RIGHT]]),
                        $this.dragged = p.e([p.pager.holders[CONST.PREV_LEFT], p.pager.holders[CONST.PREV_RIGHT]]);
                    p.e([p.pager.holders[CONST.NEXT_LEFT], p.pager.holders[CONST.NEXT_RIGHT]]).addClass("hidden");
                }
                else
                {
                    $this.dragged = p.pager.holders[CONST.PREV],
                        $this.revealed = p.pager.holders[CONST.CUR];
                    p.pager.holders[CONST.NEXT].addClass("hidden");
                }

                $this.dragged.addClass("animate").removeClass("hidden");
                $this.revealed.addClass("wait").removeClass("hidden");

                //$this.dragged.setStyle("opacity", "0");
                $this.dragged.setStyle("transform", "translate(-200%, 0)");
                $this.revealed.setStyle("transform", "translate(0%, 0)");

                $this.direction = "left";
            }

        },

        flip_animator:
        {
            listen: true,
            dragging: false,
            dragged: null,
            revealed: null,
            hidden: null,
            fullhidden: null,
            moveStart: null,
            size: null,
            last: null,
            target: null,
            direction: "none",
            animationTimeout: null,

            init: function ()
            {
                p.e(document).on("mousedown touchstart", this.listenerStart);
                p.e(document).on("mousemove touchmove", this.listenerMove);
                p.e(document).on("mouseup touchend mouseleave touchleave", this.listenerEnd);
            },

            destruct: function ()
            {
                p.e(document).off("mousedown touchstart", this.listenerStart);
                p.e(document).off("mousemove touchmove", this.listenerMove);
                p.e(document).off("mouseup touchend mouseleave touchleave", this.listenerEnd);
            },

            intersects: function (pos, rect)
            {
                return (pos.x > rect.left && pos.x < rect.right && pos.y > rect.top && pos.y < rect.bottom);
            },

            posFromEvent: function (event)
            {
                if (event.type.startsWith("touch"))
                {
                    if (event.touches.length == 1)
                    {
                        return { x: event.touches[0].pageX, y: event.touches[0].pageY };
                    }
                    else if (event.touches.length == 2)
                    {
                        var a = { x: event.touches[0].pageX, y: event.touches[0].pageY },
                            b = { x: event.touches[1].pageX, y: event.touches[1].pageY },
                            midpoint = p.math.Midpoint2D(a, b);

                        return { x: midpoint.x, y: midpoint.y, dist: p.math.Dist2D(a, b) };
                    }
                }
                else
                {
                    return { x: event.pageX, y: event.pageY };
                }
            },

            animateRight: function (dist)
            {
                var $this = p.flip_animator, CONST = p.pager.constant;
                $this.dragged.addClass("animate");
                $this.revealed.addClass("wait");
                p.overlays.setHidden(true);

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    // Ensure style
                    if (p.pager.showPage(p.pager.current_page_left + 2))
                        $this.dragged.removeClass("hidden");
                    else
                        $this.dragged.addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_right + 2))
                        $this.revealed.removeClass("hidden");
                    else
                        $this.revealed.addClass("hidden");

                    $this.fullhidden.addClass("hidden");

                    $this.dragged.setStyle("left", "auto");

                    $this.revealed.setStyle("left", "auto");
                    $this.revealed.setStyle("right", p.pager.current_padding + "px");
                    $this.revealed.find(".wrapper").setStyle("left", "auto");
                    $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");
                    $this.dragged.find(".wrapper").setStyle("right", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                    $this.hidden.find(".wrapper").setStyle("right", "auto");
                    $this.hidden.find(".wrapper").setStyle("width", $this.size.width + "px");

                    // Animate
                    $this.dragged.setStyle("right", Math.min((p.pager.current_padding + (dist / 2)), $this.size.width + p.pager.current_padding) + "px");
                    $this.dragged.setStyle("width", Math.min((dist / 2), $this.size.width) + "px");

                    $this.hidden.setStyle("width", Math.max(0, $this.size.width - dist) + "px");

                    $this.revealed.setStyle("width", Math.min((dist / 2), $this.size.width) + "px");
                }
                else
                {
                    // Ensure style
                    if (p.pager.showPage(p.pager.current_page + 1))
                        $this.revealed.removeClass("hidden");

                    $this.dragged.find(".wrapper").setStyle("left", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");

                    // Animate
                    $this.dragged.setStyle("right", Math.min((p.pager.current_padding + (dist)), $this.size.width + p.pager.current_padding) + "px");
                    $this.dragged.setStyle("width", Math.max(($this.size.width - dist), 0) + "px");
                }

            },
            animateLeft: function (dist)
            {
                var $this = p.flip_animator, CONST = p.pager.constant;

                $this.dragged.addClass("animate");
                $this.revealed.addClass("wait");
                p.overlays.setHidden(true);

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    // Ensure Style
                    if (p.pager.showPage(p.pager.current_page_left - 2))
                        $this.revealed.removeClass("hidden");
                    else
                        $this.revealed.addClass("hidden");

                    if (p.pager.showPage(p.pager.current_page_right - 2))
                        $this.dragged.removeClass("hidden");
                    else
                        $this.dragged.addClass("hidden");

                    $this.dragged.setStyle("right", "auto");
                    $this.dragged.find(".wrapper").setStyle("left", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                    $this.revealed.find(".wrapper").setStyle("right", "auto");
                    $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");
                    $this.hidden.find(".wrapper").setStyle("left", "auto");
                    $this.hidden.find(".wrapper").setStyle("width", $this.size.width + "px");

                    $this.revealed.setStyle("right", "auto");
                    $this.revealed.setStyle("left", p.pager.current_padding + "px");

                    // Animate
                    $this.dragged.setStyle("left", Math.min((p.pager.current_padding + (dist / 2)), $this.size.width + p.pager.current_padding) + "px");
                    $this.dragged.setStyle("width", Math.min((dist / 2), $this.size.width) + "px");

                    $this.hidden.setStyle("width", Math.max(0, $this.size.width - dist) + "px");

                    $this.revealed.setStyle("width", Math.min((dist / 2), $this.size.width) + "px");
                }
                else
                {
                    // Ensure Style
                    if (p.pager.showPage(p.pager.current_page - 1))
                        $this.revealed.removeClass("hidden");
                    else
                        $this.revealed.addClass("hidden");

                    $this.dragged.setStyle("right", p.pager.current_padding + "px");
                    $this.dragged.find(".wrapper").setStyle("left", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                    $this.revealed.find(".wrapper").setStyle("left", "auto");
                    $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");

                    // Animate
                    $this.dragged.setStyle("left", Math.min(p.pager.current_padding + dist, $this.size.width + p.pager.current_padding) + "px");
                    $this.dragged.setStyle("width", Math.max(0, (($this.size.width) - dist)) + "px");
                    $this.revealed.setStyle("width", Math.min(dist, $this.size.width) + "px");
                }
            },
            animateRightTo: function (from, to, callback)
            {
                var $this = p.flip_animator,
                    CONST = p.pager.constant,
                    t = 0,
                    max = 30,
                    requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
                var fnc = function ()
                {
                    $this.animateRight(p.math.easing.easeInOutQuad(t++, from, to - from, max));
                    if (t < max)
                        $this.animationTimeout = requestFrame(fnc);
                    else if (p.is.function(callback))
                    {
                        callback();
                        cancelFrame($this.animationTimeout);
                    }
                };

                cancelFrame($this.animationTimeout);
                $this.animationTimeout = requestFrame(fnc);
            },
            animateLeftTo: function (from, to, callback)
            {
                var $this = p.flip_animator,
                    CONST = p.pager.constant,
                    t = 0,
                    max = 30,
                    requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
                var fnc = function ()
                {
                    $this.animateLeft(p.math.easing.easeInOutQuad(t++, from, to - from, max));
                    if (t < max)
                        $this.animationTimeout = requestFrame(fnc);
                    //$this.animationTimeout = setTimeout(fnc, 300 / max);
                    else if (p.is.function(callback))
                    {
                        callback();
                        cancelFrame($this.animationTimeout);
                    }
                };

                //clearTimeout($this.animationTimeout);
                //fnc();
                cancelFrame($this.animationTimeout);
                $this.animationTimeout = requestFrame(fnc);
            },

            listenerStart: function (e)
            {
                var $this = p.flip_animator, CONST = p.pager.constant;
                // console.log(p.e(e.target).getParent(".overlay"));
                if (p.e(e.target).hasClass("overlay") || p.e(e.target).getParent(".overlay").length > 0) return;
                if (!$this.lockAnimation && !p.pager.touchBlocked)
                {
                    if (p.pager.current_animation == CONST.FLIP)
                    {
                        if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                        {
                            var rectLeft = p.pager.holders[CONST.CUR_LEFT][0].getBoundingClientRect(),
                                rectRight = p.pager.holders[CONST.CUR_RIGHT][0].getBoundingClientRect(),
                                targetRectLeft = { top: rectLeft.top, left: rectLeft.left, right: rectLeft.left + rectLeft.width * 0.1, bottom: rectLeft.bottom },
                                targetRectRight = { top: rectRight.top, left: rectRight.right - rectRight.width * 0.1, right: rectRight.right, bottom: rectRight.bottom },
                                pos = $this.posFromEvent(e);

                            if ($this.intersects(pos, targetRectRight) && p.pager.showPage(p.pager.current_page_left + 2))
                            {
                                // One time calculation
                                $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                                $this.target = p.pager.page_container[0].getBoundingClientRect();
                                $this.target = {
                                    top: $this.target.top + p.pager.current_padding,
                                    left: $this.target.left + p.pager.current_padding,
                                    right: $this.target.right - p.pager.current_padding,
                                    bottom: $this.target.bottom - p.pager.current_padding
                                };
                                $this.moveStart = pos;

                                p.pager.paging_handled_by_animator = true;

                                $this.startDragRight();
                            }
                            else if ($this.intersects(pos, targetRectLeft) && p.pager.showPage(p.pager.current_page_right - 2))
                            {
                                // One time calculation
                                $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                                $this.target = p.pager.page_container[0].getBoundingClientRect();
                                $this.target = {
                                    top: $this.target.top + p.pager.current_padding,
                                    left: $this.target.left + p.pager.current_padding,
                                    right: $this.target.right - p.pager.current_padding,
                                    bottom: $this.target.bottom - p.pager.current_padding
                                };
                                $this.moveStart = pos;

                                p.pager.paging_handled_by_animator = true;

                                $this.startDragLeft();
                            }
                        }
                        else
                        {
                            var rect = p.pager.holders[CONST.CUR][0].getBoundingClientRect(),
                                targetRectLeft = { top: rect.top, left: rect.left, right: rect.left + rect.width * 0.1, bottom: rect.bottom },
                                targetRectRight = { top: rect.top, left: rect.right - rect.width * 0.1, right: rect.right, bottom: rect.bottom },
                                pos = $this.posFromEvent(e);

                            // One time calculation
                            $this.size = { width: rect.width, height: rect.height };
                            $this.target = p.pager.page_container[0].getBoundingClientRect();
                            $this.target = {
                                top: $this.target.top + p.pager.current_padding,
                                left: $this.target.left + p.pager.current_padding,
                                right: $this.target.right - p.pager.current_padding,
                                bottom: $this.target.bottom - p.pager.current_padding
                            };
                            $this.moveStart = pos;

                            if ($this.intersects(pos, targetRectRight) && p.pager.showPage(p.pager.current_page + 1))
                            {
                                p.pager.paging_handled_by_animator = true;
                                $this.startDragRight();
                            }
                            else if ($this.intersects(pos, targetRectLeft) && p.pager.showPage(p.pager.current_page - 1))
                            {
                                p.pager.paging_handled_by_animator = true;
                                $this.startDragLeft();
                            }
                        }
                    }
                }
            },
            listenerMove: function (e)
            {
                var $this = p.flip_animator, CONST = p.pager.constant;
                if (!$this.lockAnimation && $this.dragging)
                {
                    var pos = $this.posFromEvent(e);
                    $this.last = pos;
                    if ($this.direction == "right")
                    {
                        var distX = Math.abs($this.target.right - pos.x);

                        $this.animateRight(distX);
                    }
                    else if ($this.direction == "left")
                    {
                        var distX = Math.abs($this.target.left - pos.x);

                        $this.animateLeft(distX);
                    }
                }
            },
            listenerEnd: function (e)
            {
                var $this = p.flip_animator, CONST = p.pager.constant;

                if (!$this.lockAnimation && $this.dragging)
                {
                    var pos = $this.last,
                        finish = function ()
                        {
                            $this.dragged.removeClass("animate");
                            $this.revealed.removeClass("wait");

                            $this.dragged.find(".wrapper").setAttr("style", "");
                            $this.revealed.find(".wrapper").setAttr("style", "");
                            $this.hidden.find(".wrapper").setAttr("style", "");
                            $this.dragged.find(".loader").setAttr("style", "");
                            $this.revealed.find(".loader").setAttr("style", "");
                            p.pager.paging_handled_by_animator = false;
                            $this.last = null;
                            $this.lockAnimation = false;
                            p.overlays.setHidden(false);
                        };

                    if ($this.direction == "right")
                    {
                        var distX = pos != null ? Math.abs($this.target.right - pos.x) : 0;
                        var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;
                        var triggerDistance = finalDistance / 10;

                        if (distX < finalDistance)
                        {
                            if (distX >= triggerDistance)
                            {
                                p.reader.playPageSound();
                                $this.animateRightTo(distX, finalDistance, function ()
                                {
                                    p.pager.goForward(null, function ()
                                    {
                                        finish();
                                    });
                                });
                            }
                            else
                            {
                                $this.animateRightTo(distX, 0, function ()
                                {
                                    finish();
                                });
                            }
                        }
                        else
                        {
                            p.pager.goForward(null, function ()
                            {
                                finish();
                            });
                        }
                    }
                    else if ($this.direction == "left")
                    {
                        var distX = pos != null ? Math.abs($this.target.left - pos.x) : 0;
                        var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;
                        var triggerDistance = finalDistance / 10;

                        if (distX < finalDistance)
                        {
                            if (distX >= triggerDistance)
                            {
                                p.reader.playPageSound();
                                $this.animateLeftTo(distX, finalDistance, function ()
                                {
                                    p.pager.goBack(function ()
                                    {
                                        finish();
                                    });
                                });
                            }
                            else
                            {
                                $this.animateLeftTo(distX, 0, function ()
                                {
                                    finish();
                                });
                            }
                        }
                        else
                        {
                            p.pager.goBack(function ()
                            {
                                finish();
                            });
                        }
                    }
                }

                $this.dragging = false;
            },

            lockAnimation: false,

            doFullAnimationNext: function (callback)
            {
                var $this = p.flip_animator,
                    CONST = p.pager.constant;

                //p.reader.dbg("anim-try-next");

                if (!$this.lockAnimation && p.pager.can_go_forward)
                {
                    $this.lockAnimation = true;

                    //p.reader.dbg("anim-do-next");

                    p.reader.playPageSound();

                    var pos = $this.last,
                        finish = function ()
                        {
                            $this.dragged.removeClass("animate");
                            $this.revealed.removeClass("wait");

                            $this.dragged.find(".wrapper").setAttr("style", "");
                            $this.revealed.find(".wrapper").setAttr("style", "");
                            $this.hidden.find(".wrapper").setAttr("style", "");
                            $this.dragged.find(".loader").setAttr("style", "");
                            $this.revealed.find(".loader").setAttr("style", "");

                            if (callback && p.is.function(callback))
                                callback();

                            $this.lockAnimation = false;
                            p.pager.paging_handled_by_animator = false;
                            $this.last = null;
                            p.overlays.setHidden(false);
                        };

                    if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    {
                        var rectLeft = p.pager.holders[CONST.CUR_LEFT][0].getBoundingClientRect(),
                            rectRight = p.pager.holders[CONST.CUR_RIGHT][0].getBoundingClientRect();

                        $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                    }
                    else
                    {
                        var rect = p.pager.holders[CONST.CUR][0].getBoundingClientRect();
                        $this.size = { width: rect.width, height: rect.height };
                    }

                    $this.target = p.pager.page_container[0].getBoundingClientRect();
                    $this.target =
                    {
                        top: $this.target.top + p.pager.current_padding,
                        left: $this.target.left + p.pager.current_padding,
                        right: $this.target.right - p.pager.current_padding,
                        bottom: $this.target.bottom - p.pager.current_padding
                    };

                    var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;

                    if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    {
                        $this.dragged = p.pager.holders[CONST.NEXT_LEFT],
                            $this.revealed = p.pager.holders[CONST.NEXT_RIGHT];
                        $this.hidden = p.pager.holders[CONST.CUR_RIGHT];
                        $this.fullhidden = p.e([p.pager.holders[CONST.PREV_LEFT], p.pager.holders[CONST.PREV_RIGHT]]);

                        if (p.pager.showPage(p.pager.current_page_left + 2))
                            $this.dragged.removeClass("hidden");
                        if (p.pager.showPage(p.pager.current_page_right + 2))
                            $this.revealed.removeClass("hidden");

                        $this.fullhidden.addClass("hidden");
                    }
                    else
                    {
                        $this.dragged = p.pager.holders[CONST.CUR],
                            $this.revealed = p.pager.holders[CONST.NEXT];
                        $this.hidden = p.e();
                        $this.fullhidden = p.pager.holders[CONST.PREV];

                        if (p.pager.showPage(p.pager.current_page + 1))
                            $this.revealed.removeClass("hidden");
                    }

                    $this.dragged.addClass("animate");
                    $this.revealed.addClass("wait");

                    if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    {
                        $this.dragged.setStyle("left", "auto");
                        $this.dragged.setStyle("right", p.pager.current_padding + "px");
                        $this.dragged.setStyle("width", "0px");

                        $this.revealed.setStyle("left", "auto");
                        $this.revealed.setStyle("right", p.pager.current_padding + "px");
                        $this.revealed.setStyle("width", "0px");
                        $this.revealed.find(".wrapper").setStyle("left", "auto");
                        $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");
                    }
                    else
                    {
                        $this.dragged.setStyle("right", p.pager.current_padding + "px");
                        $this.dragged.setStyle("width", $this.size.width);
                        $this.dragged.find(".wrapper").setStyle("left", "auto");
                        $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                    }

                    $this.direction = "right";

                    $this.animateRightTo(0, finalDistance, function ()
                    {
                        p.pager.goForward(null, function ()
                        {
                            finish();
                        });
                    });
                }
            },
            doFullAnimationPrev: function (callback)
            {
                var $this = p.flip_animator,
                    CONST = p.pager.constant;

                if (!$this.lockAnimation && p.pager.can_go_back)
                {
                    $this.lockAnimation = true;

                    p.reader.playPageSound();

                    var pos = $this.last,
                        finish = function ()
                        {
                            $this.dragged.removeClass("animate");
                            $this.revealed.removeClass("wait");

                            $this.dragged.find(".wrapper").setAttr("style", "");
                            $this.revealed.find(".wrapper").setAttr("style", "");
                            $this.hidden.find(".wrapper").setAttr("style", "");
                            $this.dragged.find(".loader").setAttr("style", "");
                            $this.revealed.find(".loader").setAttr("style", "");

                            if (callback && p.is.function(callback))
                                callback();

                            $this.lockAnimation = false;
                            p.pager.paging_handled_by_animator = false;
                            $this.last = null;
                            p.overlays.setHidden(false);
                        };


                    if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    {
                        var rectLeft = p.pager.holders[CONST.CUR_LEFT][0].getBoundingClientRect(),
                            rectRight = p.pager.holders[CONST.CUR_RIGHT][0].getBoundingClientRect();

                        $this.size = { width: rectLeft.width || rectRight.width, height: rectLeft.height || rectRight.height };
                    }
                    else
                    {
                        var rect = p.pager.holders[CONST.CUR][0].getBoundingClientRect();
                        $this.size = { width: rect.width, height: rect.height };
                    }

                    $this.target = p.pager.page_container[0].getBoundingClientRect();
                    $this.target = {
                        top: $this.target.top + p.pager.current_padding,
                        left: $this.target.left + p.pager.current_padding,
                        right: $this.target.right - p.pager.current_padding,
                        bottom: $this.target.bottom - p.pager.current_padding
                    };

                    var finalDistance = (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE) ? $this.size.width * 2 : $this.size.width;

                    if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    {
                        $this.dragged = p.pager.holders[CONST.PREV_RIGHT],
                            $this.revealed = p.pager.holders[CONST.PREV_LEFT];
                        $this.hidden = p.pager.holders[CONST.CUR_LEFT];
                        $this.fullhidden = p.e([p.pager.holders[CONST.NEXT_LEFT], p.pager.holders[CONST.NEXT_RIGHT]]);

                        if (p.pager.showPage(p.pager.current_page_left - 2))
                            $this.revealed.removeClass("hidden");
                        if (p.pager.showPage(p.pager.current_page_right - 2))
                            $this.dragged.removeClass("hidden");

                        $this.fullhidden.addClass("hidden");
                    }
                    else
                    {
                        $this.dragged = p.pager.holders[CONST.CUR],
                            $this.revealed = p.pager.holders[CONST.PREV];
                        $this.hidden = p.e();
                        $this.fullhidden = p.pager.holders[CONST.NEXT];

                        if (p.pager.showPage(p.pager.current_page - 1))
                            $this.revealed.removeClass("hidden");

                        $this.dragged.removeClass("hidden");
                    }

                    $this.dragged.addClass("animate");
                    $this.revealed.addClass("wait");

                    if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    {
                        $this.dragged.setStyle("right", "auto");
                        $this.dragged.setStyle("left", p.pager.current_padding + "px");
                        $this.dragged.setStyle("width", "0px");
                        $this.dragged.find(".wrapper").setStyle("left", "auto");
                        $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");

                        $this.revealed.setStyle("right", "auto");
                        $this.revealed.setStyle("left", p.pager.current_padding + "px");
                        $this.revealed.setStyle("width", "0px");
                    }
                    else
                    {
                        $this.dragged.setStyle("right", p.pager.current_padding + "px");
                        $this.dragged.find(".wrapper").setStyle("left", "auto");
                        $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                        $this.revealed.find(".wrapper").setStyle("left", "auto");
                        $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");
                    }

                    $this.direction = "left";

                    $this.animateLeftTo(0, finalDistance, function ()
                    {
                        p.pager.goBack(null, function ()
                        {
                            finish();
                        });
                    });
                }
            },

            startDragRight: function (e)
            {
                var $this = p.flip_animator, CONST = p.pager.constant,
                    requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

                $this.dragging = true;
                cancelFrame($this.animationTimeout);
                //p.pager.renderStop();

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.dragged = p.pager.holders[CONST.NEXT_LEFT],
                        $this.revealed = p.pager.holders[CONST.NEXT_RIGHT];
                    $this.hidden = p.pager.holders[CONST.CUR_RIGHT];
                    $this.fullhidden = p.e([p.pager.holders[CONST.PREV_LEFT], p.pager.holders[CONST.PREV_RIGHT]]);

                    if (p.pager.showPage(p.pager.current_page_left + 2))
                        $this.dragged.removeClass("hidden");
                    if (p.pager.showPage(p.pager.current_page_right + 2))
                        $this.revealed.removeClass("hidden");

                    $this.fullhidden.addClass("hidden");
                }
                else
                {
                    $this.dragged = p.pager.holders[CONST.CUR],
                        $this.revealed = p.pager.holders[CONST.NEXT];
                    $this.hidden = p.e();
                    $this.fullhidden = p.pager.holders[CONST.PREV];

                    if (p.pager.showPage(p.pager.current_page + 1))
                        $this.revealed.removeClass("hidden");
                }

                $this.dragged.addClass("animate");
                $this.revealed.addClass("wait");

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.dragged.setStyle("left", "auto");
                    $this.dragged.setStyle("right", p.pager.current_padding + "px");
                    $this.dragged.setStyle("width", "0px");

                    $this.revealed.setStyle("left", "auto");
                    $this.revealed.setStyle("right", p.pager.current_padding + "px");
                    $this.revealed.setStyle("width", "0px");
                    $this.revealed.find(".wrapper").setStyle("left", "auto");
                    $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");
                }
                else
                {
                    $this.dragged.setStyle("right", p.pager.current_padding + "px");
                    $this.dragged.setStyle("width", $this.size.width);
                    $this.dragged.find(".wrapper").setStyle("left", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                }

                $this.direction = "right";
            },
            startDragLeft: function (e)
            {
                var $this = p.flip_animator, CONST = p.pager.constant,
                    requestFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
                    cancelFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

                $this.dragging = true;
                cancelFrame($this.animationTimeout);
                //p.pager.renderStop();

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.dragged = p.pager.holders[CONST.PREV_RIGHT],
                        $this.revealed = p.pager.holders[CONST.PREV_LEFT];
                    $this.hidden = p.pager.holders[CONST.CUR_LEFT];
                    $this.fullhidden = p.e([p.pager.holders[CONST.NEXT_LEFT], p.pager.holders[CONST.NEXT_RIGHT]]);

                    if (p.pager.showPage(p.pager.current_page_left - 2))
                        $this.revealed.removeClass("hidden");
                    if (p.pager.showPage(p.pager.current_page_right - 2))
                        $this.dragged.removeClass("hidden");

                    $this.fullhidden.addClass("hidden");
                }
                else
                {
                    $this.dragged = p.pager.holders[CONST.CUR],
                        $this.revealed = p.pager.holders[CONST.PREV];
                    $this.hidden = p.e();
                    $this.fullhidden = p.pager.holders[CONST.NEXT];

                    if (p.pager.showPage(p.pager.current_page - 1))
                        $this.revealed.removeClass("hidden");

                    $this.dragged.removeClass("hidden");
                }

                $this.dragged.addClass("animate");
                $this.revealed.addClass("wait");

                if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                {
                    $this.dragged.setStyle("right", "auto");
                    $this.dragged.setStyle("left", p.pager.current_padding + "px");
                    $this.dragged.setStyle("width", "0px");
                    $this.dragged.find(".wrapper").setStyle("left", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");

                    $this.revealed.setStyle("right", "auto");
                    $this.revealed.setStyle("left", p.pager.current_padding + "px");
                    $this.revealed.setStyle("width", "0px");
                }
                else
                {
                    $this.dragged.setStyle("right", p.pager.current_padding + "px");
                    $this.dragged.find(".wrapper").setStyle("left", "auto");
                    $this.dragged.find(".wrapper").setStyle("width", $this.size.width + "px");
                    $this.revealed.find(".wrapper").setStyle("left", "auto");
                    $this.revealed.find(".wrapper").setStyle("width", $this.size.width + "px");
                }

                $this.direction = "left";
            }
        },

        overlays:
        {
            elements: [],
            objects: [],
            container: null,
            lastRenderPage: -1,
            lastRenderZoom: -1,
            lastRenderMode: -1,

            init: function ()
            {
                var $this = p.overlays;
                this.container = p.e("#reader-container .overlayer");
                // this.elements = [];
                // debugger;
                if (!p.pager.pages)
                {
                    return setTimeout($this.init, 25);
                }
                // debugger;

                for (var i in p.pager.pages)
                {
                    var page = p.pager.pages[i];
                    if (page.overlays)
                    {
                        for (var k in page.overlays)
                        {
                            var overlay = page.overlays[k];
                            overlay.display = overlay["class"];
                            overlay.openWidth = parseInt(overlay["popup_width"]);
                            overlay.openHeight = parseInt(overlay["popup_height"]);
                            overlay.page = i;

                            $this.elements.push(overlay);
                            // debugger;
                        }
                    }
                }
                // var elements = p.reader.container.find(".overlay"), i = 0, l = elements.length;
                // for (; i < l; i++)
                // {
                //     this.parse(elements[i]);
                // }
                // elements.remove();

            },

            setHidden: function (hidden)
            {
                var $this = p.overlays;

                if (hidden)
                    $this.container.addClass("hidden");
                else
                    $this.container.removeClass("hidden");

            },

            parse: function (e)
            {
                var ele = p.e(e);

                var data = {
                    html: ele.getHtml().trim(),
                    display: ele.getAttr("data-display"),
                    type: ele.getAttr("data-type"),
                    x: parseInt(ele.getAttr("data-x")),
                    y: parseInt(ele.getAttr("data-y")),
                    width: parseInt(ele.getAttr("data-width")),
                    height: parseInt(ele.getAttr("data-height")),
                    page: parseInt(ele.getAttr("data-page")),
                    openWidth: parseInt(ele.getAttr("data-open-width")),
                    openHeight: parseInt(ele.getAttr("data-open-height")),
                    icon: ele.getAttr("data-icon")
                };

                this.elements.push(data);
            },

            render: function ()
            {
                var $this = p.overlays, i = 0, l = $this.elements.length, CONST = p.pager.constant, pgr = p.pager;

                if ($this.lastRenderMode != pgr.current_mode || $this.lastRenderPage != pgr.current_page || $this.lastRenderZoom != pgr.current_zoom)
                {
                    //console.log("Render");
                    $this.lastRenderMode = pgr.current_mode;
                    $this.lastRenderPage = pgr.current_page;
                    $this.lastRenderZoom = pgr.current_zoom;

                    $this.prepare();
                    $this.objects = [];

                    if (p.pager.current_mode == CONST.SINGLE)
                    {
                        var c = $this.container.find(".page");
                        //console.log(c);
                    }
                    else if (p.pager.current_mode == CONST.DOUBLE)
                    {
                        var left = $this.container.find(".left");
                        var right = $this.container.find(".right");
                    }

                    for (; i < l; i++)
                    {

                        var e = $this.elements[i];
                        var o;
                        //console.log(e);

                        if (p.pager.current_mode == CONST.SINGLE)
                        {
                            if (e.page == p.pager.current_page)
                            {
                                o = $this.make(e).appendTo(c);
                            }
                        }
                        else if (p.pager.current_mode == CONST.DOUBLE)
                        {
                            if (e.page == p.pager.current_page_left)
                            {
                                o = $this.make(e).appendTo(left);
                            }
                            else if (e.page == p.pager.current_page_right)
                            {
                                o = $this.make(e).appendTo(right);
                            }
                        }

                        if (o)
                        {
                            $this.objects.push(o);
                            //console.log($this.objects);
                        }
                    }
                }
            },

            getAvailableSpace: function ()
            {
                var rect = p.math.rect(p.pager.content_container[0].scrollTop,
                    p.pager.content_container[0].scrollLeft,
                    p.reader.container.getHeight() + p.pager.content_container[0].scrollTop,
                    p.reader.container.getWidth() + p.pager.content_container[0].scrollLeft);

                var topBlocked = false;
                var leftBlocked = false;
                var rightBlocked = false;
                var bottomBlocked = false;

                if (p.e("#reader-container .controls .top, #reader-container .controls .topleft .horizontal, #reader-container .controls .topright .horizontal").children().length > 0)
                    topBlocked = true;

                if (p.e("#reader-container .controls .left, #reader-container .controls .topleft .vertical, #reader-container .controls .bottomleft .vertical").children().length > 0)
                    leftBlocked = true;

                if (p.e("#reader-container .controls .bottom, #reader-container .controls .bottomleft .horizontal, #reader-container .controls .bottomright .horizontal").children().length > 0)
                    bottomBlocked = true;

                if (p.e("#reader-container .controls .right, #reader-container .controls .topright .vertical, #reader-container .controls .bottomright .vertical").children().length > 0)
                    rightBlocked = true;

                if (topBlocked) rect.top += p.e("#reader-container .controls .top").getHeight();
                if (leftBlocked) rect.left += p.e("#reader-container .controls .left").getWidth();
                if (bottomBlocked) rect.bottom -= p.e("#reader-container .controls .bottom").getHeight();
                if (rightBlocked) rect.right -= p.e("#reader-container .controls .right").getWidth();

                if (p.reader.overview.isOpen)
                {
                    if (p.reader.settings.overview_direction == "top")
                    {
                        rect.top += p.reader.settings.overview_size;
                    }
                    else if (p.reader.settings.overview_direction == "bottom")
                    {
                        rect.bottom -= p.reader.settings.overview_size;
                    }
                    else if (p.reader.settings.overview_direction == "right")
                    {
                        rect.right -= p.reader.settings.overview_size;
                    }
                    else if (p.reader.settings.overview_direction == "left")
                    {
                        rect.left += p.reader.settings.overview_size;
                    }
                }

                return rect;
            },

            prepare: function ()
            {
                var $this = p.overlays, CONST = p.pager.constant;
                p.pager.touchBlocked = false;
                $this.container.children().remove();
                $this.container.setStyle("padding", p.pager.current_padding + "px");
                if (p.pager.current_mode == CONST.SINGLE)
                {
                    var size = p.pager.getPageSize(p.pager.current_page);
                    p.e("<div>", { "class": "page", width: size.width + "px", height: size.height + "px" }).appendTo($this.container);
                }
                else if (p.pager.current_mode == CONST.DOUBLE)
                {
                    var sizeLeft = p.pager.getPageSize(p.pager.current_page_left);
                    var sizeRight = p.pager.getPageSize(p.pager.current_page_right);
                    p.e("<div>", { "class": "page left", width: sizeLeft.width + "px", height: sizeLeft.height + "px" }).appendTo($this.container);
                    p.e("<div>", { "class": "page right", width: sizeRight.width + "px", height: sizeRight.height + "px" }).appendTo($this.container);
                }
            },
            make_audio_controls: function (e)
            {
                var ele = e[0];
                var container = p.e("<div>", { "class": "controller flex horizontal" });
                var playing = false;
                var play_pause = p.e("<div>", { "class": "icon", "html": "play_arrow" }).appendTo(container);
                var volume = p.reader.make_slider({
                    "dark": true,
                    "discrete": true,
                    min: 0,
                    max: 100,
                    value: 50,
                    sigfigs: 0,
                    callback: function (vol)
                    {
                        try
                        {
                            ele.volume = vol / 100;
                        } catch (ex) { }
                    }
                }).appendTo(container);

                play_pause.on("click tap", function ()
                {
                    if (playing)
                    {
                        play_pause.setHtml("play_arrow");

                        try
                        {
                            ele.pause();
                        } catch (ex) { }
                        playing = false;
                    }
                    else
                    {
                        play_pause.setHtml("pause");
                        try
                        {
                            ele.play();
                        } catch (ex)
                        {
                            p.reader.makeMessage(p.locale.getString("ERROR_TITLE"), p.locale.getString("UNSUPPORTED_AUDIO")).appendTo(p.reader.container);
                        }
                        playing = true;
                    }
                });
                try
                {
                    ele.volume = 0.5;
                } catch (ex) { }

                container.extend({
                    close: function ()
                    {
                        play_pause.setHtml("play_arrow");

                        try
                        {
                            ele.pause();
                            playing = false;
                        } catch (ex) { }


                    }
                });

                return container;
            },
            make_video_controls: function (e, onmeta)
            {
                var uid = p.random.guid();
                p.e(e.getParents()[0]).setAttr("id", uid);

                var ele = e[0];
                var container = p.e("<div>", { "class": "controller flex horizontal" });
                var playing = false;
                var play_pause = p.e("<div>", { "class": "icon", "html": "play_arrow" }).appendTo(container);
                var seekbar = p.reader.make_slider({
                    "dark": true,
                    "discrete": false,
                    buffering: true,
                    buffer: 0,
                    min: 0,
                    max: 100,
                    value: 1,
                    callback: function (time, user)
                    {
                        if (fncs)
                            fncs.player.onSeek(time, user);
                    }
                }, uid).appendTo(container);
                var audio = p.e("<div>", { "class": "icon", "html": "volume_up" }).appendTo(container);
                var audio_bar_container = p.e("<div>", { "class": "hidden_menu flex vertical" }).appendTo(container);
                var fullscreen = p.e("<div>", { "class": "icon", "html": "fullscreen" }).appendTo(container);

                var volume = p.reader.make_slider({
                    dark: true,
                    discrete: true,
                    vertical: true,
                    min: 0,
                    max: 100,
                    value: 50,
                    sigfigs: 0,
                    callback: function (vol)
                    {
                        try
                        {
                            ele.volume = vol / 100;
                        } catch (ex) { }
                    }
                }, uid).appendTo(audio_bar_container);

                var fncs = {
                    player:
                    {
                        playpause: function ()
                        {
                            if (playing)
                            {
                                fncs.player.pause();
                            }
                            else
                            {
                                fncs.player.play();
                            }
                        },
                        pause: function ()
                        {
                            try
                            {
                                ele.pause();
                            }
                            catch (ex)
                            { }
                            playing = false;
                        },
                        play: function ()
                        {
                            try
                            {
                                ele.play();
                            }
                            catch (ex)
                            {
                                p.reader.makeMessage(p.locale.getString("ERROR_TITLE"), p.locale.getString("UNSUPPORTED_VIDEO")).appendTo(p.reader.container);
                            }
                        },
                        onTime: function ()
                        {
                            seekbar.slider.value = ele.currentTime;
                            seekbar.slider.max = ele.duration;
                            seekbar.slider.update(true);
                        },
                        onSeek: function (time, user)
                        {
                            if (user)
                            {
                                try
                                {
                                    ele.currentTime = time;
                                } catch (ex) { }
                            }
                        },
                        onProgress: function (e)
                        {
                            if (ele.buffered.length > 0)
                            {
                                //console.log(ele.buffered.start(0), ele.buffered.end(0), ele.duration);
                                seekbar.slider.setBuffer(p.math.Percentage.XofY(ele.buffered.end(0), ele.duration));
                            }
                        },
                        onState: function ()
                        {
                            if (ele.paused)
                            {
                                play_pause.setHtml("play_arrow");
                                playing = false;
                            }
                            else
                            {
                                play_pause.setHtml("pause");
                                playing = true;
                            }
                        },
                        onMeta: function ()
                        {
                            if (onmeta && p.is.function(onmeta))
                                onmeta();
                        },
                        onFullscreen: function (type, element)
                        {
                            if (p.e(element).getAttr("id") == uid)
                            {
                                if (type == "exit")
                                {
                                    fullscreen.setHtml("fullscreen_enter");
                                }
                                else
                                {
                                    fullscreen.setHtml("fullscreen_exit");
                                }
                            }
                        }
                    }
                };

                e.on("timeupdate", fncs.player.onTime);
                e.on("progress", fncs.player.onProgress);
                e.on("loadedmetadata", fncs.player.onMeta);
                e.on("pause play ended abort", fncs.player.onState);

                fullscreen.on("click tap", function fullscreenToggler()
                {
                    if (p.fullscreen.isFullscreen)
                    {
                        p.fullscreen.exit();
                    }
                    else
                    {
                        p.fullscreen.enter(e.getParents()[0]);
                    }
                });

                p.subscribe("fullscreen", fncs.player.onFullscreen);

                audio.on("mouseenter", function ()
                {
                    audio_bar_container.toggleClass("open");
                });
                audio_bar_container.on("mouseleave", function ()
                {
                    audio_bar_container.removeClass("open");
                });


                play_pause.on("click tap", fncs.player.playpause);

                try
                {
                    ele.volume = 0.5;
                } catch (ex) { }

                container.extend(fncs);

                return container;
            },
            make_iframe_controls: function (e)
            {
                var ele = e[0];
                var container = p.e("<div>", { "class": "navigator flex horizontal" });
                var info = p.e("<div>").appendTo(container);
                var back = p.e("<div>", { "class": "icon", html: "arrow_back" }).appendTo(container);
                var forward = p.e("<div>", { "class": "icon", html: "arrow_forward" }).appendTo(container);
                var reload = p.e("<div>", { "class": "icon", html: "refresh" }).appendTo(container);

                ele.readystatechange = function (evt)
                {
                    //console.log(evt, ele.readyState);
                };


                return container;
            },
            make_gallery: function (sources)
            {
                var uid = p.random.guid()
                var container = p.e("<div>", { "class": "container", "id": uid });
                var arrow_left = p.e("<div>", { "class": "navigation icon left", "html": "keyboard_arrow_left" }).appendTo(container);
                var arrow_right = p.e("<div>", { "class": "navigation icon right", "html": "keyboard_arrow_right" }).appendTo(container);
                var fullscreen = p.e("<div>", { "class": "fullscreen icon", "html": "fullscreen_enter" }).appendTo(container);
                var dots_container = p.e("<div>", { "class": "dots" }).appendTo(container);

                var loaders = [];
                var dots = [];

                for (var i = 0; i < sources.length; i++)
                {
                    //var src = sources[i];
                    loaders.push(p.reader.stageLoader().appendTo(container));
                    dots.push(p.e("<div>", { "class": "dot" }).appendTo(dots_container));
                }

                var fncs =
                {
                    gallery:
                    {
                        hasBeenStarted: false,
                        index: 0,
                        max: sources.length - 1,
                        start: function ()
                        {
                            if (!fncs.gallery.hasBeenStarted)
                            {
                                fncs.gallery.hasBeenStarted = true;
                                for (var i = 0; i < sources.length; i++)
                                {
                                    var src = sources[i];
                                    loaders[i].load([src]);
                                }

                                loaders[0].addClass("appear");
                                dots[0].addClass("active");
                            }
                        },
                        next: function ()
                        {
                            var $this = fncs.gallery, next = $this.index + 1;
                            if (next > $this.max)
                                next = 0;

                            p.e(loaders).removeClass("vanish appear left right");
                            loaders[$this.index].addClass("vanish");
                            loaders[next].addClass("appear right");
                            $this.index = next;
                            p.e(dots).removeClass("active");
                            dots[$this.index].addClass("active");
                        },
                        prev: function ()
                        {
                            var $this = fncs.gallery, prev = $this.index - 1;
                            if (prev <= 0)
                                prev = $this.max;

                            p.e(loaders).removeClass("vanish appear left right");
                            loaders[$this.index].addClass("vanish");
                            loaders[prev].addClass("appear left");
                            $this.index = prev;
                            p.e(dots).removeClass("active");
                            dots[$this.index].addClass("active");
                        },
                        onFullscreen: function (type, element)
                        {
                            if (p.e(element).getAttr("id") == uid)
                            {
                                if (type == "exit")
                                {
                                    fullscreen.setHtml("fullscreen_enter");
                                }
                                else
                                {
                                    fullscreen.setHtml("fullscreen_exit");
                                }
                            }
                        },
                        fullscreen: function ()
                        {
                            if (p.fullscreen.isFullscreen)
                            {
                                p.fullscreen.exit();
                                //p.reader.container.removeClass("child-fullscreen");
                            }
                            else
                            {
                                p.fullscreen.enter(container[0]);
                                //p.reader.container.addClass("child-fullscreen");
                            }
                        },
                    }
                };

                p.TouchHandler(container,
                    {
                        propagate: false,
                        priority: true,
                        onSwipeLeft: fncs.gallery.next,
                        onSwipeRight: fncs.gallery.prev
                    });

                p.subscribe("fullscreen", fncs.gallery.onFullscreen);
                arrow_left.on("click tap", fncs.gallery.prev);
                arrow_right.on("click tap", fncs.gallery.next);
                fullscreen.on("click tap", fncs.gallery.fullscreen);

                container.extend(fncs);
                return container;
            },
            make_vimeo: function (frame)
            {
                var iframe = frame[0];
                var vimeo_instance = {
                    iframe: iframe,
                    player: null,
                    isPlaying: false,
                    isReady: false,

                    init: function ()
                    {
                        // $f = Froogaloop.js

                        vimeo_instance.player = $f(frame[0]);
                        $f(frame[0]).addEvent("ready", vimeo_instance.onReady);
                    },

                    onReady: function ()
                    {
                        //console.log("vimeo onReady");
                        $f(frame[0]).addEvent("pause", vimeo_instance.onPause);
                        $f(frame[0]).addEvent("play", vimeo_instance.onPlay);
                        $f(frame[0]).addEvent("finish", vimeo_instance.onFinish);

                        // content_size.width = overlay.popup_width + 20;
                        // content_size.height = overlay.popup_height + (overlay.class == "area" ? 20 : 50);
                        vimeo_instance.isReady = true;
                        // debugger;
                        // onLoaded();
                    },
                    onPause: function ()
                    {
                        //console.log("vimeo onPause");
                        vimeo_instance.isPlaying = false;
                    },
                    onPlay: function ()
                    {
                        //console.log("vimeo onPlay");
                        vimeo_instance.isPlaying = true;
                    },
                    onFinish: function ()
                    {
                        //console.log("vimeo onFinish");
                        vimeo_instance.isPlaying = false;
                    },
                    play: function ()
                    {
                        //console.log("vimeo play()");
                        vimeo_instance.player.api("play");
                    },
                    pause: function ()
                    {
                        //console.log("vimeo pause()");
                        vimeo_instance.player.api("pause");
                    }
                };

                return vimeo_instance;
            },
            make: function (e)
            {
                var ele = p.e("<div>", { "class": "overlay visible " + e.type });
                var scale = p.pager.current_zoom;

                var dimmer = p.e("<div>", { "class": "dimmer" }).appendTo(ele);
                var icon = p.e("<div>", { "class": "icon" }).appendTo(ele);
                var controls = p.e("<div>", { "class": "controls flex horizontal reverse" }).appendTo(ele);
                var content = p.e("<div>", { "class": "content" }).appendTo(ele);
                var wrapper = p.e("<div>", { "class": "wrap" }).appendTo(content);
                var requireTouch = false;
                var overflowed = false;

                var btn_close = p.e("<div>", { "class": "icon close", html: "close" }).appendTo(controls);

                if (e.display == "area")
                {
                    ele.addClass("area");
                    ele.setStyle("width", (e.width * scale) + "px");
                    ele.setStyle("height", (e.height * scale) + "px");
                }
                else
                {
                    ele.addClass("pin");
                }

                ele.setStyle("left", (e.x * scale) + "px");
                ele.setStyle("top", (e.y * scale) + "px");

                // ICON
                switch (e.type)
                {
                    case "text":
                        icon.setHtml("format_align_left");
                        break;
                    case "html":
                        icon.setHtml("code");
                        break;
                    case "image":
                        icon.setHtml("image");
                        break;
                    case "gallery":
                        icon.setHtml("photo_library");
                        break;
                    case "video":
                    case "youtube":
                    case "vimeo":
                        icon.setHtml("video_library");
                        break;
                    case "audio":
                        icon.setHtml("library_music");
                        break;
                    case "link":
                        icon.setHtml("link");
                        break;
                    case "page":
                        icon.setHtml("input");
                        break;
                    case "embed":
                        icon.setHtml("web");
                        break;
                }

                if (e.icon)
                    icon.setHtml(e.icon);

                // CONTENT
                switch (e.type)
                {
                    case "text":
                    case "html":
                        wrapper.setHtml(e.content);
                        break;
                    case "image":
                        // var source = p.e(e.html);
                        // "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web".format(BASEPATH, p.reader.book_id, encodeURIComponent(overlay.content)),
                        p.e("<img>", {
                            "src": "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web".format(BASEPATH, p.reader.book_id, encodeURIComponent(e.content)),
                            "alt": p.locale.getString("IMG_ALT")
                        }).appendTo(wrapper);
                        requireTouch = true;
                        break;
                    case "gallery":
                        // var sources = p.e(e.html);
                        var paths = [];
                        // sources.each(function ()
                        // {
                        //     paths.push(p.e(this).getAttr("src"));
                        // });
                        for (var idx in e.content)
                        {
                            paths.push("{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web".format(BASEPATH, p.reader.book_id, encodeURIComponent(e.content[idx])));
                        }
                        var gallery = p.overlays.make_gallery(paths).appendTo(wrapper);

                        e.openWidth = (!isNaN(e.openWidth) && e.openWidth > 100 ? e.openWidth : 10000);
                        e.openHeight = (!isNaN(e.openHeight) && e.openHeight > 100 ? e.openHeight : 10000);
                        break;
                    case "audio":
                        var audio = p.e("<audio>");
                        // var sources = p.e(e.html).appendTo(audio);

                        for (var mime in e.content)
                        {
                            var $source = p.e("<source>", {
                                src: "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web&mime={3}".format(BASEPATH, p.reader.book_id, encodeURIComponent(e.content[mime]), mime),
                                type: mime
                            }).appendTo(audio);
                        }

                        var ctrls = p.overlays.make_audio_controls(audio);
                        audio.appendTo(wrapper);
                        ctrls.appendTo(wrapper);
                        break;
                    case "video":
                        var videoWrap = p.e("<div>", { "class": "player" });
                        var videoShield = p.e("<div>", { "class": "shield" }).appendTo(videoWrap);
                        var video = p.e("<video>").appendTo(videoWrap);
                        // var sources = p.e(e.html).appendTo(video);

                        for (var mime in e.content)
                        {
                            var $source = p.e("<source>", {
                                src: "{0}/api/web/?cmd=get/file/media&udid={1}&file={2}&class=web&platform=web&mime={3}".format(BASEPATH, p.reader.book_id, encodeURIComponent(e.content[mime]), mime),
                                type: mime
                            }).appendTo(video);
                        }

                        var ctrls = p.overlays.make_video_controls(video, function ()
                        {
                            e.openWidth = (!isNaN(e.openWidth) && e.openWidth > 100 ? e.openWidth : Math.max(320, video[0].videoWidth));
                            e.openHeight = (!isNaN(e.openHeight) && e.openHeight > 100 ? e.openHeight : Math.max(240, video[0].videoHeight));
                            fncs.adjustSize(e.openWidth, e.openHeight);
                        });
                        videoWrap.appendTo(wrapper);
                        ctrls.appendTo(videoWrap);
                        e.openWidth = (!isNaN(e.openWidth) && e.openWidth > 100 ? e.openWidth : Math.max(320, video[0].videoWidth));
                        e.openHeight = (!isNaN(e.openHeight) && e.openHeight > 100 ? e.openHeight : Math.max(240, video[0].videoHeight));

                        p.reader.container.on("keyup", function (evt)
                        {
                            if (ele.hasClass("open") && (evt.keyCode == 32 || evt.keyCode == 75)) // On space and K
                            {
                                if (evt && evt.preventDefault) evt.preventDefault();
                                if (evt && evt.stopPropagation) evt.stopPropagation();
                                ctrls.player.playpause();
                            }
                        });
                        break;

                    case "vimeo":
                        var source = e.content;
                        var guid = p.random.guid();
                        var target = p.e("<iframe>", {
                            id: guid,
                            src: "//player.vimeo.com/video/{0}?api=1&player_id={1}".format(source, guid),
                            height: "100%",
                            width: "100%",
                        });
                        target.appendTo(wrapper);

                        var ctrls = p.overlays.make_vimeo($(target[0]), null);
                        ctrls.init();

                        e.openWidth = (!isNaN(e.openWidth) && e.openWidth > 100 ? e.openWidth : 320);
                        e.openHeight = (!isNaN(e.openHeight) && e.openHeight > 100 ? e.openHeight : 240);

                        p.reader.container.on("keyup", function (evt)
                        {
                            if (ele.hasClass("open") && (evt.keyCode == 32 || evt.keyCode == 75)) // On space and K
                            {
                                if (evt && evt.preventDefault) evt.preventDefault();
                                if (evt && evt.stopPropagation) evt.stopPropagation();
                                ctrls.player.playpause();
                            }
                        });
                        break;


                    case "youtube":
                        var source = e.content;
                        if (e.target == "internal")
                        {
                            var guid = p.random.guid();
                            var target = p.e("<div>", { id: guid });
                            e.openWidth = (!isNaN(e.openWidth) && e.openWidth > 100 ? e.openWidth : 320);
                            e.openHeight = (!isNaN(e.openHeight) && e.openHeight > 100 ? e.openHeight : 240);
                            var ctrls = null;

                            target.appendTo(wrapper);
                            var initYoutubeFrame = function ()
                            {
                                if (!p.reader.ytapi_loaded)
                                    return setTimeout(initYoutubeFrame, 100);

                                ctrls = new YT.Player(guid, {
                                    height: e.openHeight + "",
                                    width: e.openWidth + "",
                                    videoId: source,
                                    playerVars: {
                                        fs: 0,
                                        playsinline: true,
                                        domain: BASEPATH
                                    },
                                    // events:
                                    // {
                                    //     "onReady": fncYTReady,
                                    //     "onStateChange": fncYTStateChange
                                    // }
                                });
                            };


                            if (!p.reader.ytapi_loaded && !p.reader.ytapi_loading)
                            {
                                p.reader.ytapi_loading = true;
                                var tag = document.createElement('script');

                                tag.src = "https://www.youtube.com/iframe_api";
                                var firstScriptTag = document.getElementsByTagName('script')[0];
                                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                                window.onYouTubeIframeAPIReady = function ()
                                {
                                    p.reader.ytapi_loaded = true;
                                };
                            }

                            setTimeout(initYoutubeFrame, 100);
                        }
                        break;

                    case "link":
                    case "page":
                        var target = e.content;
                        break;
                    case "embed":
                        var source = e.content;
                        var target = e.content;
                        var iframe = p.e("<iframe>").appendTo(wrapper);
                        e.openWidth = (!isNaN(e.openWidth) && e.openWidth > 100 ? e.openWidth : 10000);
                        e.openHeight = (!isNaN(e.openHeight) && e.openHeight > 100 ? e.openHeight : 10000);
                        //var controls = p.overlays.make_iframe_controls(iframe).appendTo(controls);
                        break;
                }

                var pinchStartWidth, pinchStartHeight, pinchStartRect;
                var pinchOffsetX, pinchOffsetY;
                var pinchRelativeScale = 0;
                var pinchStartFactor = 0;
                var pinchStart = null;
                var pinchCurrent = null;
                var currentZoom = 1.0;
                var maxZoom = 2.0;
                var minZoom = 0.1;
                var externalPlayerObject = null;

                var fncs =
                {
                    adjustSize: function (width, height)
                    {
                        if (ele.hasClass("open"))
                        {
                            var relPos = ele.getRelativeXY(".content");
                            var openWidth = Math.max((width + 8 * 2), 200);
                            var openHeight = Math.max((height + 8 + 32 + 8), 80);

                            var projectedRect = p.math.rect(relPos.y, relPos.x, relPos.y + openHeight, relPos.x + openWidth);
                            var fittedRect = p.math.rect(relPos.y, relPos.x, relPos.y + openHeight, relPos.x + openWidth);
                            var availableSpace = p.overlays.getAvailableSpace();

                            var newTop = ((e.y * scale));
                            var newLeft = ((e.x * scale));
                            var infiniteLoopSecurityCounterJustInCase = 0; // You never know with floating points

                            while (!availableSpace.contains(fittedRect))
                            {
                                if (fittedRect.height > availableSpace.height)
                                    overflowed = true, fittedRect.height = availableSpace.height;

                                if (fittedRect.width > availableSpace.width)
                                    overflowed = true, fittedRect.width = availableSpace.width;

                                if (fittedRect.top < availableSpace.top)
                                    fittedRect.moveTopTo(availableSpace.top);

                                if (fittedRect.left < availableSpace.left)
                                    fittedRect.moveLeftTo(availableSpace.left);

                                if (fittedRect.bottom > availableSpace.bottom)
                                    fittedRect.moveTopBy(availableSpace.bottom - fittedRect.bottom);

                                if (fittedRect.right > availableSpace.right)
                                    fittedRect.moveLeftBy(availableSpace.right - fittedRect.right);

                                if (infiniteLoopSecurityCounterJustInCase++ > 10)
                                    break;
                            }

                            newTop += fittedRect.top - projectedRect.top;
                            newLeft += fittedRect.left - projectedRect.left;
                            openWidth = fittedRect.width;
                            openHeight = fittedRect.height;

                            //ele.addClass("open" + (overflowed ? " overflow" : ""));
                            if (overflowed)
                                ele.addClass("overflow");
                            else
                                ele.removeClass("overflow");

                            ele.setStyle("width", openWidth + "px");
                            ele.setStyle("height", openHeight + "px");
                            ele.setStyle("top", newTop + "px");
                            ele.setStyle("left", newLeft + "px");
                        }

                    },
                    open: function (evt)
                    {
                        if (evt && evt.preventDefault) evt.preventDefault();
                        if (evt && evt.stopPropagation) evt.stopPropagation();
                        //console.log("open");
                        if (!ele.hasClass("open"))
                        {
                            p.overlays.closeAll();
                            switch (e.type)
                            {
                                case "link":
                                    if (e.target == "internal")
                                        p.pager.goToPage(p.pager.current_page = parseInt(target));
                                    else
                                        window.open(target);
                                    break;
                                case "vimeo":
                                case "youtube":
                                    if (e.target == "internal")
                                    {
                                        p.pager.touchBlocked = true;
                                        var openWidth = (!isNaN(e.openWidth) ? e.openWidth : wrapper.getWidth());
                                        var openHeight = (!isNaN(e.openHeight) ? e.openHeight : wrapper.getHeight());
                                        ele.addClass("open");
                                        fncs.adjustSize(openWidth, openHeight);
                                    }
                                    else if (e.type == "youtube" && (e.target == "external" || e.target == "browser"))
                                    {
                                        window.open("https://www.youtube.com/watch?v=" + e.content, "_blank");
                                    }
                                    else if (e.type == "vimeo" && (e.target == "external" || e.target == "browser"))
                                    {
                                        window.open("https://vimeo.com/" + e.content, "_blank");
                                    }
                                    break;
                                // case "embed":
                                //     if (!iframe.getAttr("src"))
                                //         iframe.setAttr("src", target);
                                default:
                                    p.pager.touchBlocked = true;
                                    if (e.type == "gallery") gallery.gallery.start();
                                    var openWidth = (!isNaN(e.openWidth) ? e.openWidth : wrapper.getWidth());
                                    var openHeight = (!isNaN(e.openHeight) ? e.openHeight : wrapper.getHeight());
                                    ele.addClass("open");
                                    fncs.adjustSize(openWidth, openHeight);
                                    break;
                            }

                            p.tracking.queue("OVERLAY_OPEN", {
                                folderId: p.reader.book_id,
                                pageNo: p.pager.current_page,
                                overlayId: e.id,
                                type: e.type,
                                style: e.display,
                            });

                            if (p.e(ele).getParents(".page").hasClass("left"))
                                p.e(".page.right").addClass("hidefix");

                        }
                    },
                    close: function (evt)
                    {
                        if (evt && evt.preventDefault) evt.preventDefault();
                        if (evt && evt.stopPropagation) evt.stopPropagation();
                        //console.log("close", ele);
                        if (ele.hasClass("open"))
                        {
                            //console.log("closing", ele);
                            ele.removeClass("open");
                            p.pager.touchBlocked = false;

                            if (e.type == "audio") { ctrls.close(); }
                            if (e.type == "video") { ctrls.player.pause(); }
                            if (e.type == "vimeo") { ctrls.pause(); }
                            if (e.type == "youtube") { try { ctrls.pauseVideo(); } catch (ex) { console.error("Youtube not yet initialized!"); } }

                            if (e.display == "area")
                            {
                                ele.setStyle("width", (e.width * scale) + "px");
                                ele.setStyle("height", (e.height * scale) + "px");
                            }
                            else
                            {
                                ele.setStyle("width", "");
                                ele.setStyle("height", "");
                            }

                            ele.setStyle("left", (e.x * scale) + "px");
                            ele.setStyle("top", (e.y * scale) + "px");
                            //console.log("closed", ele);

                            if (p.e(ele).getParents(".page").hasClass("left"))
                                p.e(".page.right").removeClass("hidefix");

                            p.tracking.queue("OVERLAY_CLOSE", {
                                overlayId: e.id,
                            });
                        }
                    },

                    onDrag: function (difference)
                    {
                        if (ele.hasClass("open") && overflowed)
                        {
                            content[0].scrollLeft += difference.x;
                            content[0].scrollTop += difference.y;
                        }
                    }
                };



                if (requireTouch)
                {
                    //console.log("touch handler");
                    p.TouchHandler(content, {
                        propagate: false,
                        priority: true,
                        onDrag: fncs.onDrag,
                        onWheelStart: fncs.onWheelStart,
                        onWheel: fncs.onWheel,
                        onWheelEnd: fncs.onWheelEnd
                    });
                }

                ele.on("click tap", fncs.open);
                btn_close.on("click tap", fncs.close);

                ele.extend(fncs);

                return ele;
            },
            closeAll: function ()
            {
                var i = 0, l = p.overlays.objects.length;
                for (; i < l; i++)
                    p.overlays.objects[i].close();
            }
        },

        pager:
        {
            pages: null,
            get page_count() { return Object.keys(this.pages).length; },
            current_script: null,
            current_mode: 1, // 1 = single, 2 = double, (3 = single reverse?, 4 = double reverse?, 5 = continuous?)
            current_mode_manual: false,
            current_renderer: 1,
            current_renderer_manual: false,
            current_zoom: 1.0,
            current_autozoom: true,
            current_padding: 20,
            current_controls: false,
            current_controls_floating: false,
            current_animation: 0,
            current_animator: null,
            holders: [],
            loaders: [],
            page_container: null,
            content_container: null,
            pages_uniform: true,
            pdf_exists: false,
            pdf_checked: false,
            pdf_checking: false,
            pdf_enabled: false,
            pdf_preparing: false,
            pdf_loaded: false,
            pdf_loaded_load: false,
            pdf_loaded_progess: false,
            pdf_rendering_slow: false,
            pdf_last_rendertimes: [],
            pdf_last_rendertime_index: 0,
            pdf_last_rendertime_max: 10,
            is_mobile: false,
            touchBlocked: false,

            get pdf_average_rendertimes()
            {
                var i = 0, o = 0, c = this.pdf_last_rendertimes.length;
                for (; i < c; i++)
                    o += this.pdf_last_rendertimes[i];
                return o / c;
            },

            //get paging_handled_by_animator()
            //{
            //    return !(this.current_animation == this.constant.NONE);
            //},
            paging_handled_by_animator: false,

            push_pdf_rendertime: function (time)
            {
                this.pdf_last_rendertimes[this.pdf_last_rendertime_index++] = time;
                if (this.pdf_last_rendertime_index >= this.pdf_last_rendertime_max)
                    this.pdf_last_rendertime_index = 0;
            },

            last_page: -1,
            last_page_left: -1,
            last_page_right: -1,
            last_autozoom: 1,

            current_page: 0,
            get current_page_firstvalid()
            {
                if (this.current_page <= 0)
                    return 1;
                else if (this.current_page > this.page_count)
                    return this.page_count;

                return this.current_page;
            },
            get current_page_left()
            {
                if (this.current_page <= 1)
                    this.current_page = 0;
                else
                {
                    if (this.current_mode == this.constant.DOUBLE || this.current_mode == this.constant.DOUBLE_REVERSE)
                    {
                        if (this.current_page % 2)
                            this.current_page--;
                    }
                }

                return this.current_page;
            },
            get current_page_right()
            {
                return this.current_page_left + 1;
            },
            get show_page_left()
            {
                return this.showPage(this.current_page_left);
                //return this.current_page_left > 0;
            },
            get show_page_right()
            {
                return this.showPage(this.current_page_right);
                //return this.current_page_right <= this.page_count;
            },

            get can_go_back()
            {
                if (this.current_mode == this.constant.DOUBLE || this.current_mode == this.constant.DOUBLE_REVERSE)
                {
                    if (Math.min(this.current_page_left, this.current_page_right) <= 1)
                        return false;
                }
                else
                {
                    if (this.current_page <= 1)
                        return false;
                }

                return true;
            },

            get can_go_forward()
            {
                if (this.current_mode == this.constant.DOUBLE || this.current_mode == this.constant.DOUBLE_REVERSE)
                {
                    if (Math.max(this.current_page_left, this.current_page_right) >= this.page_count)
                        return false;
                }
                else
                {
                    if (this.current_page >= this.page_count)
                        return false;
                }

                return true;
            },

            showPage: function (page)
            {
                return page > 0 && page <= this.page_count;
            },

            _lastContentContained: null,
            _contentContainedInvalidated: true,
            get contentContained()
            {
                if (this._contentContainedInvalidated)
                {
                    var contentSize = { width: this.page_container.getWidth(), height: this.page_container.getHeight() };
                    var containerSize = { width: p.reader.container.getWidth(), height: p.reader.container.getHeight() };
                    this._lastContentContained = contentSize.width <= containerSize.width && contentSize.height <= containerSize.height;
                    this._contentContainedInvalidated = false;
                }

                return this._lastContentContained;
            },

            invalidateContentContained: function ()
            {
                this._contentContainedInvalidated = true;
            },

            constant:
            {
                // Modes
                SINGLE: 1,
                DOUBLE: 2,
                SINGLE_REVERSE: 3,
                DOUBLE_REVERSE: 4,
                SCROLL: 5,

                // Canvases
                PREV_LEFT: 0,
                PREV_RIGHT: 1,
                CUR_LEFT: 2,
                CUR_RIGHT: 3,
                NEXT_LEFT: 4,
                NEXT_RIGHT: 5,
                PREV: 0,
                CUR: 1,
                NEXT: 2,

                // Renderers
                IMG: 1,
                PDF: 2,

                // Animations
                NONE: 0,
                FADE: 1,
                FLIP: 2,
                SWIPE: 3
            },

            ready_for_first_load: false,
            first_load_done: false,
            onFirstLoad: function ()
            {
                if (!this.first_load_done && this.ready_for_first_load)
                {
                    this.first_load_done = true;
                    p.e(".main-loader").addClass("hidden");

                    if (p.pager.is_mobile || p.reader.settings.no_pdf)
                        p.reader.setProgress(100);

                    p.pager.setAnimation(p.pager.constant[p.reader.get("animation", "flip").toUpperCase()]);

                    if (p.reader.settings.slideshow_mode)
                    {
                        p.reader.slideshow.play();
                    }
                }
            },

            init: function ()
            {
                var $this = this;
                this.page_container = p.e("#reader-container .content .pages");
                this.content_container = p.e("#reader-container .content");
                // debugger;
                if (this.pages != null)
                {
                    this.prepareElements();

                    if (p.reader.settings.overview_start_open)
                        p.reader.overview.open();

                    var pinchStartWidth, pinchStartHeight, pinchStartRect;
                    var pinchOffsetX, pinchOffsetY;
                    var pinchRelativeScale = 0;
                    var pinchStartFactor = 0;
                    var pinchStart = null;
                    var pinchCurrent = null;

                    //console.log(p.reader.container.find(".content"));
                    p.TouchHandler(this.content_container,
                        {
                            propagate: true,
                            wheelTick: p.reader.settings.zoom_wheel_step * 10,
                            onSwipeRight: function ()
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_swiping && !p.pager.paging_handled_by_animator)
                                    {
                                        if (p.pager.current_animator == null)
                                            p.pager.goBack();
                                        else
                                            p.pager.current_animator.doFullAnimationPrev();
                                    }
                            },
                            onSwipeLeft: function ()
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_swiping && !p.pager.paging_handled_by_animator)
                                    {
                                        if (p.pager.current_animator == null)
                                            p.pager.goForward();
                                        else
                                            p.pager.current_animator.doFullAnimationNext();
                                    }
                            },
                            onDrag: function (diff)
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_dragging)
                                    {
                                        p.pager.content_container[0].scrollLeft += diff.x;
                                        p.pager.content_container[0].scrollTop += diff.y;
                                    }
                            },
                            onDoubleTap: function (e)
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_double_tapping)
                                    {
                                        if (!$this.current_autozoom)
                                        {
                                            $this.current_autozoom = true;
                                            $this.adjust({ reload: true });
                                        }
                                        // else
                                        // {
                                        //     //debugger;
                                        //     pinchRelativeScale = 1 / $this.current_zoom;
                                        //     var scrollTop = ((((e.y) * pinchRelativeScale)) - (($this.content_container.getHeight() / 2))),
                                        //         scrollLeft = ((((e.x) * pinchRelativeScale)) - (($this.content_container.getWidth() / 2)));

                                        //     $this.setZoom(p.reader.settings.zoom_doubleclick);
                                        //     $this.adjust({
                                        //         reload: true,
                                        //         scroll:
                                        //         {
                                        //             top: scrollTop,
                                        //             left: scrollLeft
                                        //         },
                                        //         callback: function ()
                                        //         {
                                        //             p.pager.content_container[0].scrollLeft = scrollLeft; // (((e.x * pinchRelativeScale)) - (($this.content_container.getWidth() / 2))); // - (p.pager.content_container[0].scrollLeft + $this.current_padding);
                                        //             p.pager.content_container[0].scrollTop = scrollTop; //(((e.y * pinchRelativeScale)) - (($this.content_container.getHeight() / 2))); // - (p.pager.content_container[0].scrollTop + $this.current_padding);
                                        //         }
                                        //     });
                                        // }
                                    }
                            },


                            /// WHEEL
                            onWheelStart: function (start)
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_mousewheel_zoom)
                                    {
                                        pinchStartWidth = $this.page_container.getWidth();
                                        pinchStartHeight = $this.page_container.getHeight();
                                        pinchStart = start;
                                        pinchStartFactor = pinchStartWidth / $this.content_container.getWidth();
                                        pinchOffsetX = (pinchStart.x) + $this.content_container[0].scrollLeft;
                                        pinchOffsetY = (pinchStart.y) + $this.content_container[0].scrollTop;
                                        pinchStartRect = $this.page_container.getRelativeBoundingClientRect();

                                        //console.log(pinchStart.x, pinchStart.y, $this.content_container[0].scrollLeft, $this.content_container[0].scrollTop);

                                        // Very important to set this here!!!
                                        // On mobile this is an insane performance drain, especially on android
                                        $this.page_container.setStyle("transformOrigin", "{0}px {1}px".format(pinchOffsetX, pinchOffsetY));

                                        //console.log("START", pinchStartRect);
                                    }
                            },
                            onWheel: function (distDiff, distChange, now)
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_mousewheel_zoom)
                                    {
                                        var zoomTest = p.pager.current_zoom * distChange;
                                        //var zoomTest = Math.min(p.reader.settings.zoom_max, Math.max(p.reader.settings.zoom_min == "auto" ? p.pager.last_autozoom : p.reader.settings.zoom_min, p.pager.current_zoom * distChange));
                                        if (zoomTest < (p.reader.settings.zoom_min == "auto" ? p.pager.last_autozoom : p.reader.settings.zoom_min))
                                        {
                                            distChange = (p.reader.settings.zoom_min == "auto" ? p.pager.last_autozoom : p.reader.settings.zoom_min) / p.pager.current_zoom;
                                        }
                                        else if (zoomTest > p.reader.settings.zoom_max)
                                        {
                                            distChange = p.reader.settings.zoom_max / p.pager.current_zoom;
                                        }

                                        pinchRelativeScale = distChange;
                                        pinchCurrent = now;

                                        $this.page_container.setStyle("padding", "{0}px".format($this.current_padding * (1 / pinchRelativeScale)));
                                        $this.page_container.setStyle("height", "{0}px".format(pinchStartHeight));
                                        $this.page_container.setStyle("width", "{0}px".format(pinchStartWidth));
                                        $this.page_container.setStyle("transform", "scale({0})".format(pinchRelativeScale));

                                    }
                            },
                            onWheelEnd: function ()
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_mousewheel_zoom)
                                    {
                                        try
                                        {
                                            var rect = $this.page_container.getRelativeBoundingClientRect();
                                            //console.log("BEFORE", rect);
                                        } catch (ex) { }

                                        p.pager.setZoom(p.pager.current_zoom * pinchRelativeScale);
                                        var scrollTop = Math.round(-rect.top), scrollLeft = Math.round(-rect.left);
                                        if ($this.current_mode == $this.constant.DOUBLE)
                                            scrollLeft -= (rect.right - $this.page_container.getRelativeBoundingClientRect().right) / 2;

                                        p.pager.adjust({
                                            reload: true,
                                            scroll: { top: scrollTop, left: scrollLeft },
                                            callback: function ()
                                            {
                                                if (rect)
                                                {
                                                    p.pager.content_container[0].scrollTop = scrollTop;
                                                    p.pager.content_container[0].scrollLeft = scrollLeft;

                                                    //console.log("AFTER", $this.page_container[0].getBoundingClientRect());

                                                    // I can't exactly figure out why we need to correct for the offset only in double sided mode
                                                    // the offset exists in single page mode too, but funnily enough it does not cause any problems there???
                                                    // I'm guessing it has something to do with "margin: 0 auto" and the way elements are centered?
                                                    //if ($this.current_mode == $this.constant.DOUBLE)
                                                    //    p.pager.content_container[0].scrollLeft -= (rect.right - $this.page_container[0].getBoundingClientRect().right) / 2;
                                                }
                                                else
                                                {
                                                    // Terrible fallback
                                                    p.pager.content_container[0].scrollLeft = (((pinchOffsetX * pinchRelativeScale)) - (($this.content_container.getWidth() / 2))) - $this.current_padding;
                                                    p.pager.content_container[0].scrollTop = (((pinchOffsetY * pinchRelativeScale)) - (($this.content_container.getHeight() / 2))) - $this.current_padding;
                                                }
                                            }
                                        }
                                        );
                                    }
                            },


                            /// PINCH
                            onPinchStart: function (start)
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_pinching)
                                    {
                                        pinchStartWidth = $this.page_container.getWidth();
                                        pinchStartHeight = $this.page_container.getHeight();
                                        pinchStart = start;
                                        pinchStartFactor = pinchStartWidth / $this.content_container.getWidth();
                                        pinchOffsetX = (pinchStart.x) + $this.content_container[0].scrollLeft;
                                        pinchOffsetY = (pinchStart.y) + $this.content_container[0].scrollTop;
                                        pinchStartRect = $this.page_container.getRelativeBoundingClientRect();

                                        //console.log(pinchStart.x, pinchStart.y, $this.content_container[0].scrollLeft, $this.content_container[0].scrollTop);

                                        // Very important to set this here!!!
                                        // On mobile this is an insane performance drain, especially on android
                                        $this.page_container.setStyle("transformOrigin", "{0}px {1}px".format(pinchOffsetX, pinchOffsetY));

                                        //console.log("START", pinchStartRect);
                                    }
                            },
                            onPinch: function (distDiff, distChange, now)
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_pinching)
                                    {
                                        var zoomTest = p.pager.current_zoom * distChange;

                                        if (zoomTest >= p.pager.last_autozoom && zoomTest <= p.reader.settings.zoom_max)
                                        {
                                            pinchRelativeScale = distChange;
                                            pinchCurrent = now;

                                            $this.page_container.setStyle("padding", "{0}px".format($this.current_padding * (1 / pinchRelativeScale)));
                                            $this.page_container.setStyle("height", "{0}px".format(pinchStartHeight));
                                            $this.page_container.setStyle("width", "{0}px".format(pinchStartWidth));
                                            $this.page_container.setStyle("transform", "scale({0})".format(pinchRelativeScale));
                                        }

                                        //$this.page_container.setAttr("style",
                                        //    "padding: {0}px; width: {1}px; height: {2}px; transform: scale({3}); transform-origin: {4}px {5}px;"
                                        //    .format(
                                        //        $this.current_padding * (1 / pinchRelativeScale),
                                        //        pinchStartWidth,
                                        //        pinchStartHeight,
                                        //        pinchRelativeScale,
                                        //        pinchOffsetX,
                                        //        pinchOffsetY
                                        //        )
                                        //    );
                                        //console.log("DURING", $this.page_container[0].getBoundingClientRect());
                                    }
                            },
                            onPinchEnd: function ()
                            {
                                if (!p.pager.touchBlocked)
                                    if (p.reader.settings.enable_pinching)
                                    {
                                        try
                                        {
                                            var rect = $this.page_container.getRelativeBoundingClientRect();
                                            //console.log("BEFORE", rect);
                                        } catch (ex) { }

                                        p.pager.setZoom(p.pager.current_zoom * pinchRelativeScale);
                                        var scrollTop = Math.round(-rect.top), scrollLeft = Math.round(-rect.left);
                                        if ($this.current_mode == $this.constant.DOUBLE)
                                            scrollLeft -= (rect.right - $this.page_container.getRelativeBoundingClientRect().right) / 2;

                                        p.pager.adjust({
                                            reload: true,
                                            scroll: { top: scrollTop, left: scrollLeft },
                                            callback: function ()
                                            {
                                                if (rect)
                                                {
                                                    p.pager.content_container[0].scrollTop = scrollTop;
                                                    p.pager.content_container[0].scrollLeft = scrollLeft;

                                                    //console.log("AFTER", $this.page_container[0].getBoundingClientRect());

                                                    // I can't exactly figure out why we need to correct for the offset only in double sided mode
                                                    // the offset exists in single page mode too, but funnily enough it does not cause any problems there???
                                                    // I'm guessing it has something to do with "margin: 0 auto" and the way elements are centered?
                                                    //if ($this.current_mode == $this.constant.DOUBLE)
                                                    //    p.pager.content_container[0].scrollLeft -= (rect.right - $this.page_container[0].getBoundingClientRect().right) / 2;
                                                }
                                                else
                                                {
                                                    // Terrible fallback
                                                    p.pager.content_container[0].scrollLeft = (((pinchOffsetX * pinchRelativeScale)) - (($this.content_container.getWidth() / 2))) - $this.current_padding;
                                                    p.pager.content_container[0].scrollTop = (((pinchOffsetY * pinchRelativeScale)) - (($this.content_container.getHeight() / 2))) - $this.current_padding;
                                                }
                                            }
                                        }
                                        );
                                    }
                            }
                        });

                    this.is_mobile = p.browser.isMobile;

                    p.e(document).on("keyup", function (e)
                    {
                        if (p.AdMan.advertising)
                            return;

                        if (e.keyCode == 37) //left
                        {
                            if (p.reader.settings.enable_keyboard_navigation)
                            {
                                if (p.pager.current_animator == null)
                                    p.pager.goBack();
                                else
                                    p.pager.current_animator.doFullAnimationPrev();
                            }
                        }

                        if (e.keyCode == 39) // right
                        {
                            if (p.reader.settings.enable_keyboard_navigation)
                            {
                                if (p.pager.current_animator == null)
                                    p.pager.goForward();
                                else
                                    p.pager.current_animator.doFullAnimationNext();
                            }
                        }

                        if (e.keyCode == 107) // Plus +
                        {
                            //p.reader.zoomIn();
                        }

                        if (e.keyCode == 109) // Minus -
                        {
                            //p.reader.zoomOut();
                        }
                    });

                    var resizeTimeout = null;

                    p.pager.content_container.on("resize", function ()
                    {
                        clearTimeout(resizeTimeout);
                        resizeTimeout = setTimeout(function ()
                        {
                            p.pager.invalidateContentContained();
                            p.pager.adjust();
                        }, 50);

                    });
                    this.adjust();

                    var lastPageSize = null;
                    for (var i in this.pages)
                    {
                        if (lastPageSize != null)
                            if (this.pages[i].ref_size.width != lastPageSize.width || this.pages[i].ref_size.height != lastPageSize.height)
                            {
                                this.pages_uniform = false;
                                break;
                            }

                        lastPageSize = this.pages[i].ref_size;
                    }

                    //this.fixPageNumber(e);
                    this.ready_for_first_load = true;

                    this.current_page = p.reader.settings.start_page;
                    this.goToPage(this.current_page);
                }
            },

            setAnimation: function (e, callback)
            {
                var $this = p.pager, CONST = $this.constant;

                if (!$this.pages_uniform || $this.is_mobile)
                    e = CONST.NONE;

                p.pager.page_container.removeClass("flip fade swipe");

                switch ($this.current_animation)
                {
                    case CONST.FLIP:
                        p.flip_animator.destruct();
                        break;

                    case CONST.FADE:

                        break;

                    case CONST.SWIPE:
                        p.swipe_animator.destruct();

                        break;
                }

                $this.current_animator = null;

                switch (e)
                {
                    case CONST.FLIP:
                        $this.current_animation = CONST.FLIP;
                        p.reader.settings.preload = true;
                        p.flip_animator.init();
                        p.pager.page_container.addClass("flip");
                        $this.current_animator = p.flip_animator;
                        break;

                    case CONST.FADE:
                        $this.current_animation = CONST.FADE;
                        p.reader.settings.preload = true;
                        p.pager.page_container.addClass("fade");

                        break;

                    case CONST.SWIPE:
                        $this.current_animation = CONST.SWIPE;
                        p.reader.settings.preload = true;
                        p.swipe_animator.init();
                        p.pager.page_container.addClass("swipe");
                        $this.current_animator = p.swipe_animator;
                        break;

                    default:
                        $this.current_animation = CONST.NONE;
                        break;
                }

                $this.adjust({ reload: true, callback: callback });
            },

            setZoom: function (e, dontFlipAutozoom)
            {
                var clampedZoom = Math.min(p.reader.settings.zoom_max, Math.max(p.reader.settings.zoom_min == "auto" ? this.last_autozoom : p.reader.settings.zoom_min, e));

                this.current_zoom = clampedZoom;
                p.PDFManagerReader.scale = clampedZoom;

                if (!dontFlipAutozoom)
                    this.current_autozoom = false;

                this.invalidateContentContained();
            },
            adjust: function (options)
            {
                var $this = p.pager,
                    CONST = $this.constant,
                    callback = options ? options.callback : null,
                    reload = options ? options.reload : false;

                //if ($this.current_animation == CONST.FLIP)
                //{
                //    $this.page_container.addClass("effect3d");
                //}
                //else
                //{
                //    $this.page_container.removeClass("effect3d");
                //}

                if (!$this.current_mode_manual)
                {
                    var calculatedMode = -1;

                    if (p.reader.container.getWidth() > p.reader.container.getHeight())
                        calculatedMode = CONST.DOUBLE;
                    else
                        calculatedMode = CONST.SINGLE;

                    if (calculatedMode != -1 && calculatedMode != $this.current_mode)
                    {
                        //console.log("Switching modes");
                        $this.current_mode = calculatedMode;

                        reload = true;
                    }
                }

                if ($this.current_mode == CONST.SINGLE && $this.current_page == 0)
                    $this.current_page = 1;

                if (!$this.current_renderer_manual)
                {
                    var calculatedRenderer = -1;
                    if ($this.is_mobile || p.reader.settings.no_pdf)
                    {
                        calculatedRenderer = CONST.IMG;
                    }
                    else if (!p.browser.isMobile && $this.pdf_enabled && $this.pdf_loaded)
                    {
                        calculatedRenderer = CONST.PDF;
                    }
                    else if (!p.browser.isMobile && !$this.pdf_enabled && !$this.pdf_checking && !$this.pdf_preparing)
                    {
                        $this.preparePdf();
                    }

                    if (calculatedRenderer != -1 && calculatedRenderer != $this.current_renderer)
                    {
                        //console.log("Switching Renderers");
                        $this.current_renderer = calculatedRenderer;
                        reload = true;
                    }
                }

                if ($this.current_autozoom)
                {
                    var calculatedZoom = $this.calculateAutozoom();
                    if (calculatedZoom != $this.current_zoom)
                    {
                        //console.log("Switching Zoom");
                        $this.setZoom(calculatedZoom, true);
                        reload = true;
                    }
                }

                if (reload)
                {
                    $this.prepareElements();
                    if (options && options.scroll)
                    {
                        p.pager.content_container[0].scrollLeft = options.scroll.left;
                        p.pager.content_container[0].scrollTop = options.scroll.top;
                    }
                    $this.goToPage($this.current_page, callback, options);
                }
            },

            calculateAutozoom: function ()
            {
                var $this = p.pager,
                    CONST = $this.constant,
                    pageSize = $this.getPageSize($this.current_page_firstvalid, true),
                    available_space = {
                        width: p.pager.content_container.getWidth() - ($this.current_padding * 2),
                        height: p.pager.content_container.getHeight() - ($this.current_padding * 2)
                    },
                    required_space = {
                        width: (($this.current_mode == CONST.DOUBLE || $this.current_mode == CONST.DOUBLE_REVERSE) ? (pageSize.width * 2) : (pageSize.width)),
                        height: pageSize.height
                    };

                var scaleWidth = available_space.width / required_space.width,
                    scaleHeight = available_space.height / required_space.height;

                //console.log("Scales", scaleWidth, scaleHeight, "Out scale:", scaleWidth > scaleHeight ? scaleHeight : scaleWidth);
                $this.last_autozoom = scaleWidth > scaleHeight ? scaleHeight : scaleWidth;
                return $this.last_autozoom;
            },

            getApiCoverPath: function (size)
            {
                return "{0}/api/web/?cmd=get/file/cover&udid={1}&class=web&platform=web&size={2}".format(BASEPATH, p.reader.book_id, size);
            },
            getFileName: function (page)
            {
                return page; //p.md5("ECB042F43mynameismarypoppins" + page).toUpperCase();
            },
            getFullPath: function (page)
            {
                return "{0}/api/web/?cmd=get/file/page&udid={1}&class=web&platform=web&type=img&page={2}".format(BASEPATH, p.reader.book_id, page);
                // return p.reader.path + "/images/" + this.getFileName(page) + ".jpg";
            },
            getThumbPath: function (page)
            {
                return "{0}/api/web/?cmd=get/file/page&udid={1}&class=web&platform=web&type=thumb&page={2}".format(BASEPATH, p.reader.book_id, page);
                // return p.reader.path + "/thumbs/" + this.getFileName(page) + ".jpg";
            },
            getPDFPath: function ()
            {
                return "{0}/api/web/?cmd=get/file/full&udid={1}&class=web&platform=web".format(BASEPATH, p.reader.book_id);
                // return p.reader.path + "/pdf/" + this.getFileName("full") + ".pdf";
            },
            getPageSize: function (page, raw)
            {
                if (this.pages[page])
                {
                    if (raw)
                        return { width: this.pages[page].ref_size.width, height: this.pages[page].ref_size.height };

                    return { width: this.pages[page].ref_size.width * this.current_zoom, height: this.pages[page].ref_size.height * this.current_zoom };
                }

                return { width: 0, height: 0 };
            },


            prepareElements: function ()
            {
                var i, CONST = this.constant;
                //this.page_container.setAttr("style", "padding: {0}px;".format(this.current_padding));

                if (this.holders.length == 0)
                {
                    var initialPageSize = this.getPageSize(1);
                    for (i = 0; i < 6; i++)
                    {
                        this.holders[i] = p.e("<div>", { "class": "page" }).appendTo(this.page_container);
                        var wrapper = p.e("<div>", { "class": "wrapper" }).appendTo(this.holders[i]);

                        this.loaders[i] = p.reader.stageLoader().appendTo(wrapper);
                        p.e("<canvas>", { "class": "loading" }).appendTo(wrapper);

                        this.holders[i].setAttr("style", "width: {0}px; height: {1}px;".format(initialPageSize.width, initialPageSize.height));
                    }
                }

                if (this.current_mode == CONST.SINGLE || this.current_mode == CONST.SINGLE_REVERSE)
                {
                    this.page_container.addClass("single").removeClass("double");
                    for (i = 0; i < 6; i++)
                    {
                        this.holders[i].removeClass("previous next left right");

                        if (i == CONST.CUR)
                            this.holders[i].removeClass("hidden previous next");
                        else if (i == CONST.PREV)
                            this.holders[i].removeClass("next").addClass("hidden previous");
                        else if (i == CONST.NEXT)
                            this.holders[i].removeClass("previous").addClass("hidden next");
                        else
                            this.holders[i].addClass("hidden").removeClass("previous next");
                    }
                }
                else if (this.current_mode == CONST.DOUBLE || this.current_mode == CONST.DOUBLE_REVERSE)
                {
                    this.page_container.addClass("double").removeClass("single");
                    for (i = 0; i < 6; i++)
                    {
                        if (i == CONST.CUR_LEFT || i == CONST.CUR_RIGHT)
                            this.holders[i].removeClass("previous next");
                        else if (i == CONST.PREV_LEFT || i == CONST.PREV_RIGHT)
                            this.holders[i].removeClass("next").addClass("previous");
                        else if (i == CONST.NEXT_LEFT || i == CONST.NEXT_RIGHT)
                            this.holders[i].removeClass("previous").addClass("next");

                        if ((i == CONST.CUR_LEFT /*|| i == CONST.PREV_LEFT || i == CONST.NEXT_LEFT*/) && this.show_page_left)
                            this.holders[i].removeClass("hidden");
                        else if ((i == CONST.CUR_RIGHT /*|| i == CONST.PREV_RIGHT || i == CONST.NEXT_RIGHT*/) && this.show_page_right)
                            this.holders[i].removeClass("hidden");
                        else
                            this.holders[i].addClass("hidden");



                        if (i == CONST.CUR_LEFT || i == CONST.PREV_LEFT || i == CONST.NEXT_LEFT)
                            this.holders[i].addClass("left").removeClass("right");
                        else if (i == CONST.CUR_RIGHT || i == CONST.PREV_RIGHT || i == CONST.NEXT_RIGHT)
                            this.holders[i].addClass("right").removeClass("left");
                        else
                            this.holders[i].removeClass("left right");


                        /////////
                        //if (i == CONST.CUR_LEFT && this.show_page_left || i == CONST.CUR_RIGHT && this.show_page_right)
                        //{
                        //    this.holders[i].removeClass("hidden");
                        //    if (i == CONST.CUR_LEFT)
                        //        this.holders[i].addClass("left");
                        //    else
                        //        this.holders[i].addClass("right");
                        //}
                        //else
                        //    this.holders[i].addClass("hidden");
                    }
                }
            },
            preparePdf: function ()
            {
                console.log("preparePdf");
                if (!this.pdf_checked && !this.pdf_checking)
                {
                    this.pdf_checking = true;
                    fetch(this.getPDFPath(), { method: "HEAD" }).then(function (response)
                    {
                        p.pager.pdf_exists = response.ok;
                        p.pager.pdf_checked = true;
                        p.pager.pdf_checking = false;

                        if (response.ok)
                        {
                            p.pager.pdf_preparing = false;
                            p.pager.pdf_enabled = true;
                            p.PDFManagerReader.setOnDocumentLoadHandler(p.pager.pdfJsOnLoad);
                            p.PDFManagerReader.setOnDocumentProgressHandler(p.pager.pdfJsOnProgress);
                            p.PDFManagerReader.setOnPageLoadHandler(p.pager.pdfJsOnPage);
                            p.PDFManagerReader.setOnPageRenderHandler(p.pager.pdfJsOnRender);
                            p.PDFManagerReader.loadFile(p.pager.getPDFPath());
                        }
                        else
                        {
                            p.pager.pdf_preparing = false;
                            p.reader.setProgress("error");
                        }
                    });
                }
            },

            goBack: function (callback, onReady)
            {
                var $this = p.pager, CONST = $this.constant;

                switch ($this.current_mode)
                {
                    case CONST.SINGLE:
                        if ($this.current_page <= 1)
                            return;

                        $this.current_page--;
                        break;
                    case CONST.SINGLE_REVERSE:
                        break;

                    case CONST.DOUBLE:
                        if ($this.current_page == 0)
                            return;

                        $this.current_page -= 2;
                        break;
                    case CONST.DOUBLE_REVERSE:
                        break;
                }

                $this.goToPage($this.current_page, callback, { onReady: onReady });
            },
            goForward: function (callback, onReady)
            {
                var $this = p.pager, CONST = $this.constant;

                switch ($this.current_mode)
                {
                    case CONST.SINGLE:
                        if ($this.current_page >= $this.page_count)
                            return;

                        $this.current_page++;
                        break;
                    case CONST.SINGLE_REVERSE:
                        break;

                    case CONST.DOUBLE:
                        if ($this.current_page_left >= $this.page_count || $this.current_page_right >= $this.page_count)
                            return;

                        $this.current_page += 2;
                        break;
                    case CONST.DOUBLE_REVERSE:
                        break;
                }

                $this.goToPage($this.current_page, callback, { onReady: onReady });
            },

            /// Rendering
            goToPage: function (e, callback, options)
            {
                var CONST = this.constant;
                p.reader.overview.scrollToCurrentPage();
                this.renderStop();
                //console.trace("Render", callback, options);
                //debugger;
                if (this.current_mode == CONST.SINGLE || this.current_mode == CONST.SINGLE_REVERSE)
                {
                    this.renderSingle(callback, options);

                    if (p.reader.settings.enable_arrows)
                    {
                        if (this.current_page <= 1)
                            p.e(".ctrl.btn.go-back").addClass("gone");
                        else
                            p.e(".ctrl.btn.go-back").removeClass("gone");

                        if (this.current_page >= this.page_count)
                            p.e(".ctrl.btn.go-fwd").addClass("gone");
                        else
                            p.e(".ctrl.btn.go-fwd").removeClass("gone");
                    }

                    $(".ctrl.input.page input").val(this.current_page);

                    p.tracking.queue("PAGE_VIEW", { folderId: p.reader.book_id, pageNo: parseInt(this.current_page) });
                }
                else if (this.current_mode == CONST.DOUBLE)
                {
                    this.renderDouble(callback, options);
                    if (p.reader.settings.enable_arrows)
                    {
                        if (this.current_page_left == 0)
                            p.e(".ctrl.btn.go-back").addClass("gone");
                        else
                            p.e(".ctrl.btn.go-back").removeClass("gone");

                        if (this.current_page_left == this.page_count || this.current_page_right == this.page_count)
                            p.e(".ctrl.btn.go-fwd").addClass("gone");
                        else
                            p.e(".ctrl.btn.go-fwd").removeClass("gone");
                    }

                    if (this.current_page_left == 0)
                        $(".ctrl.input.page input").val(this.current_page_right);
                    else if (this.current_page_right >= this.page_count)
                        $(".ctrl.input.page input").val(this.current_page_left);
                    else
                        $(".ctrl.input.page input").val(this.current_page_left + "-" + this.current_page_right);


                    if (this.current_page_left != 0)
                        p.tracking.queue("PAGE_VIEW", { folderId: p.reader.book_id, pageNo: parseInt(this.current_page_left) });
                    if (this.current_page_right < this.page_count)
                        p.tracking.queue("PAGE_VIEW", { folderId: p.reader.book_id, pageNo: parseInt(this.current_page_right) });
                }
                $(".ctrl.input.page .max").html(this.page_count);
                p.overlays.render();
                p.ls.set(p.reader.book_id + "_page", this.current_page);
            },

            checkAndCallback: function checkAndCallback(cb)
            {
                if (cb && p.is.function(cb))
                {
                    //console.trace("Calling");
                    cb();
                } else
                {
                    //console.trace("Not Calling");
                }

                var $this = p.pager, CONST = $this.constant;

                switch ($this.current_mode)
                {
                    case CONST.SINGLE:
                        if (p.AdMan.getAd($this.current_page) != null)
                        {
                            var ad = p.AdMan.getAd($this.current_page);
                            //console.log("Ad for page", $this.current_page, ad);
                            if (!ad.running && !ad.expired)
                            {
                                ad.start();
                            }
                        }
                        break;

                    case CONST.DOUBLE:

                        if (p.AdMan.getAd($this.current_page_left) != null)
                        {
                            var ad = p.AdMan.getAd($this.current_page_left);
                            //console.log("Ad for page", $this.current_page_left, ad);
                            if (!ad.running && !ad.expired)
                            {
                                ad.start();
                            }
                        }

                        if (p.AdMan.getAd($this.current_page_right) != null)
                        {
                            var ad = p.AdMan.getAd($this.current_page_right);
                            //console.log("Ad for page", $this.current_page_right, ad);
                            if (!ad.running && !ad.expired)
                            {
                                ad.start();
                            }
                        }
                        break;
                }

            },

            renderStop: function ()
            {
                p.PDFQueue.reset();
                clearTimeout(this.renderTimeout);
            },
            renderInstance: 0,
            renderTimeout: null,
            renderWaitingTime: 2000,
            renderSingleSelf: function RENDERER_SinglePage_GenerateSelfVariables(callback)
            {
                var self = {};
                self.callback = callback,
                    self.this = p.pager,
                    self.iid = ++self.this.renderInstance,
                    self.CONST = self.this.constant,
                    self.scrollPerformed = false,
                    self.cur = self.this.current_page_firstvalid,
                    self.next = self.this.current_page_firstvalid + 1,
                    self.prev = self.this.current_page_firstvalid - 1,
                    self.pageSize = self.this.getPageSize(self.cur),
                    self.pageSizeNext = self.this.getPageSize(self.next),
                    self.pageSizePrev = self.this.getPageSize(self.prev),
                    self.canvas = self.this.holders[self.CONST.CUR].find("canvas"),
                    self.canvasNext = self.this.holders[self.CONST.NEXT].find("canvas"),
                    self.canvasPrev = self.this.holders[self.CONST.PREV].find("canvas"),
                    self.containerWidth = self.pageSize.width + self.this.current_padding * 2,
                    self.containerHeight = self.pageSize.height + self.this.current_padding * 2,
                    self.readjust = (Math.round(self.containerHeight) != Math.round(self.this.page_container.getHeight()) || Math.round(self.containerWidth) != Math.round(self.this.page_container.getWidth()));
                return self;
            },
            renderSingle: function RENDERER_SinglePage_Main(callback, options)
            {
                var self = p.pager.renderSingleSelf(callback);
                if (self.readjust && options && options.adjusted)
                    self.readjust = false;
                if (self.readjust)
                {
                    self.this.page_container.setAttr("style", "padding: {0}px; width: {1}px; height: {2}px;".format(
                        self.this.current_padding,
                        self.containerWidth,
                        self.containerHeight));

                    if (options && options.scroll && self.scrollPerformed == false)
                    {
                        self.scrollPerformed = true;
                        p.pager.content_container[0].scrollLeft = options.scroll.left;
                        p.pager.content_container[0].scrollTop = options.scroll.top;
                    }

                    var fixedoptions = p.is.object(options) ? options : {};
                    p.extend(fixedoptions, { reload: true, callback: callback, adjusted: true });
                    return setTimeout(function () { p.pager.adjust(fixedoptions); }, 1);
                }

                var onRenderedPerformed = false, onReadyPerformed = false;

                var onRendered = function RENDERER_SinglePage_Main_onRenderedCallback()
                {
                    if (!onRenderedPerformed)
                    {
                        onRenderedPerformed = true;

                        if (self.this.ready_for_first_load && !self.this.first_load_done)
                            self.this.onFirstLoad();

                        self.this.checkAndCallback(self.callback);
                        setTimeout(function ()
                        {
                            self.this.prerenderSingleClean(self);
                        }, 100);
                    }
                };
                var onReady = function RENDERER_SinglePage_Main_onReadyCallback()
                {
                    if (!onReadyPerformed && !onRenderedPerformed)
                    {
                        onReadyPerformed = true;

                        if (options && options.scroll && self.scrollPerformed == false)
                        {
                            self.scrollPerformed = true;
                            p.pager.content_container[0].scrollLeft = options.scroll.left;
                            p.pager.content_container[0].scrollTop = options.scroll.top;
                        }

                        //self.this.prepareElements();
                        setTimeout(function ()
                        {
                            if (options && options.onReady && p.is.function(options.onReady))
                                options.onReady();
                            self.this.prerenderSingleDirty(self);
                        }, 100);
                    }
                };

                if (self.this.current_renderer == self.CONST.IMG)
                {
                    self.this.renderSingleImg(self, onReady, onRendered, options);
                }
                else if (self.this.current_renderer == self.CONST.PDF)
                {
                    self.canvas.addClass("loading");
                    self.this.renderSinglePdf(self, onReady, onRendered, options);
                }
            },
            renderSingleImg: function RENDERER_SinglePage_Image(self, onReady, onFinished, options)
            {
                var readyPerformed = false, finalPerformed = false;
                self.this.holders[self.CONST.CUR].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSize.width, self.pageSize.height));
                self.this.loaders[self.CONST.CUR].setAttr("style", "");
                self.this.loaders[self.CONST.CUR].load([self.this.getThumbPath(self.cur)],
                    function RENDERER_SinglePage_Image_ReadyRender()
                    {
                        if (!readyPerformed)
                        {
                            readyPerformed = true;
                            self.this.checkAndCallback(onReady);

                            setTimeout(function ()
                            {
                                self.this.loaders[self.CONST.CUR].load([self.this.getThumbPath(self.cur), self.this.getFullPath(self.cur)],
                                    function RENDERER_SinglePage_Image_FinalRender()
                                    {
                                        if (!finalPerformed)
                                        {
                                            finalPerformed = true;
                                            self.this.checkAndCallback(onFinished);
                                        }
                                    });
                            }, 1);
                        }
                    }, true);
            },
            renderSinglePdf: function RENDERER_SinglePage_PDF(self, onReady, onFinished, options)
            {
                var readyPerformed = false, finalPerformed = false;
                self.this.loaders[self.CONST.CUR].setAttr("style", "");
                self.this.loaders[self.CONST.CUR].load([self.this.getFullPath(self.cur)],
                    function RENDERER_SinglePage_PDF_ReadyRender()
                    {
                        self.this.holders[self.CONST.CUR].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSize.width, self.pageSize.height));
                        if (!readyPerformed)
                        {
                            readyPerformed = true;
                            self.this.checkAndCallback(onReady);
                            self.this.checkAndCallback(onFinished);

                            self.this.renderTimeout = setTimeout(function ()
                            {
                                p.PDFQueue.push(self.canvas, self.cur, function RENDERER_SinglePage_PDF_FinalRender()
                                {
                                    if (!finalPerformed)
                                    {
                                        finalPerformed = true;
                                        self.canvas.removeClass("loading");
                                        //self.this.checkAndCallback(onFinished);
                                    }
                                });
                                p.PDFQueue.start();
                            }, self.this.renderWaitingTime);
                        }
                    }, true);
            },
            prerenderSingleDirty: function RENDERER_SinglePage_Prerender_Dirty(self, callback)
            {
                self.this.holders[self.CONST.NEXT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeNext.width, self.pageSizeNext.height));
                self.this.holders[self.CONST.PREV].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizePrev.width, self.pageSizePrev.height));
                p.e([self.canvasNext, self.canvasPrev]).addClass("loading");

                var counter = 0;
                var target = self.this.showPage(self.next) + self.this.showPage(self.prev);
                var waitAndCallback = function RENDERER_SinglePage_Prerender_Dirty_Waiter()
                {
                    if (++counter == target)
                        self.this.checkAndCallback(callback);
                };

                if (self.this.showPage(self.next))
                    self.this.loaders[self.CONST.NEXT].load([self.this.getThumbPath(self.next)], waitAndCallback);
                if (self.this.showPage(self.prev))
                    self.this.loaders[self.CONST.PREV].load([self.this.getThumbPath(self.prev)], waitAndCallback);
            },
            prerenderSingleClean: function RENDERER_SinglePage_Prerender_Clean(self, callback)
            {
                self.this.holders[self.CONST.NEXT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeNext.width, self.pageSizeNext.height));
                self.this.holders[self.CONST.PREV].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizePrev.width, self.pageSizePrev.height));
                p.e([self.canvasNext, self.canvasPrev]).addClass("loading");

                var counter = 0;
                var target = self.this.showPage(self.next) + self.this.showPage(self.prev);
                var waitAndCallback = function RENDERER_SinglePage_Prerender_Clean_Waiter()
                {
                    if (++counter == target)
                        self.this.checkAndCallback(callback);
                };

                //if (self.this.current_renderer == self.CONST.IMG)
                //{
                if (self.this.showPage(self.next))
                    self.this.loaders[self.CONST.NEXT].load([self.this.getThumbPath(self.next), self.this.getFullPath(self.next)], waitAndCallback);
                if (self.this.showPage(self.prev))
                    self.this.loaders[self.CONST.PREV].load([self.this.getThumbPath(self.prev), self.this.getFullPath(self.prev)], waitAndCallback);
                //}
                //else if (self.this.current_renderer == self.CONST.PDF)
                //{
                //    if (self.this.showPage(self.next))
                //        p.PDFQueue.push(self.canvasNext, self.next, function RENDERER_SinglePage_Prerender_Clean_PDFNext_Done() { self.canvasNext.removeClass("loading"), waitAndCallback(); }, true);
                //    if (self.this.showPage(self.prev))
                //        p.PDFQueue.push(self.canvasPrev, self.prev, function RENDERER_SinglePage_Prerender_Clean_PDFPrev_Done() { self.canvasPrev.removeClass("loading"), waitAndCallback(); }, true);
                //    p.PDFQueue.start();
                //}
            },


            renderDoubleSelf: function RENDERER_DoublePage_GenerateSelfVariables(callback)
            {
                var self = {};
                self.callback = callback,
                    self.this = p.pager,
                    self.iid = ++self.this.renderInstance,
                    self.CONST = self.this.constant,
                    self.scrollPerformed = false,
                    self.pageSizeLeft = self.this.getPageSize(self.this.current_page_left),
                    self.pageSizeRight = self.this.getPageSize(self.this.current_page_right),
                    self.pageSizePrevLeft = self.this.getPageSize(self.this.current_page_left - 2),
                    self.pageSizePrevRight = self.this.getPageSize(self.this.current_page_right - 2),
                    self.pageSizeNextLeft = self.this.getPageSize(self.this.current_page_left + 2),
                    self.pageSizeNextRight = self.this.getPageSize(self.this.current_page_right + 2),
                    self.canvasLeft = self.this.holders[self.CONST.CUR_LEFT].find("canvas"),
                    self.canvasRight = self.this.holders[self.CONST.CUR_RIGHT].find("canvas"),
                    self.canvasNextLeft = self.this.holders[self.CONST.NEXT_LEFT].find("canvas"),
                    self.canvasNextRight = self.this.holders[self.CONST.NEXT_RIGHT].find("canvas"),
                    self.canvasPrevLeft = self.this.holders[self.CONST.PREV_LEFT].find("canvas"),
                    self.canvasPrevRight = self.this.holders[self.CONST.PREV_RIGHT].find("canvas"),
                    self.left = self.this.current_page_left,
                    self.right = self.this.current_page_right,
                    self.nextLeft = self.this.current_page_left + 2,
                    self.nextRight = self.this.current_page_right + 2,
                    self.prevLeft = self.this.current_page_left - 2,
                    self.prevRight = self.this.current_page_right - 2,
                    self.containerWidth = ((self.pageSizeLeft.width > self.pageSizeRight.width) ? (self.pageSizeLeft.width * 2) : (self.pageSizeRight.width * 2)) + (self.this.current_padding * 2),
                    self.containerHeight = ((self.pageSizeLeft.height > self.pageSizeRight.height) ? self.pageSizeLeft.height : self.pageSizeRight.height) + (self.this.current_padding * 2),
                    self.readjust = (Math.round(self.containerHeight) != Math.round(self.this.page_container.getHeight()) || Math.round(self.containerWidth) != Math.round(self.this.page_container.getWidth()));

                //console.log(self.containerHeight, self.this.page_container.getHeight(), self.containerWidth, self.this.page_container.getWidth());
                return self;
            },
            renderDouble: function RENDERER_DoublePage_Main(callback, options)
            {
                var self = p.pager.renderDoubleSelf(callback);

                if (self.readjust && options && options.adjusted)
                    self.readjust = false;

                if (self.readjust)
                {
                    self.this.page_container.setAttr("style", "padding: {0}px; width: {1}px; height: {2}px;".format(
                        self.this.current_padding,
                        self.containerWidth,
                        self.containerHeight));

                    if (options && options.scroll && self.scrollPerformed == false)
                    {
                        self.scrollPerformed = true;
                        p.pager.content_container[0].scrollLeft = options.scroll.left;
                        p.pager.content_container[0].scrollTop = options.scroll.top;
                    }

                    var fixedoptions = p.is.object(options) ? options : {};
                    p.extend(fixedoptions, { reload: true, callback: callback, adjusted: true });
                    return setTimeout(function () { p.pager.adjust(fixedoptions); }, 1);
                }

                var onRenderedPerformed = false, onReadyPerformed = false;

                var onRendered = function RENDERER_DoublePage_Main_onRenderedCallback()
                {
                    if (!onRenderedPerformed)
                    {
                        onRenderedPerformed = true;

                        if (self.this.ready_for_first_load && !self.this.first_load_done)
                            self.this.onFirstLoad();

                        self.this.checkAndCallback(self.callback);
                        setTimeout(function ()
                        {
                            self.this.prerenderDoubleClean(self);
                        }, 100);
                    }
                };
                var onReady = function RENDERER_DoublePage_Main_onReadyCallback()
                {
                    if (!onReadyPerformed && !onRenderedPerformed)
                    {
                        onReadyPerformed = true;

                        if (options && options.scroll && self.scrollPerformed == false)
                        {
                            self.scrollPerformed = true;
                            p.pager.content_container[0].scrollLeft = options.scroll.left;
                            p.pager.content_container[0].scrollTop = options.scroll.top;
                        }

                        //self.this.prepareElements();
                        setTimeout(function ()
                        {
                            if (options && options.onReady && p.is.function(options.onReady))
                                options.onReady();
                            self.this.prerenderDoubleDirty(self);
                        }, 100);
                    }
                };

                if (self.this.current_renderer == self.CONST.IMG)
                {
                    self.this.renderDoubleImg(self, onReady, onRendered, options);
                }
                else if (self.this.current_renderer == self.CONST.PDF)
                {
                    p.e([self.canvasLeft, self.canvasRight, self.canvasNextLeft, self.canvasNextRight, self.canvasPrevLeft, self.canvasPrevRight]).addClass("loading");
                    self.this.renderDoublePdf(self, onReady, onRendered, options);
                }
            },
            renderDoubleImg: function RENDERER_DoublePage_Image(self, onReady, onFinished, options)
            {
                var readyPerformed = false,
                    finalPerformed = false,
                    targetReady = self.this.showPage(self.left) + self.this.showPage(self.right),
                    counterReady = 0,
                    targetFinish = targetReady,
                    counterFinish = 0;
                var waitReady = function RENDERER_DoublePage_Image_Ready_Waiter()
                {
                    if (++counterReady == targetReady)
                    {
                        readyPerformed = true;
                        self.this.checkAndCallback(onReady);
                    }
                };
                var waitFinish = function RENDERER_DoublePage_Image_Finish_Waiter()
                {
                    if (++counterFinish == targetFinish)
                    {
                        finalPerformed = true;
                        self.this.checkAndCallback(onFinished);
                    }
                };
                var performFinalRender = function ()
                {
                    setTimeout(function ()
                    {
                        if (self.this.show_page_left)
                        {
                            self.this.loaders[self.CONST.CUR_LEFT].load([self.this.getThumbPath(self.left), self.this.getFullPath(self.left)],
                                function RENDERER_DoublePage_Image_FinalRender_Left()
                                {
                                    if (!finalPerformed)
                                    {
                                        waitFinish();
                                    }
                                });
                        }
                        if (self.this.show_page_right)
                        {
                            self.this.loaders[self.CONST.CUR_RIGHT].load([self.this.getThumbPath(self.right), self.this.getFullPath(self.right)],
                                function RENDERER_DoublePage_Image_FinalRender_Right()
                                {
                                    if (!finalPerformed)
                                    {
                                        waitFinish();
                                    }
                                });
                        }
                    }, 1);
                };


                if (self.this.show_page_left)
                {
                    self.this.holders[self.CONST.CUR_LEFT].removeClass("hidden");
                    self.this.holders[self.CONST.CUR_LEFT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeLeft.width, self.pageSizeLeft.height));
                    self.this.loaders[self.CONST.CUR_LEFT].setAttr("style", "");
                    self.this.loaders[self.CONST.CUR_LEFT].load([self.this.getThumbPath(self.left)],
                        function RENDERER_DoublePage_Image_ReadyRender()
                        {
                            if (!readyPerformed)
                            {
                                waitReady();

                                if (readyPerformed)
                                {
                                    performFinalRender();
                                }
                            }
                        }, true);
                }
                else
                {
                    self.this.holders[self.CONST.CUR_LEFT].addClass("hidden");
                }
                if (self.this.show_page_right)
                {
                    self.this.holders[self.CONST.CUR_RIGHT].removeClass("hidden");
                    self.this.holders[self.CONST.CUR_RIGHT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeRight.width, self.pageSizeRight.height));
                    self.this.loaders[self.CONST.CUR_RIGHT].setAttr("style", "");
                    self.this.loaders[self.CONST.CUR_RIGHT].load([self.this.getThumbPath(self.right)],
                        function RENDERER_DoublePage_Image_ReadyRender()
                        {
                            if (!readyPerformed)
                            {
                                waitReady();

                                if (readyPerformed)
                                {
                                    performFinalRender();
                                }
                            }
                        }, true);
                }
                else
                {
                    self.this.holders[self.CONST.CUR_RIGHT].addClass("hidden");
                }
            },
            renderDoublePdf: function RENDERER_DoublePage_PDF(self, onReady, onFinished, options)
            {
                var readyPerformed = false,
                    hiresPerformed = false,
                    finalPerformed = false,
                    targetReady = self.this.showPage(self.left) + self.this.showPage(self.right),
                    targetFinish = targetReady,
                    counterReady = 0,
                    counterFinish = 0;
                var waitReady = function RENDERER_DoublePage_PDF_Ready_Waiter()
                {
                    if (++counterReady == targetReady)
                    {
                        readyPerformed = true;
                        self.this.checkAndCallback(onReady);
                        self.this.checkAndCallback(onFinished);
                    }
                };
                var waitFinish = function RENDERER_DoublePage_PDF_Finish_Waiter()
                {
                    if (++counterFinish == targetFinish)
                    {
                        finalPerformed = true;
                        //self.this.checkAndCallback(onFinished);
                    }
                };

                var performFinalRender = function ()
                {
                    self.this.renderTimeout = setTimeout(function ()
                    {
                        if (self.this.show_page_left)
                        {
                            p.PDFQueue.push(self.canvasLeft, self.left, function RENDERER_DoublePage_PDF_FinalRender_Left()
                            {
                                self.canvasLeft.removeClass("loading");
                                if (!finalPerformed)
                                {
                                    waitFinish();
                                }
                            });
                        }
                        if (self.this.show_page_right)
                        {
                            p.PDFQueue.push(self.canvasRight, self.right, function RENDERER_DoublePage_PDF_FinalRender_Right()
                            {
                                self.canvasRight.removeClass("loading");
                                if (!finalPerformed)
                                {
                                    waitFinish();
                                }
                            });
                        }
                        p.PDFQueue.start();
                    }, self.this.renderWaitingTime);
                };


                if (self.this.show_page_left)
                {
                    self.this.holders[self.CONST.CUR_LEFT].removeClass("hidden");
                    self.this.holders[self.CONST.CUR_LEFT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeLeft.width, self.pageSizeLeft.height));
                    self.this.loaders[self.CONST.CUR_LEFT].setAttr("style", "");
                    self.this.loaders[self.CONST.CUR_LEFT].load([self.this.getFullPath(self.left)],
                        function RENDERER_DoublePage_PDF_ReadyRender()
                        {
                            if (!readyPerformed)
                            {
                                waitReady();

                                if (readyPerformed)
                                {
                                    performFinalRender();
                                }
                            }
                        }, true);
                }
                else
                {
                    self.this.holders[self.CONST.CUR_LEFT].addClass("hidden");
                }
                if (self.this.show_page_right)
                {
                    self.this.holders[self.CONST.CUR_RIGHT].removeClass("hidden");
                    self.this.holders[self.CONST.CUR_RIGHT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeRight.width, self.pageSizeRight.height));
                    self.this.loaders[self.CONST.CUR_RIGHT].setAttr("style", "");
                    self.this.loaders[self.CONST.CUR_RIGHT].load([self.this.getFullPath(self.right)],
                        function RENDERER_DoublePage_PDF_ReadyRender()
                        {
                            if (!readyPerformed)
                            {
                                waitReady();

                                if (readyPerformed)
                                {
                                    performFinalRender();
                                }
                            }
                        }, true);
                }
                else
                {
                    self.this.holders[self.CONST.CUR_RIGHT].addClass("hidden");
                }
            },
            prerenderDoubleDirty: function RENDERER_DoublePage_Prerender_Dirty(self, callback)
            {
                self.this.holders[self.CONST.NEXT_LEFT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeNextLeft.width, self.pageSizeNextLeft.height));
                self.this.holders[self.CONST.NEXT_RIGHT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeNextRight.width, self.pageSizeNextRight.height));

                self.this.holders[self.CONST.PREV_LEFT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizePrevLeft.width, self.pageSizePrevLeft.height));
                self.this.holders[self.CONST.PREV_RIGHT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizePrevRight.width, self.pageSizePrevRight.height));

                p.e([self.canvasNextLeft, self.canvasNextRight, self.canvasPrevLeft, self.canvasPrevRight]).addClass("loading");

                var counter = 0;
                var target = self.this.showPage(self.nextLeft) + self.this.showPage(self.nextRight) + self.this.showPage(self.prevLeft) + self.this.showPage(self.prevRight);
                var waitAndCallback = function RENDERER_DoublePage_Prerender_Dirty_Waiter()
                {
                    if (++counter == target)
                        self.this.checkAndCallback(callback);
                };

                if (self.this.showPage(self.nextLeft))
                {
                    self.this.loaders[self.CONST.NEXT_LEFT].load([self.this.getThumbPath(self.nextLeft)], waitAndCallback);
                }

                if (self.this.showPage(self.nextRight))
                {
                    self.this.loaders[self.CONST.NEXT_RIGHT].load([self.this.getThumbPath(self.nextRight)], waitAndCallback);
                }

                if (self.this.showPage(self.prevLeft))
                {
                    self.this.loaders[self.CONST.PREV_LEFT].load([self.this.getThumbPath(self.prevLeft)], waitAndCallback);
                }

                if (self.this.showPage(self.prevRight))
                {
                    self.this.loaders[self.CONST.PREV_RIGHT].load([self.this.getThumbPath(self.prevRight)], waitAndCallback);
                }
            },
            prerenderDoubleClean: function RENDERER_DoublePage_Prerender_Clean(self, callback)
            {
                self.this.holders[self.CONST.NEXT_LEFT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeNextLeft.width, self.pageSizeNextLeft.height));
                self.this.holders[self.CONST.NEXT_RIGHT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizeNextRight.width, self.pageSizeNextRight.height));

                self.this.holders[self.CONST.PREV_LEFT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizePrevLeft.width, self.pageSizePrevLeft.height));
                self.this.holders[self.CONST.PREV_RIGHT].setAttr("style", "width: {0}px; height: {1}px;".format(self.pageSizePrevRight.width, self.pageSizePrevRight.height));

                p.e([self.canvasNextLeft, self.canvasNextRight, self.canvasPrevLeft, self.canvasPrevRight]).addClass("loading");

                var counter = 0;
                var target = self.this.showPage(self.nextLeft) + self.this.showPage(self.nextRight) + self.this.showPage(self.prevLeft) + self.this.showPage(self.prevRight);
                var waitAndCallback = function RENDERER_DoublePage_Prerender_Clean_Waiter()
                {
                    if (++counter == target)
                        self.this.checkAndCallback(callback);
                };

                //if (self.this.current_renderer == self.CONST.IMG)
                //{
                if (self.this.showPage(self.nextLeft))
                {
                    self.this.loaders[self.CONST.NEXT_LEFT].load([self.this.getThumbPath(self.nextLeft), self.this.getFullPath(self.nextLeft)], waitAndCallback);
                }

                if (self.this.showPage(self.nextRight))
                {
                    self.this.loaders[self.CONST.NEXT_RIGHT].load([self.this.getThumbPath(self.nextRight), self.this.getFullPath(self.nextRight)], waitAndCallback);
                }

                if (self.this.showPage(self.prevLeft))
                {
                    self.this.loaders[self.CONST.PREV_LEFT].load([self.this.getThumbPath(self.prevLeft), self.this.getFullPath(self.prevLeft)], waitAndCallback);
                }

                if (self.this.showPage(self.prevRight))
                {
                    self.this.loaders[self.CONST.PREV_RIGHT].load([self.this.getThumbPath(self.prevRight), self.this.getFullPath(self.prevRight)], waitAndCallback);
                }
                //}
                //else if (self.this.current_renderer == self.CONST.PDF)
                //{
                //    if (self.this.showPage(self.nextLeft))
                //        p.PDFQueue.push(self.canvasNextLeft, self.nextLeft, function RENDERER_DoublePage_Prerender_Clean_PDFNextLeft_Done() { self.canvasNextLeft.removeClass("loading"), waitAndCallback(); }, true);
                //    if (self.this.showPage(self.nextRight))
                //        p.PDFQueue.push(self.canvasNextRight, self.nextRight, function RENDERER_DoublePage_Prerender_Clean_PDFNextRight_Done() { self.canvasNextRight.removeClass("loading"), waitAndCallback(); }, true);

                //    if (self.this.showPage(self.prevLeft))
                //        p.PDFQueue.push(self.canvasPrevLeft, self.prevLeft, function RENDERER_DoublePage_Prerender_Clean_PDFPrevLeft_Done() { self.canvasPrevLeft.removeClass("loading"), waitAndCallback(); }, true);
                //    if (self.this.showPage(self.prevRight))
                //        p.PDFQueue.push(self.canvasPrevRight, self.prevRight, function RENDERER_DoublePage_Prerender_Clean_PDFPrevRight_Done() { self.canvasPrevRight.removeClass("loading"), waitAndCallback(); }, true);


                //    p.PDFQueue.start();
                //}
            },

            pdfJsOnLoad: function (e)
            {
                console.log("pdfjs onload");
                p.pager.pdf_loaded_load = true;

                p.pager.pdf_loaded = p.pager.pdf_loaded_load && p.pager.pdf_loaded_progess;
                if (p.pager.pdf_loaded)
                {
                    p.pager.adjust();
                }
            },
            pdfJsOnProgress: function (e)
            {
                var perc = p.math.Percentage.XofY(e.loaded, e.total);
                p.reader.setProgress(perc);
                if (perc >= 100)
                {
                    p.pager.pdf_loaded_progess = true;

                    p.pager.pdf_loaded = p.pager.pdf_loaded_load && p.pager.pdf_loaded_progess;
                    if (p.pager.pdf_loaded)
                    {
                        p.pager.adjust();
                    }
                }
            },
            pdfJsOnPage: function (e)
            {
                //console.log(e);
            },
            pdfJsOnRender: function (e)
            {
            },
        },

        TouchHandler: function (on, options)
        {
            var SWIPE_THRESHOLD = options.swipeThreshold || 125;
            var MOVE_THRESHOLD = options.moveThreshold || 10;
            var SWIPE_CANCEL = options.swipeCancel || 70;
            var DOUBLETAP_CANCEL = options.doubleTapCancel || 200;
            var WHEEL_CANCEL = options.wheelCancel || 500;
            var WHEEL_TICK = options.wheelTick || 1;
            var DIR_LEFT = 1;
            var DIR_RIGHT = -1;
            var PROPAGATE = true;

            var mStartPoint,
                mEndPoint,
                mLastTouch,
                mIsTouch,
                mDirection,
                mDeviation,
                mDistance,
                mDragging,
                mSwiping,
                mPinching,
                mWaitingForDoubleTap,
                mDoubleTapTimeout,
                mOptimized,
                mZoomTimeout,
                mZooming,
                mCumulZoom = WHEEL_TICK,
                mLastSrc;

            mOptimized = mPinching = mDragging = mSwiping = mZooming = false;

            var target = p.e(on);
            //console.log("Touch handler on", target);  

            var sign = function (e)
            {
                return e < 0 ? -1 : 1;
            };

            var isContained = function ()
            {
                //var res = target[0].scrollHeight <= target.getHeight() || target[0].scrollWidth <= target.getWidth();
                //if (!res)
                //console.log(target[0].scrollHeight, target.getHeight(), target[0].scrollWidth, target.getWidth());
                return target[0].scrollHeight <= target.getHeight() || target[0].scrollWidth <= target.getWidth();
            };

            var fncDoubleTapCheck = function (e)
            {
                if (e.type == "mouseleave")
                    return;

                var src = p.e(e.srcElement);
                if (!src.getParents().hasClass("ctrl"))
                {
                    if (mWaitingForDoubleTap)
                    {
                        mWaitingForDoubleTap = false;
                        clearTimeout(mDoubleTapTimeout);
                        if (src.getAttr("id") == mLastSrc.getAttr("id") && src.getAttr("class") == mLastSrc.getAttr("class"))
                        {
                            //debugger;
                            if (options.onDoubleTap && p.is.function(options.onDoubleTap))
                            {
                                //debugger;
                                options.onDoubleTap(fncGetPos(e) || mLastTouch || mStartPoint);
                            }
                        }
                    }
                    else
                    {
                        mWaitingForDoubleTap = true;
                        mLastSrc = src;
                        mDoubleTapTimeout = setTimeout(function () { mWaitingForDoubleTap = false; }, DOUBLETAP_CANCEL);
                    }
                }
            };

            //var debugbox = p.e("<div>", { "class": "debugbox" });
            //debugbox.appendTo(p.reader.container);

            var fncGetPos = function (event)
            {
                var offset = target.getPageXY();
                if (event.type.startsWith("touch"))
                {
                    if (event.touches.length == 1)
                    {
                        return { x: event.touches[0].pageX - offset.x, y: event.touches[0].pageY - offset.y };
                    }
                    else if (event.touches.length == 2)
                    {
                        var a = { x: event.touches[0].pageX - offset.x, y: event.touches[0].pageY - offset.y },
                            b = { x: event.touches[1].pageX - offset.x, y: event.touches[1].pageY - offset.y },
                            midpoint = p.math.Midpoint2D(a, b);

                        return { x: midpoint.x, y: midpoint.y, dist: p.math.Dist2D(a, b) };
                    }
                }
                else
                {
                    return { x: event.pageX - offset.x, y: event.pageY - offset.y };
                }
            };

            var fncOnDown = function (e)
            {
                if (!p.e(e.srcElement).getParents().hasClass("ctrl"))
                {
                    p.pager.content_container[0].focus();

                    if (e && e.preventDefault) e.preventDefault();
                    if (!PROPAGATE && e && e.stopPropagation) e.stopPropagation();

                    mIsTouch = e.type.startsWith("touch");

                    if (mIsTouch && e.touches.length >= 2)
                    {
                        mPinching = true;
                        mSwiping = mDragging = false;
                        mStartPoint = mLastTouch = fncGetPos(e);

                        if (options.onPinchStart && p.is.function(options.onPinchStart))
                        {
                            options.onPinchStart(mStartPoint);
                        }
                    }
                    else if (isContained())
                    {
                        mSwiping = true;
                        mStartPoint = fncGetPos(e);
                    }
                    else
                    {
                        mDragging = true;
                        mLastTouch = fncGetPos(e);
                    }
                }
            };

            var fncOnMove = function (e)
            {
                //console.info(e);

                if (e && e.preventDefault) e.preventDefault();
                if (!PROPAGATE && e && e.stopPropagation) e.stopPropagation();

                if (!mOptimized)
                {
                    target.addClass("speed");
                    mOptimized = true;
                }

                if (mPinching)
                {
                    var current = fncGetPos(e);
                    var difference = current.dist - mStartPoint.dist;
                    var differenceChange = current.dist / mStartPoint.dist;

                    if (options.onPinch && p.is.function(options.onPinch))
                    {
                        options.onPinch(difference, differenceChange, current);
                    }
                }
                else if (!isContained() && mDragging)
                {
                    var current = fncGetPos(e);
                    var difference =
                    {
                        x: mLastTouch.x - current.x,
                        y: mLastTouch.y - current.y
                    };


                    //console.log("scroll");

                    if (options.onDrag && p.is.function(options.onDrag))
                    {
                        options.onDrag(difference);
                    }
                    //mContent.scrollLeft(mContent.scrollLeft() + difference.x);
                    //mContent.scrollTop(mContent.scrollTop() + difference.y);

                    //console.info(difference);
                }

                if (mIsTouch || !isContained())
                    mLastTouch = fncGetPos(e);
            };

            var fncOnUp = function (e)
            {
                //return console.info(e);

                if (e && e.preventDefault) e.preventDefault();
                if (!PROPAGATE && e && e.stopPropagation) e.stopPropagation();


                if (mOptimized)
                {
                    target.removeClass("speed");
                    mOptimized = false;
                }

                if (!mPinching)
                {
                    fncDoubleTapCheck(e);
                }

                if (isContained() && mSwiping)
                {
                    if (e.type.startsWith("touch") && mLastTouch != null && mLastTouch != undefined)
                        mEndPoint = mLastTouch;
                    else
                        mEndPoint = fncGetPos(e) || mStartPoint;

                    mDistance = p.math.Dist2D(mStartPoint, mEndPoint);
                    mDeviation = Math.abs(mStartPoint.y - mEndPoint.y);
                    mDirection = (mStartPoint.x > mEndPoint.x) ? DIR_LEFT : DIR_RIGHT;

                    if (mDistance > SWIPE_THRESHOLD && mDeviation < SWIPE_CANCEL)
                    {
                        if (mDirection == DIR_LEFT)
                        {
                            if (options.onSwipeLeft && p.is.function(options.onSwipeLeft))
                            {
                                options.onSwipeLeft();
                            }
                        }
                        else
                        {
                            if (options.onSwipeRight && p.is.function(options.onSwipeRight))
                            {
                                options.onSwipeRight();
                            }
                        }
                    }
                    mSwiping = false;
                }
                else if (mPinching)
                {
                    if (options.onPinchEnd && p.is.function(options.onPinchEnd))
                    {
                        options.onPinchEnd(mLastTouch);
                    }
                    mPinching = false;
                }
                else
                {
                    mDragging = false;
                }

            };

            var fncWheel = function (e)
            {
                if (e && e.preventDefault) e.preventDefault();
                if (e && e.stopPropagation) e.stopPropagation();

                if (!mPinching && !mDragging)
                {
                    if (!mZooming)
                    {
                        mZooming = true;

                        mStartPoint = fncGetPos(e);
                        mCumulZoom = 10;

                        if (options.onWheelStart && p.is.function(options.onWheelStart))
                        {
                            options.onWheelStart(mStartPoint);
                        }
                    }

                    mCumulZoom += (WHEEL_TICK * -sign(e.deltaY));
                    var current = fncGetPos(e);
                    var difference = WHEEL_TICK * sign(e.deltaY);
                    var differenceChange = mCumulZoom / 10;

                    if (options.onWheel && p.is.function(options.onWheel))
                    {
                        options.onWheel(difference, differenceChange, current);
                    }

                    clearTimeout(mZoomTimeout);
                    mZoomTimeout = setTimeout(fncWheelTimeout, WHEEL_CANCEL);
                }

            };

            var fncWheelTimeout = function ()
            {
                if (mZooming)
                {
                    if (options.onWheelEnd && p.is.function(options.onWheelEnd))
                    {
                        options.onWheelEnd(mStartPoint);
                    }
                    mZooming = false;
                }
            };

            target.on("wheel", fncWheel);
            target.on("mousedown touchstart", fncOnDown);
            target.on("mouseleave mouseup touchend", fncOnUp);
            target.on("mousemove touchmove", fncOnMove);
        },

        PDFQueue:
        {
            queue: [],
            current_callback: null,
            runnning: false,
            qid: 0,

            push: function (canvas, page, callback, prerender)
            {
                this.queue.push({ canvas: canvas, page: page, callback: callback, prerender: prerender || false, qid: p.PDFQueue.qid });
            },

            reset: function ()
            {
                this.qid++;
                this.queue = [];
            },

            start: function (callback)
            {
                this.current_callback = callback;
                if (!this.runnning)
                {
                    this.runnning = true;
                    this._render();
                }
            },

            _render: function ()
            {
                var $this = p.PDFQueue, item = $this.queue.pop(), render = false, CONST = p.pager.constant, start = (new Date()).getTime();
                if (item)
                {
                    //if (p.pager.current_mode == CONST.SINGLE || p.pager.current_mode == CONST.SINGLE_REVERSE)
                    //    render = item.page == p.pager.current_page_firstvalid;
                    //else if (p.pager.current_mode == CONST.DOUBLE || p.pager.current_mode == CONST.DOUBLE_REVERSE)
                    //    render = (item.page == p.pager.current_page) || (item.page == p.pager.current_page + 1);

                    //render = render || item.prerender;
                    //render = 

                    render = item.qid == p.PDFQueue.qid;

                    if (render)
                    {
                        try
                        {
                            //console.info("Rendering page", item.page, "on", item.canvas);
                            p.PDFManagerReader.renderPageOn(item.page, p.reader.render_canvas, function ()
                            {
                                p.pager.push_pdf_rendertime((new Date()).getTime() - start);

                                if (item.qid == p.PDFQueue.qid)
                                {
                                    var context = item.canvas[0].getContext('2d');
                                    item.canvas[0].width = p.reader.render_canvas[0].width;
                                    item.canvas[0].height = p.reader.render_canvas[0].height;

                                    context.drawImage(p.reader.render_canvas[0], 0, 0);

                                    if (item.callback && p.is.function(item.callback))
                                        item.callback();
                                }

                                $this._render();
                            });

                            /*
                            p.PDFManagerReader.renderPageOn(item.page, item.canvas, function ()
                            {
                                p.pager.push_pdf_rendertime((new Date()).getTime() - start);

                                if (item.callback && p.is.function(item.callback))
                                    item.callback();

                                $this._render();
                            });
                            */
                        }
                        catch (ex)
                        {
                            //console.error("Render failed");
                            $this._render();
                        }
                    }
                    else
                    {
                        //console.log("Skipping render"); 
                        $this._render();
                    }
                }
                else
                {
                    $this.runnning = false;

                    if ($this.current_callback && p.is.function($this.current_callback))
                        $this.current_callback();
                }
            }
        },

        PDFManagerReader:
        {
            DEBUG_SYMBOL: "PDFM",
            document: null,
            pages: {},
            scale: 1.0,
            ref_factor: -1,

            _rendering: false,
            _loading: 0,

            get isRendering()
            {
                return this._rendering;
            },

            get isLoading()
            {
                return this._loading > 0;
            },

            loadFile: function PDFM_loadFile(e, callback)
            {
                this._loading++;
                let task = pdfjsLib.getDocument({
                    url: e,
                    stopAtErrors: false
                });

                task.onProgress = this.onDocumentProgress;

                task.promise.then(function PDFM_loadFile_chain(a)
                {
                    if (callback && p.is.function(callback))
                        callback(a);

                    p.PDFManagerReader.onDocumentLoad(a);
                });
            },
            getPage: function PDFM_getPage(i, callback)
            {
                if (this.pages.hasOwnProperty(i))
                {
                    if (callback && p.is.function(callback))
                        callback(this.pages[i]);
                }
                else
                {
                    if (this.document != null)
                    {
                        this.document.getPage(parseInt(i)).then(function PDFM_getPage_chain(e)
                        {
                            if (callback && p.is.function(callback))
                                callback(e);

                            p.PDFManagerReader.onPageLoad(e);
                        }, function PDFM_getPage_chainException(ex)
                        {
                            console.error("GetPageChainException", ex, ex.stack, "for page", i, "Callback on:", callback);
                        }).then(null, function PDFM_getPage_chainException(ex)
                        {
                            console.error("GetPageUncaughtException", ex, ex.stack, "for page", i, "Callback on:", callback);
                        });
                    }
                }
            },
            renderPageOn: function PDFM_renderPageOn(page, canvas, callback, ignorePageSize)
            {
                if (p.isExElement(canvas) == false || canvas.length != 1)
                {
                    //Trace(["Invalid canvas"], this.DEBUG_SYMBOL, CONSOLE_ERROR);
                    return;
                }

                //console.log(page, 0, this.document.numPages);

                if (page == 0 || page > this.document.numPages)
                {
                    if (callback && p.is.function(callback))
                        callback();

                    return;
                }

                this._rendering = true;

                var RENDER_CONTEXT =
                {
                    canvasContext: canvas[0].getContext("2d"),
                    viewport: null
                };

                if (this.pages.hasOwnProperty(page))
                {
                    if (this.ref_factor == -1 && ignorePageSize !== true)
                    {
                        RENDER_CONTEXT.viewport = this.pages[page].getViewport({ scale: 1.0 });
                        var refSize = p.pager.getPageSize(page, true);
                        this.ref_factor = refSize.width / RENDER_CONTEXT.viewport.viewBox[2];
                        // console.log("Ref factor", this.ref_factor, "refSize", refSize, "viewport", RENDER_CONTEXT.viewport);
                    }
                    else if (this.ref_factor == -1 && ignorePageSize == true)
                    {
                        this.ref_factor = 1;
                    }

                    RENDER_CONTEXT.viewport = this.pages[page].getViewport({ scale: this.scale * this.ref_factor });
                    canvas[0].width = RENDER_CONTEXT.viewport.width;
                    canvas[0].height = RENDER_CONTEXT.viewport.height;

                    //p.Pager.refSize.width = RENDER_CONTEXT.viewport.width / this.scale;
                    //p.Pager.refSize.height = RENDER_CONTEXT.viewport.height / this.scale;

                    //$("#loader-left")[0].width = RENDER_CONTEXT.viewport.width;
                    //$("#loader-left")[0].height = RENDER_CONTEXT.viewport.height;
                    //$("#loader-right")[0].width = RENDER_CONTEXT.viewport.width;
                    //$("#loader-right")[0].height = RENDER_CONTEXT.viewport.height;
                    try
                    {
                        this.pages[page].render(RENDER_CONTEXT).promise.then(function PDFM_renderPageOn_chain(e)
                        {
                            p.PDFManagerReader._rendering = false;

                            if (callback && p.is.function(callback))
                                callback(e);

                            p.PDFManagerReader.onPageRender(e);
                        }, function PDFM_renderPageOn_chainException(ex)
                        {
                            console.error("RenderPageOn_ChainException", ex, ex.stack, "for page", page, "on canvas", canvas, "callback on:", callback);

                            p.PDFManagerReader._rendering = false;

                            if (callback && p.is.function(callback))
                                callback(false);

                            p.PDFManagerReader.onPageRender(false);
                        }).then(null, function (ex)
                        {
                            console.error("RenderPageOn_UncaughtException", ex, ex.stack, "for page", page, "on canvas", canvas, "callback on:", callback);

                            p.PDFManagerReader._rendering = false;

                            if (callback && p.is.function(callback))
                                callback(false);

                            p.PDFManagerReader.onPageRender(false);
                        });
                    }
                    catch (ex)
                    {
                        console.error("RenderPageOn_FatalException", ex, ex.stack, "for page", page, "on canvas", canvas, "callback on:", callback);

                        p.PDFManagerReader._rendering = false;

                        if (callback && p.is.function(callback))
                            callback(false);

                        p.PDFManagerReader.onPageRender(false);
                    }
                }
                else
                {
                    try
                    {
                        this.getPage(page, function PDFM_renderPageOn_loader()
                        {
                            p.PDFManagerReader.renderPageOn(page, canvas, callback, ignorePageSize);
                        });
                    }
                    catch (ex)
                    {
                        console.error("GetPageChainException", ex, ex.stack, "for page", page, "Callback on:", callback);
                    }
                }
            },

            //#region Events

            // #region Handlers

            _handlerOnDocumentLoad: null,
            _handlerOnDocumentProgress: null,
            _handlerOnPageLoad: null,
            _handlerOnPageRender: null,

            setOnDocumentLoadHandler: function PDFM_handlerSet_onDocumentLoad(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnDocumentLoad = e;
            },
            setOnDocumentProgressHandler: function PDFM_handlerSet_onDocumentProgress(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnDocumentProgress = e;
            },
            setOnPageLoadHandler: function PDFM_handlerSet_onPageLoad(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnPageLoad = e;
            },
            setOnPageRenderHandler: function PDFM_handlerSet_onPageRender(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnPageRender = e;
            },

            // #endregion

            onDocumentLoad: function PDFM_handlerTrigger_onDocumentLoad(e)
            {
                var $this = p.PDFManagerReader;
                $this.document = e;

                if ($this._handlerOnDocumentLoad != null && p.is.function($this._handlerOnDocumentLoad))
                {
                    $this._handlerOnDocumentLoad(e);
                }
            },
            onDocumentProgress: function PDFM_handlerTrigger_onDocumentProgress(e)
            {
                var $this = p.PDFManagerReader;

                if (e && e["loaded"] && e["total"])
                {
                    if (e.loaded >= e.total)
                    {
                        $this._loading--;

                        if ($this._loading < 0)
                            $this._loading = 0;
                    }
                }

                if ($this._handlerOnDocumentProgress != null && p.is.function($this._handlerOnDocumentProgress))
                {
                    $this._handlerOnDocumentProgress(e);
                }
            },
            onPageLoad: function PDFM_handlerTrigger_onPageLoad(e)
            {
                var $this = p.PDFManagerReader;
                $this.pages[e.pageNumber] = e;

                if ($this._handlerOnPageLoad != null && p.is.function($this._handlerOnPageLoad))
                {
                    $this._handlerOnPageLoad(e);
                }
            },
            onPageRender: function PDFM_handlerTrigger_onPageRender(e)
            {
                var $this = p.PDFManagerReader;

                if ($this._handlerOnPageRender != null && p.is.function($this._handlerOnPageRender))
                {
                    $this._handlerOnPageRender(e);
                }
            }

            //#endregion
        },

    };

    exHelp.extend(MaglifyReaderInit);
    window.reader__inited = true;
})(window);