(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        search:
        {
            button: null,
            _elements: null,
            _lastQuery: "",

            Init: function ()
            {
                //p.material.placeholder($("[material-placeholder]"));

                // this.field = $("#drawer-search-input");
                this.button = $("#drawer-action-search");
                // this.button_back = $("#drawer-action-back-from-search");

                // //console.log(this.field, this.button);

                // if (this.field.length > 0 && this.button.length > 0)
                // {
                //     this.field.off("keyup blur focus", this.onSearch);

                this.button.off("click", this.open);
                //     this.button_back.off("click", this.deferStop);

                //     this.field.on("keyup blur focus", this.onSearch);

                this.button.on("click", this.open);
                //     this.button_back.on("click", this.deferStop);
                // }
            },

            stageLoaderId: 0,
            stageLoader: function ()
            {
                var ele = p.e("<div>", { "class": "loader" }),
                    spinner = p.e("<div>", { "class": "spinner" }).appendTo(ele);
                var thisId = ++p.search.stageLoaderId;

                var c =
                {
                    currentStage: -1,
                    instanceId: 0,
                    sources: [],
                    elements: [],
                    spinner: null,
                    callback: null,
                    loadInstance: null,

                    load: function (sources, callback, suppressLoading)
                    {
                        if (sources)
                        {
                            var allsame = true;

                            for (var i = 0; i < sources.length; i++)
                                if (sources[i] != c.sources[i])
                                {
                                    allsame = false;
                                    break;
                                }

                            if (allsame)
                            {
                                if (callback && p.is.function(callback))
                                {
                                    callback();
                                }
                                return;
                            }

                            c.sources = sources;
                            c.spinner.removeClass("hidden");
                            for (var i = 0; i < c.elements.length; i++)
                                c.elements[i].remove();

                            c.elements = [];

                            for (var i = 0; i < c.sources.length; i++)
                                c.elements[i] = p.e("<img>", { "class": suppressLoading && i == 0 ? "" : "loading" }).appendTo(ele);
                        }
                        c.callback = callback;

                        c.loadInstance = new c.loadEvent(++c.instanceId);
                        c.currentStage = -1;
                        c.loadInstance.onLoad();
                    },
                    loadEvent: function (iid)
                    {
                        var $this = this;
                        this.id = iid;
                        this.onLoad = function ()
                        {
                            if ($this.id != c.instanceId)
                            {
                                return;
                            }
                            if (c.currentStage >= 0)
                            {
                                c.spinner.addClass("hidden");
                                if (c.elements[c.currentStage])
                                    c.elements[c.currentStage].removeClass("loading");
                            }

                            c.currentStage++;

                            if (c.currentStage < c.sources.length)
                            {
                                c.elements[c.currentStage].on("load", $this.onLoad);
                                c.elements[c.currentStage].setAttr("src", c.sources[c.currentStage]);
                            }
                            else
                            {
                                if (c.callback && p.is.function(c.callback))
                                {
                                    if ($this.id != c.instanceId)
                                    {
                                        return;
                                    }
                                    c.callback();
                                    c.callback = null;
                                }
                            }
                        };

                    }
                };
                c.spinner = spinner;
                exHelp.extend(ele, c);
                return ele;
            },

            makeModal: function ()
            {
                var elements = {
                    modal: null,

                    content_wrapper: null,
                    header: null,
                    header_text: null,
                    search_field_wrapper: null,
                    search_field_icon: null,
                    search_field: null,
                    search_field_label: null,
                    search_button: null,
                    content: null,

                    footer: null,
                    cancel_button: null
                };
                elements.modal = p.e("<div>", { "id": "search-modal", "class": "modal modal-fixed-footer" });

                elements.header = p.e("<div>", { "class": "modal-fixed-header" }).appendTo(elements.modal);
                elements.header_text = p.e("<h4>", { "html": p.locale.getString("SEARCH") }).appendTo(elements.header);
                elements.search_field_wrapper = p.e("<div>", { "class": "input-field" }).appendTo(elements.header);
                elements.search_field_icon = p.e("<i>", { "class": "material-icons prefix", html: "search" }).appendTo(elements.search_field_wrapper);
                elements.search_field = p.e("<input>", { "id": "search-modal-field", "type": "text", "placeholder": p.locale.getString("SEARCH") }).appendTo(elements.search_field_wrapper);
                elements.search_field_label = p.e("<label>", { "for": "search-modal-field" }).appendTo(elements.search_field_wrapper);

                elements.content_wrapper = p.e("<div>", { "class": "modal-content" }).appendTo(elements.modal);

                elements.footer = p.e("<div>", { "class": "modal-footer" }).appendTo(elements.modal);
                elements.cancel_button = p.e("<a>", { "href": "#!", "class": "modal-close waves-effect btn-flat", html: p.locale.getString("CLOSE") }).appendTo(elements.footer);

                return elements;
            },

            open: function ()
            {
                var $this = p.search;
                if ($this._elements == null || p.e("#search-modal").length == 0)
                {
                    $this._elements = $this.makeModal();
                    $this._elements.modal.appendTo("body");
                    $($this._elements.modal).modal({
                        ready: function ()
                        {
                            // p.e($(".modal-overlay").detach()[0]).appendTo("body");
                        },
                        complete: function ()
                        {
                            $this._elements.modal.remove();
                            $this._elements = null;
                        }
                    });
                    $this._elements.search_field.on("change input", $this.onDelayedSearch);
                }

                $($this._elements.modal).modal("open");
            },

            searchTimeout: null,
            onDelayedSearch: function ()
            {
                var $this = p.search;
                if ($this.searchTimeout != null)
                    clearTimeout($this.searchTimeout);

                $this.searchTimeout = setTimeout($this.onSearch, 500);
            },

            searchId: 0,
            onSearch: function ()
            {
                var $this = p.search;
                if ($this._elements != null)
                {
                    var needle = $this._elements.search_field[0].value.toLowerCase();
                    // console.log(needle, $this._lastQuery);
                    if (needle && needle.length > 0)
                    {
                        if (needle == $this._lastQuery)
                            return;


                        $this._lastQuery = needle;
                        $this.searchId++;

                        var mySearchId = $this.searchId + 0;

                        p.net.Request(["search"], { query: needle }, function (success, data)
                        {
                            if (mySearchId != $this.searchId) return;

                            if (success)
                            {
                                if (data && data.results)
                                {
                                    $this._elements.content_wrapper.setHtml("");
                                    $this._elements.content_wrapper[0].scrollTop = 0;
                                    var results = data.results;


                                    p.tracking.queue("SEARCH", {
                                        "searchStr": needle,
                                        searchResultsCategories: 0,
                                        searchResultsContents: results.length
                                    });

                                    if (results.length == 0)
                                    {
                                        p.e("<div>", { "class": "result empty", "html": "__LOCALE__:SEARCH_NONE" }).appendTo($this._elements.content_wrapper);
                                    }
                                    else
                                    {
                                        for (var i in results)
                                        {
                                            var result = results[i];
                                            var el = p.e("<div>", {
                                                "class": "result flex cols",
                                                "data-page": result.page,
                                                "data-udid": result.udid,
                                                "title": result.name
                                            });

                                            var thumb = p.e("<div>", { "class": "thumb thecover" }).appendTo(el);
                                            var loader = p.search.stageLoader();
                                            loader.load(["{0}/api/web/?cmd=get/file/cover&udid={1}&class=web&platform=web&size=medium".format(BASEPATH, result.udid)]);
                                            loader.appendTo(thumb);

                                            var pageThumb = p.e("<div>", { "class": "thumb thepage" }).appendTo(el);
                                            var loaderPage = p.search.stageLoader();
                                            loaderPage.load(["{0}/api/web/?cmd=get/file/page&udid={1}&class=web&platform=web&type=thumb&page={2}".format(BASEPATH, result.udid, result.page)]);
                                            loaderPage.appendTo(pageThumb);



                                            var text = p.e("<div>", { "class": "preview" }).appendTo(el);
                                            var snippet = result.text;

                                            var expression = needle.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                                            // var regexp = new RegExp(expression, 'ig');
                                            var regexp = new RegExp(data.regexp.substring(1, data.regexp.length - 1), 'ig');
                                            snippet = snippet.replace(regexp, "<span class='highlight'>$&</span>");

                                            text.setHtml("<strong>{0}</strong> auf Seite {1}<p>{2}</p>".format(result.name, result.page, snippet));
                                            el.appendTo($this._elements.content_wrapper);

                                            el.on("click tap", function ()
                                            {
                                                var $ele = $(this);
                                                if (p.books.isInShelf($ele.attr("data-udid")))
                                                {
                                                    p.common.transitionLoader.show(function ()
                                                    {
                                                        window.location = "{0}/reader/?/{1}/{2}".format(BASEPATH, $ele.attr("data-udid"), $ele.attr("data-page"));
                                                    });
                                                }
                                                else
                                                {
                                                    $($this._elements.modal).modal("close");
                                                    p.seamlessNavigation.Push({ cmd: "shop", "stack": "/shelf/details/{0}/{1}/".format($ele.attr("data-udid"), encodeURIComponent($ele.attr("title").replace(/[^a-zA-Z0-9]/ig, "_"))) });
                                                }
                                            });

                                        }
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }
    };


    exHelp.extend(module);
})(window);