(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        common:
        {
            _loader: null,
            _loaderText: null,

            _hideTimeout: null,
            _showTimeout: null,

            _lastCall: (new Date).getTime(),

            _showtime: -1,
            _showtimer: null,

            _count: 0,

            hideContent: function ()
            {
                try
                {
                    if (this._loader)
                    {
                        $(this._loader).addClass("full");
                    }
                }
                catch (ex)
                {
                }
            },

            setAd: function (e, dur)
            {

            },

            setInfo: function (txt)
            {
                if (this._loader)
                {
                    if (this._loaderText)
                    {
                        $(this._loaderText).html(txt);
                    }
                    else
                    {
                        this._loaderText = $div({ "class": "spinner-info hidden", "html": txt });
                        $(this._loader).append(this._loaderText);
                    }

                    if ($(this._loaderText).hasClass("hidden"))
                    {
                        if (this._showtimer === null)
                        {
                            this._showtimer = setTimeout(function () { p.common.showInfo(); }, 2000);
                        }
                    }
                }
            },

            showInfo: function ()
            {
                try
                {
                    if (this._loader)
                    {
                        if (this._loaderText)
                        {
                            $(this._loaderText).removeClass("hidden");
                        }
                    }
                }
                catch (ex)
                {
                }
            },

            showLoader: function ShowLoader(target)
            {
                //CallTrace(arguments, "LOAD");
                //return;
                var t = (new Date).getTime() - this._lastCall;
                this._lastCall = (new Date).getTime();

                this._count++;
                var caller = (arguments.callee && arguments.callee.caller && arguments.callee.caller.name) || "Anon";
                Trace(["Show", this._count, "by", caller, "Time since last call:", t], "LOADER", CONSOLE_INFO);

                if (this._loader === null && this._count > 0)
                {
                    clearTimeout(this._hideTimeout);
                    this._showTimeout = setTimeout(function ShowLoader_Deferral()
                    {
                        p.common._showDo(target);
                    }, 500);
                }
            },

            _showDo: function ShowLoader_DO(target)
            {
                if (this._loader === null && this._count > 0)
                {
                    this._showtime = (new Date).getTime();
                    Trace(["SHOW DO", target], "LOAD", CONSOLE_INFO);

                    var $container = $div({ "class": "load-indicator" });
                    var $spinner = p.paper.spinner(); //$div({ "class": "spinner" });
                    $container.append($spinner);

                    this._loader = $container;

                    //if (!target)
                    $("body").append($container);
                    //else
                    //    $(target).append($container);

                    setTimeout(function () { p.common._loader.addClass("show"); }, 250);
                }
            },

            hideLoader: function HideLoader()
            {
                //CallTrace(arguments, "LOAD");

                var t = (new Date).getTime() - this._lastCall;
                this._lastCall = (new Date).getTime();

                this._count--;
                if (this._count < 0) this._count = 0;
                var caller = (arguments.callee && arguments.callee.caller && arguments.callee.caller.name) || "Anon";
                Trace(["Hide", this._count, "by", caller, "Time since last call:", t], "LOADER", CONSOLE_INFO);
                // Trace(["Hide", this._count, "by", arguments.callee.caller.name, "Time since last call:", t], "LOADER", CONSOLE_INFO);

                clearTimeout(this._hideTimeout);
                clearTimeout(this._showTimeout);
                this._hideTimeout = setTimeout(function HideLoader_Deferral()
                {
                    p.common._hideDo();
                }, 250);
            },

            _hideDo: function HideLoader_DO()
            {
                //CallTrace(arguments, "LOAD");
                // console.error("HIDE");

                if (this._loader !== null && this._count <= 0)
                {
                    clearTimeout(this._showTimeout);
                    Trace(["Hide done"], "LOADER", CONSOLE_INFO);
                    this._showtime = -1;
                    clearTimeout(this._showtimer);
                    this._showtimer = null;

                    if (this._loaderText !== null)
                    {
                        $(this._loaderText).remove();
                        this._loaderText = null;
                    }

                    $(this._loader).remove();
                    this._loader = null;
                }
            },

            // Background loading state
            _bgloader: null,
            _bghideTimeout: null,
            set_bg_load_state: function (percent)
            {
                //console.info("Loading state: " + percent + "%");
                if (percent >= 100 || percent <= 0)
                {
                    if (percent >= 100)
                    {
                        $(this._bgloader).children(".bar").css("width", "100%");
                        $(this._bgloader).addClass("finish");
                    }

                    var $this = this;
                    clearTimeout(this._bghideTimeout);
                    this._bghideTimeout = setTimeout(function ()
                    {
                        if ($this._bgloader !== null)
                        {
                            $($this._bgloader).remove();
                            $this._bgloader = null;
                        }
                    }, 2000);


                    return;
                }

                if (this._bgloader === null)
                {
                    this._bgloader = $div({ "class": "bg-loader" });
                    this._bgloader.append($div({ "class": "bar" }));
                    $("body").append(this._bgloader);
                }

                clearTimeout(this._bghideTimeout);
                $(this._bgloader).removeClass("finish");

                $(this._bgloader).children(".bar").css("width", percent + "%");
            },

            transitionLoader:
            {
                show: function (callback)
                {
                    // return;
                    var div = $(".transition-loader");

                    if (!div || div.length == 0)
                    {
                        div = $div({ "class": "transition-loader" });
                        $("body").append(div);
                    }

                    div.append(p.paper.spinner());

                    setTimeout(function ()
                    {
                        div.addClass("expand");

                        setTimeout(function ()
                        {
                            if (callback && p.is.function(callback))
                                callback();
                        }, 1000);
                    }, 50);
                },

                status: function (str)
                {
                    var div = $(".transition-loader");
                    var label = div.children(".status");
                    if (label.length == 0)
                    {
                        label = $div({ "class": "status title" });
                        div.append(label);
                    }

                    label.html(str);
                },

                hide: function (callback)
                {
                    var div = $(".transition-loader");
                    if (div.length > 0)
                    {
                        var ended = false;
                        div.on("transitionend webkitTransitionend oTransitionend", function ()
                        {
                            if (ended) return;
                            ended = true;

                            div.remove();
                            if (callback && p.is.function(callback))
                                callback();
                        });

                        div.removeClass("expand");
                    }
                    else
                    {
                        if (callback && p.is.function(callback))
                            callback();
                    }
                },
            },

            customTextArrayParse: function (e)
            {
                var outElements = [];

                for (var key in e)
                {
                    var rawElement = e[key];
                    var element = null;

                    if (key == "LINK")
                    {
                        element = $("<a>", {
                            html: rawElement["text"]
                        });

                        p.seamlessNavigation.Link_State(element, rawElement);
                    }
                    else if (key == "BUTTON")
                    {
                        element = p.paper.button(rawElement["text"], false, null, null, "branded fill");

                        p.seamlessNavigation.Link_State(element, rawElement);
                    }
                    else
                    {
                        element = $("<p>", { html: rawElement });
                    }

                    if (element != null)
                        outElements.push(element);
                }

                return outElements;
            }
        },

        tracking:
        {
            _queue: [],
            _postponedQueue: [],
            _runnerTimeout: null,
            _sessionId: null,
            _deviceId: null,
            _deviceToken: null,
            _trackrId: 0,
            _ready: false,

            _queueRunner: function ()
            {
                var $this = p.tracking;

                if ($this._queue.length > 0)
                {
                    var sendQueue = [];

                    while ($this._queue.length > 0 || sendQueue.length < 10)
                    {
                        sendQueue.push($this._queue.shift());
                    }

                    $this.saveQueue();

                    var trackrs = [];

                    for (var key in sendQueue)
                        trackrs.push(p.jsonStringify(sendQueue[key]));

                    p.net.Request(["update", "trackr"], { trackr: p.jsonStringify(trackrs) }, $this.onQueueSent);
                }
                else
                {
                    $this.scheduleQueueRun();
                }
            },

            onQueueSent: function (success, data)
            {
                var $this = p.tracking;

                if ($this._queue.length > 0)
                {
                    $this._queueRunner();
                }
                else
                {
                    $this.scheduleQueueRun();
                }
            },

            scheduleQueueRun: function ()
            {
                var $this = p.tracking;
                clearTimeout($this._runnerTimeout);
                $this._runnerTimeout = setTimeout($this._queueRunner, 30 * 1000);
            },

            init: function ()
            {
                var $this = p.tracking;

                // Initialize Queue
                var savedQueue = p.ls.get("queue");
                if (savedQueue)
                {
                    $this._queue = p.jsonParse(savedQueue);
                }

                if ($this._queue == null)
                    $this._queue = [];

                // Initialize DeviceId
                var savedDeviceId = p.ls.get("deviceid");
                if (savedDeviceId)
                {
                    $this._deviceId = savedDeviceId;
                }

                if ($this._deviceId == null)
                {
                    $this.syncDeviceId();
                }
                else
                {
                    $this.tryResumeSession();
                }
            },

            tryResumeSession: function ()
            {
                var $this = p.tracking;

                try
                {
                    var savedSession = p.ls.get("session");
                    if (savedSession)
                    {
                        var session = p.jsonParse(savedSession);
                        if (session)
                        {
                            var sessionTime = moment(session.date).valueOf();//  p.date.fix(session.date).getTime();
                            var nowTime = moment().valueOf();
                            var timeout = 5 * 60 * 1000;

                            $this._sessionId = session.id;
                            $this._trackrId = session.num;

                            if ((nowTime - sessionTime) < timeout)
                            {
                            }
                            else
                            {
                                $this.sessionEnd();
                            }
                        }
                    }
                }
                catch (ex)
                {
                    Trace(["Error while trying to resume session:", ex.message], "TRACKING", CONSOLE_ERROR);
                }

                $this._ready = true;

                if ($this._sessionId == null)
                    $this.sessionStart();

                $this._queueRunner();
            },
            sessionKeepAlive: function ()
            {
                var $this = p.tracking;

                if ($this._sessionId)
                {
                    p.ls.set("session", p.jsonStringify(
                        {
                            id: $this._sessionId,
                            date: moment().format("YYYY-MM-DD HH:mm:ss"),
                            num: $this._trackrId
                        }));
                }
            },

            syncDeviceId: function ()
            {
                var $this = p.tracking;

                var savedDeviceToken = p.ls.get("devicetoken");
                if (savedDeviceToken)
                {
                    $this._deviceToken = savedDeviceToken;
                }

                if ($this._deviceToken == null)
                {
                    $this._deviceToken = p.random.guid();
                    p.ls.set("devicetoken", $this._deviceToken);
                }

                p.net.Request(["sync", "token"], { devicetoken: $this._deviceToken }, $this.onDeviceIdSynced);
            },

            onDeviceIdSynced: function (success, data)
            {
                var $this = p.tracking;
                if (success)
                {
                    if (data.success)
                    {
                        $this._deviceId = data.id;
                        p.ls.set("deviceid", $this._deviceId);

                        $this.tryResumeSession();

                        $this.queue("APP_INSTALL",
                            {
                                language: navigator.language,
                                version: navigator.appVersion,
                                screenResX: screen.width,
                                screenResY: screen.height
                            });
                    }
                    else
                    {
                        Trace(["Token Sync request successful but reported error"], "TRACKER", CONSOLE_ERROR);
                    }
                }
                else
                {
                    Trace(["Token Sync request failed"], "TRACKER", CONSOLE_ERROR);
                }
            },

            queue: function (actionType, payload)
            {
                var $this = p.tracking;

                if ($this._sessionId == null)
                {
                    $this._postponedQueue.push({ actionType: actionType, payload: payload });
                }
                else
                {
                    var trackr =
                    {
                        actionType: actionType,
                        dateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        sessionId: $this._sessionId,
                        trackrId: $this._trackrId++,
                        readerId: $this._deviceId
                    };

                    if (p.user.is_logged_in)
                        trackr.userId = p.user.userdata.id;

                    $.extend(trackr, payload);

                    // console.log(trackr);

                    $this._queue.push(trackr);
                    $this.saveQueue();
                }

                $this.sessionKeepAlive();
            },

            saveQueue: function ()
            {
                var $this = p.tracking;
                p.ls.set("queue", p.jsonStringify($this._queue));
            },

            sessionStart: function ()
            {
                var $this = p.tracking;
                if ($this._sessionId != null)
                    $this.sessionEnd();

                $this._sessionId = p.random.guid();
                $this._trackrId = 0;

                $this.queue("SESSION_START");

                if ($this._postponedQueue.length > 0)
                {
                    while ($this._postponedQueue.length > 0)
                    {
                        var bit = $this._postponedQueue.shift();
                        $this.queue(bit.actionType, bit.payload);
                    }
                }
            },
            sessionEnd: function ()
            {
                var $this = p.tracking;
                $this.queue("SESSION_END");
                p.ls.set("session", null);
            }


        },

        sonderlocke: function (name)
        {
            return (window["SONDERLOCKE"] != void 0 && window["SONDERLOCKE"][name] != void 0);
        },

        handleError: function (data)
        {
            var overlay = p.polymer.overlay({
                title: LOCALE["ERROR"],
                text: LOCALE[data.error] ? LOCALE[data.error] : data.error,
                backdrop: true,
                dismissable: true,
                callback: data.callback
            });

            $("body").append(overlay.element);
            p.defer(function ()
            {
                overlay.open();
            });

            return overlay;
        },

        handleAsk: function (data, onYes, onNo)
        {
            var overlay = p.polymer.overlay({
                title: data.title,
                text: data.text,
                backdrop: true,
                dismissable: true,
                content: data.content,
                content_before: data.content_before,
                actions: { affirmative: true, dismissive: true },
                callback: function (yes)
                {
                    if (yes && onYes && p.is.function(onYes))
                        onYes();
                    else if (onNo && p.is.function(onNo))
                        onNo();
                }
            });

            $("body").append(overlay.element);
            p.defer(function ()
            {
                overlay.open();
            });

            return overlay;
        },

        handleInfo: function (data, onClose)
        {
            var overlay = p.polymer.overlay({
                title: data.title,
                text: data.text,
                backdrop: true,
                dismissable: false,
                content: data.content,
                content_before: data.content_before,
                actions: { affirmative: true, dismissive: false },
                callback: onClose
            });

            $("body").append(overlay.element);
            p.defer(function ()
            {
                overlay.open();
            });

            return overlay;
        },

        showTerms: function ()
        {
            var content = $div({ "vertical": "", "layout": "", "center-justified": "" });
            var spinner = p.paper.spinner();
            spinner.attr("self-center", "");
            spinner.addClass("padded");
            content.append(spinner);

            var overlay = p.polymer.overlay({
                title: LOCALE["TERMS"],
                backdrop: true,
                dismissable: true,
                big: true,
                content: content,
                actions: { affirmative: LOCALE["CLOSE"], dismissive: false },
                onOpen: function ()
                {
                    // overlay.element[0].resizeHandler();
                }
            });

            var onTermsLoaded = function (success, data)
            {
                // console.log("terms loaded", success, data);
                if (success && data && data.success)
                {
                    var termsTxt = data.terms;
                    var termsParas = p.array.sieve(termsTxt.split("\n"));
                    var termsHtml = "<p>" + termsParas.join("</p><p>") + "</p>";
                    content.html(termsHtml);

                    setTimeout(function ()
                    {
                        // overlay.element[0].resizeHandler();
                    }, 100);
                    //overlay.element[0].resizeHandler();
                }
            };

            $("body").append(overlay.element);
            p.defer(function ()
            {
                overlay.open();
                // console.log("blah");
                p.net.Request(["terms"], {}, onTermsLoaded);
            });

            return overlay;
        },

        share:
        {
            _emailSelectedContent: null,
            _emailSelectedContents: [],
            _emailSuccess: false,
            _emailNum: 1,

            _buildMailShareContentList: function ()
            {
                var $this = p.share;

                p.books.eachShelf(function (e)
                {
                    var typeOK = e.type.web != "subscription";
                    var allowSocial = e.custom_data.allow_social;
                    var allowMail = e.custom_data.allow_social_mail;

                    var valid = typeOK && allowMail && allowSocial;

                    if (valid)
                    {
                        var $cover_img = $("<img>",
                            {
                                "draggable": "false",
                                "class": "dynamic loading",
                                //"src": "{0}/data/{1}/cover_medium.jpg".format(URLPATH, id)
                                "src": "{0}/api/web/?cmd=get/file/cover&udid={1}&size=small".format(BASEPATH, e.udid)
                            });

                        $cover_img.attr("width", 100);
                        $cover_img.attr("height", 142);
                        $cover_img.on("load", function (e)
                        {
                            $(this).removeClass("loading");
                        });

                        var $container = $div({ "class": "selection-container shadow", "udid": e.udid, "tabindex": 0, "z": 1 });
                        var $checkmark = p.paper.icon("check");
                        var $name = $div({ "class": "name", "html": e.name });
                        var $fader = $div({ "class": "rect" });

                        var $inner = $div({ "class": "inner" });
                        $inner.append($fader).append($cover_img).append($name).append($checkmark);

                        $container.append($inner);

                        if (e.udid == $this._emailSelectedContent)
                            $container.addClass("selected");

                        $container.on("click keyup", function (e)
                        {
                            if (e.type == "keyup")
                            {
                                // 13 = enter
                                // 32 = space

                                if (e.keyCode != 13) // && e.keyCode != 32)
                                {
                                    return false;
                                }
                            }

                            $(this).toggleClass("selected");
                            if ($(this).hasClass("selected"))
                                $this._emailSelectedContents.push($(this).attr("udid"));
                            else
                                $this._emailSelectedContents.splice($this._emailSelectedContents.indexOf($(this).attr("udid")), 1);

                            if ($this._emailSelectedContents.length == 0)
                                $("#share-do").attr("disabled", "");
                            else
                                $("#share-do").removeAttr("disabled");

                            e.handled = true;
                            if (e.stopPropagation) e.stopPropagation();
                            if (e.preventDefault) e.preventDefault();
                            return true;
                        });

                        $("#share-contents-container").append($container);
                    }
                }, false);
            },


            onMailShareResponse: function Share_ServerResponse(success, data)
            {
                var $this = p.share;
                if (success && data && data.success)
                {
                    $this._emailSuccess = true;
                    $("#share-modal").modal("close");
                }
                else
                {
                    p.handleError({ error: data.error });
                    p.form_helper.setDisabledButton($("#share-do"), false);
                    p.form_helper.setDisabledButton($("#share-cancel"), false);
                    p.form_helper.setDisabled($("#share-input-mail-decorator"), false);
                    p.form_helper.setDisabled($("#share-input-message-decorator"), false);
                    $("#share-contents-container").removeAttr("disabled");
                }
            },

            onMailShareDo: function Share_Do()
            {
                var $this = p.share;
                //var mail = ($("#share-input-mail").val() + "").trim();
                var msg = ($("#share-input-message").val() + "").trim();

                //var mailOk = mail.length > 0 && p.user.forms.register.regex.email.exec(mail) == mail;

                var mailFields = $("#share-recipients-list input[data-recipient]");
                var badFields = [];
                var emptyFields = [];
                var okFields = [];

                for (var i = 0; i < mailFields.length; i++)
                {
                    var m = $(mailFields[i]);
                    var mt = (m.val() + "").trim();

                    if (mt.length != 0)
                    {
                        if (p.user.forms.register.regex.email.exec(mt) == mt)
                        {
                            okFields.push(m);
                        }
                        else
                        {
                            badFields.push(m);
                        }
                    }
                    else
                    {
                        emptyFields.push(m);
                    }
                }

                var mailOk = badFields.length == 0 && okFields.length > 0;

                //console.log(okFields, emptyFields, badFields);
                if (emptyFields.length > 0)
                    $(emptyFields).each(function () { if ($(this).attr("data-recipient") != 1) $(this).parent().remove(); });

                if (mailOk)
                {
                    //p.form_helper.setError($("#share-input-mail-decorator"), null);
                    $(okFields).each(function ()
                    {
                        p.form_helper.setError($(this).parent(), null);
                        p.form_helper.setDisabled($(this).parent(), true);
                    });

                    p.form_helper.setDisabledButton($("#share-do"), true, true);
                    p.form_helper.setDisabledButton($("#share-cancel"), true, false);
                    //p.form_helper.setDisabled($("#share-input-mail-decorator"), true);
                    p.form_helper.setDisabled($("#share-input-message-decorator"), true);
                    $("#share-contents-container").attr("disabled", "");

                    var recList = [];
                    for (var i = 0; i < okFields.length; i++)
                        recList.push(($(okFields[i]).val() + "").trim());

                    p.net.Request(["do", "share"],
                        {
                            "recipients": recList,
                            "udids": $this._emailSelectedContents,
                            "message": msg
                        }, $this.onMailShareResponse);
                }
                else
                {
                    //p.form_helper.setError($("#share-input-mail-decorator"), LOCALE["REGISTER_HINT_BAD_EMAIL"]);
                    $(badFields).each(function () { p.form_helper.setError($(this).parent(), LOCALE["REGISTER_HINT_BAD_EMAIL"]); });
                }
            },

            onMailShareModal: function Share_ModalBuild(success, data)
            {
                var $this = p.share;

                if (!!success && !!data.success)
                {
                    var $modal = $(data["modal-html"].trim());
                    let modal = null;
                    $("body").append($modal);
                    // console.log($modal);
                    $this._buildMailShareContentList();

                    var onOpen = function ()
                    {
                        $("#share-do").on("click", function ()
                        {
                            setTimeout(function ()
                            {
                                $this.onMailShareDo();
                            }, 100);
                        });

                        $("#share-cancel").on("click", function ()
                        {
                            setTimeout(function ()
                            {
                                // $("#share-modal").modal("close");
                                modal.close();
                            }, 100);
                        });

                        $("#share-add-recipient").on("click", function ()
                        {
                            setTimeout(function ()
                            {
                                var newField = $($("#share-recipient-template").html());
                                newField.children("input").attr("data-recipient", ++$this._emailNum);

                                $("#share-recipients-list").append(newField);
                                // $("#share-modal")[0].resizeHandler();

                                setTimeout(function ()
                                {
                                    newField.removeClass("invisible");
                                }, 100);

                            }, 100);
                        });

                        p.common.hideLoader();
                    };

                    var onClose = function ()
                    {
                        modal.destroy();
                        $("#share-modal").remove();

                        if ($this._emailSuccess)
                        {
                            p.handleInfo({
                                title: LOCALE["SHARE_MAIL_TITLE"],
                                text: LOCALE["SHARE_MAIL_SUCCESS"]
                            });
                        }

                        $this._emailSuccess = false;
                        $this._emailSelectedContent = null;
                        $this._emailSelectedContents = [];
                    };

                    p.defer(function ()
                    {
                        // $("#share-modal")[0].addEventListener("core-overlay-open-completed", onOpen);
                        // $("#share-modal")[0].addEventListener("core-overlay-close-completed", onClose);
                        // $("#share-modal")[0].open();
                        // $("#share-modal")[0].resizeHandler();
                        modal = M.Modal.init($modal[0], {
                            onOpenEnd: onOpen,
                            onCloseEnd: onClose,
                           
                            dismissible: false
                        });

                        modal.open();
                        // $modal.modal({
                        //     ready: onOpen,
                        //     complete: onClose
                        // });
                        // $modal.modal("open");
                    }, 10);
                }
            },

            email: function (currentcontent)
            {
                var $this = p.share;
                $this._emailSelectedContent = currentcontent;
                $this._emailSelectedContents = [currentcontent];

                p.common.showLoader();
                p.net.Request(["share"], {}, $this.onMailShareModal);
            },
            _options: "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600",

            twitter: function (url, msg)
            {
                var shareUrlEncoded = encodeURIComponent(url).replace(/\-/gi, "%2D");
                window.open("https://twitter.com/share?text={1}&url={0}".format(shareUrlEncoded, encodeURI(msg)), "_blank", this._options);
            },
            gplus: function (url, msg)
            {
                var shareUrlEncoded = encodeURIComponent(url).replace(/\-/gi, "%2D");
                window.open("https://plus.google.com/share?url={0}".format(shareUrlEncoded, encodeURI(msg)), "_blank", this._options);
            },
            facebook: function (url)
            {
                var shareUrlEncoded = encodeURIComponent(url).replace(/\-/gi, "%2D");
                window.open("https://www.facebook.com/sharer/sharer.php?u={0}".format(shareUrlEncoded), "_blank", this._options);
            }
            // Jetez un oeil &#224; &#231;a!

        },

        _aspectWatcherRunning: false,
        hookAspectWatcher: function ()
        {
            if (this._aspectWatcherRunning == false)
            {
                this._aspectWatcherRunning = true;
                var b = $("body");
                setInterval(function ()
                {
                    if (window.innerWidth > window.innerHeight && !b.hasClass("wide"))
                    {
                        b.removeClass("tall").addClass("wide");
                    }
                    else if (window.innerHeight > window.innerWidth && !b.hasClass("tall"))
                    {
                        b.removeClass("wide").addClass("tall");
                    }
                }, 500);
            }
        },

        seamlessNavigation:
        {
            lastBase: "",

            _fromApp: false,

            get FromApp()
            {
                return this._fromApp;
                //return (p.seamlessNavigation.GetStack(true).indexOf("popup") != -1);
            },

            DeferAction: function (act)
            {
                var oldDeferrals = p.jsonParse(p.ls.get("deferred_actions"));
                if (!oldDeferrals)
                    oldDeferrals = {};

                var stackStr = p.array.sieve(this.GetStack(true)).join("/");

                oldDeferrals[stackStr] = act;

                // console.log(oldDeferrals);

                p.ls.set("deferred_actions", p.jsonStringify(oldDeferrals));
            },

            GetDeferredAction: function ()
            {
                var oldDeferrals = p.jsonParse(p.ls.get("deferred_actions"));
                if (!oldDeferrals)
                    oldDeferrals = {};

                var stackStr = p.array.sieve(this.GetStack(true)).join("/");

                if (oldDeferrals[stackStr])
                {
                    var action = oldDeferrals[stackStr];
                    delete oldDeferrals[stackStr];
                    p.ls.set("deferred_actions", p.jsonStringify(oldDeferrals));
                    return action;
                }

                return null;
            },

            Hook: function Navigation_InitHook()
            {
                var base = window.location.href.replace(URLPATH, "");
                Trace(["Hooked on", base], SYMBOL_NAVIGATION);

                //this.Navigate();

                var state = this._fixState({ cmd: base });

                this._ProcessState(state);

                window.onpopstate = this.Pop;
            },

            LinkStopper: function Navigation_ClickPrevent($obj)
            {
                var $this = $($obj);
                $this.on("click", function (e)
                {
                    if (e && e.stopPropagation)
                        e.stopPropagation();
                });
            },

            _initProp: "sn-inited",
            _waitTime: 100,

            _linkInited: function (obj)
            {
                var $this = p.seamlessNavigation;
                if (!obj.prop($this._initProp))
                    return false;

                //Trace([obj, "Object already hooked"], SYMBOL_NAVIGATION);
                return true;
            },

            _initLink: function (obj)
            {
                var $this = p.seamlessNavigation;
                if (!$this._linkInited(obj))
                    obj.prop($this._initProp, true);
            },

            fixEvent: function (e)
            {
                e && e.stopPropagation && e.stopPropagation();
                e && e.preventDefault && e.preventDefault();
                e && (e.handled = true);
            },

            Link: function (obj)
            {
                var $this = p.seamlessNavigation;
                var $obj = $(obj);
                if (!$this._linkInited($obj))
                {
                    var stack = $obj.attr("href");
                    var cmd = $obj.attr("cmd");
                    var target = $obj.attr("target");
                    var animator = $obj.attr("animator");
                    var selection = $obj.attr("selection");
                    var selection_before = $obj.attr("selection-before");
                    var title = $obj.attr("title");
                    var branded = !!$obj.attr("branded");

                    if (cmd == "=" || !cmd)
                        cmd = $this.currentState.cmd;

                    $this.Link_State($obj, {
                        stack: stack,
                        cmd: cmd,
                        animator: animator,
                        selection: selection,
                        selection_beforeFetch: selection_before,
                        title: title,
                        target: target,
                        branded_body: branded,
                        callback: null
                    });
                }
            },

            Link_State: function (obj, state)
            {
                var $this = p.seamlessNavigation;
                var $obj = $(obj);
                if (!$this._linkInited($obj))
                {
                    var onClick = function (e)
                    {
                        // try
                        // {
                        //     if (p.browser.isChrome)
                        //         $("#layout-main::shadow #mainPanel #mainContainer").animate({ scrollTop: 0 }, 500);
                        //     else
                        //         $("#layout-main #mainPanel #mainContainer").animate({ scrollTop: 0 }, 500);
                        // } catch (ex) { }

                        $this.Push(state);
                        $this.fixEvent(e);
                    };

                    let isDown = false;
                    $obj.on("mousedown", function (e)
                    {
                        isDown = true;
                    });
                    $obj.on("mouseup", function (e)
                    {
                        if (isDown)
                        {
                            isDown = false; 
                            Trace([$obj, "Clicked"], SYMBOL_NAVIGATION);
                            setTimeout(onClick, $this._waitTime, e);
                        }
                    });
                    $obj.on("mouseleave", function (e)
                    {
                        isDown = false;
                    });
                    $obj.on("click", function (e)
                    {
                    });

                    $obj.removeAttr("href");
                    $obj.removeAttr("target");

                    $this._initLink($obj);
                }
            },

            Link_External: function (obj, newWindow, transition_loader)
            {
                var $this = p.seamlessNavigation;
                var $obj = $(obj);
                if (!$this._linkInited($obj))
                {
                    var url = $obj.attr("href");
                    $obj.attr("href", "#");

                    var onClick = function (e)
                    {
                        if (newWindow)
                            window.open(url, "_blank");
                        else
                            window.location = url;

                        if (e)
                            $this.fixEvent(e);
                    };

                    var onTransition = function (e)
                    {
                        onClick(null);
                    };

                    $obj.on("click", function (e)
                    {
                        if (transition_loader)
                        {
                            p.common.transitionLoader.show(onTransition);
                        }
                        else
                        {
                            setTimeout(onClick, $this._waitTime, e);
                        }
                    });

                    $this._initLink($obj);
                }
            },

            GetStack: function (as_array)
            {
                //var stack = window.location.href.replace(URLPATH, "").replace("/?", "");

                //if (as_array)
                //    return stack.split("#")[0].split("/");

                //return stack;

                var $this = p.seamlessNavigation;

                if (as_array)
                    return $this.currentState.stack_array;
                else
                    return $this.currentState.stack;
            },

            Pop: function Navigation_Event_onPop(e)
            {
                if (e.state)
                    p.seamlessNavigation._ProcessState(e.state);
            },

            Push: function Navigation_Goto_PUSH(state)
            {
                var $this = p.seamlessNavigation;
                var $state = $this._fixState(state);
                window.history.pushState($state, $state.title, URLPATH + "/?" + $state.stack);
                $this._ProcessState($state);
            },

            Replace: function Navigation_Goto_Replace(state)
            {
                var $this = p.seamlessNavigation;
                var $state = $this._fixState(state);
                window.history.replaceState($state, $state.title, URLPATH + "/?" + $state.stack);
                $this._ProcessState($state);
            },

            Reload: function Navigation_Reload()
            {
                var $this = p.seamlessNavigation;
                var replicatedState = p.array.clone($this.currentState);
                $this.currentState.cmd = null;
                $this._ProcessState(replicatedState);
            },

            ////////////

            currentState:
            {
                cmd: null,
                cmd_string: null,
                target: null,
                stack: null,
                stack_array: null,
                animator: null,
                selection: null,
                selection_beforeFetch: null,

                from: null,
                from_state: null,
                title: null,

                branded_body: false,

                callback: null
            },

            cachedState: null,

            get stateTemplate()
            {
                return {
                    cmd: null,
                    cmd_string: null,
                    target: null,
                    stack: null,
                    stack_array: null,
                    animator: null,
                    selection: null,
                    selection_beforeFetch: null,

                    from: null,
                    from_state: null,
                    title: null,

                    branded_body: false
                };
            },

            _fixState: function (e)
            {
                var $this = p.seamlessNavigation;
                var base = $this.stateTemplate;
                $.extend(base, e);

                //console.log("fixing state", e);

                if (base.cmd == null)
                {
                    base.cmd = ["shop"];
                    base.stack = "/shelf/";
                }

                if (p.is.string(base.cmd))
                {
                    if (base.cmd.startsWith("/?"))
                        base.cmd = base.cmd.replace("/?", "");

                    base.cmd = p.array.sieve(base.cmd.split("/"));
                }

                //if (base.cmd[0] == "shelf")
                //    base.cmd[0] = "shop";

                if (base.cmd[0] == "login" || base.cmd[0] == "register" || base.cmd[0] == "recover")
                    base.branded_body = true;

                base.cmd_string = base.cmd.join("/");

                if (!base.stack)
                    base.stack = "/{0}/".format(base.cmd.join("/"));

                if (base.stack.startsWith("/?"))
                    base.stack = base.stack.replace("/?", "");

                if (!base.stack.startsWith("/"))
                    base.stack = "/" + base.stack;

                if (!base.stack.endsWith("/"))
                    base.stack = base.stack + "/";

                base.stack_array = p.array.sieve(base.stack.split("/"));

                if (!base.title)
                {
                    if (base.stack == "/shelf/")
                        base.title = LOCALE["SHELF"];
                    else if (base.stack == "/shop/")
                        base.title = LOCALE["SHOP"];
                    else if (base.stack == "/account/")
                        base.title = LOCALE["MY_ACCOUNT"];
                }

                if (base.title)
                    base.title = p.decode(base.title);

                //console.log("fixed to", base);

                return base;
            },

            /*
 
            State:
                cmd: if != then load from server
                
                target: if set and loading from cmd, then populate this target instead of body
                stack: stack for whatever
 
                if(cmd same and animator, then set selection)
                animator: core-animated-pages for this state
                selection: the selected attribute for the animator
                selection_beforeFetch: if fetching ( cmd!=last.cmd ) and animator then animate before fetching
 
                from: referrer,
                title: title without page-name prefix,
                
                branded_body: if true then add "branded" class to body
            */

            _ProcessState: function Navigation_ProcessState(e)
            {
                if (!e)
                {
                    Trace(["State invalid", e, "Defaulting to shelf"], SYMBOL_NAVIGATION, CONSOLE_ERROR);
                    p.seamlessNavigation.Push({ cmd: ["shop"], stack: "/shelf/" });
                    return;
                }

                var $this = p.seamlessNavigation;
                var fetch = false;

                if (e.callback != null && typeof e.callback == "string")
                {
                    if (e.callback.startsWith("p.") && e.callback.endsWith(";"))
                    {
                        try
                        {
                            eval(e.callback);
                        } catch (x) { }
                    }
                }

                if ($this.currentState.cmd_string != e.cmd_string &&
                    !(
                        ($this.currentState.cmd_string == "shop" || $this.currentState.cmd_string == "shelf" || $this.currentState.cmd_string == "account") &&
                        (e.cmd_string == "shop" || e.cmd_string == "shelf" || e.cmd_string == "account")
                    )
                )
                {
                    Trace(["Fetch required (cmd mismatch)", $this.currentState.cmd_string, "!=", e.cmd_string], SYMBOL_NAVIGATION);
                    fetch = true;

                    if ((e.animator && $("#" + e.animator)[0]) && e.selection_beforeFetch)
                    {
                        p.polymer.AnimateTo("#" + e.animator, e.selection_beforeFetch);
                        // $("#" + e.animator)[0].selected = e.selection_beforeFetch;
                    }
                }
                else
                {
                    if ((e.animator && $("#" + e.animator)[0]) && e.selection)
                    {
                        p.polymer.AnimateTo("#" + e.animator, e.selection);
                        // $("#" + e.animator)[0].selected = e.selection;
                    }
                    //else
                    //{
                    //    Trace(["Animator State invalid", "Animator target", e.animator, "Selection", e.selection], SYMBOL_NAVIGATION, CONSOLE_ERROR);
                    //    return;
                    //}
                }


                if (fetch === true)
                {
                    p.common.showLoader();
                    $this.cachedState = p.array.clone($this.currentState);
                    $this.currentState.cmd = e.cmd;
                    $this.currentState.cmd_string = e.cmd_string;
                    $this.currentState.target = e.target;
                    p.net.Request(e.cmd, {}, p.seamlessNavigation._onFetch, e);
                }
                else
                {
                    Trace(["To", e.stack, "From", $this.currentState.stack, e], SYMBOL_NAVIGATION);

                    if (e.title)
                        document.title = "{1} - {0}".format(SITENAME, e.title);
                    else
                        document.title = SITENAME;

                    var base = window.location.href.replace(URLPATH, "");
                    var callstack = base.substr(3).split("#")[0].split("/");
                    callstack = p.array.sieve(callstack);

                    if (callstack.indexOf("popup") != -1)
                    {
                        p.seamlessNavigation._fromApp = true;
                        $("body").addClass("from-app");
                    }

                    if (e.branded_body)
                        $("body").addClass("branded");
                    else
                        $("body").removeClass("branded");

                    if ($this.cachedState != null)
                    {
                        e.from = $this.cachedState.stack;
                        e.from_state = p.array.clone($this.cachedState);
                        $this.cachedState = null;
                    }
                    else
                    {
                        e.from = $this.currentState.stack;
                        e.from_state = p.array.clone($this.currentState);
                    }

                    $this.currentState = e;

                    Trace(["Now", $this.currentState], SYMBOL_NAVIGATION);

                    p.trigger("stack", [$this.currentState.stack_array]);
                }
            },

            _preserveObjects: ".transition-loader",
            _detachPreservedObjects: function Navigation_DetachPreservedObjects(e)
            {
                var $this = p.seamlessNavigation;
                var $e = $(e);
                var $found = $e.children($this._preserveObjects);

                if ($found.length > 0)
                {
                    return $found.detach();
                }

                return [];
            },

            _onFetch: function Navigation_OnServerFetchResponse(success, data, state)
            {
                var $this = p.seamlessNavigation;
                p.common.hideLoader();

                if (data.redirect)
                {
                    return $this.Push({ cmd: data.redirect });
                }

                if (data.html)
                {
                    var buffer;
                    //console.log("html target: ", $this.currentState.target, $($this.currentState.target));
                    if ($this.currentState.target)
                    {
                        buffer = $this._detachPreservedObjects($($this.currentState.target));
                        $($this.currentState.target).html(data.html);
                        $($this.currentState.target).append(buffer);
                    }
                    else
                    {
                        buffer = $this._detachPreservedObjects($("body"));
                        $("body").html(data.html);
                        $("body").append(buffer);
                    }
                }

                if (data.state)
                {
                    $.extend(state, data.state);
                }

                $this._ProcessState(state);
            }
        },

        firefox:
        {
            generateEventOffset: function (evt)
            {
                var eoffsetX = (evt.offsetX || evt.clientX - $(evt.target).offset().left + window.pageXOffset);
                var eoffsetY = (evt.offsetY || evt.clientY - $(evt.target).offset().top + window.pageYOffset);
                evt.offsetX = eoffsetX;
                evt.offsetY = eoffsetY;
                return evt;
            }

        },

        material:
        {
            progress: function (e, val)
            {
                var $this = $(e);

                if (val < 0)
                    $this.addClass("indeterminate");
                else
                {
                    $this.removeClass("indeterminate");
                    $this.children(".value").css("width", val + "%");
                }
            },

            placeholder: function (e)
            {
                if (!$(e).prop("material-placeholder-initialized"))
                {
                    var placeholder = $(e);
                    var input = $(e).children("input");

                    input.on("focus", function ()
                    {
                        placeholder.addClass("focus");
                    });

                    input.on("blur", function ()
                    {
                        placeholder.removeClass("focus");
                    });

                    placeholder.prop("material-placeholder-initialized", true);
                }
            },

            raiseOnHover: function (e)
            {
                var z = $(e).attr("z");
                if (!z) z = 0;
                $(e).prop("material-original-z", z);

                $(e).on("mouseenter", function ()
                {
                    var z = $(this).attr("z");
                    if (!z) z = 0;
                    z = parseInt(z);

                    $(this).attr("z", z + 1);
                });

                $(e).on("mouseleave", function ()
                {
                    $(this).attr("z", $(this).prop("material-original-z"));
                });
            },
            dropdown_icon: function (e)
            {
                return $div({ "class": "icon icon-{0}".format(e) });
            },
            dropdown: function (e)
            {
                if ($(e).attr("material-dropdown") && !$(e).prop("mdha"))
                {
                    var inToolbar = true;
                    if ($(e).parents(".toolbar", ".toolbar").length == 0)
                    {
                        inToolbar = false;
                    }

                    var body = $("body");
                    if (!body.prop("material-dropdown-canceller-attached"))
                    {
                        body.on("click", function (bodyClickEvent)
                        {
                            if ($(bodyClickEvent.target).parents(".dropdown").length == 0 &&
                                !$(bodyClickEvent.target).attr("material-dropdown") &&
                                !$(bodyClickEvent.target).parent().attr("material-dropdown"))
                            {
                                $(".dropdown.drop").removeClass("drop");
                            }
                        });
                        body.prop("material-dropdown-canceller-attached", true);
                    }

                    var $dropDown = $("#" + $(e).attr("material-dropdown"));
                    $dropDown.css("left", $(e).position().left);

                    $dropDown.children(":not(.header)").each(function ()
                    {
                        if (!$(this).prop("material-dropdown-handler-attached"))
                        {
                            p.material.ripple($(this));
                            $(this).on("click", function ()
                            {
                                setTimeout(function ()
                                {
                                    $(e).trigger("click");
                                }, 200);
                            });
                            $(this).prop("material-dropdown-handler-attached", true);
                        }
                    });
                    var dropWidth = -1;
                    $(e).on("click", function ()
                    {
                        $dropDown.children(":not(.header)").each(function ()
                        {
                            if (!$(this).prop("material-dropdown-handler-attached"))
                            {
                                p.material.ripple($(this));
                                $(this).on("click", function ()
                                {
                                    setTimeout(function ()
                                    {
                                        $(e).trigger("click");
                                    }, 200);
                                });
                                $(this).prop("material-dropdown-handler-attached", true);
                            }
                        });


                        //if (dropWidth == -1)
                        dropWidth = $dropDown.width();

                        if ($dropDown.hasClass("align-right"))
                        {
                            $dropDown.css("left", ($(e).position().left + $(e).width()) - dropWidth).delay(200).toggleClass("drop");
                        }
                        else
                        {
                            $dropDown.css("left", $(e).position().left).delay(200).toggleClass("drop");;
                        }
                        if (!inToolbar)
                        {
                            $dropDown.css("top", $(e).position().top + "px");
                        }
                    });
                    $(e).prop("mdha", true);
                }
            },
            ripple: function (e, spill)
            {
                if (!$(e).hasClass("shadow") && !$(e).prop("material-ripple-id"))
                {
                    $(e).addClass("ripple");

                    if (!!spill)
                        $(e).addClass("spill");

                    var myIdentifier = p.random.string(10);
                    $(e).prop("material-ripple-id", myIdentifier);

                    var maxSize = $(e).width() > $(e).height() ? $(e).width() : $(e).height();
                    if ($(e).width() <= 0 || $(e).height() <= 0)
                        maxSize = -1;
                    //console.log($(e), $(e).width(), $(e).height());
                    if (!!spill)
                        maxSize *= 3;
                    else
                        maxSize *= 1.5;

                    var $ripple = null;

                    var $removeRippleFnc = function (evt)
                    {
                        //console.log(evt);
                        if (evt.data.ripple != null)
                        {
                            $(evt.data.ripple).addClass("grow");
                            $(evt.data.ripple).width(maxSize * 2);
                            $(evt.data.ripple).height(maxSize * 2);
                            setTimeout(function ()
                            {
                                $(evt.data.ripple).remove();
                            }, 500);
                        }
                    };

                    $(e).on("mousedown", function (evt)
                    {
                        if (evt.target &&
                            (
                                ($(evt.target).hasClass("ripple") && $(evt.target).prop("material-ripple-id") != myIdentifier)
                                ||
                                ($(evt.target).parent().hasClass("ripple") && $(evt.target).parent().prop("material-ripple-id") != myIdentifier)
                            ))
                            return;

                        //console.log($(e).attr("id"), evt);
                        if (maxSize <= 10)
                        {
                            maxSize = $(e).width() > $(e).height() ? $(e).width() : $(e).height();
                            if ($(e).width() <= 0 || $(e).height() <= 0)
                                maxSize = -1;

                            if (!!spill)
                                maxSize *= 3;
                            else
                                maxSize *= 1.5;
                        }

                        if ($(e).hasClass("btn-charm"))
                        {
                            maxSize = 36;
                        }

                        //console.log($(e), $(e).width(), $(e).height());

                        // Create ripple
                        $ripple = $("<ripple>");
                        // Add
                        $(e).append($ripple);

                        var $thisRipple = $ripple;

                        //console.log(evt.offsetX, evt.offsetY, evt.clientX, evt.clientY, evt);

                        // Every other browser
                        if (evt.offsetX != undefined && evt.offsetY != undefined)
                        {
                            if ($(e).hasClass("btn-charm"))
                            {
                                $ripple.css("left", $(e).outerWidth() / 2);
                                $ripple.css("top", $(e).outerHeight() / 2);
                            }
                            else if (!evt.target || $(evt.target).prop("material-ripple-id") == myIdentifier || $(evt.target).hasClass("overlay-cbox"))
                            {
                                $ripple.css("left", evt.offsetX);
                                $ripple.css("top", evt.offsetY);
                            }
                            else
                            {
                                //console.log(evt.offsetX, evt.offsetY, $(evt.target).offset(), $(evt.target).position());
                                var eoffsetX = evt.offsetX + $(evt.target).position().left;
                                var eoffsetY = evt.offsetY + $(evt.target).position().top;
                                $ripple.css("left", eoffsetX);
                                $ripple.css("top", eoffsetY);
                            }

                            // Wait for render
                            // Strike that, timeout still works best
                            setTimeout(function ()
                            {
                                // Grow
                                //$ripple.addClass("grow");
                                $thisRipple.css("width", maxSize);
                                $thisRipple.css("height", maxSize);
                            }, 10);
                        }
                        else
                        {
                            // Firefox
                            var eoffsetX = (evt.offsetX || evt.clientX - $(evt.target).offset().left + window.pageXOffset);
                            var eoffsetY = (evt.offsetY || evt.clientY - $(evt.target).offset().top + window.pageYOffset);
                            $ripple.css("left", eoffsetX);
                            $ripple.css("top", eoffsetY);

                            if ($(e).hasClass("btn-charm"))
                            {
                                $ripple.css("left", $(e).outerWidth() / 2);
                                $ripple.css("top", $(e).outerHeight() / 2);
                            }

                            setTimeout(function ()
                            {
                                // Grow
                                //$ripple.addClass("grow");
                                $thisRipple.css("width", maxSize);
                                $thisRipple.css("height", maxSize);
                            }, 10);

                        }

                        $(e).off("mouseup mouseleave", $removeRippleFnc);
                        $(e).on("mouseup mouseleave", { ripple: $thisRipple }, $removeRippleFnc);
                    });
                }
                else
                {
                    Trace(["Can't attach ripple effect to", e, "because of shadow class"], "MATERIAL");
                }
            }
        },

        polymer:
        {
            overlay: function (data)
            {
                var $overlay = $div({ "class": "modal" });
                var $content = $div({ "class": "modal-content" });
                var $actions = $div({ "class": "modal-footer" });

                if (data.big)
                    $overlay.addClass("modal-fixed-footer");

                $overlay.append($content);
                $overlay.append($actions);

                var buttonClicked = false;
                var result = null;

                var openFnc = function ()
                {
                    // if (!!data.backdrop)
                    // {
                    //     backdrop_element = $(".core-overlay-backdrop.core-opened");
                    // }

                    $overlay.modal("open");
                    // $overlay[0].open();
                };

                var closeFnc = function ()
                {
                    $overlay.modal("close");
                    // $overlay[0].close();
                };

                var affirmFnc = function ()
                {
                    buttonClicked = true;
                    result = true;
                    setTimeout(function ()
                    {
                        closeFnc();
                    }, 100);
                };

                var dismissFnc = function ()
                {
                    buttonClicked = true;
                    result = false;
                    setTimeout(function ()
                    {
                        closeFnc();
                    }, 100);
                };

                var handleOpenFnc = function ()
                {
                    // if (!!data.backdrop && backdrop_element)
                    // {
                    //     backdrop_original_zindex = backdrop_element.css("z-index");
                    //     backdrop_element.css("z-index", parseInt($overlay.css("z-index")) - 1);
                    // }

                    if (data.onOpen && p.is.function(data.onOpen))
                        data.onOpen();
                };

                var handleCloseFnc = function ()
                {
                    $overlay.remove();

                    // if (!!data.backdrop && backdrop_element)
                    // {
                    //     backdrop_element.css("z-index", backdrop_original_zindex);
                    // }

                    if (data.onClose && p.is.function(data.onClose))
                        data.onClose(buttonClicked);

                    if (data.callback && p.is.function(data.callback))
                    {
                        if (buttonClicked)
                            data.callback(result);
                        else
                            data.callback(false);
                    }

                };

                var affirmative = null;
                var dismissive = null;

                // $overlay.addClass("dialog");
                // $overlay.attr("transition", "core-transition-center");

                if (p.seamlessNavigation.FromApp)
                {
                    $overlay.addClass("fullscreen");
                }

                if (!!data.id)
                    $overlay.attr("id", data.id);

                // if (!!data.backdrop)
                //     $overlay.attr("backdrop", "");

                // if (!data.dismissable)
                //     $overlay.attr("autoclosedisabled", "");

                if (!!data.content_before)
                    $content.append(data.content_before);

                if (!!data.title)
                    $content.append($("<h4>", { html: data.title }));

                if (!!data.subhead)
                    $content.append($("<h5>", { html: data.subhead }));

                if (!!data.text)
                    $content.append($("<p>", { html: data.text }));

                if (!!data.content)
                    $content.append(data.content);

                if (!!data.actions)
                {
                    if (!!data.actions.affirmative)
                    {
                        if (p.is.jQuery(data.actions.affirmative))
                        {
                            affirmative = data.actions.affirmative;
                        }
                        else if (p.is.string(data.actions.affirmative))
                        {
                            affirmative = p.paper.button(data.actions.affirmative, null, null, null, "modal-action fill");
                        }
                        else
                        {
                            if (!!data.actions.dismissive)
                                affirmative = p.paper.button(LOCALE["YES"], null, null, null, "modal-action fill");
                            else
                                affirmative = p.paper.button(LOCALE["OK"], null, null, null, "modal-action fill");
                        }
                    }

                    if (!!data.actions.dismissive)
                    {
                        if (p.is.jQuery(data.actions.dismissive))
                        {
                            dismissive = data.actions.dismissive;
                        }
                        else if (p.is.string(data.actions.dismissive))
                        {
                            dismissive = p.paper.button(data.actions.dismissive, null, null, null, "modal-action text");
                        }
                        else
                        {
                            dismissive = p.paper.button(LOCALE["NO"], null, null, null, "modal-action text");
                        }
                    }
                }
                else
                {
                    affirmative = p.paper.button(LOCALE["OK"], null, null, null, "modal-action fill");
                }

                if (dismissive != null)
                {
                    dismissive.attr("dismissive", "");
                    dismissive.on("click", dismissFnc);
                    $actions.append(dismissive);
                }

                if (affirmative != null)
                {
                    affirmative.attr("affirmative", "");
                    affirmative.on("click", affirmFnc);
                    $actions.append(affirmative);
                }

                p.defer(function ()
                {
                    // $overlay[0].addEventListener("core-overlay-open-completed", handleOpenFnc);
                    // $overlay[0].addEventListener("core-overlay-close-completed", handleCloseFnc);
                    let modal = M.Modal.init($overlay[0], {
                        onOpenEnd: handleOpenFnc,
                        onCloseEnd: handleCloseFnc,

                        opacity: !!data.backdrop ? 0.5 : 0.0,        
                        dismissible: !!data.dismissable,
                    });

                    modal.open();
                    // $overlay.modal({
                    //     dismissible: !!data.dismissable,
                    //     opacity: !!data.backdrop ? 0.5 : 0.0,
                    //     ready: handleOpenFnc,
                    //     complete: handleCloseFnc
                    // });
                });

                return {
                    element: $overlay,
                    open: openFnc,
                    close: closeFnc
                };
            },

            image: function (src, sizing, size, classes)
            {
                var $img = $("<core-image preload fade>");
                $img.attr("sizing", (sizing ? sizing : "cover"));
                $img.attr("src", src);
                !!classes && $img.addClass(classes);
                if (size) $img.css("width", size.width) && $img.css("height", size.height);

                return $img;
            },
            _menuid: 0,
            menu: function (preselect, valueattr)
            {
                var $menu = $("<ul>", {
                    "class": "collapsible",
                    "data-collapsible": "accordion"
                });
                $menu.collapsible();
                return $menu;
            },

            submenu: function (label, icon, preselect, valueattr)
            {
                var $s = $("<li>");
                if (valueattr)
                    $s.attr("valueattr", valueattr);

                var link = $("<a>", { "class": "collapsible-header waves-effect waves-light" });
                link.append($("<span>", { "class": "nav-text", "text": label }));
                if (icon)
                    link.append($("<i>", { "class": "material-icons", text: icon }));

                $s.append(link);

                var $container = $("<div>", { "class": "collapsible-body" });
                var $submenu = p.polymer.menu();

                $s.append($container);
                $container.append($submenu);

                return { "element": $s, "container": $submenu };
            },

            item: function (label, icon)
            {
                var $i = $("<li>");
                var link = $("<a>", { "class": "collapsible-header waves-effect waves-light" });
                if (icon)
                    link.append($("<i>", { "class": "material-icons", text: icon }));
                link.append($("<span>", { "class": "nav-text", "text": label }));

                $i.append(link);

                return $i;
            },

            tooltip: function (content, tooltip)
            {
                var $t = $("<core-tooltip>");
                $t.attr("label", tooltip);
                $t.append(content);

                return $t;
            },
            chip: function (content, tooltip)
            {
                var $t = $div({ "class": "chip" });
                $t.append(content);
                $t.append(tooltip);

                return $t;
            },

            _animator_states: {},

            AnimateTo: function (animator, selection)
            {
                p.polymer._animator_states[animator] = selection;
                var $a = $(animator);
                if ($a.length > 0)
                {
                    // $a[0].selected = selection;
                    $a.children().removeClass("active");
                    if (p.is.string(selection))
                    {
                        $a.children().each(function ()
                        {
                            if ($(this).attr("name") == selection)
                            {
                                $(this).addClass("active");
                                return false;
                            }
                        });
                    }
                    else
                    {
                        $($a.children()[selection]).addClass("active");
                    }
                }
            },

            AnimatorFix: function (animator)
            {
                // if (p.polymer._animator_states[animator] != undefined)
                // {
                //     var $a = $(animator);
                //     if ($a.length > 0)
                //     {
                //         $a[0].selected = p.polymer._animator_states[animator];
                //     }
                // }
            },

            isInPrimary: function ()
            {
                return document.querySelector("#animator-content").selected == 0;
            },

            animateToPrimary: function ()
            {
                p.polymer.AnimateTo("#animator-content", 0);
            },

            animateToSecondary: function ()
            {
                p.polymer.AnimateTo("#animator-content", 1);
                //document.querySelector("#animator-content").selected = 1;
            },

            animateToTertiary: function ()
            {
                p.polymer.AnimateTo("#animator-content", 2);
                //document.querySelector("#animator-content").selected = 2;
            },

            animateDrawerToNested: function ()
            {
                p.polymer.AnimateTo("#animator-categories", 0);
                //document.querySelector("#animator-categories").selected = "nested";
            },
            animateDrawerToFlat: function ()
            {
                p.polymer.AnimateTo("#animator-categories", 1);
                //document.querySelector("#animator-categories").selected = "flat";
            },
            animateDrawerToSearch: function ()
            {
                p.polymer.AnimateTo("#animator-categories", 2);
                //document.querySelector("#animator-categories").selected = "search";
            },

            dummyhero: function ()
            {
                return $("<div id=\"dummy-hero\" hero hero-id=\"transition_hero\" />");
            }

        },

        paper:
        {
            input_decorator: function (control)
            {
                var $i = $("<paper-input-decorator floatinglabel crossfade class=\"branded text\">");
                $i.append(control);
                if (control.attr("placeholder"))
                    $i.attr("label", control.attr("placeholder"));

                return $i;
            },

            button: function (txt, raised, icon_before, icon_after, classes)
            {
                var $btn = $("<a>", { "class": "btn waves-effect" });
                // var $btn = raised ? $("<paper-button raised>") : $("<paper-button>");

                $btn.html(txt);
                classes && $btn.attr("class", "btn waves-effect " + classes);

                if (icon_before)
                {
                    if (p.is.jQuery(icon_before))
                        $btn.prepend(icon_before);
                    else
                        $btn.prepend(p.paper.icon(icon_before));
                }

                if (icon_after)
                {
                    if (p.is.jQuery(icon_after))
                        $btn.append(icon_after);
                    else
                        $btn.append(p.paper.icon(icon_after));
                }

                return $btn;
            },

            icon: function (name)
            {
                return $("<i>", { "class": "material-icons", text: name.replace("-", "_") });
                // var $i = $("<core-icon>", { "icon": name });
                // $i.attr("icon", name);
                // return $i;
            },

            icon_button: function (icon_name)
            {
                var $btn = $("<a>", { "class": "btn-floating waves-effect" });
                $btn.append(p.paper.icon(icon_name));
                // var $btn = $("<paper-icon-button>");

                // $btn.attr("icon", icon_name);
                return $btn;
            },

            ripplecontainer: function ()
            {
                return $("<paper-ripple fit>");
            },

            item: function (label, icon)
            {
                var $i = $("<paper-item>");

                $i.html((icon ? "&nbsp;" : "") + label);

                if (icon)
                {
                    $i.prepend(p.paper.icon(icon));
                }

                return $i;
            },

            checkbox: function (checked)
            {
                var c = $("<paper-checkbox>");
                if (checked)
                    c.attr("checked", "");
                return c;
            },

            spinner: function ()
            {
                return $('<div class="preloader-wrapper small active"><div class="spinner-layer spinner-blue"><div class="circle-clipper left"><div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div><div class="spinner-layer spinner-red"><div class="circle-clipper left"><div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div><div class="spinner-layer spinner-yellow"><div class="circle-clipper left"><div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div><div class="spinner-layer spinner-green"><div class="circle-clipper left"><div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div></div>');
            },

            make_animateable: function (obj, type, id)
            {
                obj.attr(type, "");
                if (type == "hero")
                    obj.attr("hero-id", id);

                return obj;
            },

            setDrawerSize: function (pxWidth)
            {
                var sizeStr = pxWidth && pxWidth >= 256 ? "{0}px".format(pxWidth) : "256px";
                $("core-drawer-panel")[0].drawerWidth = sizeStr;
            }
        },

        form_helper:
        {
            getElements: function (e)
            {
                var $this = p.form_helper;
                var $e = $(e);
                var $control = null;
                var $label = null;

                if ($e.hasClass("input-field"))
                {
                    $control = $($e.find("input, select, textarea"));
                    $label = $($e.find("label"));
                }
                else if ($e.hasClass("custom-select"))
                {
                    var children = { select: null, input: null };
                    $e.children().each(function ()
                    {
                        var elements = $this.getElements($(this));
                        if (elements.control.is("select"))
                            children.select = elements;
                        else
                            children.input = elements;
                    });

                    if (children.select.control.val() == "__CUSTOM__")
                    {
                        // $control = children.input.control;
                        $label = children.input.label;
                    }
                    else 
                    {
                        // $control = children.select.control;
                        $label = children.select.label;
                    }
                    $control = $([children.select.control, children.input.control]);
                }
                else
                {
                    $control = $e;
                    $label = $($e.parent().find("label"));
                }

                return { control: $control, label: $label };
            },
            setErrorOnInput: function (e, msg)
            {
                p.form_helper.setErrorOnDecorator($(e).parent(), msg);
            },
            setErrorOnDecorator: function (e, msg)
            {
                if (msg && msg.length > 0)
                {
                    $(e).attr("isInvalid", true);
                    $(e).attr("error", msg);
                }
                else
                {
                    $(e).attr("isInvalid", false);
                    $(e).removeAttr("error");
                }
            },
            setErrorOnPaperDropdown: function (e, msg)
            {
                p.form_helper.setErrorOnDecorator(e, msg);
            },
            setErrorOnCustomSelect: function (e, msg)
            {
                p.form_helper.setErrorOnPaperDropdown($(e).children("paper-dropdown-menu"), msg);
                p.form_helper.setErrorOnDecorator($(e).children("paper-input-decorator"), msg);
            },

            decode: function (e)
            {
                if (e && e.length > 0)
                {
                    return $("<textarea/>").html(e).text();
                }

                return e;
            },
            setError: function (e, msg)
            {
                var $this = p.form_helper;
                var ele = $this.getElements(e);
                var $msg = $this.decode(msg);

                if ($msg && $msg.length > 0)
                    ele.control.addClass("invalid");
                else
                    ele.control.removeClass("invalid");

                return ele.label.attr("data-error", $msg);

                // if ($(e).is("paper-input-decorator"))
                //     return $this.setErrorOnDecorator(e, $this.decode(msg));
                // else if ($(e).is("paper-dropdown-menu"))
                //     return $this.setErrorOnPaperDropdown(e, $this.decode(msg));
                // else if ($(e).attr("custom-select") != undefined)
                //     return $this.setErrorOnCustomSelect(e, $this.decode(msg));
                // else
                //     return $this.setErrorOnInput(e, $this.decode(msg));
            },

            setDisabledOnInput: function (e, disabled)
            {
                if (disabled)
                {
                    $(e).parent().attr("disabled", "");
                    $(e).attr("disabled", "");
                }
                else
                {
                    $(e).parent().removeAttr("disabled");
                    $(e).removeAttr("disabled");
                }
            },
            setDisabledOnDecorator: function (e, disabled)
            {
                if (disabled)
                {
                    $(e).children().attr("disabled", "");
                    $(e).attr("disabled", "");
                }
                else
                {
                    $(e).children().removeAttr("disabled");
                    $(e).removeAttr("disabled");
                }
            },
            setDisabledOnDecoratorTextarea: function (e, disabled)
            {
                if (disabled)
                {
                    $(e).children().attr("disabled", "");
                    $($(e).children()[0]).children().attr("disabled", "");
                    $(e).attr("disabled", "");
                }
                else
                {
                    $(e).children().removeAttr("disabled");
                    $($(e).children()[0]).children().removeAttr("disabled");
                    $(e).removeAttr("disabled");
                }
            },
            setDisabledOnPaperDropdown: function (e, disabled)
            {
                if (disabled)
                {
                    $(e).attr("disabled", "");
                }
                else
                {
                    $(e).removeAttr("disabled");
                }
            },
            setDisabledOnCustomSelect: function (e, disabled)
            {
                p.form_helper.setDisabledOnPaperDropdown($(e).children("paper-dropdown-menu"), disabled);
                p.form_helper.setDisabledOnDecorator($(e).children("paper-input-decorator"), disabled);
            },

            setDisabled: function (e, disabled)
            {
                // if ($(e).is("paper-input-decorator"))
                // {
                //     if ($(e).children().length > 0)
                //     {
                //         if ($($(e).children()[0]).is("paper-autogrow-textarea"))
                //             return p.form_helper.setDisabledOnDecoratorTextarea(e, disabled);
                //         else
                //             return p.form_helper.setDisabledOnDecorator(e, disabled);
                //     }
                // }
                // else if ($(e).is("paper-dropdown-menu"))
                //     return p.form_helper.setDisabledOnPaperDropdown(e, disabled);
                // else if ($(e).attr("custom-select") != undefined)
                //     return p.form_helper.setDisabledOnCustomSelect(e, disabled);
                // else
                //     return p.form_helper.setDisabledOnInput(e, disabled);
                var $e = $(e);
                var $this = p.form_helper;
                var ele = $this.getElements(e);

                if (disabled)
                    return ele.control.attr("disabled", "");
                else
                    return ele.control.removeAttr("disabled");
            },

            setDisabledButton: function (e, disabled, spinner)
            {
                var $e = $(e);

                if (disabled)
                {
                    if (spinner)
                    {
                        $e.prop("has-spinner", true);
                        $e.prop("original-text", $e.html());

                        $e.html(p.paper.spinner());
                    }
                    else
                    {
                        $e.prop("has-spinner", false);
                    }

                    $e.attr("disabled", "");
                }
                else
                {
                    if ($e.prop("has-spinner"))
                    {
                        $e.prop("has-spinner", false);
                        $e.html($e.prop("original-text"));
                    }

                    $e.removeAttr("disabled");
                }
            },

            value: function (e)
            {

                var $this = p.form_helper;
                var ele = $this.getElements(e);
                var value = null;
                if (ele.control.length > 1)
                {
                    var select = $($(ele.control[0]).is("select") ? ele.control[0] : ele.control[1]);
                    var input = $($(ele.control[0]).is("select") ? ele.control[1] : ele.control[0]);
                    if (select.val() == "__CUSTOM__")
                        value = input.val();
                    else
                        value = select.val();
                }
                else
                {
                    if (ele.control.attr("type") == "checkbox")
                        return ele.control[0].checked;
                    else
                        value = ele.control.val();
                }

                return value.trim();
                // if ($(e).attr("custom-select"))
                // {
                //     var selectVal = $(e).children("paper-dropdown-menu .dropdown core-menu")[0].selected;

                //     if (selectVal == "__CUSTOM__")
                //     {
                //         selectVal = $(e).children("paper-input-decorator input").val().trim();
                //     }

                //     if (!selectVal) return "";
                //     return selectVal;
                // }

                // if ($(e).is("paper-input-decorator"))
                //     return $(e).children("input").val().trim();

                // if ($(e).is("paper-dropdown-menu"))
                // {
                //     var val = $(e).children(".dropdown").children("core-menu")[0].selected;
                //     if (!val) return "";
                //     return val;
                // }

                // return $(e).val().trim();
            },

            setValue: function (e, val)
            {
                var $this = p.form_helper;
                var ele = $this.getElements(e);
                var target = null;
                if (ele.control.length > 1)
                {
                    var select = $($(ele.control[0]).is("select") ? ele.control[0] : ele.control[1]);
                    var input = $($(ele.control[0]).is("select") ? ele.control[1] : ele.control[0]);
                    if (select.val() == "__CUSTOM__")
                        target = input;
                    else
                        target = select;
                }
                else
                {
                    target = ele.control;
                }

                target.val(val);




                // if ($(e).is("paper-input-decorator"))
                //     $(e).children("input").val(val);

                // if ($(e).is("paper-dropdown-menu"))
                // {
                //     var val = $(e).children(".dropdown").children("core-menu")[0].selected = val;
                // }
            }
        },

        boothtml:
        {
            carousel: function (pics, id, w, h)
            {
                if (!id) id = "gallery-carousel";

                // Carousel
                var container = $div({
                    "id": id,
                    "class": "carousel slide",
                    "data-ride": "carousel"
                });

                container.css("width", w).css("height", h);

                // Indicators
                var indicators = $("<ol>", { "class": "carousel-indicators" });
                for (var i = 0; i < pics.length; i++)
                    indicators.append($("<li>", {
                        "data-target": "#{0}".format(id),
                        "data-slide-to": i,
                        "class": (i == 0) ? "active" : ""
                    }));

                // Content
                var slideWrapper = $div({ "class": "carousel-inner" });
                for (var i = 0; i < pics.length; i++)
                {
                    var $item = $div({ "class": "item" });
                    i == 0 && $item.addClass("active");

                    var $img = $("<img>", { "src": pics[i], "alt": "" });
                    $item.append(
                        $img
                    );
                    slideWrapper.append($item);


                    i == 0 && (!w || !h) && $img.on("load", function ()
                    {
                        container.css("width", $(this).width()).css("height", $(this).height());
                    });
                }

                // Controls
                var $control_left = $("<a>", { "class": "left carousel-control", "href": "#{0}".format(id), "data-slide": "prev" });
                $control_left.append($("<span>", { "class": "glyphicon glyphicon-chevron-left" }));


                var $control_right = $("<a>", { "class": "right carousel-control", "href": "#{0}".format(id), "data-slide": "next" });
                $control_right.append($("<span>", { "class": "glyphicon glyphicon-chevron-right" }));

                // Build
                container
                    .append(indicators)
                    .append(slideWrapper)
                    .append($control_left)
                    .append($control_right);

                return container;
            }
        }
    };


    exHelp.extend(module);
})(window);