(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let s = p.storage("books");
    s.loaded = [];
    s.tags = {};
    s.sorting = "publish_desc";
    s.currentCategory = null;
    s.currentCategories = [];
    s.currentCategoryShop = null;
    s.currentCategoriesShop = [];

    let module =
    {
        books:
        {
            //#region Storage
            _storage: p.storage("books"),
            addToStorage: function (id, data)
            {
                if (this.isInStorage(id))
                    return this.getFromStorage(id);

                this._storage.loaded[id] = data;
                return this._storage.loaded[id];
            },
            updateToStorage: function (id, data)
            {
                if (!this.isInStorage(id))
                    return this.addToStorage(id, data);

                $.extend(this._storage.loaded[id], data);
                return this._storage.loaded[id];
            },
            isInStorage: function (id)
            {
                if (this._storage.loaded && this._storage.loaded[id])
                    return true;

                return false;
            },
            getFromStorage: function (id)
            {
                if (this.isInStorage(id))
                    return this._storage.loaded[id];

                return null;
            },
            clearStorage: function ()
            {
                this._storage.loaded = [];
            },

            // Single Category
            getCurrentCategory: function ()
            {
                if (p.settings["mode"] == "shelf")
                {
                    if (this._storage.currentCategory == null)
                        return { id: -1, name: LOCALE["FILTER_ALL_CATEGORIES"] };

                    return this._storage.currentCategory;
                }
                else if (p.settings["mode"] == "shop")
                {
                    if (this._storage.currentCategoryShop == null)
                        return { id: -1, name: LOCALE["FILTER_ALL_CATEGORIES"] };

                    return this._storage.currentCategoryShop;
                }
            },
            setCurrentCategory: function (c)
            {
                if (p.settings["mode"] == "shelf")
                {
                    if (p.sonderlocke("BANNER_ONLY_ON_ALL") && c.id != -1)
                    {
                        $("#shelf-banners").addClass("hidden");
                    }
                    else
                    {
                        $("#shelf-banners").removeClass("hidden");
                    }
                    this._storage.currentCategory = c;
                    this.saveCurrentCategory();
                    return this._storage.currentCategory;
                }
                else if (p.settings["mode"] == "shop")
                {
                    this._storage.currentCategoryShop = c;
                    this.saveCurrentCategory();
                    return this._storage.currentCategoryShop;
                }
            },
            saveCurrentCategory: function ()
            {
                let index = "anonymous";
                if (p.user.is_logged_in)
                {
                    index = p.user.userdata.username;
                }

                p.ls.set(index + "_curcat", p.jsonStringify(this._storage.currentCategory));
                p.ls.set(index + "_curcatShop", p.jsonStringify(this._storage.currentCategoryShop));
            },
            loadCurrentCategory: function ()
            {
                let index = "anonymous";
                if (p.user.is_logged_in)
                {
                    index = p.user.userdata.username;
                }

                let saved_value = p.jsonParse(p.ls.get(index + "_curcat"));
                let saved_value_shop = p.jsonParse(p.ls.get(index + "_curcatShop"));

                if (!saved_value)
                    saved_value = null;
                if (!saved_value_shop)
                    saved_value_shop = null;

                this._storage.currentCategory = saved_value;
                this._storage.currentCategoryShop = saved_value_shop;

                if (p.settings["mode"] == "shelf")
                    return saved_value;
                else if (p.settings["mode"] == "shop")
                    return saved_value_shop;
            },

            // Multiple Categories
            getCurrentCategories: function ()
            {
                if (p.settings["mode"] == "shelf")
                {
                    if (this._storage.currentCategories == null)
                        return [];

                    return this._storage.currentCategories;
                }
                else if (p.settings["mode"] == "shop")
                {
                    if (this._storage.currentCategoriesShop == null)
                        return [];

                    return this._storage.currentCategoriesShop;
                }
            },
            setCurrentCategories: function (c)
            {
                if (p.settings["mode"] == "shelf")
                {
                    this._storage.currentCategories = c;
                    this.saveCurrentCategories();
                    return this._storage.currentCategories;
                }
                else if (p.settings["mode"] == "shop")
                {
                    this._storage.currentCategoriesShop = c;
                    this.saveCurrentCategories();
                    return this._storage.currentCategoriesShop;
                }
            },
            saveCurrentCategories: function ()
            {
                let index = "anonymous";
                if (p.user.is_logged_in)
                {
                    index = p.user.userdata.username;
                }

                p.ls.set(index + "_curcats", p.jsonStringify(this._storage.currentCategories));
                p.ls.set(index + "_curcatsShop", p.jsonStringify(this._storage.currentCategoriesShop));
            },
            loadCurrentCategories: function ()
            {
                let index = "anonymous";
                if (p.user.is_logged_in)
                {
                    index = p.user.userdata.username;
                }

                let saved_value = p.jsonParse(p.ls.get(index + "_curcats"));
                let saved_value_shop = p.jsonParse(p.ls.get(index + "_curcatsShop"));

                if (!saved_value)
                    saved_value = [];
                if (!saved_value_shop)
                    saved_value_shop = [];

                this._storage.currentCategories = saved_value;
                this._storage.currentCategoriesShop = saved_value_shop;

                if (p.settings["mode"] == "shelf")
                {
                    return saved_value;
                }
                else if (p.settings["mode"] == "shop")
                {
                    return saved_value_shop;
                }
            },
            isInSelectedCategories: function (needle)
            {
                let cats = this.getCurrentCategories();
                for (let key in cats)
                {
                    if (cats[key] == needle)
                        return true;
                }

                return false;
            },

            getSorting: function ()
            {
                return this._storage.sorting;
            },
            setSorting: function (s)
            {
                this._storage.sorting = s;
                this.saveSorting();
                return this._storage.sorting;
            },
            saveSorting: function ()
            {
                let index = "anonymous";
                if (p.user.is_logged_in)
                {
                    index = p.user.userdata.username;
                }

                p.ls.set(index + "_sorting", this._storage.sorting);
            },
            loadSorting: function ()
            {
                let index = "anonymous";
                if (p.user.is_logged_in)
                {
                    index = p.user.userdata.username;
                }

                let savedValue = p.ls.get(index + "_sorting");

                if (!savedValue)
                    savedValue = "publish_desc";

                this._storage.sorting = savedValue;
                return this.getSorting();
            },
            getSortingFnc: function ()
            {
                switch (this._storage.sorting)
                {
                    case "publish_asc":
                        return this.sortFncPublishAsc;
                        break;
                    case "publish_desc":
                        return this.sortFncPublishDesc;
                        break;

                    case "purchase_asc":
                        return this.sortFncPurchaseAsc;
                        break;
                    case "purchase_desc":
                        return this.sortFncPurchaseDesc;
                        break;

                    case "alpha_asc":
                        return this.sortFncNameAsc;
                        break;
                    case "alpha_desc":
                    default:
                        return this.sortFncNameDesc;
                        break;
                }
            },

            //#endregion

            //#region Filtering

            categories: [],
            categoriesFlat: [],
            categoriesLoaded: false,
            polymerLoaded: false,
            categoriesBuilt: false,
            categoriesDoFade: false,

            onReadyLoader: function ()
            {
                if (p.books.polymerLoaded == true)
                    return;

                if (NESTED_CATEGORIES)
                    p.polymer.animateDrawerToNested();
                else
                    p.polymer.animateDrawerToFlat();

                p.books.polymerLoaded = true;
            },

            loadCategories: function ()
            {
                //if (p.books.categoriesLoaded)
                //    return;
                //p.books.categoriesLoaded = true;

                if (!p.books.categoriesLoaded)
                {
                    Trace(["Categories not loaded, loading now"], SYMBOL_SHELF);

                    p.books.categoriesLoaded = true;

                    if (NESTED_CATEGORIES)
                        p.books.loadCurrentCategory();
                    else
                        p.books.loadCurrentCategories();

                    p.net.Request(["get", "categories"], {}, p.books.onLoadCategories);
                }
                else
                {
                    Trace(["Categories loaded already"], SYMBOL_SHELF);
                    let catcontent = "";
                    if (NESTED_CATEGORIES)
                    {
                        catcontent = $("#categories-nested").html().trim();
                    }
                    else
                    {
                        catcontent = $("#categories-flat").html().trim();
                    }
                    Trace(["Category content size", catcontent.length], SYMBOL_SHELF);

                    if (catcontent.length < 50)
                    {
                        Trace(["Categories empty, rebuilding"], SYMBOL_SHELF);
                        p.books.doBuildCategories();
                    }
                }
            },

            onLoadCategories: function (success, data)
            {
                if (success && data && data.success)
                {
                    p.books.categories = data.categories;

                    let $flat = [];
                    for (let i = 0; i < p.books.categories.length; i++)
                    {
                        p.books._flattenCategories(p.books.categories[i], $flat);
                    }
                    $flat.sort(this.sortFncNameDesc);
                    p.books.categoriesFlat = $flat;
                    p.books.categoriesFlat.sort(this.sortFncNameDesc);

                    p.books.doBuildCategories();
                }
            },

            doBuildCategories: function ()
            {
                p.books.categoriesBuilt = false;
                if (NESTED_CATEGORIES)
                    p.books.buildCategories("#categories-nested", false);
                else
                    p.books.buildCategories("#categories-flat", true);


                p.books.buildCategories("#categories-search", true);

                p.books.updateCategorySelection();

                p.defer(function ()
                {
                    if (NESTED_CATEGORIES)
                        p.polymer.animateDrawerToNested();
                    else
                        p.polymer.animateDrawerToFlat();

                    p.books.categoriesBuilt = true;
                });
            },

            updateCategorySelectionNested: function (item)
            {
                let parent = $(item).parent();
                parent[0].selected = $(item).attr("cat-id");

                if (!parent.is("core-menu"))
                    p.books.updateCategorySelectionNested(parent);
            },

            updateCategorySelectionFade: function ()
            {
                let $this = this;

                if ($this.categoriesDoFade)
                {
                    let container = $("#animator-categories");
                    container.addClass("fade-out");
                    setTimeout(function ()
                    {
                        $this.updateCategorySelection();

                        setTimeout(function ()
                        {
                            container.removeClass("fade-out");
                        }, 320);
                    }, 300);
                    $this.categoriesDoFade = false;
                }
                else
                {
                    $this.updateCategorySelection();
                }
            },

            updateCategorySelection: function ()
            {
                    if (p.books.getCurrentCategory())
                    {
                        p.defer(function ()
                        {
                            $('#animator-categories ul li a:not([cat-id="' + p.books.getCurrentCategory().id + '"])').removeClass("active");
                            $('#animator-categories ul li a[cat-id="' + p.books.getCurrentCategory().id + '"]').addClass("active");
                        });
                    }
            },

            catHasContentInShelf: function (catid)
            {
                let has = false;
                p.books.eachShelf(function (book)
                {
                    if (book.categories && book.categories.length > 0)
                    {
                        for (let i = 0; i < book.categories.length; i++)
                        {
                            if (parseInt(book.categories[i]) == parseInt(catid))
                            {
                                has = true;
                                return false; // stop eachShelf
                            }
                        }
                    }
                }, false);

                return has;
            },

            buildMultiCategories: function (renderOn)
            {
                let $this = p.books;
                let $menu = $div({ "layout": "", "vertical": "" });

                if ($this.categories)
                {
                    p.books.categoriesFlat.sort($this.sortFncNameDesc);
                    for (let i = 0; i < $this.categoriesFlat.length; i++)
                    {


                        let $item = $("<core-label horizontal layout>");
                        $item = $($item);
                        //console.log($this.isInSelectedCategories($this.categoriesFlat[i].id), $this.categoriesFlat[i].id);
                        let checkbox = p.paper.checkbox($this.isInSelectedCategories($this.categoriesFlat[i].id));
                        let label = $div({ "text": $this.categoriesFlat[i].name, "flex": "" });

                        checkbox.attr("aria-labelledby", $item.attr("id"));
                        label.on("click", { cb: checkbox }, function (e)
                        {
                            //console.log(e);
                            $(e.data.cb)[0].checked = !$(e.data.cb)[0].checked;
                        });

                        $item.append(checkbox).append(label);
                        $item.attr("cat-id", $this.categoriesFlat[i].id);
                        checkbox[0].addEventListener("core-change", p.books.categoryChecked);


                        p.paper.make_animateable($item, "hero", "category-hero-" + $this.categoriesFlat[i].id);
                        p.paper.make_animateable($item, "cross-fade");

                        if (!p.books.catHasContentInShelf($this.categoriesFlat[i].id))
                            $item.addClass("no-contents");

                        $menu.append($item);
                    }
                }

                $(renderOn).html("").append($menu);
            },

            categoryChecked: function (e)
            {
                let id = $(this).parent().attr("cat-id");

                let currentCategories = p.books.getCurrentCategories();

                while (currentCategories.indexOf(id) >= 0)
                    currentCategories.splice(currentCategories.indexOf(id), 1);

                if (this.checked)
                {
                    currentCategories.push(id);
                }
                else
                {
                    while (currentCategories.indexOf(id) >= 0)
                        currentCategories.splice(currentCategories.indexOf(id), 1);
                }

                currentCategories = p.array.sieve(currentCategories);
                p.books.setCurrentCategories(currentCategories);
                p.books.filterBookDivs();

                if (p.books.categoriesBuilt && p.seamlessNavigation.currentState.stack_array.length > 1)
                    p.seamlessNavigation.Push({ cmd: "shop", stack: "/shelf/" });
            },

            onCategoryChange: function (e)
            {
                //console.log(e.detail, e.detail.item.label_, e.detail.item.opened);

                //if (this.opened)
                //{
                //    $(this).attr("icon", "expand-more");
                //}
                //else
                //{
                //    $(this).attr("icon", "chevron-right");
                //}
            },

            buildCategories: function (renderOn, flat, forSearch)
            {
                let $this = p.books;

                let $menu = p.polymer.menu(null, "cat-id");

                if ($this.categories)
                {
                    if (!flat)
                    {
                        $menu.append(p.books._buildCatNested({ id: -1, name: LOCALE["FILTER_ALL_CATEGORIES"] }));
                        for (let i = 0; i < $this.categories.length; i++)
                        {
                            $menu.append(p.books._buildCatNested($this.categories[i], 0));
                        }
                    }
                    else
                    {
                        p.books.categoriesFlat.sort($this.sortFncNameDesc);
                        for (let i = 0; i < $this.categoriesFlat.length; i++)
                        {
                            $menu.append(p.books._buildCatNested($this.categoriesFlat[i], 0));
                        }
                    }
                }
                $(renderOn).html("").append($menu);
            },

            onCategoryClick: function (e)
            {
                p.books.setCurrentCategory($(this).prop("category"));

                p.tracking.queue("CATEGORY_SELECT", { catId: $(this).prop("category").id });

                p.books.showSorting(false);

                p.books.updateCategorySelection(); // Update selection in all views

                p.books.filterBookDivs();

                if (p.books.categoriesBuilt && p.seamlessNavigation.currentState.stack_array.length > 1)
                    p.seamlessNavigation.Push({ cmd: "shop", stack: "/shelf/" });
            },

            _buildCatNested: function (item, indent)
            {
                let $item;

                if (item.children)
                {
                    let $submenu = p.polymer.submenu(item.name, "chevron_right", null, "cat-id");
                    $item = $submenu.element;

                    for (let i = 0; i < item.children.length; i++)
                    {
                        $submenu.container.append(p.books._buildCatNested(item.children[i], indent + 1));
                    }
                }
                else
                {
                    $item = p.polymer.item(item.name);
                }

                let $clickThing = $($item.children()[0]);
                $clickThing.prop("category", item);
                $clickThing.prop("indent", indent);
                $clickThing.attr("cat-id", item.id);

                if (!$clickThing.prop("click-handler-attached"))
                {
                    $clickThing.on("click", p.books.onCategoryClick);
                    $clickThing.prop("click-handler-attached", true);
                }

                return $item;
            },

            _flattenCategories: function (item, to)
            {
                if (item.children)
                {
                    for (let i = 0; i < item.children.length; i++)
                    {
                        p.books._flattenCategories(item.children[i], to);
                    }
                    //item.children = null;
                }

                let item_copy = p.array.clone(item);
                item_copy.children = null;
                to.push(item_copy);
            },
            //#region Sorting

            initSorting: function ()
            {
                let sortBtn = $("#drawer-action-sort");
                let backSortBtn = $("#drawer-action-back-from-sort");
                let sortContainer = $("#sorting-container");
                let initProp = "sorting-inited";

                if (!sortBtn.prop(initProp))
                {
                    sortBtn.on("click", function () { setTimeout(p.books.showSorting, 100, true); });
                    sortBtn.prop(initProp, true);
                }

                if (!backSortBtn.prop(initProp))
                {
                    backSortBtn.on("click", function () { setTimeout(p.books.showSorting, 100, false); });
                    backSortBtn.prop(initProp, true);
                }

                if (!sortContainer.prop(initProp))
                {
                    p.books.hookSortingButtons(function ()
                    {
                        if (p.settings["mode"] == "shelf")
                        {
                            p.shelf.waitForLoaded(p.shelf.onStack, p.array.sieve(p.seamlessNavigation.GetStack(true)));
                        }
                        else if (p.settings["mode"] == "shop")
                        {
                            p.shop.waitForLoaded(p.shop.onStack, p.array.sieve(p.seamlessNavigation.GetStack(true)));
                        }

                        p.books.showSorting(false);
                    });
                    sortContainer.prop(initProp, true);
                }

            },

            showSorting: function (show)
            {
                // $("#menu-left").addClass("animate");

                if (show)
                {
                    p.polymer.AnimateTo("#menu-left", 2);
                    // document.querySelector("#animator-drawer").selected = 2;
                    $("#menu-left").addClass("tall");
                    // $("#sorting-container").addClass("show");
                }
                else
                {
                    p.polymer.AnimateTo("#menu-left", 0);
                    // document.querySelector("#animator-drawer").selected = 0;
                    $("#menu-left").removeClass("tall");
                    // $("#sorting-container").removeClass("show");
                }
            },

            sortFncNameAsc: function (a, b)
            {
                if (a.name.toLowerCase() < b.name.toLowerCase())
                    return 1;
                if (a.name.toLowerCase() > b.name.toLowerCase())
                    return -1;
                return 0;
            },
            sortFncNameDesc: function (a, b)
            {
                if (a.name.toLowerCase() > b.name.toLowerCase())
                    return 1;
                if (a.name.toLowerCase() < b.name.toLowerCase())
                    return -1;
                return 0;
            },
            sortFncPublishAsc: function (a, b)
            {
                return a.publish_date - b.publish_date;
            },
            sortFncPublishDesc: function (a, b)
            {
                return b.publish_date - a.publish_date;
            },
            sortFncPurchaseAsc: function (a, b)
            {
                return a.purchase_time - b.purchase_time;
            },
            sortFncPurchaseDesc: function (a, b)
            {
                return b.purchase_time - a.purchase_time;
            },

            hookSortingButtons: function (onSort)
            {
                p.books.loadSorting();
                let setSorting = function (val)
                {
                    //console.log("Sort: ", val);
                    p.books.setSorting(val);
                    $(".sorting-btn").removeClass("active");
                    switch (val)
                    {
                        case "publish_asc":
                            $("#sort-publish-asc").addClass("active");
                            break;
                        case "publish_desc":
                            $("#sort-publish-desc").addClass("active");
                            break;

                        case "purchase_asc":
                            $("#sort-purchase-asc").addClass("active");
                            break;
                        case "purchase_desc":
                            $("#sort-purchase-desc").addClass("active");
                            break;

                        case "alpha_asc":
                            $("#sort-alpha-asc").addClass("active");
                            break;
                        case "alpha_desc":
                        default:
                            $("#sort-alpha-desc").addClass("active");
                            break;
                    }

                    if (onSort && p.is.function(onSort))
                        onSort();
                };

                setSorting(p.books.getSorting());

                $("#sort-publish-asc").on("click", function () { setSorting("publish_asc"); });
                $("#sort-publish-desc").on("click", function () { setSorting("publish_desc"); });
                $("#sort-purchase-asc").on("click", function () { setSorting("purchase_asc"); });
                $("#sort-purchase-desc").on("click", function () { setSorting("purchase_desc"); });
                $("#sort-alpha-asc").on("click", function () { setSorting("alpha_asc"); });
                $("#sort-alpha-desc").on("click", function () { setSorting("alpha_desc"); });
            },
            //#endregion

            filterBookDivs: function (ignoreTags)
            {
                //if (BANNER_MODE)
                //{
                //    if (p.books.multipleTagsSelected())
                //    {
                //        p.books.selectTagExclusive(p.books.getFirstTag().name);
                //    }

                //}

                let bc = $("#content-primary .book-container");
                let searchWord = null;

                // if (p.search.searchTerm.length > 0)
                //     searchWord = p.search.searchTerm;


                // p.search.getNoResults().detach();
                let count = 0;

                let match = function (haystack, needle)
                {
                    if (p.is.string(haystack))
                    {
                        if (haystack.toLowerCase().indexOf(needle.toLowerCase()) != -1)
                        {
                            // console.log(haystack.toLowerCase(), needle);
                            return true;
                        }
                    }
                    else if (haystack.length > 0)
                    {
                        for (let i = 0; i < haystack.length; i++)
                            if (haystack[i].toLowerCase().indexOf(needle.toLowerCase()) != -1)
                            {
                                // console.log(haystack[i].toLowerCase(), needle);
                                return true;
                            }
                    }

                    return false;
                };

                bc.children(".book").each(function ()
                {
                    let $this = $(this);
                    let book = p.books.get($this.attr("id"));

                    //let hasTags = false;
                    let hasCategory = false;
                    let hasTerm = searchWord == null ? true : false;

                    let currentCatId = p.books.getCurrentCategory() ? p.books.getCurrentCategory().id : -1;

                    // Tags Filter
                    if (!ignoreTags)
                    {
                        if (book.categories && Object.keys(book.categories).length > 0)
                        {
                            if (searchWord != null || currentCatId == -1)
                            {
                                hasCategory = true;
                            }
                            else
                            {
                                    for (let $key in book.categories)
                                    {
                                        if (book.categories[$key] == currentCatId)
                                        {
                                            hasCategory = true;
                                            break;
                                        }
                                    }
                            }
                        }
                        else
                        {
                            //if ((BANNER_MODE && currentCatId == -1))
                            //    hasCategory = true;
                            //else // By your powers combined
                            hasCategory = currentCatId == -1 || !NESTED_CATEGORIES;
                        }
                    }
                    else
                    {
                        hasCategory = true;
                    }

                    //if (book.tags && Object.keys(book.tags).length > 0)
                    //{
                    //    for (let $key in book.tags)
                    //    {
                    //        if (p.books.isTagSelected(book.tags[$key]))
                    //        {
                    //            hasTags = true;
                    //            break;
                    //        }
                    //    }
                    //}

                    //if (!hasTags && Object.keys(book.tags).length == 0)
                    //    hasTags = true;

                    // Search Filter
                    if (searchWord != null)
                    {
                        if (!match(book.name, searchWord) &&
                            !match(book.description.long, searchWord) &&
                            !match(book.description.short, searchWord) &&
                            !match(book.author, searchWord) &&
                            !match(book.isbn, searchWord) &&
                            !match(book.tags, searchWord))
                        {
                            hasTerm = false;
                        }
                        else
                        {
                            hasTerm = true;
                        }
                    }

                    // And set
                    if (hasCategory && hasTerm)
                    {
                        $this.removeClass("nomatch");
                        count++;
                    }
                    else
                    {
                        $this.addClass("nomatch");
                    }
                });

                if (count == 0)
                {
                    // bc.append(p.search.getNoResults());
                }

                return count;
            },

            //#endregion 

            //#region Loading
            _loadedShop: false,
            _loadedShelf: false,

            _onLoaded: function (success, data, $onDone)
            {
                if (success && data && data["books"])
                {
                    p.books.loadCategories();

                    // now load books
                    for ($book in data.books)
                    {
                        let bookId = data.books[$book].udid;
                        let bookData = data.books[$book];

                        if (bookData.custom_data)
                            bookData.custom_data = p.jsonParse(bookData.custom_data);

                        if (bookData.purchase_type.web.trim().length > 0 && bookData.devices.web == true)
                        {
                            this.updateToStorage(bookId, bookData);
                        }
                    }
                }

                //p.books.fillTags();
                if ($onDone && $.isFunction($onDone))
                    $onDone();
            },

            _loadShop: function ($onDone)
            {
                // console.trace("_loadShop");
                p.net.Request(["get", "all"], {}, function (a, b) { p.books._onLoaded.call(p.books, a, b, $onDone); });
            },
            //_loadShelf: function ($onDone)
            //{
            //    p.net.Request(["get", "shelf"], {}, function (a, b) { p.books._onLoaded.call(p.books, a, b, $onDone); });
            //},

            load: function ($onDone)
            {
                let onDone = function ()
                {
                    if (!!p.books._loadedShop && !!p.books._loadedShelf)
                    {
                        if ($onDone && $.isFunction($onDone))
                            $onDone();
                    }
                };
                let onShop = function ()
                {
                    p.books._loadedShop = true;
                    p.books._loadedShelf = true;
                    onDone();
                };
                //let onShelf = function ()
                //{
                //    p.books._loadedShelf = true;
                //    onDone();
                //};

                p.books._loadedShop = p.books._loadedShelf = false;
                this._loadShop(onShop);
                //this._loadShelf(onShelf);
            },

            invalidateLoadState: function ()
            {
                p.books._loadedShop = p.books._loadedShelf = false;
                p.books.clearStorage();
            },
            //#endregion

            //#region Querying

            SHELFTYPE:
            {
                INVALID: -1,
                PURCHASED: 0,
                FREEBIE: 1,
                PREVIEW: 2,
                SUBSCRIBED: 3,
                PENDING: 4
            },

            getShelftype: function (id)
            {
                if (this.isInShelf(id))
                {
                    let book = this.getFromStorage(id);

                    if (book.is_payment_pending)
                        return this.SHELFTYPE.PENDING;

                    switch (book.type.web)
                    {
                        case "free_all":
                        case "free_shelf":
                            if (book.preview.available === true && book.is_purchased === false)
                                return this.SHELFTYPE.PREVIEW;
                            else
                                return this.SHELFTYPE.FREEBIE;
                            break;
                        case "free_shop":
                            if (book.preview.available === true && book.is_purchased === false)
                                return this.SHELFTYPE.PREVIEW;
                            else
                                return this.SHELFTYPE.PURCHASED;
                            break;
                        case "priced":
                            if (book.is_purchased === true)
                                return this.SHELFTYPE.PURCHASED;
                            else if (book.preview.available === true)
                                return this.SHELFTYPE.PREVIEW;
                            break;
                        case "subscription":
                            if (book.is_purchased === true)
                                return this.SHELFTYPE.SUBSCRIBED;
                            break;
                    }

                    //if (book.is_freebie === true)
                    //    return this.SHELFTYPE.FREEBIE;
                    //else if (book.purchased === true)
                    //    return this.SHELFTYPE.PURCHASED;
                    //else if (book.purchased === false && book.has_preview === true)
                    //    return this.SHELFTYPE.PREVIEW;
                    //else
                    //    return this.SHELFTYPE.INVALID;
                }

                return this.SHELFTYPE.INVALID;
            },

            isInShelf: function (id)
            {
                if (this.isInStorage(id))
                {
                    let book = this.getFromStorage(id);

                    if (book.is_bookshelf == true || book.is_payment_pending == true)
                        return true;
                }

                return false;
            },
            isInShop: function (id)
            {
                if (this.isInStorage(id))
                {
                    let book = this.getFromStorage(id);

                    if (book.is_bookshelf == true)
                    {
                        return book.is_preview;
                    }

                    if (book.is_payment_pending == true)
                        return false;

                    return true;
                }

                return false;
            },

            isOwned: function (id)
            {
                if (this.isInStorage(id))
                {
                    let book = this.getFromStorage(id);

                    if (book.is_purchased === true)
                        return true;
                }

                return false;
            },

            get: function (id)
            {
                return this.getFromStorage(id);
            },
            getAll: function ()
            {
                return this._storage.loaded;
            },

            getShelf: function ()
            {
                let result = [];

                for (let $id in this._storage.loaded)
                {
                    if (this.isInShelf($id))
                        result.push(this.get($id));
                }

                return result;
            },

            getShop: function ()
            {
                let result = [];

                for (let $id in this._storage.loaded)
                {
                    if (this.isInShop($id))
                        result.push(this.get($id));
                }

                return result;
            },

            get loaded()
            {
                return (this._loadedShop && this._loadedShelf);
            },

            each: function (callback, async)
            {
                if (!callback || !$.isFunction(callback))
                    return;

                let unsorted = this.getAll();
                let all = [];

                for (let $id in unsorted)
                {
                    all.push(unsorted[$id]);
                }

                all.sort(p.books.getSortingFnc());

                for (let $id in all)
                {
                    let callbackResult = true;
                    if (async)
                        p.defer(function () { callback(all[$id]); });
                    else
                        callbackResult = callback(all[$id]);

                    if (callbackResult === false)
                        break;
                }
            },

            eachShop: function (callback, async)
            {
                if (!callback || !$.isFunction(callback))
                    return;

                let unsorted = this.getAll();
                let all = [];

                for (let $id in unsorted)
                {
                    all.push(unsorted[$id]);
                }

                all.sort(p.books.getSortingFnc());

                for (let $id in all)
                {
                    if (this.isInShop(all[$id].udid))
                    {
                        if (async)
                            p.defer(function (e) { callback(e); }, undefined, [all[$id]]);
                        else
                            callback(all[$id]);
                    }
                }
            },

            eachShelf: function (callback, async)
            {
                // console.log("eachShelf");

                if (!callback || !p.is.function(callback))
                    return;

                let unsorted = this.getAll();
                let all = [];

                for (let $id in unsorted)
                {
                    all.push(unsorted[$id]);
                }

                all.sort(p.books.getSortingFnc());

                for (let $id in all)
                {
                    if (this.isInShelf(all[$id].udid))
                    {
                        // console.log($id);
                        if (async)
                            p.defer(function (e) { callback(e); }, undefined, [all[$id]]);
                        else
                            callback(all[$id]);
                    }
                }
            },

            //#endregion

            showPrintModal: function PrintModal(id)
            {
                let $book = this.get(id);
                let selectedPages = [];
                let cancel = false;

                let DEBUG_TAG_PRINT = "spooler";

                let iFrameContainer = null;
                let iFrame = null;
                let iFrameDocument = null;
                let iFrameBody = null;

                let renderProgress = {
                    currentPage: -1,
                    currentProgress: -1,
                    maxProgress: -1
                };

                let $onImgLoad = function ImgDeloader(e)
                {
                    $(this).removeClass("loading");
                };

                let doPrint = function Spooler_Print()
                {
                    $("#print-progress-info").html(LOCALE["PRINT_PRINTING"]);

                    for (let i in selectedPages)
                    {
                        let id = "page-canvas-{0}".format(selectedPages[i]);
                        let canvas = $("#" + id);
                        let canvas_raw = document.getElementById(id);
                        let canvas_raw_context = canvas_raw.getContext("2d");

                        // Does not work anymore since webcomponents or something makes it so
                        // that elements are not proper DOMNode's anymore.
                        //canvas.css("page-break-after", "always");
                        //canvas.css("page-break-before", "always");
                        //canvas.css("width", "100%");
                        //canvas = canvas.detach();
                        //iFrameBody.appendChild(canvas);
                        //iFrameBody.appendChild(canvas[0].cloneNode(true));

                        let iFrameCanvas = iFrameDocument.createElement("canvas");
                        iFrameCanvas.width = canvas_raw.width;
                        iFrameCanvas.height = canvas_raw.height;
                        let iFrameCanvasContext = iFrameCanvas.getContext("2d");
                        //iFrameCanvasContext.drawImage(canvas_raw, 0, 0);
                        iFrameCanvasContext.putImageData(canvas_raw_context.getImageData(0, 0, canvas_raw.width, canvas_raw.height), 0, 0);
                        iFrameCanvas.style.pageBreakAfter = "always";
                        iFrameCanvas.style.pageBreakBefore = "always";
                        iFrameCanvas.style.width = "100%";

                        iFrameBody.appendChild(iFrameCanvas);
                        canvas.remove();
                    }

                    if (p.browser.isMSIE || p.browser.isEdge)
                    {
                        window["pdfrenderframe"].focus();
                        window["pdfrenderframe"].print();
                        window["pdfrenderframe"].onafterprint = function ()
                        {
                            $("#print-modal").modal("close");
                        };
                        $("#print-modal").modal("close");
                    }
                    else
                    {
                        window["pdfrenderframe"].print();
                        $("#print-modal").modal("close");
                    }
                };

                let renderPages = function Spooler_RenderLoop()
                {
                    $("#print-progressbar").removeClass("indeterminate").addClass("determinate");
                    $("#print-progressbar").css("width", "0%");
                    $("#print-progress-info").html(LOCALE["PRINT_RENDERING"].format(renderProgress.currentProgress, renderProgress.maxProgress));

                    let render = function Spooler_Render()
                    {
                        if (cancel) return;

                        Trace(["Rendering page", selectedPages[renderProgress.currentPage], "with index", renderProgress.currentPage], DEBUG_TAG_PRINT);
                        p.PDFManager.renderPageOn(selectedPages[renderProgress.currentPage], "page-canvas-{0}".format(selectedPages[renderProgress.currentPage]));
                    };

                    let onRender = function Spooler_onRender()
                    {
                        if (cancel) return;
                        let keys = Object.keys(selectedPages);
                        renderProgress.currentProgress++;

                        let targetKey = keys[renderProgress.currentProgress];
                        renderProgress.currentPage = targetKey;

                        $("#print-progress-info").html(LOCALE["PRINT_RENDERING"].format(renderProgress.currentProgress + 1, renderProgress.maxProgress));
                        //p.material.progress($("#print-progressbar"), p.math.Percentage.XofY(renderProgress.currentProgress, renderProgress.maxProgress));

                        $("#print-progressbar").css("width", p.math.Percentage.XofY(renderProgress.currentProgress, renderProgress.maxProgress).toFixed(2) + "%");

                        if (renderProgress.currentProgress < renderProgress.maxProgress)
                            render();
                        else
                        {
                            doPrint();
                        }
                    };
                    p.PDFManager.setOnPageRenderHandler(onRender);

                    // Make canvases
                    renderProgress.currentPage = null;
                    for (let i in selectedPages)
                    {
                        if (cancel) return;
                        if (renderProgress.currentPage == null)
                            renderProgress.currentPage = i;

                        let id = "page-canvas-{0}".format(selectedPages[i]);
                        let canvas = $("<canvas>", { "id": id });

                        iFrameContainer.append(canvas);
                        p.PDFManager.addCanvas(id, canvas);
                    }

                    // Render
                    render();
                };

                let pdfOnDocumentLoad = function Spooler_onPDFLoad(e)
                {
                    //console.log(e);
                    //try
                    //{
                    //    let styles = document.head.getElementsByTagName("style");
                    //    let pdfFonts = styles[styles.length - 1];
                    //    let sheet = pdfFonts.sheet;
                    //    window["pdfrenderframe"].document.getElementsByTagName("head")[0].appendChild(pdfFonts);
                    //    window["pdfrenderframe"].document.getElementsByTagName("head")[0].getElementsByTagName("style")[0].sheet = sheet;
                    //}
                    //catch (ex)
                    //{
                    //    console.log(ex);
                    //}

                    //p.material.progress($("#print-progressbar"), 0);
                    $("#print-progressbar").removeAttr("indeterminate");
                    $("#print-progressbar").val(0);
                    renderPages();
                };
                let pdfOnDocumentProgress = function (e)
                {
                    //console.log(e);
                    /*
                    {
                        loaded: int,
                        total:int
                    }
                    */
                    if (e && e.loaded && e.total)
                    {
                        $("#print-progressbar").removeClass("indeterminate").addClass("determinate");
                        $("#print-progress-info").html(LOCALE["PRINT_LOADING"] + " " + p.math.Percentage.XofY(e.loaded, e.total).toFixed(2) + "%");
                        $("#print-progressbar").css("width", p.math.Percentage.XofY(e.loaded, e.total).toFixed(2) + "%");
                    }
                    else
                        $("#print-progress-info").html(LOCALE["PRINT_LOADING"]);
                };
                let pdfOnPageLoad = function (e) { };
                let pdfOnPageRender = function (e) { };

                let onPDFJSLoaded = function Spooler_onPDFJSLoaded()
                {
                    if (cancel) return;


                    // Set PDFJS Worker
                    PDFJS.workerSrc = BASEPATH + "/payload/js/pdfjs/pdf.worker.min.js";

                    iFrameContainer = $("#print-iframe");
                    iFrame = $("<iframe>", { "id": "pdfrenderframe", "name": "pdfrenderframe" });
                    iFrameContainer.append(iFrame);

                    setTimeout(function Spooler_AfterIFRAME()
                    {
                        if (cancel) return;
                        iFrameDocument = window["pdfrenderframe"].document;
                        iFrameBody = iFrameDocument.getElementsByTagName("body")[0];

                        renderProgress.currentPage = 1;
                        renderProgress.currentProgress = 0;
                        renderProgress.maxProgress = selectedPages.length;

                        p.PDFManager.setOnDocumentLoadHandler(pdfOnDocumentLoad);
                        p.PDFManager.setOnDocumentProgressHandler(pdfOnDocumentProgress);
                        p.PDFManager.setOnPageLoadHandler(pdfOnPageLoad);
                        //p.PDFManager.setOnPageRenderHandler(pdfOnPageRender);

                        p.PDFManager.scale = 2.0;

                        $("#print-progress-info").html(LOCALE["PRINT_LOADING"]);
                        p.PDFManager.loadFile("{0}/api/web/?cmd=get/file/full&udid={1}&platform=web&class=web".format(BASEPATH, $book.udid));
                    }, 10);

                };

                let onLoadPDFJS = function Spooler_LoadPDFJS()
                {
                    $("#print-pages, #print-previews-title, #print-previews").addClass("hidden");
                    $("#print-progress").removeClass("hidden");

                    //p.material.progress($("#print-progressbar"), -1);
                    $("#print-progress-info").html(LOCALE["PRINT_PREPARING"]);

                    if (window["PDFJS"] == void 0 || p["PDFManager"] == void 0)
                    {
                        // PDF Stuff not loaded yet -> Load it
                        $.getScript(BASEPATH + "/payload/js/pdfjs/compatibility.min.js");
                        $.getScript(BASEPATH + "/payload/js/pdfjs/pdf.min.js");
                        // $.getScript(BASEPATH + "/payload/js/pdfmanager.min.js");
                    }

                    let waitingTimeout = setInterval(function ()
                    {
                        if (window["PDFJS"] != void 0 && window["PDFJS"]["version"] != void 0 && p["PDFManager"] != void 0)
                        {
                            onPDFJSLoaded();
                            clearInterval(waitingTimeout);
                        }
                    }, 100);
                };

                let onPages = function Spooler_onPageSelectionChanged(e)
                {
                    selectedPages = [];
                    if (e == "all")
                    {
                        for (let i = 1; i <= $book.pagecount.web; i++)
                        {
                            selectedPages.push(i);
                        }
                    }
                    else
                    {
                        let split = e.split(",");
                        split = p.array.sieve(split);
                        for (let i = 0; i < split.length; i++)
                        {
                            split[i] = split[i].trim();
                        }

                        for (let i = 0; i < split.length; i++)
                        {
                            let s = split[i];
                            if (s.indexOf("-") != -1)
                            {
                                let rangeSplit = s.split("-");
                                let from = parseInt(rangeSplit[0].trim());
                                let to = parseInt(rangeSplit[1].trim());

                                for (let cur = from; cur <= to; cur++)
                                    selectedPages.push(cur);
                            }
                            else
                            {
                                selectedPages.push(parseInt(s));
                            }
                        }
                    }
                    //console.log(selectedPages);

                    $("#print-nopreviews").addClass("hidden");
                    $("#print-previews").children().addClass("hidden");
                    for (let i in selectedPages)
                    {
                        let page = selectedPages[i];
                        $("#print-previews img[data-page={0}]".format(page)).removeClass("hidden");
                    }

                    if (Object.keys(selectedPages).length == 0)
                        $("#print-nopreviews").removeClass("hidden");
                };

                let onPrintModal = function Spooler_ModalBuild(success, data)
                {
                    if (!!success && !!data.success)
                    {
                        let $printModal = $(data["modal-html"].trim());
                        let modal = null;
                        $("body").append($printModal);

                        let max = $book.pagecount.web;
                        let now = 1;

                        for (; now <= max; now++)
                        {
                            let $cover = $("<img>", {
                                "data-page": now,
                                "class": "dynamic loading"
                            });

                            $cover.attr("loading", "lazy");
                            $cover.on("load", $onImgLoad);
                            $cover.attr("src", "{0}/api/web/?cmd=get/file/page&udid={1}&type=thumb&page={2}&platform=web&class=web".format(BASEPATH, $book.udid, now));

                            $("#print-previews").append($cover);
                        }

                        let onOpen = function ()
                        {
                            //$("#print-modal").on("hidden.bs.modal", function ()
                            //{
                            //    cancel = true;
                            //    $("#print-modal").remove();
                            //});

                            onPages("all");

                            let onRadioChange = function ()
                            {
                                //console.log(this.selected);
                                switch (this.selected)
                                {
                                    case "all":
                                        onPages("all");
                                        $("#print-pages-custom-input-decorator").addClass("hidden");
                                        break;
                                    case "custom":
                                        onPages($("#print-pages-custom-input").val());
                                        $("#print-pages-custom-input-decorator").removeClass("hidden");
                                        $("#print-pages-custom-input").focus();
                                        break;
                                }
                            };
                            // $("#page-select")[0].addEventListener("core-select", onRadioChange);
                            $("#page-select").on("change", function (e)
                            {
                                switch ($(e.target).val())
                                {
                                    case "all":
                                        onPages("all");
                                        $("#print-pages-custom-input-decorator").addClass("hidden");
                                        break;
                                    case "custom":
                                        onPages($("#print-pages-custom-input").val());
                                        $("#print-pages-custom-input-decorator").removeClass("hidden");
                                        $("#print-pages-custom-input").focus();
                                        break;
                                }
                            });

                            $("#print-pages-custom-input").on("keyup", function ()
                            {
                                //$("#print-pages-all").removeAttr("checked");
                                //$("#print-pages-custom").attr("checked");
                                //$("#page-select")[0].selected = "custom";
                                onPages($("#print-pages-custom-input").val());
                            });

                            $("#print-do").on("click", function ()
                            {
                                setTimeout(function ()
                                {
                                    $("#print-do").addClass("hidden");

                                    onLoadPDFJS();
                                }, 100);
                            });

                            $("#print-cancel").on("click", function ()
                            {
                                setTimeout(function ()
                                {
                                    modal.close();
                                }, 100);
                            });

                            p.common.hideLoader();
                        };

                        let onClose = function ()
                        {
                            cancel = true;
                            modal.destroy();
                            $printModal.remove();
                        };

                        p.defer(function ()
                        {
                            // $printModal[0].addEventListener("core-overlay-open-completed", onOpen);
                            // $printModal[0].addEventListener("core-overlay-close-completed", onClose);
                            modal = M.Modal.init($printModal[0], {
                                onOpenEnd: onOpen,
                                onCloseEnd: onClose,

                                dismissible: false
                            });

                            modal.open();
                            // $printModal.modal({
                            //     dismissible: false,
                            //     ready: onOpen,
                            //     complete: onClose
                            // });
                            // $printModal.modal("open");
                            // $printModal[0].resizeHandler();
                        });
                    }
                };

                p.common.showLoader();
                p.net.Request(["print"], {}, onPrintModal);
            },

            makeToReadTransition: function (id)
            {
                let $book = this.get(id);

                let $container = $div({ "class": "to-reader branded-fill ci" });
                $container.append(p.paper.icon("av:play-arrow"));

                let $title = $("<a>", { "class": "title", "text": $book.name, "title": $book.name + "\n\n" + $book.description.short, "hero-id": "title" + id, "hero": true });
                $container.append($title);

                // $container = p.paper.make_animateable($container, "hero", "toreader" + id);

                return $container;
            },

            makeThumbnail: function (id)
            {
                let $book = this.get(id);

                let custom_data = $book.custom_data;

                // let $card = $div({
                //     "class": "card"
                // });

                // let $cover = $div({ "class": "card-image" });
                // let $cover_img = $("<img>",
                // {
                //     "class": "dynamic loading",
                //     "src": "{0}/api/web/?cmd=get/file/cover&udid={1}&size=medium".format(BASEPATH, id)
                // });

                // // Image loader
                // $cover_img.on("load", function (e)
                // {
                //     $(this).removeClass("loading");
                // });
                // $cover_img.attr("width", 200);
                // $cover_img.attr("height", 284);
                // $cover.append($cover_img);

                // $card.append($cover);
                // return $card;


                //////////////////////////////////////////////////////
                let $container = $div(
                    {
                        "class": "book toastify card z-depth-1 hoverable waves-effect",
                        "z": "0",
                        "id": id,
                        "cross-fade": ""
                    });

                // $container.on("mouseenter", function ()
                // {
                //     $(this).attr("z", "1");
                // });
                // $container.on("mouseleave", function ()
                // {
                //     $(this).attr("z", "0");
                // });

                let $wrapper = $div({ "class": "wrapper" });

                // Elements
                let $cover = $div({ "class": "cover" });
                let $cover_img = $("<img>",
                    {
                        "class": "dynamic loading"
                    });
                let $details = $div({ "class": "details flex rows" });
                let $author = $div({ "class": "author", "text": $book.author });
                let $title = $("<a>", { "class": "title", "text": $book.name, "title": $book.name + "\n\n" + $book.description.short, "hero-id": "title" + id, "hero": true });
                let $price = $("<span>", { "class": "price", "html": "" });
                let $sample = $("<span>", { "class": "sample", "html": LOCALE["FREESAMPLE"] });
                let $description = $div();
                let $hover = $div({ "class": "hover" });

                $cover_img.attr("width", 200);
                $cover_img.attr("height", 284);

                // Image loader
                $cover_img.attr("loading", "lazy");
                $cover_img.on("load", function (e)
                {
                    $(this).removeClass("loading");
                });
                $cover_img.attr("src", "{0}/api/web/?cmd=get/file/cover&udid={1}&size=medium".format(BASEPATH, id));

                // Build
                $cover.append($cover_img);
                $details.append($title).append($author).append($price).append($description);
                $wrapper.append($cover).append($details).append($hover);
                $container.append($wrapper);
                // $container.append(p.paper.ripplecontainer);

                let $titleStr = $book.name + "\n\n" + $book.description.short;
                $container.attr("title", $book.name.trim());

                // Adjustments
                if (this.isInShelf(id))
                {
                    switch (this.getShelftype(id))
                    {
                        default:
                        case this.SHELFTYPE.FREEBIE:
                            $price.html(LOCALE["FREE"]).addClass("free");
                            break;

                        case this.SHELFTYPE.PREVIEW:
                            $price.html(LOCALE["SAMPLE"]).addClass("has-sample");
                            break;

                        case this.SHELFTYPE.PURCHASED:
                            $price.html(LOCALE["OWNED"]).addClass("owned");
                            break;

                        case this.SHELFTYPE.SUBSCRIBED:
                            $price.html(LOCALE["SUBSCRIBED"]).addClass("owned");
                            break;

                        case this.SHELFTYPE.PENDING:
                            $price.html(LOCALE["PAYMENT_PENDING"]).addClass("pending");
                            break;
                    }

                    //#region Read icon
                    let $iconInfo = $("<a>", {
                        "class": "btn-floating hoverable play-read text",
                        "title": $book.name.trim()
                        // "title": LOCALE["RESUME_READING"]
                    });

                    $iconInfo.append(p.paper.icon("info"));
                    $iconInfo.on("click", function (evnt)
                    {
                        // p.common.showLoader();

                        evnt && evnt.stopPropagation && evnt.stopPropagation();
                        evnt && evnt.preventDefault && evnt.preventDefault();
                    });

                    let $icoDownload = $div({ "class": "ico download" });
                    let $icoPrint = $div({ "class": "ico print" });

                    $icoDownload.append(p.glyphicon("download-alt"));
                    $icoPrint.append(p.glyphicon("print"));

                    // p.material.ripple($icoDownload, true);
                    $icoDownload.on("click", function (e)
                    {
                        window.open("{0}/api/web/?cmd=get/file/download&udid={1}&class=web&platform=web".format(BASEPATH, $book.udid), "_blank");

                        if (e && e.stopPropagation)
                            e.stopPropagation();

                        if (e && e.preventDefault)
                            e.preventDefault();
                    });
                    // p.material.ripple($icoPrint, true);
                    $icoPrint.on("click", function (e)
                    {
                        p.books.showPrintModal(id);

                        if (e && e.stopPropagation)
                            e.stopPropagation();

                        if (e && e.preventDefault)
                            e.preventDefault();
                    });
                    //"href": "{0}/reader/?/{1}/".format(BASEPATH, $book.udid)
                    if (this.getShelftype(id) != this.SHELFTYPE.SUBSCRIBED)
                    {
                        $hover.append($iconInfo);
                        // $iconInfo.attr("cmd", "shop");
                        $container.attr("href", "{0}/reader/?/{1}/".format(BASEPATH, $book.udid));
                        $iconInfo.attr("href", "/shelf/details/{0}/{1}/".format(id, encodeURIComponent($book.name.replace(/[^a-zA-Z0-9]/ig, "_"))));
                        // Click handling
                        p.seamlessNavigation.Link_External($container, false, true);
                        p.seamlessNavigation.Link($iconInfo);
                    }
                    else
                    {
                        // p.seamlessNavigation.Link_External($iconInfo, false, true);                                

                        $container.attr("cmd", "shop");
                        $container.attr("href", "/shelf/details/{0}/{1}/".format(id, encodeURIComponent($book.name.replace(/[^a-zA-Z0-9]/ig, "_"))));

                        // Click handling
                        p.seamlessNavigation.Link($container);
                    }
                    //#enderegion

                    //#region Progress bar
                    if (p.user.is_logged_in)
                    {
                        let readerData = p.ls.get("{0}_{1}_data".format(p.user.userdata.username, $book.udid));
                        if (readerData && readerData.length > 0)
                        {
                            readerData = p.jsonParse(readerData);

                            let percent = p.math.Percentage.XofY(readerData.currentPage, $book.pagecount.web);
                            if (percent > 100.0)
                                percent = 100.0;

                            let $barContainer = $div({
                                "class": "prog progress",
                                "title": LOCALE["READING_PERCENTAGE"].format(readerData.currentPage, $book.pagecount.web, percent.toFixed(0))
                            });
                            let $bar = $div({ "class": "value" });
                            $bar.attr("style", "width: " + percent.toFixed(2) + "%");

                            $barContainer.append($bar);
                            $hover.append($barContainer);
                        }
                    }

                    //#endregion

                }
                else
                {
                    if ($book.type.web == "free_shop")
                        $price.html(LOCALE["FREE"]).addClass("free");
                    else
                        $price.html("{0} {1}".format(parseFloat($book.price.web).toFixed(2).replace(".", ","), LOCALE["CURRENCY_SIGN"]));

                    $container.attr("href", "/shop/details/{0}/{1}/".format(id, encodeURIComponent($book.name.replace(/[^a-zA-Z0-9]/ig, "_"))));

                    if ($book.purchase_type.web == "external")
                    {
                        $price.html(LOCALE["EXTERNAL_COVER"]);
                    }
                    if ($book.type.web == "free_shelf")
                    {
                        $price.html(LOCALE["FREE_SHELF_COVER"]);
                    }

                    if ($book.preview.available)
                        $sample.insertBefore($price);

                    $container.attr("cmd", "shop");

                    // Click handling
                    p.seamlessNavigation.Link($container);
                }

                return $container;
            },

            makeDetails: function (id)
            {
                let bookdata = this.get(id);

                if (!bookdata)
                {
                    p.handleError(
                        {
                            error: "NOT_AVAILABLE_FOR_USER",
                            callback: function () { p.seamlessNavigation.Push({}); }
                        });

                    return;
                }

                let publish_date = moment(parseInt(bookdata.publish_date) * 1000);

                let $main = $("#content-secondary .content");
                $main.html("");

                let price = (bookdata.type.web == "free_shop") ? LOCALE["FREE"] : "{0} {1}".format(parseFloat(bookdata.price.web).toFixed(2).replace(".", ","), LOCALE["CURRENCY_SIGN"]);

                //#region Elements
                let $back = p.paper.icon_button("arrow-back");
                //p.paper.button(LOCALE["BACK"], false, null, null, "branded text"); //$div({ "class": "back-button btn btn-default button", "html": LOCALE["BACK"] });

                // Containers
                let $container = $div({ "class": "details", "fit": "", "layout": "" });

                if ($("body").width() > 970)
                    $container.attr("horizontal", "");

                let $container_left = $div({ "class": "left" });
                let $container_right = $div({ "class": "right" });
                let $container_top = $div({ "class": "top", "layout": "", "horizontal": "" });

                //#region Elements
                let $cover_wrapper = $div({ "class": "cover ", "z": "1" });
                let $cover_img = $("<img>", {
                    "class": "dynamic",
                    //"src": "{0}/data/{1}/cover_medium.jpg".format(BASEPATH, bookdata.udid)
                    "src": "{0}/api/web/?cmd=get/file/cover&udid={1}&size=medium".format(BASEPATH, id),
                    "hero-id": "cover" + id,
                    "hero": true
                });

                let $title = $("<h1>", { "class": "display2", "text": bookdata.name });
                let $devices = $("<h1>", { "html": LOCALE["DEVICES"], "class": "subhead" });
                let $isbn = $("<h1>", { "html": "ISBN", "class": "subhead" });

                let $author = $div({ "class": "title", "text": bookdata.author });

                let $published_head = $div({ "class": "title", "html": LOCALE["PUBLISHED"] });
                let $published = $("<div>", { "class": "subhead", "text": publish_date.format(" DD.MM.YYYY") });

                let $short_desc = $("<p>", { "class": "", "html": bookdata.description.short });
                let $more = $div({ "class": "book-details" });

                let $hDescription = $("<h1>", { "class": "display1", html: LOCALE["DESCRIPTION"] });

                //let lDesc_Text = "" + bookdata.description.long;
                //let lDesc_Paras = lDesc_Text.split("\n");

                let $buttons = $div({ "class": "actions" });

                //#endregion

                //#region Buttons

                let $bBuy = p.paper.button(LOCALE["BUY_FOR"].format(price), false, null, null, "branded fill");
                $bBuy.attr("id", "button-buy");

                let $bBuyFree = p.paper.button(LOCALE["FREE"], false, null, null, "branded fill");

                let $bAddSample = p.paper.button(LOCALE["ADD_SAMPLE_TO_SHELF"], false, null, null, "branded fill");

                let $bRead = p.paper.button(LOCALE["READ"], true, null, null, "branded fill");
                $bRead.attr("href", "{0}/reader/?/{1}/".format(BASEPATH, bookdata.udid));
                //p.seamlessNavigation.MakeRef($bRead, true);
                p.seamlessNavigation.Link_External($bRead, false, true);

                let $bReadSample = p.paper.button(LOCALE["READ"], true, null, null, "branded fill");
                $bReadSample.attr("href", "{0}/reader/?/{1}/".format(BASEPATH, bookdata.udid));
                //p.seamlessNavigation.MakeRef($bReadSample, true);
                p.seamlessNavigation.Link_External($bReadSample, false, true);

                //#region Extra buttons

                let $extras = $div({ "class": "extras" });

                let $bExtra_Print = p.paper.icon_button("print");
                let $bExtra_Download = p.paper.icon_button("file-download");
                let $bExtra_Mail = p.paper.icon_button("mail");

                $bExtra_Download.on("click", function (e)
                {
                    window.open("{0}/api/web/?cmd=get/file/download&udid={1}&class=web&platform=web".format(BASEPATH, bookdata.udid), "_blank");

                    if (e && e.stopPropagation)
                        e.stopPropagation();

                    if (e && e.preventDefault)
                        e.preventDefault();
                });

                $bExtra_Print.on("click", function (e)
                {
                    p.books.showPrintModal(id);

                    if (e && e.stopPropagation)
                        e.stopPropagation();

                    if (e && e.preventDefault)
                        e.preventDefault();
                });

                $bExtra_Mail.on("click", function (e)
                {
                    p.share.email(id);

                    if (e && e.stopPropagation)
                        e.stopPropagation();

                    if (e && e.preventDefault)
                        e.preventDefault();
                });

                //#region Share Dropdown

                //let $bExtra_ShareMain = $("<paper-menu-button>"); //, { "relative": "" });
                let $bExtra_ShareMain = $div({ "class": "animator" }); //, { "relative": "" });
                // $bExtra_ShareMain.attr("transitions", "hero-transition cross-fade");

                let $sharePage_0 = $("<section>");
                let $sharePage_1 = $("<section>");

                let $share_start = p.paper.icon_button("share");
                let $share_facebook = p.paper.icon_button("social:post-facebook");
                let $share_twitter = p.paper.icon_button("social:post-twitter");
                let $share_gplus = p.paper.icon_button("social:post-gplus");

                $sharePage_0.append($share_start);
                $sharePage_1.append($share_gplus).append($share_twitter).append($share_facebook);

                let socialBtnCollection = [$back, $bExtra_Print, $bExtra_Download, $bExtra_Mail, $share_start, $share_facebook, $share_twitter, $share_gplus];
                $(socialBtnCollection).each(function ()
                {
                    $(this).addClass("text").addClass("flat").addClass("transparent");
                })

                // p.paper.make_animateable($share_start, "hero", "share_icon_2");

                // p.paper.make_animateable($share_gplus, "hero", "share_icon_0");
                // p.paper.make_animateable($share_twitter, "hero", "share_icon_1");
                // p.paper.make_animateable($share_facebook, "hero", "share_icon_2");

                // p.paper.make_animateable($share_gplus, "cross-fade");
                // p.paper.make_animateable($share_twitter, "cross-fade");
                // p.paper.make_animateable($share_facebook, "cross-fade");

                $bExtra_ShareMain.append($sharePage_0).append($sharePage_1);

                let startSharingFnc = function ()
                {
                    $bExtra_ShareMain[0].selected = 1;
                };

                $share_start.on("click", function ()
                {
                    setTimeout(startSharingFnc, 100);
                });

                $share_twitter.on("click", function ()
                {
                    p.share.twitter(window.location.href, LOCALE["SHARE_TEXT"]);
                    $bExtra_ShareMain[0].selected = 0;
                });
                $share_facebook.on("click", function ()
                {
                    p.share.facebook(window.location.href);
                    $bExtra_ShareMain[0].selected = 0;
                });
                $share_gplus.on("click", function ()
                {
                    p.share.gplus(window.location.href, LOCALE["SHARE_TEXT"]);
                    $bExtra_ShareMain[0].selected = 0;
                });

                //$bExtra_ShareMain.append(p.paper.icon_button("social:share"));


                //let $shareDrop = $("<paper-dropdown>", { "class": "dropdown" });
                //$shareDrop.addClass("dropdown");
                //let $shareMenu = $("<core-menu>", { "class": "menu" });
                //$shareDrop.append($shareMenu);

                //let $shareTwitter = p.paper.item("Twitter", "social:post-twitter");
                //let $shareFacebook = p.paper.item("Facebook", "social:post-facebook");
                //let $shareGoogle = p.paper.item("Google+", "social:post-gplus");

                //$shareTwitter.on("click", function ()
                //{
                //    p.share.twitter(window.location.href, LOCALE["SHARE_TEXT"]);
                //});
                //$shareFacebook.on("click", function ()
                //{
                //    p.share.facebook(window.location.href);
                //});
                //$shareGoogle.on("click", function ()
                //{
                //    p.share.gplus(window.location.href, LOCALE["SHARE_TEXT"]);
                //});

                //$shareMenu
                //    .append($shareTwitter)
                //    .append($shareFacebook)
                //    .append($shareGoogle);

                //$bExtra_ShareMain.append($shareDrop);
                //#endregion 

                //#endregion

                //#endregion

                //#region Events
                // $cover_wrapper.on("mouseenter", function ()
                // {
                //     $(this).attr("z", "2");
                // });
                // $cover_wrapper.on("mouseleave", function ()
                // {
                //     $(this).attr("z", "1");
                // });
                $bAddSample.on("click", function ()
                {
                    let doPurchase = function ()
                    {
                        p.shop.purchase.start_reading_sample(bookdata.udid, {
                            button: $bAddSample,
                            name: bookdata.name,
                            callback: function (success, data)
                            {
                                if (success)
                                {
                                    p.books.invalidateLoadState();
                                    p.books.load(function () { p.seamlessNavigation.Reload(); });
                                }
                                else
                                {
                                    p.handleError(data);
                                }
                            }
                        });
                    };

                    setTimeout(doPurchase, 100);
                });

                if (bookdata.purchase_type.web == "external")
                {
                    $bBuy.html(LOCALE["EXTERNAL_DETAILS"]);
                }
                if (bookdata.type.web == "free_shelf")
                {
                    $bBuy.html(LOCALE["FREE_SHELF_DETAILS"]);
                }

                $bBuy.on("click", function ()
                {
                    let doPurchase = function ()
                    {
                        p.shop.purchase.start(bookdata.udid, {
                            button: $bBuy,
                            name: bookdata.name,
                            callback: function (success, data)
                            {
                                // console.log("callback");
                                if (p.seamlessNavigation.FromApp)
                                {
                                    p.seamlessNavigation.Push({ cmd: "cancel", stack: "/cancel/" });
                                    return location.reload();
                                }
                                else
                                {
                                    if (success)
                                    {
                                        p.books.invalidateLoadState();
                                        p.books.load(function () { p.seamlessNavigation.Reload(); });
                                    }
                                    else
                                    {
                                        p.handleError(data);
                                    }
                                }
                            }
                        });
                    };

                    setTimeout(doPurchase, 100);
                });
                $bBuyFree.on("click", function ()
                {
                    let doPurchase = function ()
                    {
                        p.shop.purchase.start(bookdata.udid, {
                            button: $bBuyFree,
                            name: bookdata.name,
                            callback: function (success, data)
                            {
                                if (success)
                                {
                                    p.books.invalidateLoadState();
                                    p.books.load(function () { p.seamlessNavigation.Reload(); });
                                }
                                else
                                {
                                    p.handleError(data);
                                }
                            }
                        });
                    };

                    setTimeout(doPurchase, 100);
                });
                //$back.on("click", function ()
                //{
                //    //history.back(); //falsch
                //    setTimeout(function ()
                //    {
                //        if (p.settings["mode"] == "shop")
                //        {
                //            p.seamlessNavigation.Push({stack:"/shop/"});
                //            //p.seamlessNavigation.Push(null, null, "/shop", true);
                //        }
                //        else
                //        {
                //            p.seamlessNavigation.Push({ stack: "/shelf/" });
                //            //p.seamlessNavigation.Push(null, null, "/shelf", true);
                //        }
                //    }, 100);
                //});
                $back.attr("cmd", "=");
                $back.attr("href", p.settings["mode"] == "shop" ? "shop" : "shelf");
                p.seamlessNavigation.Link($back);
                //#endregion

                //#region Deviceicons

                let diDesktop, diTabletAndroid, diTabletApple, diTabletBlackberry, diPhoneAndroid, diPhoneApple, diPhoneBlackberry;

                diDesktop = $div({ "class": "appicon desktop available" });
                diPhoneAndroid = $div({ "class": "appicon phone android" });
                diTabletAndroid = $div({ "class": "appicon tablet android" });
                diPhoneApple = $div({ "class": "appicon phone apple" });
                diTabletApple = $div({ "class": "appicon tablet apple" });
                diPhoneWindows = $div({ "class": "appicon phone windows" });
                diTabletWindows = $div({ "class": "appicon tablet windows" });
                diPhoneAmazon = $div({ "class": "appicon phone amazon" });
                diTabletAmazon = $div({ "class": "appicon tablet amazon" });

                diDesktop = p.polymer.chip(diDesktop, "Desktop");
                diPhoneAndroid = p.polymer.chip(diPhoneAndroid, "Android Smartphone");
                diTabletAndroid = p.polymer.chip(diTabletAndroid, "Android Tablet");
                diPhoneApple = p.polymer.chip(diPhoneApple, "iPhone");
                diTabletApple = p.polymer.chip(diTabletApple, "iPad");
                diPhoneWindows = p.polymer.chip(diPhoneWindows, "Windows Phone 8+");
                diTabletWindows = p.polymer.chip(diTabletWindows, "Windows App Store");
                diPhoneAmazon = p.polymer.chip(diPhoneAmazon, "Amazon Fire Phone");
                diTabletAmazon = p.polymer.chip(diTabletAmazon, "Amazon Kindle Fire HD");

                !bookdata.devices.phone.android && diPhoneAndroid.addClass("hidden");
                !bookdata.devices.phone.ios && diPhoneApple.addClass("hidden");
                !bookdata.devices.phone.windows && diPhoneWindows.addClass("hidden");
                !bookdata.devices.phone.amazon && diPhoneAmazon.addClass("hidden");

                !bookdata.devices.tablet.android && diTabletAndroid.addClass("hidden");
                !bookdata.devices.tablet.ios && diTabletApple.addClass("hidden");
                !bookdata.devices.tablet.windows && diTabletWindows.addClass("hidden");
                !bookdata.devices.tablet.amazon && diTabletAmazon.addClass("hidden");

                //#endregion

                // Transition Setup
                // $cover_wrapper = p.paper.make_animateable($cover_wrapper, "hero", "coverwrapper" + id);
                // $cover_img = p.paper.make_animateable($cover_img, "hero", "cover" + id);
                // $title = p.paper.make_animateable($title, "hero", "title" + id);
                // $buttons = p.paper.make_animateable($buttons, "hero", "action" + id);
                // $author = p.paper.make_animateable($author, "hero", "author" + id);

                // $container_left = p.paper.make_animateable($container_left, "hero", "details" + id);
                // $container_right = p.paper.make_animateable($container_right, "hero", "description" + id);
                // $container = p.paper.make_animateable($container, "hero", "container" + id);

                // $container_top = p.paper.make_animateable($container_top, "cross-fade");
                // $container_left = p.paper.make_animateable($container_left, "cross-fade");
                // $container_right = p.paper.make_animateable($container_right, "cross-fade");

                //#region Assembly

                $container_top.append($back);

                $container_left
                    .append($cover_wrapper)
                    .append($title)
                    .append($author)
                    .append(bookdata.type.relevant != "subscription" ? $published : "")
                    .append($("<hr>"))
                    .append($buttons)
                    .append($("<hr>"))
                    .append($short_desc)
                    .append($("<hr>"));

                $container_left
                    .append($devices)
                    .append(diDesktop)
                    .append(diPhoneAndroid)
                    .append(diTabletAndroid)
                    .append(diPhoneApple)
                    .append(diTabletApple)
                    .append(diPhoneWindows)
                    .append(diTabletWindows)
                    .append(diPhoneAmazon)
                    .append(diTabletAmazon);

                if (bookdata.isbn)
                {
                    $container_left
                        .append($isbn)
                        .append($div({ "html": bookdata.isbn }));
                }

                $container_right.append($hDescription);
                //for (let i = 0; i < lDesc_Paras.length; i++)
                //    lDesc_Paras[i].trim().length > 0 && $container_right.append($("<p>", { "class": "body1", "text": lDesc_Paras[i] }));
                $container_right.append(bookdata.description.long);

                $container
                    .append($container_top)
                    .append($container_left)
                    .append($container_right);

                $cover_wrapper.append($cover_img);

                //#endregion 
                let json = bookdata.custom_data;

                // Adjustments
                if (this.isInShelf(id))
                {
                    switch (this.getShelftype(id))
                    {
                        case this.SHELFTYPE.INVALID:
                        case this.SHELFTYPE.FREEBIE:
                        case this.SHELFTYPE.PURCHASED:
                            $buttons.append($bRead);

                            if (p.seamlessNavigation.currentState.from_state && p.seamlessNavigation.currentState.from_state.stack_array)
                            {
                                let fromStack = p.seamlessNavigation.currentState.from_state.stack_array;
                                if (fromStack[0] == "shop" && fromStack[1] == "checkout")
                                {
                                    if (p.shop.purchase.isFlowCompleted)
                                    {
                                        let $message = $div({ "class": "message success subhead" });
                                        let $icon = p.paper.icon("done");

                                        $message.html(LOCALE["PURCHASE_COMPLETED"]);
                                        $message.prepend($icon);

                                        $buttons.append($message);
                                    }
                                }
                            }

                            if (json)
                            {
                                if (json.allow_print) // && !p.browser.isEdge)
                                {
                                    $container_top.append($bExtra_Print);
                                }
                                if (json.allow_download)
                                {
                                    $container_top.append($bExtra_Download);
                                }
                                if (json.allow_social_mail)
                                {
                                    $container_top.append($bExtra_Mail);
                                }
                            }
                            break;

                        case this.SHELFTYPE.PREVIEW:
                            if (bookdata.type.web == "free_shop")
                            {
                                $buttons.append($bBuyFree);
                            }
                            else
                            {
                                $buttons.append($bBuy);
                            }
                            $buttons.append($bReadSample);
                            break;

                        case this.SHELFTYPE.PENDING:
                            let $message = $div({ "class": "message info subhead" });
                            let $icon = p.paper.icon("info");

                            $message.html(LOCALE["PAYMENT_PENDING"]);
                            $message.prepend($icon);

                            $buttons.append($message);
                            break;
                    }
                }
                else
                {
                    if (bookdata.type.web == "free_shop")
                    {
                        $buttons.append($bBuyFree);
                    }
                    else
                    {
                        $buttons.append($bBuy);
                    }

                    if (bookdata.preview.available && p.seamlessNavigation.FromApp === false)
                        $buttons.append($bAddSample);
                }

                if (json)
                {
                    if (json.allow_social)
                    {
                        $container_top.append($bExtra_ShareMain);
                    }
                }

                $main.append($container);
                $main.append(p.polymer.dummyhero());
            }
        }
    };

    exHelp.extend(module);
})(window);