(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let MaglifyDownloadInit =
    {
        download:
        {
            js_ready: false,
            polymer_ready: false,
            staged: false,

            Init: function MaglifyInit()
            {
                var asyncStart = function ()
                {
                    if (!p.net || !p.common)
                    {
                        // console.log("Defer Init", !!p.shop, !!p.shelf, !!p.net, !!p.user, !!p.search, !!p.books, !!p.common);
                        setTimeout(asyncStart, 100);
                    }
                    else
                    {
                        p.hookAspectWatcher();
                        p.download.js_ready = true;
                        // console.log("JS Ready");
                        p.download.stage();
                    }
                };

                asyncStart();
            },

            onFrameLoaded: function (e)
            {
                // console.log("frame loaded");
                var $this = p.download;
                var iframe = $("#dl-frame")[0];
                var iframeWindow = $(iframe.contentWindow)[0];
                var response = "" + iframeWindow.document.body.innerHTML;

                if (response.length > 0)
                {
                    var response_json = p.jsonParse(response);
                    // console.log(response_json);

                    if (!response_json["error"])
                    {
                        return $this.unstage("UNEXPECTED");
                    }
                }
                else
                {
                    $this.finish();
                }
            },

            stage: function ()
            {
                var $this = p.download;
                if ($this.js_ready && !$this.staged)
                {
                    $this.staged = true;
                    // console.log("Staging");

                    var base = window.location.href.replace(BASEPATH, "");
                    var bits = p.array.sieve(base.split("/"));

                    if (bits.length >= 5)
                    {
                        var valid_to = moment(parseInt(bits[2]) * 1000);
                        var udids = bits[3];
                        var hash = bits[4];

                        if (moment().valueOf() <= valid_to.valueOf())
                        {
                            var iframe = $("#dl-frame");
                            var form = $("#dl-form");

                            iframe.load($this.onFrameLoaded);
                            iframe.ready($this.onFrameLoaded);

                            $("#dl-expires").val(bits[2]);
                            $("#dl-udids").val(udids);
                            $("#dl-hash").val(hash);

                            form.submit();
                        }
                        else
                        {
                            return $this.unstage("EXPIRED");
                        }
                    }
                    else
                    {
                        return $this.unstage("PARAMETER_MISSING");
                    }
                }
            },

            unstage: function (error)
            {
                var msg = "Shit is broken yo!";


                switch (error)
                {
                    case "EXPIRED":
                        msg = LOCALE["DOWNLOAD_PAGE_MSG_ERROR_EXPIRED"];
                        break;
                    default:
                        msg = LOCALE["DOWNLOAD_PAGE_MSG_ERROR_GENERIC"];
                        break;
                }

                $("#dl-error-message").html(msg);
                $("#dl-message-working, #dl-message-error").toggleClass("hidden");

            },

            finish: function ()
            {
                $("#dl-message-working, #dl-message-success").toggleClass("hidden");
            }
        }
    };

    exHelp.extend(MaglifyDownloadInit);
    window.download__inited = true;
})(window);