(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    var s = p.storage("shelf");
    s.books = [];

    let module =
    {
        shelf:
        {
            _storage: p.storage("shelf"),
            booksLoaded: false,
            showAll: true,

            Init: function (stack)
            {
                // console.log("shelf.Init", stack);
                // $("paper-tabs")[0].selected = 0;
                $("#menu-main-wrapper .tabs a.active").removeClass("active");
                $($("#menu-main-wrapper .tabs").children()[0]).children().addClass("active");

                if (!p.books.loaded)
                    p.books.load();

                Trace(["Stack", stack], SYMBOL_SHELF);

                this.waitForLoaded(this.onStack, stack);

                // Hook links
                //if (!BANNER_MODE)
                //{
                //    p.seamlessNavigation.MakeLink("#nav_shelf a", true);
                //    p.seamlessNavigation.MakeLink("#nav_shop a", true);
                //    p.books.hookFilterButton("#nav_filter");

                //    p.material.ripple("#nav_shelf");
                //    p.material.ripple("#nav_shop");
                //}
                //else
                //{
                //    $("ul.nav").addClass("banner");
                //    $("#nav_shelf a").remove();
                //}
                //p.material.ripple("#nav_filter");

                //p.books.hookSortingButtons(function ()
                //{
                //    p.shelf.waitForLoaded(p.shelf.onStack, stack);
                //});

                //$("#filters-sorting .sort-row button").each(function ()
                //{
                //    p.material.ripple($(this), false);
                //});


                p.settings["mode"] = "shelf";
                $("body").removeClass("shop").addClass("shelf");
            },

            waitForLoaded: function (e, args)
            {
                if (p.books.loaded)
                {
                    Trace(["Preloaded"], SYMBOL_SHELF);
                    $.isFunction(e) && e.call(p.shelf, args);
                }
                else
                {
                    setTimeout(p.shelf.waitForLoaded, 100, e, args);
                    Trace(["Waiting for preload"], SYMBOL_SHELF);
                }
            },

            onStack: function (stack)
            {
                try
                {
                    if ($("#menu-shelf, #menu-shop").parent()[0].selected == -1)
                        $("#menu-shelf, #menu-shop").parent()[0].selected = 0;
                } catch (ex) { }

                if (stack[0] == "shelf" || (ENABLE_SHOP && stack[0] == "shop"))
                {
                    if (stack.length > 1)
                    {
                        if (stack[1] == "details")
                        {
                            if (stack.length >= 2)
                            {
                                this.showAll = false;
                                this.showDetails(stack[2]);
                                return;
                            }
                        }
                    }

                    this.showBooks(stack);
                }

                p.common.hideLoader();
                p.common.transitionLoader.hide();
            },

            showBooks: function ($stack)
            {
                // console.log("showBooks", $stack);
                p.common.showLoader();

                var $main = $("#content-primary .content");

                var animation_style = "slide-up";

                if (p.polymer.isInPrimary())
                {
                    //$main.attr(animation_style, "");

                    var animation_hero = p.paper.spinner();
                    p.paper.make_animateable(animation_hero, "hero", "transition_hero");

                    $("#content-tertiary .content").html("")
                        .append(animation_hero);

                    //$("#content-tertiary .content").html($("#content-primary .content").html());

                    p.polymer.animateToTertiary(); // Fade out

                    setTimeout(function ()
                    {
                        //$main.removeAttr(animation_style);
                        p.shelf.doShowBooks($main, $stack);
                    }, 300);
                }
                else
                {
                    p.shelf.doShowBooks($main);
                }
            },

            doShowBooks: function ($main, $stack)
            {
                // console.log("doShowBooks");
                $main.html("");

                if (!p.sonderlocke("BANNER_ONLY_ON_ALL") || (p.sonderlocke("BANNER_ONLY_ON_ALL") && p.books.getCurrentCategory() && p.books.getCurrentCategory().id == -1))
                {
                    var bannerPlaceholder = $("<div>", { "id": "shelf-banners" });
                    $main.append(bannerPlaceholder);
                    p.banners.show(bannerPlaceholder, "shelf");
                }

                var $container = $div({ "class": "book-container" });
                $main.append($container);

                var has = false;
                p.books.eachShelf(function (book)
                {
                    has = true;
                    $container.append(p.books.makeThumbnail(book.udid));
                }, false);

                if (!has)
                {
                    if (SONDERLOCKE && (SONDERLOCKE["UNREGISTERED_SHELFTEXT"] || SONDERLOCKE["EMPTY_SHELFTEXT"]))
                    {
                        if (p.user.is_logged_in)
                        {
                            $container.append(p.common.customTextArrayParse(SONDERLOCKE["EMPTY_SHELFTEXT"]));
                        }
                        else
                        {
                            $container.append(p.common.customTextArrayParse(SONDERLOCKE["UNREGISTERED_SHELFTEXT"]));
                        }
                    }
                    else
                    {
                        $container.append($("<h1>", { "html": LOCALE["ERROR_EMPTY_SHELF_TITLE"] }));
                        $container.append($("<p>", { "html": LOCALE["ERROR_EMPTY_SHELF_TEXT"] }));
                    }
                }

                p.books.filterBookDivs();
                $main.append(p.polymer.dummyhero());

                p.polymer.animateToPrimary();

                p.common.hideLoader();
                p.common.transitionLoader.hide();
            },

            showDetails: function (id)
            {
                p.common.showLoader();

                Trace(["Details for item", id], SYMBOL_SHELF);
                p.books.makeDetails(id);

                p.polymer.animateToSecondary();

                //if (BANNER_MODE)
                //{
                //    $("#nav_shop, #nav_filter").remove();
                //    try{
                //        $("#nav_shelf").html(p.books.getFirstSelectedTag().name).removeClass("hidden");
                //    } catch (ex) { }
                //    $(".tag").remove();
                //}

                p.common.hideLoader();
                p.common.transitionLoader.hide();
            }
        }
    };


    exHelp.extend(module);
})(window);