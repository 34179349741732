(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        net:
        {
            reqId: 0,

            Request: function (cmd, payload, onFinished, callback_data)
            {
                /// <summary>
                /// Sends a request to the WebAPI
                /// </summary>
                /// <param name="cmd" type="Array">Array of command callstack, ex.: ["get", "user"] == get/user</param>
                /// <param name="payload" type="Object">Object with payload data</param>
                /// <param name="onFinished" type="Function">Callback when the request finishes</param>
                ///

                var basePayload = { "platform": "web", "class": "web", "from-app": p.seamlessNavigation.FromApp };
                $.extend(basePayload, payload);

                var data =
                {
                    cmd: cmd,
                    payload: basePayload,
                    "_": (new Date()).getTime()
                };

                var $id = p.net.reqId++;

                Trace(["> Request ID", $id, "CMD", p.is.array(cmd) ? cmd.join("/") : cmd, "Payload", data], SYMBOL_NETWORK);

                $.ajax(BASEPATH + "/api/web/",
                    {
                        data: data,
                        type: "POST",
                        complete: function (e, status)
                        {
                            var $jsonData = p.jsonParse(e.responseText);

                            Trace(["< Response ID", $id, "Payload", $jsonData], SYMBOL_NETWORK);

                            if (status == "success")
                            {
                                if (onFinished && $.isFunction(onFinished))
                                    onFinished(true, $jsonData, callback_data);
                            }
                            else
                            {
                                if (onFinished && $.isFunction(onFinished))
                                    onFinished(false, $jsonData, callback_data);
                            }
                        }
                    });
            }
        }
    };

    exHelp.extend(module);
})(window);