(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "moment",
        "reader__inited",
        "download__inited",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let MaglifyInit =
    {
        init:
        {
            Init: function MaglifyInit()
            {
                // if url contains /reader/
                if (window.location.href.indexOf("/reader/") > -1)
                {
                    p.reader.init();
                    return;
                }
                // if url contains /download/
                if (window.location.href.indexOf("/download/?/") > -1)
                {
                    p.download.Init();
                    return;
                }

                var langKey = p.e("html").getAttr("lang");
                p.locale.set(langKey);
                p.storage("locale")[langKey] = LOCALE;
                //console.time("Init");
                p.common.showLoader();

                var asyncStart = function ()
                {
                    if (!p.shop || !p.shelf || !p.net || !p.user || !p.search || !p.books || !p.common)
                    {
                        if (p.common && p.common.showLoader)
                        {
                            if (p.common._count == 0) { p.common.showLoader(); }
                        }

                        // console.log("Defer Init", !!p.shop, !!p.shelf, !!p.net, !!p.user, !!p.search, !!p.books, !!p.common);
                        setTimeout(asyncStart, 100);
                    }
                    else
                    {
                        p.hookAspectWatcher();
                        p.subscribe("stack", p.init.onStack);
                        p.subscribe("user_status_get", p.init.onUser);
                        //document.body.onscroll = p.init.onScroll;
                        $("body").on("scroll", p.init.onScroll);
                        $(window).on("resize", p.init.onResize);
                        window.addEventListener("polymer-ready", p.init.PolymerInit);

                        p.user.Init();
                        p.seamlessNavigation.Hook();
                        p.tracking.init();

                        p.common.hideLoader();
                        //console.timeEnd("Init");
                    }
                };

                asyncStart();
                // Dependency checks
                //if (!p.shop || !p.shelf || !p.net || !p.user || !p.search)
                //    return alert("Missing files");
            },

            PolymerInit: function (e)
            {
                //console.log("Polymer ready", e);
                p.trigger("polymer-ready");

                Trace(["Polymer Loaded Fixing animators"], SYMBOL_NETWORK);

                p.polymer.AnimatorFix("#animator-content");
                p.polymer.AnimatorFix("#animator-categories");
            },

            onUser: function Init_Event_onUser(e)
            {
                Trace(["Status updated", e], SYMBOL_USER);
                p.user.renderStatus();
            },

            onStack: function Init_Event_onStack(e)
            {
                // console.log("Stack updated", e, e[0]);
                //$("body").removeClass("branded");
                if (e && e.length > 0)
                {
                    switch (e[0])
                    {
                        case "shop":
                            if (!ENABLE_SHOP)
                                p.shelf.Init(e);
                            else
                                p.shop.Init(e);
                            p.books.initSorting();
                            p.books.loadCategories();
                            p.books.updateCategorySelectionFade();
                            break;
                        case "shelf":
                            p.shelf.Init(e);
                            p.books.initSorting();
                            p.books.loadCategories();
                            p.books.updateCategorySelectionFade();
                            break;
                        case "login":
                        case "register":
                        case "recover":
                            p.user.PrepareForms(e);
                            break;
                        case "account":
                            //p.user.manage.Init(e);
                            p.user.account.Init();
                            p.books.loadCategories();
                            break;
                    }
                }

                $("[autolink]").each(function StackLinkifier() { p.seamlessNavigation.Link(this); });

                if (!$("#termslink").prop("inited"))
                {

                    $("#termslink").on("click", function (e)
                    {
                        p.showTerms();

                        e && e.preventDefault && e.preventDefault();
                        e && e.stopPropagation && e.stopPropagation();
                    });

                    $("#termslink").prop("inited", true);
                }

                if (!$("#menu-toggle-drawer").prop("inited"))
                {
                    $("#menu-toggle-drawer").on("click", function ()
                    {
                        //document.querySelector("#animator-drawer").selected = 0;
                    });

                    $("#menu-toggle-drawer").prop("inited", true);
                }

                p.seamlessNavigation.Link_State($("#shelf-button"),
                    {
                        "cmd": ["shop"],
                        "stack": "/shelf/",
                        "callback": "p.books.categoriesDoFade = true;"
                    });

                p.seamlessNavigation.Link_State($("#category-button"),
                    {
                        "cmd": ["shop"],
                        "stack": "/shelf/"
                    });

                p.seamlessNavigation.Link_State($("#shop-button"),
                    {
                        "cmd": ["shop"],
                        "stack": "/shop/",
                        "callback": "p.books.categoriesDoFade = true;"
                    });

                p.search.Init();
                p.user.renderStatus();

                if (p.localStorage.get("cookies_accepted") != 1 && $("#cookieinfo").length == 0)
                {
                    var $cookieInfo = $div({ "class": "cookie-info", "id": "cookieinfo" });
                    var $termsLink = "<a href='#' id='cookie-terms'>" + LOCALE["TERMS"] + "</a>";
                    var $para = $("<p>", { "class": "body1 invert white-text", "html": LOCALE["COOKIE_INFO"].replace("%%TERMS%%", $termsLink) });
                    var $acceptCookies = $("<a>");
                    $acceptCookies.attr("class", "btn fill waves-effect");
                    $acceptCookies.html(LOCALE["COOKIE_ACCEPT"]);
                    $acceptCookies.attr("self-center");


                    $cookieInfo.append($para);
                    $cookieInfo.append($acceptCookies);

                    $("body").append($cookieInfo);
                    $("#cookie-terms").on("click", function (e)
                    {
                        p.showTerms();

                        e && e.preventDefault && e.preventDefault();
                        e && e.stopPropagation && e.stopPropagation();
                    });

                    $acceptCookies.on("click", function ()
                    {
                        e && e.preventDefault && e.preventDefault();
                        e && e.stopPropagation && e.stopPropagation();
                        p.localStorage.set("cookies_accepted", 1);
                        setTimeout(function ()
                        {
                            $cookieInfo.remove();
                        }, 300);
                    });
                }

                //p.common.hideLoader();
            },

            onScroll: function Init_Event_onScroll()
            {
            },

            onResize: function ()
            {
                if ($("body").width() > 970)
                {
                    $(".book-details").attr("horizontal", "");
                }
                else
                {
                    $(".book-details").removeAttr("horizontal");
                }
            }
        }
    };

    exHelp.extend(MaglifyInit);
    MaglifyInit.init.Init();
})(window);