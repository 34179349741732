(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;
    let s = p.storage("store");
    s.books = [];

    let module =
    {
        shop:
        {
            _storage: p.storage("store"),
            booksLoaded: false,
            ownedLoaded: false,
            showAll: true,

            Init: function (stack)
            {
                //$("#nav_shop").addClass("active");
                // $("paper-tabs")[0].selected = 1;
                $("#menu-main-wrapper .tabs a.active").removeClass("active");
                $($("#menu-main-wrapper .tabs").children()[1]).children().addClass("active");

                if (!p.books.loaded)
                    p.books.load();

                Trace(["Stack", stack], SYMBOL_STORE);

                this.waitForLoaded(this.onStack, stack);

                //p.debugpanel(window.location.href);

                // Hook links
                //if (!BANNER_MODE)
                //{
                //    p.seamlessNavigation.MakeLink("#nav_shelf a", true);
                //    p.seamlessNavigation.MakeLink("#nav_shop a", true);
                //    p.books.hookFilterButton("#nav_filter");
                //    p.material.ripple("#nav_shelf");
                //    p.material.ripple("#nav_shop");
                //}
                //else
                //{
                //    $("ul.nav").addClass("banner");
                //    $("#nav_shelf a").remove();
                //}
                //p.material.ripple("#nav_filter");

                //p.books.hookSortingButtons(function ()
                //{
                //    p.shop.waitForLoaded(p.shop.onStack, stack);
                //});

                //$("#filters-sorting .sort-row button").each(function ()
                //{
                //    p.material.ripple($(this), false);
                //});

                p.settings["mode"] = "shop";
                $("body").removeClass("shelf").addClass("shop");
            },

            waitForLoaded: function (e, args)
            {
                if (p.books.loaded)
                {
                    Trace(["Preloaded"], SYMBOL_STORE);
                    $.isFunction(e) && e.call(p.shop, args);
                }
                else
                {
                    setTimeout(p.shop.waitForLoaded, 100, e, args);
                    Trace(["Waiting for preload"], SYMBOL_STORE);
                }
            },

            onStack: function (stack)
            {
                if (stack.length > 0)
                {
                    if (stack.length > 1)
                    {
                        switch (stack[1])
                        {
                            case "details":
                                this.showAll = false;
                                this.showDetails(stack[2]);
                                //p.books.hookFilterButton("#nav_filter");
                                break;
                            case "checkout":
                                this.purchase.checkout(stack);
                                break;
                        }
                    }
                    else
                    {
                        this.showBooks();
                        //p.books.hookFilterButton("#nav_filter");
                    }
                }

                p.common.hideLoader();
                p.common.transitionLoader.hide();
            },

            showBooks: function ()
            {
                p.common.showLoader();

                let $main = $("#content-primary .content");

                let animation_style = "slide-up";

                if (p.polymer.isInPrimary())
                {
                    //$main.attr(animation_style, "");

                    let animation_hero = p.paper.spinner();
                    p.paper.make_animateable(animation_hero, "hero", "transition_hero");

                    $("#content-tertiary .content").html("")
                        .append(animation_hero);

                    //$("#content-tertiary .content").html($("#content-primary .content").html());

                    p.polymer.animateToTertiary(); // Fade out

                    setTimeout(function ()
                    {
                        //$main.removeAttr(animation_style);
                        p.shop.doShowBooks($main);
                    }, 300);
                }
                else
                {
                    p.shop.doShowBooks($main);
                }

            },

            doShowBooks: function ($main)
            {
                $main.html("");

                let $container = $div({ "class": "book-container" });
                $main.append($container);

                let has = false;
                let doRecommendations = false;
                p.books.eachShop(function (book)
                {
                    has = true;
                    $container.append(p.books.makeThumbnail(book.udid));
                }, false);

                if (!has && doRecommendations)
                {
                    $container.append($("<h1>", { "html": LOCALE["ERROR_EMPTY_SHOP_TITLE"] }));
                    $container.append($("<p>", { "html": LOCALE["ERROR_EMPTY_SHOP_TEXT"] }));

                    // Build suggestion

                    let suggestions = [];

                    // Go through all books in the shelf
                    p.books.eachShelf(function (book)
                    {
                        // get reader state data
                        let readerData = p.ls.get("{0}_{1}_data".format(p.user.userdata.username, book.udid));
                        // If we have reader state data
                        if (readerData && readerData.length > 0)
                        {
                            // parse it
                            readerData = p.jsonParse(readerData);

                            // Get progress
                            let percent = p.math.Percentage.XofY(readerData.currentPage, book.pagecount.web);

                            // If we have started reading
                            if (percent > 0)
                            {
                                // Push to suggestions with progress
                                suggestions.push({
                                    book: book,
                                    percent: percent
                                });
                            }
                        }
                    }, false);

                    // If we have suggestions
                    if (suggestions.length > 0 && doRecommendations)
                    {
                        // Sort them by read-progress DESC
                        suggestions.sort(function (a, b) { return b.percent - a.percent; });

                        // Add "Suggestions" title
                        $container.append($("<h2>", { "html": LOCALE["ERROR_EMPTY_SHOP_SUGGESTION"] }));

                        // Go through all suggestions
                        for (let i = 0; i < suggestions.length; i++)
                        {
                            // Add thumbnail for suggestions
                            let book = suggestions[i].book;
                            $container.append(p.books.makeThumbnail(book.udid));
                        }
                    }
                }

                $main.append(p.polymer.dummyhero());
                p.books.filterBookDivs();

                p.polymer.animateToPrimary();

                p.common.hideLoader();
                p.common.transitionLoader.hide();
            },

            fromAppTriggered: false,

            showDetails: function (id)
            {
                p.common.showLoader();

                Trace(["Details for item", id], SYMBOL_STORE);
                p.books.makeDetails(id);

                p.polymer.animateToSecondary();

                //if (p.seamlessNavigation.FromApp)
                //    $(".actions").append(DEBUGVALUE);

                if (p.seamlessNavigation.FromApp || p.seamlessNavigation.GetDeferredAction() == "purchase")
                {
                    if (!p.shop.fromAppTriggered)
                    {
                        p.shop.fromAppTriggered = true;
                        $("#button-buy").trigger("click");
                    }
                }


                //if (BANNER_MODE)
                //{
                //    $("#nav_shop, #nav_filter").remove();
                //    $("#nav_shelf").html(p.books.getFirstSelectedTag().name).removeClass("hidden");
                //    $(".tag").remove();
                //}

                p.common.hideLoader();
                p.common.transitionLoader.hide();
            },

            purchase:
            {
                inFlow: false,
                isFlowCompleted: false,
                current: null,

                get _stateTemplate()
                {
                    return {
                        id: null,
                        internal_id: null,
                        udid: null,
                        name: null,
                        is_free: null,
                        price: null,
                        price_net: null,
                        price_vat: null,
                        price_gross: null,
                        price_discount: null,
                        price_subtotal: null,
                        price_total: null,
                        method: null,
                        set_default: null,
                        public_id: null,
                        provider_id: null,
                        provider_user: null,
                        provider_data: null,
                        checkout_action: null,
                        transaction: null,
                        book: null,
                        coupon: null,
                        checkout_shown: false,
                        custom_fields: null,
                        terms_read: false,
                        i: 0,
                        max: 5
                    };
                },

                _callback: null,
                _button_controller: null,
                _modal: null,
                _cancelled: false,

                _elements:
                {
                    checkout:
                    {
                        container: null,
                        spinner: null,
                        text: null,
                        subtext: null,
                        cover: null,
                        button: null
                    }
                },

                start: function (id, data)
                {
                    Trace([id, "Starting purchase"], "STORE");

                    let $this = p.shop.purchase;
                    if ($this.current != null)
                    {
                        $this.cancel(null, true);
                    }

                    $this.current = $this._stateTemplate;
                    $this.current.id = id;
                    $this.current.name = data.name;
                    $this._callback = data.callback;
                    $this._button_controller = data.button;
                    $this._cancelled = false;

                    if ($this._button_controller)
                    {
                        p.form_helper.setDisabledButton($this._button_controller, true, false);
                    }

                    p.net.Request(["do", "purchase", "gate", $this.current.id], {}, $this._onGate);
                },

                start_reading_sample: function (id, data)
                {
                    Trace([id, "Starting reading sample acquisition"], "STORE");

                    let $this = p.shop.purchase;
                    if ($this.current != null)
                    {
                        $this.cancel();
                    }

                    $this.current = $this._stateTemplate;
                    $this.current.id = id;
                    $this.current.name = data.name;
                    $this._callback = data.callback;
                    $this._button_controller = data.button;
                    $this._cancelled = false;

                    if ($this._button_controller)
                    {
                        p.form_helper.setDisabledButton($this._button_controller, true, true);
                    }

                    if (p.user.is_logged_in)
                    {
                        setTimeout(function ()
                        {
                            p.net.Request(["do", "add-shelf", $this.current.id], {}, $this._onAddToShelf);
                        }, 1000);
                    }
                    else
                    {
                        let $askImage = $("<img>", {
                            "class": "dynamic loading left cover",
                            "src": "{0}/api/web/?cmd=get/file/cover&udid={1}&size=medium".format(BASEPATH, $this.current.id)
                        });
                        $askImage.on("load", function ()
                        {
                            $(this).removeClass("loading");
                        });

                        p.handleAsk(
                            {
                                title: LOCALE["LOGIN_PROMPT"],
                                text: LOCALE["LOGIN_PROMPT_ADDSAMPLE"].format($this.current.name),
                                content_before: $askImage
                            },
                            function ()
                            {
                                p.seamlessNavigation.Push({ cmd: ["login"], stack: "/login/", title: LOCALE["LOGIN"] });
                            },
                            function ()
                            {
                                $this.cancel();
                            });

                        return;
                    }
                },

                checkout: function (stack)
                {
                    let $this = p.shop.purchase;
                    if ($this.inFlow)
                        return;

                    $this.inFlow = true;
                    p.seamlessNavigation.currentState.cmd = ["shop"];
                    p.seamlessNavigation.currentState.cmd_string = "shop";
                    p.seamlessNavigation.currentState.animator = "#animator-content";
                    p.seamlessNavigation.currentState.selection = 2;

                    $this._elements.checkout.container = $div({ "id": "checkout-container" });
                    $this._elements.checkout.spinner = p.paper.spinner();
                    $this._elements.checkout.text = $div({ "id": "checkout-info", "html": LOCALE["PURCHASE_GET_TRANSACTION"], "class": "title" });
                    $this._elements.checkout.subtext = $div({ "id": "checkout-subinfo", "class": "caption" });
                    $this._elements.checkout.cover = $("<img>", { "class": "dynamic loading" });
                    $this._elements.checkout.button = p.paper.button("PLACEHOLDER", false, false, false, "branded");

                    // p.paper.make_animateable($this._elements.checkout.spinner, "cross-fade");
                    // p.paper.make_animateable($this._elements.checkout.text, "cross-fade");
                    // p.paper.make_animateable($this._elements.checkout.subtext, "cross-fade");
                    // p.paper.make_animateable($this._elements.checkout.button, "cross-fade");

                    $this._elements.checkout.container
                        .append($this._elements.checkout.spinner)
                        .append($this._elements.checkout.text)
                        .append($this._elements.checkout.subtext)
                        .append($this._elements.checkout.button);
                    //.append($this._elements.checkout.cover);

                    $("#content-tertiary .content").append($this._elements.checkout.container);
                    p.polymer.AnimateTo("#animator-content", 2);

                    let $action = stack[2];
                    let $target = stack[3];
                    let $ppData = stack[stack.length - 1];

                    // console.log($action, $target, $ppData);


                    $this.current = $this._stateTemplate;
                    $this.current.provider_data = $this.decodePaypalData($ppData);
                    $this.current.checkout_action = $action;
                    $this.current.id = $target;

                    //asda;
                    p.net.Request(["get", "transaction"], { "public_id": $target }, $this._onTransactionCheckout);
                },

                cancel: function (error, close)
                {
                    let $this = p.shop.purchase;

                    Trace([$this.current.id, "Cancelling", error], "STORE");

                    if (close && $this._modal && $this._modal.length > 0)
                    {
                        $this._modal.modal("close");
                    }

                    if ($this._elements.checkout.container)
                    {
                        $this._elements.checkout.container.addClass("completed");
                        $this._elements.checkout.spinner.removeAttr("active");
                        $this._elements.checkout.text.html(LOCALE["PURCHASE_ERRORED"]);

                        if ($this.current.book)
                        {
                            $this._elements.checkout.button.html(LOCALE["PURCHASE_BACK"].format($this.current.book.name));

                            $this._elements.checkout.button.attr("href", "/shop/details/{0}/{1}/".format($this.current.book.udid, encodeURIComponent($this.current.book.name.replace(/[^a-zA-Z0-9]/ig, "_"))));
                            $this._elements.checkout.button.attr("cmd", "shop");
                            $this._elements.checkout.button.attr("title", $this.current.book.name);
                            p.seamlessNavigation.Link($this._elements.checkout.button);
                        }
                        else
                        {
                            $this._elements.checkout.button.html(LOCALE["ERROR_LABEL_BACKTOSHOP"]);
                        }
                    }

                    if ($this._callback && p.is.function($this._callback))
                    {
                        if (error)
                        {
                            let error_data =
                            {
                                error: error,
                                tag: "SHOP_PURCHASE"
                            };

                            $this._callback(false, error_data);
                        }
                        else
                        {
                            $this._callback(true);
                        }
                    }

                    if ($this._button_controller)
                    {
                        p.form_helper.setDisabledButton($this._button_controller, false, false);
                    }
                },

                decodePaypalData: function (str)
                {
                    let $str = "" + str;
                    let bytes = $str.split("&");
                    let outarr = [];
                    for (let i = 0; i < bytes.length; i++)
                    {
                        if (bytes[i].trim().length == 0)
                            continue;

                        let bits = bytes[i].split("=");
                        outarr[bits[0]] = bits[1];
                    }

                    return outarr;
                },

                _onAddToShelf: function (success, data)
                {
                    let $this = p.shop.purchase;
                    if (success && data && data.success)
                    {
                        $this.cancel();
                    }
                    else
                    {
                        $this.cancel(data.error);
                    }
                },

                _performCheckout: function ()
                {
                    let $this = p.shop.purchase;
                    if ($this.current.checkout_action == "cancel")
                    {
                        $this._elements.checkout.text.html(LOCALE["PURCHASE_CANCELLING"]);
                        $this._checkoutCancel();
                    }
                    else if ($this.current.checkout_action == "execute")
                    {
                        $this._elements.checkout.text.html(LOCALE["PURCHASE_PURCHASING"]);
                        $this._checkoutExecute();
                    }
                },

                _checkoutExecute: function ()
                {
                    let $this = p.shop.purchase;
                    if ($this.current.checkout_action == "execute")
                    {
                        $this._elements.checkout.text.html(LOCALE["PURCHASE_PURCHASING_PAYPAL"]);

                        if ($this.current.i < $this.current.max)
                        {
                            p.net.Request(["do", "purchase", "execute-paypal", $this.current.book.udid],
                                {
                                    "public_id": $this.current.transaction.public_id,
                                    "payer_id": $this.current.provider_data.PayerID
                                }, $this._onCheckoutExecute);

                            $this.current.i++;
                        }
                        else
                        {
                            p.handleError({ "error": "PURCHASE_PAYPAL_PENDING" });
                            $this._checkoutCancel();
                        }
                    }
                    else
                    {
                        $this.cancel("INVALID_STATE");
                    }
                },

                _checkoutCancel: function ()
                {
                    let $this = p.shop.purchase;
                    if ($this.current.checkout_action == "cancel")
                    {
                        $this._elements.checkout.text.html(LOCALE["PURCHASE_CANCELLING"]);

                        p.net.Request(["do", "purchase", "cancel", $this.current.book.udid],
                            {
                                "public_id": $this.current.transaction.public_id
                            }, $this._onCheckoutCancel);
                    }
                    else
                    {
                        $this.cancel("INVALID_STATE");
                    }
                },

                _onCheckoutExecute: function (success, data)
                {
                    let $this = p.shop.purchase;

                    if (success && data)
                    {
                        if (data.error && (data.error == "PAYMENT_PENDING" || data.error == "PAYPAL_ERROR"))
                        {
                            $this.current.checkout_action == "cancel";
                            $this._checkoutExecute();
                        }
                        else
                        {
                            $this.isFlowCompleted = true;
                            p.books.invalidateLoadState();
                            if (p.seamlessNavigation.FromApp)
                            {
                                p.seamlessNavigation.Push({ cmd: "close", stack: "/close/" });
                                return location.reload();
                            }
                            else
                            {
                                p.seamlessNavigation.Push({ cmd: "shop", "stack": "/shelf/details/{0}/{1}/".format($this.current.book.udid, encodeURIComponent($this.current.book.name.replace(/[^a-zA-Z0-9]/ig, "_"))) });
                            }
                        }
                    }
                },

                _onCheckoutCancel: function (success, data)
                {
                    // ignore state here, either it didn't work and no purchase
                    // was actually made, or it did work and it was cancelled
                    let $this = p.shop.purchase;
                    if (p.seamlessNavigation.FromApp)
                    {
                        p.seamlessNavigation.Push({ cmd: "cancel", stack: "/cancel/" });
                        return location.reload();
                    }
                    else
                    {
                        $this._elements.checkout.text.html(LOCALE["PURCHASE_CANCELLED"]);
                        $this._elements.checkout.subtext.html(LOCALE["PURCHASE_CANCELLED_SUB"]);

                        $this._elements.checkout.button.html(LOCALE["PURCHASE_BACK"].format($this.current.book.name));

                        $this._elements.checkout.button.attr("href", "/shop/details/{0}/{1}/".format($this.current.book.udid, encodeURIComponent($this.current.book.name.replace(/[^a-zA-Z0-9]/ig, "_"))));
                        $this._elements.checkout.button.attr("cmd", "shop");
                        $this._elements.checkout.button.attr("title", $this.current.book.name);
                        p.seamlessNavigation.Link($this._elements.checkout.button);

                        $this._elements.checkout.container.addClass("completed");
                        $this._elements.checkout.spinner.removeAttr("active");
                    }
                },

                _onTransactionCheckout: function (success, data)
                {
                    let $this = p.shop.purchase;
                    if (success && data && data.success)
                    {
                        $this.current.transaction = data.transaction;
                        $this.current.internal_id = $this.current.transaction.product_id;
                        p.net.Request(["get", "book_internal", $this.current.internal_id], {}, $this._onBookInternal);
                    }
                    else
                    {
                        $this.cancel(data.error);
                    }
                },

                _onBookInternal: function (success, data)
                {
                    let $this = p.shop.purchase;
                    if (success && data && data.success)
                    {
                        $this.current.book = data.book;

                        $this._elements.checkout.cover.attr("src", "{0}/api/web/?cmd=get/file/cover&udid={1}&size=medium".format(BASEPATH, $this.current.book.udid));
                        $this._elements.checkout.cover.on("load", function () { $(this).removeClass("loading"); });

                        $this._elements.checkout.spinner = p.paper.make_animateable($this._elements.checkout.spinner, "hero", "cover" + $this.current.book.udid);
                        $this._elements.checkout.text = p.paper.make_animateable($this._elements.checkout.text, "hero", "title" + $this.current.book.udid);
                        $this._elements.checkout.subtext = p.paper.make_animateable($this._elements.checkout.subtext, "hero", "author" + $this.current.book.udid);
                        $this._elements.checkout.button = p.paper.make_animateable($this._elements.checkout.button, "hero", "action" + $this.current.book.udid);

                        $this._performCheckout();
                    }
                    else
                    {
                        $this.cancel(data.error);
                    }
                },

                _lockModal: function (lock)
                {
                    let $this = p.shop.purchase;

                    p.form_helper.setDisabledButton("#checkout-cancel", lock);
                    p.form_helper.setDisabledButton("#checkout-do", lock, lock);
                    p.form_helper.setDisabled("#checkout-method-dropdown", lock);

                    if ($("#checkout-coupon-add").length > 0)
                    {
                        p.form_helper.setDisabledButton($("#checkout-coupon-add"), lock);
                        p.form_helper.setDisabled("#checkout-coupon-code", lock);
                    }

                    if ($("#checkout-invoice-address-form").length > 0)
                    {
                        for (let fieldName in $this.current.custom_fields)
                        {
                            let field = $this.current.custom_fields[fieldName];
                            p.form_helper.setDisabled(field.control, lock);
                        }
                    }

                    if (lock)
                        $("#checkout-method-checker").addClass("closed");
                    else
                        $("#checkout-method-checker").removeClass("closed");
                },

                _tryUnlockPayButton: function ()
                {
                    let $this = p.shop.purchase;
                    let btn = $("#checkout-do");

                    let unlock = true;

                    if ($this.current.terms_read != true)
                        unlock = false;

                    switch ($this.current.method)
                    {
                        case "paypal":
                        case "invoice":
                            break;
                        default:
                            unlock = false;
                            break;
                    }

                    if ($this.current.terms_read && ($this.current.method == "paypal" || $this.current.method == "invoice"))
                        btn.removeClass("disabled");
                    else
                        btn.addClass("disabled");
                },

                _onTransaction: function (success, data)
                {
                    let $this = p.shop.purchase;
                    if (success && data && data.success)
                    {
                        if ($this.current.is_free || data.finished)
                        {
                            // Cancel without error and instruct to close modal
                            return $this.cancel(false, true);
                        }
                        else
                        {
                            if ($this.current.method == "paypal")
                            {
                                $("#checkout-loader").addClass("working");
                                $("#checkout-loader-text").html(LOCALE["PURCHASE_REDIRECT_PAYPAL"]);
                                return location.assign(data.redirect);
                            }
                            else if ($this.current.method == "invoice")
                            {
                                p.user.Init(); // reload user data
                                return p.handleInfo(
                                    {
                                        title: LOCALE["PURCHASE_CHECKOUT_INVOICE"],
                                        text: LOCALE["PURCHASE_INVOICE_SUCCESS"]
                                    }, function (e)
                                {
                                    $this.cancel(false, true);
                                });
                            }
                        }
                    }

                    // We fell through => Errorz

                    if ($this.current.method == "paypal")
                    {
                        $this.cancel("ERROR_PAYPAL_ERROR", true);
                    }
                    else if ($this.current.method == "invoice")
                    {
                        $this.cancel("ERROR_INVOICE_ERROR", true);
                    }
                    else
                    {
                        $this.cancel("ERROR_PAYMENT_ERROR", true);
                    }
                },

                _pay: function ()
                {
                    let $this = p.shop.purchase;
                    if ($this.current != null)
                    {
                        $this._lockModal(true);

                        if ($this.current.is_free)
                        {
                            p.net.Request(["do", "purchase", "buy-free", $this.current.id], {}, $this._onTransaction);
                        }
                        else
                        {
                            if ($this.current.set_default)
                            {
                                p.ls.set("default_payment_method", $this.current.method);
                            }

                            if ($this.current.method == "paypal")
                            {
                                p.net.Request(["do", "purchase", "buy-paypal", $this.current.id], { "coupon": $this.current.coupon }, $this._onTransaction);
                            }
                            else if ($this.current.method == "invoice")
                            {
                                let emptyFields = [];
                                let filledFields = {};
                                for (let fieldName in $this.current.custom_fields)
                                {
                                    let field = $this.current.custom_fields[fieldName];
                                    if (field.settings.required)
                                    {
                                        if (p.form_helper.value(field.control).length == 0)
                                        {
                                            emptyFields.push(field.control);
                                        }
                                    }

                                    filledFields[fieldName] = p.form_helper.value(field.control);

                                    p.form_helper.setError(field.control, null);
                                }

                                if (emptyFields.length > 0)
                                {
                                    for (let i = 0; i < emptyFields.length; i++)
                                        p.form_helper.setError(emptyFields[i], LOCALE["ERROR_EMPTY_FIELD"]);

                                    $this._lockModal(false);
                                    return;
                                }

                                // console.log(filledFields);

                                p.net.Request(["do", "purchase", "buy-invoice", $this.current.id],
                                    {
                                        "coupon": $this.current.coupon,
                                        "custom_fields": filledFields
                                    }, $this._onTransaction);
                            }
                            else
                            {
                                $this.cancel("ERROR_INVALID_PAYMENT_METHOD", true);
                            }
                        }
                        //$("#checkout-loader").addClass("working");
                    }
                },

                _methodSelected: function (e)
                {
                    // if (e.detail.isSelected)
                    // {
                    let value = $(this).val(); //children(".dropdown").children("core-menu")[0].selected;
                    let $this = p.shop.purchase;
                    $this.current.method = value;
                    $("#checkout-method-checker").removeClass("closed");

                    if (value == "invoice")
                        $("#checkout-invoice-address").removeClass("hidden");
                    else
                        $("#checkout-invoice-address").addClass("hidden");


                    p.defer(function ()
                    {
                        // $this._resizeModal();
                        $this._tryUnlockPayButton();
                    }, null, null, 100);
                    // }
                },

                _setDefaultChecked: function (e)
                {
                    let $this = p.shop.purchase;
                    $this.current.set_default = $(this)[0].checked;
                    // console.log($(this)[0].checked); 
                },
                _setTermsChecked: function (e)
                {
                    let $this = p.shop.purchase;
                    $this.current.terms_read = $(this)[0].checked; //this.checked;
                    $this._tryUnlockPayButton();
                },

                _couponInputChanged: function (e)
                {
                    let $this = p.shop.purchase;

                    if (e.keyCode == 13)
                    {
                        $this._couponAdd();
                    }
                    else
                    {
                        $(this).val($(this).val().toUpperCase());
                    }
                },

                _couponCheck: function (coupon, onresult)
                {
                    let $this = p.shop.purchase;
                    p.net.Request(["do", "purchase", "couponcheck", $this.current.id], { "coupon": coupon },
                        function (success, data)
                        {
                            if (success && data && data.success)
                            {
                                let purchase_data = data["purchase-info"];
                                let purchase_calculation = data["purchase-calculation"];
                                let coupon_data = data["coupon-info"];

                                $this.current.price = purchase_calculation.prices.item;
                                $this.current.price_discount = purchase_calculation.prices.discount;
                                $this.current.price_subtotal = purchase_calculation.prices.subtotal;
                                $this.current.price_vat = purchase_calculation.prices.vat;
                                $this.current.price_total = purchase_calculation.prices.total;
                                $this.current.coupon = coupon_data;

                                onresult(true);
                            }
                            else
                            {
                                onresult(false);
                            }
                        });
                },

                _couponAdd: function ()
                {
                    let $this = p.shop.purchase;
                    let code_input = $("#checkout-coupon-code");
                    let code = p.form_helper.value(code_input);

                    if (code.length == 0)
                    {
                        p.form_helper.setError(code_input, LOCALE["PURCHASE_COUPON_EMPTY"]);
                        return;
                    }

                    p.form_helper.setError(code_input, null);
                    p.form_helper.setDisabledButton($("#checkout-coupon-add"), true, true);
                    p.form_helper.setDisabled(code_input, true);

                    $this._couponCheck(code, function (success)
                    {
                        if (success)
                        {
                            let cName = $this.current.coupon.name + " ({0})";
                            let cVal = $this.current.coupon.value;
                            let cDiscount = "-{0} {1}";

                            if ($this.current.coupon.is_fixed)
                            {
                                cName = cName.format("{0} {1}".format(cVal.toFixed(2), LOCALE["CURRENCY_SIGN"]));
                            }
                            else
                            {
                                cName = cName.format("{0}%".format(cVal.toFixed(0)));
                            }
                            cDiscount = cDiscount.format($this.current.price_discount.toFixed(2), LOCALE["CURRENCY_SIGN"]);

                            $("#ct-subtotal").html("{0} {1}".format($this.current.price_subtotal.toFixed(2), LOCALE["CURRENCY_SIGN"]));

                            $("#ct-vat-price").html("{0} {1}".format($this.current.price_vat.toFixed(2), LOCALE["CURRENCY_SIGN"]));

                            $("#ct-discount-label").html(cName);
                            $("#ct-discount-price").html(cDiscount);
                            $("#ct-discount").removeClass("empty");

                            $("#ct-total").html("{0} {1}".format($this.current.price_total.toFixed(2), LOCALE["CURRENCY_SIGN"]));

                            $("#checkout-coupons").addClass("hidden");

                            if ($this.current.price_total.toFixed(2) == 0.0)
                            {
                                $("#checkout-method").addClass("hidden");
                                $("#ct-total").html(LOCALE["FREE"]);
                                //$this.current.method = "freecoupon";
                                //$this.current.set_default = false;
                            }
                        }
                        else
                        {
                            p.form_helper.setError(code_input, LOCALE["PURCHASE_COUPON_INVALID"]);
                            p.form_helper.setDisabledButton($("#checkout-coupon-add"), false);
                            p.form_helper.setDisabled(code_input, false);
                        }
                    });
                },

                _modalOpen: function ()
                {
                    let $this = p.shop.purchase;

                    if (!$this.current.checkout_shown)
                    {
                        $this.current.checkout_shown = true;

                        let delayedClose = function ()
                        {
                            // console.log("cancel button");
                            setTimeout(function ()
                            {
                                $this.cancel(null, true);
                            }, 100);
                        };
                        let delayedCouponAdd = function ()
                        {
                            setTimeout(function ()
                            {
                                $this._couponAdd();
                            }, 100);
                        };
                        let delayedPay = function ()
                        {
                            setTimeout(function ()
                            {
                                $this._pay();
                            }, 100);
                        };

                        $("#checkout-cancel").on("click", delayedClose);
                        $("#checkout-do").on("click", delayedPay);

                        // $("#checkout-method-dropdown")[0].addEventListener("core-select", $this._methodSelected);
                        $("#checkout-method-dropdown").on("change", $this._methodSelected);
                        // $("#checkout-method-set-default")[0].addEventListener("change", $this._setDefaultChecked);
                        $("#checkout-method-set-default").on("change", $this._setDefaultChecked);

                        $("#checkout-coupon-add").on("click", delayedCouponAdd);
                        $("#checkout-coupon-code").on("keyup", $this._couponInputChanged);

                        $("#checkout-terms-show").on("click", function ()
                        {
                            p.showTerms();
                        });

                        $("#checkout-terms-accepted").on("change click input value", $this._setTermsChecked);

                        //$("#checkout-modal").addClass("shadow").attr("z", 2);
                        //$("#checkout-modal").css("overflow", "visible");

                        let defaultMethod = p.ls.get("default_payment_method");
                        if (defaultMethod)
                        {
                            // $("#checkout-method-dropdown core-menu")[0].selected = defaultMethod;
                            $("#checkout-method-dropdown").val(defaultMethod);
                            // $("#checkout-method-set-default")[0].checked = true;
                            $("#checkout-method-set-default")[0].checked = true;
                        }

                        if ($("#checkout-method-dropdown").children().length == 1)
                        {
                            let $method = $("#checkout-method-dropdown").children().attr("value");
                            // console.log($method);
                            $("#checkout-method-dropdown").val($method);
                            $("#checkout-method-dropdown").trigger("change");
                        }

                        // $(window).on("resize", function ()
                        // {
                        //     // $this._windowiosresizefix();
                        // });
                    }
                },

                _modalClose: function (e)
                {
                    let $this = p.shop.purchase;

                    // if ($(e.target).is($this._modal))
                    // {
                    if ($this._modal.length > 0)
                        $this._modal.remove();

                    if ($("#checkout-loader").length > 0)
                        $("#checkout-loader").remove();
                    // }
                },

                _requestModal: function ()
                {
                    let $this = p.shop.purchase;
                    if ($this.current != null)
                    {
                        p.net.Request(["do", "purchase", "checkout", $this.current.id], {}, $this._onFlowStart);
                    }
                },

                _initModal: function ()
                {
                    let $this = p.shop.purchase;
                    // console.log("init modal");
                    if (!p.seamlessNavigation.FromApp)
                    {
                        $this._modal = $("#checkout-modal");
                        if ($this._modal.length == 0)
                            return $this.cancel("MODAL_NOT_FOUND");
                    }
                    if ($this.current.is_free)
                    {
                        $("#ct-vat").addClass("empty");
                        $("#ct-product-price, #ct-total").html(LOCALE["FREE"]);
                        $("#checkout-method, #checkout-coupons").addClass("hidden");
                        $("#checkout-do").html(LOCALE["BUY"]);
                        $("#checkout-do").removeClass("disabled");
                    }

                    if ($("#checkout-invoice-address").length > 0)
                    {
                        $this.current.custom_fields = p.user.getCustomFields();
                        // console.log($this.current.custom_fields);

                        let $fieldContainer = $("#checkout-invoice-address-form");
                        let user_data = p.jsonParse(p.user.userdata.custom);

                        for (let fieldName in $this.current.custom_fields)
                        {
                            let field = $this.current.custom_fields[fieldName];
                            $fieldContainer.append(field.control);

                            if (user_data && user_data[fieldName])
                            {
                                p.form_helper.setValue(field.control, user_data[fieldName]);
                            }
                        }
                    }

                    p.defer(function ()
                    {
                        $('select').formSelect();
                        if (!p.seamlessNavigation.FromApp)
                        {
                            let modal = M.Modal.init($this._modal[0], {
                                onOpenEnd: $this._modalOpen,
                                onCloseEnd: $this._modalClose,
                               
                                dismissible: false
                            });
    
                            modal.open();    
                        }
                        else
                        {
                            $this._modalOpen();
                        }
                    }, null, null, 100);

                },

                _windowiosresizefix: function ()
                {
                    // let $this = p.shop.purchase;
                    // $this._resizeModal();
                },

                _resizeData: 0,
                _resizeInterval: null,
                _resizeModal: function ()
                {
                    // if (p.seamlessNavigation.FromApp)
                    //     return;

                    // let $this = p.shop.purchase;
                    // //$this._modal[0].resizeHandler();

                    // clearInterval($this._resizeInterval);
                    // $this._resizeData = 0;
                    // $this._resizeInterval = setInterval($this._resizeIntervalFnc, 10);
                },

                _resizeIntervalFnc: function ()
                {
                    // let $this = p.shop.purchase;
                    // if ($this._resizeData < 30)
                    // {
                    //     $this._resizeData++;
                    //     $this._modal[0].resizeHandler();
                    // }
                    // else
                    // {
                    //     clearInterval($this._resizeInterval);
                    // }
                },

                _onGate: function (success, data)
                {
                    let $this = p.shop.purchase;

                    if (success && data && data.success)
                    {
                        switch (data.gate)
                        {
                            case "internal":
                                $this._requestModal();
                                break;
                            case "external":
                                location.assign(data.url);
                                break;
                            default:
                                $this.cancel("INVALID_GATE");
                                break;
                        }
                    }
                    else
                    {
                        if (data && data.error)
                        {
                            if (data.error == "NOT_LOGGED_IN")
                            {
                                let $askImage = $("<img>", {
                                    "class": "dynamic loading left cover",
                                    "src": "{0}/api/web/?cmd=get/file/cover&udid={1}&size=medium".format(BASEPATH, $this.current.id)
                                });
                                $askImage.on("load", function ()
                                {
                                    $(this).removeClass("loading");
                                });

                                p.handleAsk(
                                    {
                                        title: LOCALE["LOGIN_PROMPT"],
                                        text: LOCALE["LOGIN_PROMPT_PURCHASE"].format($this.current.name),
                                        content_before: $askImage
                                    },
                                    function ()
                                    {
                                        p.seamlessNavigation.Push({ cmd: ["login"], stack: "/login/", title: LOCALE["LOGIN"] });
                                    },
                                    function ()
                                    {
                                        $this.cancel();
                                    });

                                return;
                            }
                        }

                        $this.cancel("GATE_REQUEST_FAILED");
                    }
                },

                _onFlowStart: function (success, data)
                {
                    let $this = p.shop.purchase;

                    if (success && data && data.success)
                    {
                        if (data["modal-html"])
                        {
                            let purchase_data = data["purchase-info"];
                            let purchase_calculation = data["purchase-calculation"];
                            $this.current.price = purchase_calculation.prices.item;
                            $this.current.price_discount = purchase_calculation.prices.discount;
                            $this.current.price_subtotal = purchase_calculation.prices.subtotal;
                            $this.current.price_vat = purchase_calculation.prices.vat;
                            $this.current.price_total = purchase_calculation.prices.total;

                            $this.current.name = purchase_data.name;
                            $this.current.internal_id = purchase_data["item-id"];
                            $this.current.is_free = purchase_data["is-free"];

                            if (p.seamlessNavigation.FromApp)
                            {
                                //alert("iOS Hack start!");
                                let ele = $(data["modal-html"].trim());
                                // console.log(ele);
                                $("body").html("")
                                    .append($(ele[0]).html())
                                    .append($(ele[1]));
                                $("body").addClass("dialog");
                            }
                            else
                                $("body").append(data["modal-html"].trim());

                            // console.log("modal html", data["modal-html"]);
                            
                            $this._initModal();
                        }
                        else
                        {
                            $this.cancel("CHECKOUT_NO_MODAL_DATA");
                        }
                    }
                    else
                    {
                        $this.cancel("CHECKOUT_REQUEST_FAILED");
                    }
                }
            }
        }
    };



    exHelp.extend(module);
})(window);