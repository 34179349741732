(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        info:
        {
            AppName: "Maglify Newton",
            Version: "10.0.0",
            Author: "soft-evolution GmbH & Co. KG"
        },

        debugpanel: function (e)
        {
            if ($("#debugpanel").length > 0)
                $("#debugpanel").html(e);
            else
                $("body").append($div({ "id": "debugpanel", "html": e }));
        },

        decode: function (e)
        {
            if (e && e.length > 0)
            {
                return $("<textarea/>").html(e).text();
            }

            return e;
        },

        ls:
        {
            uniqueKey: null,

            makeKey: function ()
            {
                if (this.uniqueKey == null)
                {
                    this.uniqueKey = p.md5(BASEPATH + SITENAME);
                }
            },

            get: function (key)
            {
                if (this.uniqueKey == null)
                    this.makeKey();

                return p.localStorage.get("{0}_{1}".format(this.uniqueKey, key));
            },
            set: function (key, value)
            {
                if (this.uniqueKey == null)
                    this.makeKey();

                return p.localStorage.set("{0}_{1}".format(this.uniqueKey, key), value);
            }
        },

        localStorage:
        {
            get: function (key)
            {
                try
                {
                    if ('localStorage' in window && window['localStorage'] !== null)
                    {
                        return window.localStorage.getItem(key);
                    }

                    if ('sessionStorage' in window && window['sessionStorage'] !== null)
                    {
                        return window.sessionStorage.getItem(key);
                    }
                }
                catch (e)
                {
                    return p.storage("localStorage")[key];
                }

                return null;
            },

            set: function (key, value)
            {
                try
                {
                    if ('localStorage' in window && window['localStorage'] !== null)
                    {
                        return window.localStorage.setItem(key, value);
                    }

                    if ('sessionStorage' in window && window['sessionStorage'] !== null)
                    {
                        return window.sessionStorage.setItem(key, value);
                    }
                }
                catch (e)
                {
                    return p.storage("localStorage")[key] = value;
                }

                return null;
            }
        },

        utf8_encode: function (string)
        {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++)
            {
                var c = string.charCodeAt(n);

                if (c < 128)
                {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048))
                {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else
                {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
            }

            return utftext;
        },

        // private method for UTF-8 decoding
        utf8_decode: function (utftext)
        {
            var string = "";
            var i = 0;
            var c = c1 = c2 = 0;

            while (i < utftext.length)
            {
                c = utftext.charCodeAt(i);

                if (c < 128)
                {
                    string += String.fromCharCode(c);
                    i++;
                }
                else if ((c > 191) && (c < 224))
                {
                    c2 = utftext.charCodeAt(i + 1);
                    string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                    i += 2;
                }
                else
                {
                    c2 = utftext.charCodeAt(i + 1);
                    c3 = utftext.charCodeAt(i + 2);
                    string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                    i += 3;
                }
            }

            return string;
        },

        sha1: function (str)
        {
            // http://kevin.vanzonneveld.net
            // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
            // + namespaced by: Michael White (http://getsprink.com)
            // +      input by: Brett Zamir (http://brett-zamir.me)
            // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
            // -    depends on: utf8_encode
            // *     example 1: sha1('Kevin van Zonneveld');
            // *     returns 1: '54916d2e62f65b3afa6e192e6a601cdbe5cb5897'
            var rotate_left = function (n, s)
            {
                var t4 = (n << s) | (n >>> (32 - s));
                return t4;
            };

            /*var lsb_hex = function (val) { // Not in use; needed?
                var str="";
                var i;
                var vh;
                var vl;
    
                for ( i=0; i<=6; i+=2 ) {
                  vh = (val>>>(i*4+4))&0x0f;
                  vl = (val>>>(i*4))&0x0f;
                  str += vh.toString(16) + vl.toString(16);
                }
                return str;
              };*/

            var cvt_hex = function (val)
            {
                var str = "";
                var i;
                var v;

                for (i = 7; i >= 0; i--)
                {
                    v = (val >>> (i * 4)) & 0x0f;
                    str += v.toString(16);
                }
                return str;
            };

            var blockstart;
            var i, j;
            var W = new Array(80);
            var H0 = 0x67452301;
            var H1 = 0xEFCDAB89;
            var H2 = 0x98BADCFE;
            var H3 = 0x10325476;
            var H4 = 0xC3D2E1F0;
            var A, B, C, D, E;
            var temp;

            str = this.utf8_encode(str);
            var str_len = str.length;

            var word_array = [];
            for (i = 0; i < str_len - 3; i += 4)
            {
                j = str.charCodeAt(i) << 24 | str.charCodeAt(i + 1) << 16 | str.charCodeAt(i + 2) << 8 | str.charCodeAt(i + 3);
                word_array.push(j);
            }

            switch (str_len % 4)
            {
                case 0:
                    i = 0x080000000;
                    break;
                case 1:
                    i = str.charCodeAt(str_len - 1) << 24 | 0x0800000;
                    break;
                case 2:
                    i = str.charCodeAt(str_len - 2) << 24 | str.charCodeAt(str_len - 1) << 16 | 0x08000;
                    break;
                case 3:
                    i = str.charCodeAt(str_len - 3) << 24 | str.charCodeAt(str_len - 2) << 16 | str.charCodeAt(str_len - 1) << 8 | 0x80;
                    break;
            }

            word_array.push(i);

            while ((word_array.length % 16) != 14)
            {
                word_array.push(0);
            }

            word_array.push(str_len >>> 29);
            word_array.push((str_len << 3) & 0x0ffffffff);

            for (blockstart = 0; blockstart < word_array.length; blockstart += 16)
            {
                for (i = 0; i < 16; i++)
                {
                    W[i] = word_array[blockstart + i];
                }
                for (i = 16; i <= 79; i++)
                {
                    W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
                }

                A = H0;
                B = H1;
                C = H2;
                D = H3;
                E = H4;

                for (i = 0; i <= 19; i++)
                {
                    temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
                    E = D;
                    D = C;
                    C = rotate_left(B, 30);
                    B = A;
                    A = temp;
                }

                for (i = 20; i <= 39; i++)
                {
                    temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
                    E = D;
                    D = C;
                    C = rotate_left(B, 30);
                    B = A;
                    A = temp;
                }

                for (i = 40; i <= 59; i++)
                {
                    temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
                    E = D;
                    D = C;
                    C = rotate_left(B, 30);
                    B = A;
                    A = temp;
                }

                for (i = 60; i <= 79; i++)
                {
                    temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
                    E = D;
                    D = C;
                    C = rotate_left(B, 30);
                    B = A;
                    A = temp;
                }

                H0 = (H0 + A) & 0x0ffffffff;
                H1 = (H1 + B) & 0x0ffffffff;
                H2 = (H2 + C) & 0x0ffffffff;
                H3 = (H3 + D) & 0x0ffffffff;
                H4 = (H4 + E) & 0x0ffffffff;
            }

            temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
            return temp.toLowerCase();
        },

        md5: function (string)
        {
            function RotateLeft(lValue, iShiftBits)
            {
                return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
            }

            function AddUnsigned(lX, lY)
            {
                var lX4, lY4, lX8, lY8, lResult;
                lX8 = (lX & 0x80000000);
                lY8 = (lY & 0x80000000);
                lX4 = (lX & 0x40000000);
                lY4 = (lY & 0x40000000);
                lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
                if (lX4 & lY4)
                {
                    return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
                }
                if (lX4 | lY4)
                {
                    if (lResult & 0x40000000)
                    {
                        return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                    } else
                    {
                        return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                    }
                } else
                {
                    return (lResult ^ lX8 ^ lY8);
                }
            }

            function F(x, y, z) { return (x & y) | ((~x) & z); }
            function G(x, y, z) { return (x & z) | (y & (~z)); }
            function H(x, y, z) { return (x ^ y ^ z); }
            function I(x, y, z) { return (y ^ (x | (~z))); }

            function FF(a, b, c, d, x, s, ac)
            {
                a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
                return AddUnsigned(RotateLeft(a, s), b);
            };

            function GG(a, b, c, d, x, s, ac)
            {
                a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
                return AddUnsigned(RotateLeft(a, s), b);
            };

            function HH(a, b, c, d, x, s, ac)
            {
                a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
                return AddUnsigned(RotateLeft(a, s), b);
            };

            function II(a, b, c, d, x, s, ac)
            {
                a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
                return AddUnsigned(RotateLeft(a, s), b);
            };

            function ConvertToWordArray(string)
            {
                var lWordCount;
                var lMessageLength = string.length;
                var lNumberOfWords_temp1 = lMessageLength + 8;
                var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
                var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
                var lWordArray = Array(lNumberOfWords - 1);
                var lBytePosition = 0;
                var lByteCount = 0;
                while (lByteCount < lMessageLength)
                {
                    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
                    lBytePosition = (lByteCount % 4) * 8;
                    lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
                    lByteCount++;
                }
                lWordCount = (lByteCount - (lByteCount % 4)) / 4;
                lBytePosition = (lByteCount % 4) * 8;
                lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
                lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
                lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
                return lWordArray;
            };

            function WordToHex(lValue)
            {
                var WordToHexValue = "", WordToHexValue_temp = "", lByte, lCount;
                for (lCount = 0; lCount <= 3; lCount++)
                {
                    lByte = (lValue >>> (lCount * 8)) & 255;
                    WordToHexValue_temp = "0" + lByte.toString(16);
                    WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
                }
                return WordToHexValue;
            };

            function Utf8Encode(string)
            {
                string = string.replace(/\r\n/g, "\n");
                var utftext = "";

                for (var n = 0; n < string.length; n++)
                {
                    var c = string.charCodeAt(n);

                    if (c < 128)
                    {
                        utftext += String.fromCharCode(c);
                    }
                    else if ((c > 127) && (c < 2048))
                    {
                        utftext += String.fromCharCode((c >> 6) | 192);
                        utftext += String.fromCharCode((c & 63) | 128);
                    }
                    else
                    {
                        utftext += String.fromCharCode((c >> 12) | 224);
                        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                        utftext += String.fromCharCode((c & 63) | 128);
                    }
                }

                return utftext;
            };

            var x = Array();
            var k, AA, BB, CC, DD, a, b, c, d;
            var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
            var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
            var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
            var S41 = 6, S42 = 10, S43 = 15, S44 = 21;

            string = Utf8Encode(string);

            x = ConvertToWordArray(string);

            a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;

            for (k = 0; k < x.length; k += 16)
            {
                AA = a; BB = b; CC = c; DD = d;
                a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
                d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
                c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
                b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
                a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
                d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
                c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
                b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
                a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
                d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
                c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
                b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
                a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
                d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
                c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
                b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
                a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
                d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
                c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
                b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
                a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
                d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
                c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
                b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
                a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
                d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
                c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
                b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
                a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
                d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
                c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
                b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
                a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
                d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
                c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
                b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
                a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
                d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
                c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
                b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
                a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
                d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
                c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
                b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
                a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
                d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
                c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
                b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
                a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
                d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
                c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
                b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
                a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
                d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
                c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
                b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
                a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
                d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
                c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
                b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
                a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
                d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
                c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
                b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
                a = AddUnsigned(a, AA);
                b = AddUnsigned(b, BB);
                c = AddUnsigned(c, CC);
                d = AddUnsigned(d, DD);
            }

            var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

            return temp.toLowerCase();
        },

        glyphicon: function (name)
        {
            return $("<span>", { "class": "glyphicon glyphicon-{0}".format(name) });
        }
    };

    exHelp.extend(module);
})(window);

function $div(e)
{
    return $("<div />", e);
}

function $h1(e)
{
    return $("<h1>", e);
}

function $tr(e)
{
    return $("<tr />", e);
}

function $td(e)
{
    return $("<td />", e);
}

// DEBUG

var DEBUG = true;
var DEBUG_OVERRIDE_SHOW_ALL = false;

var CONSOLE_NORMAL = 0;
var CONSOLE_INFO = 1;
var CONSOLE_PERF = 2;
var CONSOLE_WARNING = 3;
var CONSOLE_ERROR = 4;
var CONSOLE_SUPERERROR = 5;
var CONSOLE_MISC = 6;

var CONSOLE_NORMAL_CSS = "color: #000;";    // Black
var CONSOLE_INFO_CSS = "color: #00F;";      // Blue
var CONSOLE_PERF_CSS = "color: #080;";      // Green
var CONSOLE_WARNING_CSS = "color: #d60;";   // Orange
var CONSOLE_MISC_CSS = "color: #907;";    // Black
var CONSOLE_ERROR_CSS = "color: #F00;";     // Red
var CONSOLE_SUPERERROR_CSS = "color: #FFF; background-color: #f00;";

var SYMBOL_NAVIGATION = "NAV";
var SYMBOL_NETWORK = "NET";
var SYMBOL_STORE = "STORE";
var SYMBOL_SHELF = "SHELF";
var SYMBOL_USER = "USER";
var SYMBOL_SEARCH_SCORING = "SCORE";
var SYMBOL_PDF = "PDF";

// Debug Symbols to ignore, to clean up console logs when looking for a specific problem
var DebugIgnoreSymbols = ["SCORE", "USER", "LOADER", "LOAD", "NET", "LOAD"];
DEBUG_OVERRIDE_SHOW_ALL && (DebugIgnoreSymbols = []);

function Trace(args, symbol, level)
{
    if (DEBUG)
    {
        for (var i = 0; i < DebugIgnoreSymbols.length; i++)
        {
            if (symbol == DebugIgnoreSymbols[i] || (DebugIgnoreSymbols[i] == ">>" && symbol === undefined))
            {
                return;
            }
        }

        var outmsg = "";

        if (Array.isArray(args))
        {
            var argarr = [];
            var litarr = [];
            for (var i = 0; i < args.length; i++)
            {
                if (IsInvalid(args[i]))
                {
                    argarr.push("" + args[i]);
                }
                else if (args[i].getTime)
                {
                    argarr.push("Date({0})".format(exHelp.date.to.shortFormat(args[i])));
                }
                else if (typeof args[i] == 'function')
                {
                    if (args[i].name == "")
                    {
                        argarr.push("Callback(ANON)");
                    }
                    else
                    {
                        argarr.push("Callback({0})".format(args[i].name));
                    }
                }
                else if (Array.isArray(args[i]))
                {
                    litarr.push(args[i]); //.push("Array[{0}]".format(args[i].length));
                }
                else if ($.isPlainObject(args[i]))
                {
                    litarr.push(args[i]);
                }
                else
                {
                    argarr.push(args[i]);
                }
            }
            outmsg = argarr.join(", ");
        }
        else
        {
            outmsg = args;
        }

        var s = (symbol === undefined) ? ">>" : symbol;
        var css = "";

        if (!level)
        {
            switch (s)
            {
                case SYMBOL_NAVIGATION:
                    level = CONSOLE_PERF;
                    break;

                case SYMBOL_NETWORK:
                    level = CONSOLE_INFO;
                    break;

                case SYMBOL_SEARCH_SCORING:
                    level = CONSOLE_MISC;
                    break;

                case SYMBOL_STORE:
                case SYMBOL_SHELF:
                case SYMBOL_PDF:
                    level = CONSOLE_WARNING;
                    break;
            }
        }

        switch (level)
        {
            case CONSOLE_NORMAL:
                css = CONSOLE_NORMAL_CSS;
                break;

            case CONSOLE_INFO:
                css = CONSOLE_INFO_CSS;
                break;

            case CONSOLE_PERF:
                css = CONSOLE_PERF_CSS;

            case CONSOLE_WARNING:
                css = CONSOLE_WARNING_CSS;
                break;
                break;

            case CONSOLE_ERROR:
                css = CONSOLE_ERROR_CSS;
                break;

            case CONSOLE_SUPERERROR:
                css = CONSOLE_SUPERERROR_CSS;
                break;

            case CONSOLE_MISC:
                css = CONSOLE_MISC_CSS;
                break;

            default:
                css = CONSOLE_NORMAL_CSS;
                break;
        }

        if (litarr && litarr.length > 0)
        {
            var outarr = ["%c:{0}\t{1}".format(s, outmsg), css];
            outarr = outarr.concat(litarr);
            //DBGLOG += "\n{0}".format(outarr.join(", "));

            //for (var i = 0; i < DebugIgnoreSymbols.length; i++)
            //{
            //    if (symbol == DebugIgnoreSymbols[i] || (DebugIgnoreSymbols[i] == ">>" && symbol === undefined))
            //    {
            //        return;
            //    }
            //}
            // if (exHelp.browser.isChrome)
            //     console.trace.apply(console, outarr);
            // else
                console.log.apply(console, outarr);
        }
        else
        {
            //DBGLOG += "\n{0} {1}".format(s, outmsg);

            //for (var i = 0; i < DebugIgnoreSymbols.length; i++)
            //{
            //    if (symbol == DebugIgnoreSymbols[i] || (DebugIgnoreSymbols[i] == ">>" && symbol === undefined))
            //    {
            //        return;
            //    }
            // //}
            // if (exHelp.browser.isChrome)
            //     console.trace("%c:{0}\t{1}".format(s, outmsg), css);
            // else
                console.log("%c:{0}\t{1}".format(s, outmsg), css);
        }
    }
}

// Logs a function with all it's arguments at call time
// Symbol: A symbol to prepend to the line, if not defined defaults to >>
function CallTrace(args, symbol, line)
{
    if (DEBUG)
    {
        for (var i = 0; i < DebugIgnoreSymbols.length; i++)
        {
            if (symbol == DebugIgnoreSymbols[i] || (DebugIgnoreSymbols[i] == ">>" && symbol === undefined))
            {
                return;
            }
        }
        var argarr = [];
        for (var i = 0; i < args.length; i++)
        {
            if (IsInvalid(args[i]))
            {
                argarr.push("" + args[i]);
            }
            else if (args[i].getTime)
            {
                argarr.push("Date({0})".format(exHelp.date.to.shortFormat(args[i])));
            }
            else if (typeof args[i] == 'function')
            {
                if (args[i].name == "")
                {
                    argarr.push("Callback(ANON)");
                }
                else
                {
                    argarr.push("Callback({0})".format(args[i].name));
                }
            }
            else if (Array.isArray(args[i]))
            {
                argarr.push("Array[{0}]".format(args[i].join(", ")));
            }
            else
            {
                argarr.push(args[i]);
            }
        }
        var s = (symbol === undefined) ? ">>" : symbol;

        //DBGLOG += "\n{0} {1}({2}) @ via {3}".format(s, args.callee.name, argarr.join(", "), (args.callee.caller.name.length < 2) ? "Anonymous Callback" : args.callee.caller.name);

        //for (var i = 0; i < DebugIgnoreSymbols.length; i++)
        //{
        //    if (symbol == DebugIgnoreSymbols[i] || (DebugIgnoreSymbols[i] == ">>" && symbol === undefined))
        //    {
        //        return;
        //    }
        //}

        // IE Fix
        if (!args.callee.caller)
            args.callee.caller = {};

        if (!args.callee.caller.name)
            args.callee.caller.name = "";

        if (exHelp.browser.isChrome)
            console.trace(
                ":{0}\t{1}({2}) @ via {3}".format
                    (
                        s,
                        args.callee.name,
                        argarr.join(", "),
                        (args.callee.caller.name.length < 2) ? "Anonymous Callback" : args.callee.caller.name
                    )
                ,
                (line ? " at line {0}".format(line) : "")
            );
        else
            console.log(
                ":{0}\t{1}({2}) @ via {3}".format
                    (
                        s,
                        args.callee.name,
                        argarr.join(", "),
                        (args.callee.caller.name.length < 2) ? "Anonymous Callback" : args.callee.caller.name
                    )
                ,
                (line ? " at line {0}".format(line) : "")
            );
    }
}

function IsInvalid(v)
{
    if (v === undefined || v === null || v === Infinity)
    {
        return true;
    }

    return false;
}

// http://stackoverflow.com/a/6713782/2064388
Object.equals = function (x, y)
{
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    //if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    //if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x)
    {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue; else return false;
        // if they have the same strict value or identity then they are equal

        //if (typeof (x[p]) !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        //if (!Object.equals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y)
    {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
        // allows x[ p ] to be set to undefined
    }
    return true;
}

// Extend jquery with helpers

$.fn.addClassAfter = function (className, time)
{
    return this.delay(time).queue(function () { $(this).addClass(className).dequeue(); });
};
$.fn.removeClassAfter = function (className, time)
{
    return this.delay(time).queue(function () { $(this).removeClass(className).dequeue(); });
};

//$div().addClassAfter();