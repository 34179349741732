(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module = {
        banners: {
            fetch: function (onDone)
            {
                p.net.Request(["get", "banners"], {},
                    function (success, data)
                    {
                        if (success)
                        {
                            if (data && data.success && data.banners)
                            {
                                onDone(data.banners);
                            }
                            else
                            {
                                onDone(null, "EMPTY_RESPONSE");
                            }
                        }
                        else
                        {
                            onDone(null, "NO_SERVER");
                        }
                    });
            },
            show: function (on, type)
            {
                var $this = p.banners;
                $this.fetch(function (data, error)
                {
                    if (data)
                    {
                        var target = $(on[0]);
                        var slider = $("<div>", { "class": "carousel carousel-slider" });

                        for (var i in data)
                        {
                            var banner_data = data[i], eBanner, eImage, eThumb;

                            if (banner_data.hash)
                            {
                                eBanner = $("<a>", { "class": "carousel-item", "href": "#slide-" + i + "!" });
                                eImage = $("<img>", { "src": "{0}/api/web/?cmd=get/file/banner&hash={1}&platform=web&class=web".format(BASEPATH, banner_data.hash) });

                                eBanner.append(eImage);
                                slider.append(eBanner);

                                if (banner_data.url_type != "none")
                                {
                                    switch (banner_data.url_type)
                                    {
                                        case "url_external":
                                            eBanner.attr("target", "_blank");
                                        case "url_internal":
                                            eBanner.attr("href", banner_data.url);
                                            break;
                                    }
                                }
                                else
                                {
                                    if (banner_data.book_udid)
                                    {
                                        eBanner.attr("href", "/shelf/details/{0}/".format(banner_data.book_udid));
                                        eBanner.attr("cmd", "shop");
                                        p.seamlessNavigation.Link(eBanner);
                                    }
                                }
                            }
                        }

                        if (data.length > 0)
                        {
                            target.append(slider);
                            slider.carousel({
                                fullWidth: true
                            });
                            if (data.length > 1)
                            {

                                var slideFnc = function ()
                                {
                                    slider.carousel("next");
                                    setTimeout(slideFnc, 10000);
                                };
                                slideFnc();
                            }
                        }

                    }
                    else
                    {
                        console.error("Fetching banners failed", error);
                    }
                });
            }
        }
    };

    exHelp.extend(module);
})(window);