(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        PDFManager:
        {
            DEBUG_SYMBOL: "PDFM",
            document: null,
            pages: {},
            canvases: {},
            scale: 1.0,

            _rendering: false,
            _loading: 0,

            get isRendering()
            {
                return this._rendering;
            },

            get isLoading()
            {
                return this._loading > 0;
            },

            addCanvas: function PDFM_addCanvas(name, canvas_id)
            {
                var canvas_obj = null;
                if (p.is.jQuery(canvas_id))
                    canvas_obj = canvas_id[0];
                else
                    canvas_obj = document.getElementById(canvas_id);

                if (canvas_obj == null || canvas_obj == undefined)
                    return;

                this.canvases[name] = canvas_obj;
            },

            loadFile: function PDFM_loadFile(e, callback)
            {
                this._loading++;
                PDFJS.getDocument(e, null, null, this.onDocumentProgress).then(function PDFM_loadFile_chain(a)
                {
                    if (callback && p.is.function(callback))
                        callback(a);

                    p.PDFManager.onDocumentLoad(a);
                });
            },
            getPage: function PDFM_getPage(i, callback)
            {
                if (this.pages.hasOwnProperty(i))
                {
                    if (callback && p.is.function(callback))
                        callback(this.pages[i]);
                }
                else
                {
                    if (this.document != null)
                    {
                        this.document.getPage(i).then(function PDFM_getPage_chain(e)
                        {
                            if (callback && p.is.function(callback))
                                callback(e);

                            p.PDFManager.onPageLoad(e);
                        });
                    }
                }
            },
            renderPageOn: function PDFM_renderPageOn(page, canvas_name, callback)
            {
                if (this.canvases.hasOwnProperty(canvas_name) == false)
                {
                    Trace(["Invalid canvas", canvas_name], this.DEBUG_SYMBOL, CONSOLE_ERROR);
                    return;
                }

                if (page == 0 || page > this.document.numPages)
                {
                    if (callback && p.is.function(callback))
                        callback();

                    return;
                }

                this._rendering = true;

                var RENDER_CONTEXT =
                {
                    canvasContext: this.canvases[canvas_name].getContext("2d"),
                    viewport: null
                };

                if (this.pages.hasOwnProperty(page))
                {
                    RENDER_CONTEXT.viewport = this.pages[page].getViewport(this.scale);
                    this.canvases[canvas_name].width = RENDER_CONTEXT.viewport.width;
                    this.canvases[canvas_name].height = RENDER_CONTEXT.viewport.height;


                    this.pages[page].render(RENDER_CONTEXT).promise.then(function PDFM_renderPageOn_chain(e)
                    {
                        p.PDFManager._rendering = false;

                        if (callback && p.is.function(callback))
                            callback(e);

                        p.PDFManager.onPageRender(e);
                    }, function PDFM_renderPageOn_chainException(ex)
                    {
                        console.error(ex);

                        p.PDFManager._rendering = false;

                        if (callback && p.is.function(callback))
                            callback(false);

                        p.PDFManager.onPageRender(false);
                    });
                }
                else
                {
                    this.getPage(page, function PDFM_renderPageOn_loader()
                    {
                        p.PDFManager.renderPageOn(page, canvas_name, callback);
                    });
                }
            },

            //#region Events

            // #region Handlers

            _handlerOnDocumentLoad: null,
            _handlerOnDocumentProgress: null,
            _handlerOnPageLoad: null,
            _handlerOnPageRender: null,

            setOnDocumentLoadHandler: function PDFM_handlerSet_onDocumentLoad(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnDocumentLoad = e;
            },
            setOnDocumentProgressHandler: function PDFM_handlerSet_onDocumentProgress(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnDocumentProgress = e;
            },
            setOnPageLoadHandler: function PDFM_handlerSet_onPageLoad(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnPageLoad = e;
            },
            setOnPageRenderHandler: function PDFM_handlerSet_onPageRender(e)
            {
                if (e && p.is.function(e))
                    this._handlerOnPageRender = e;
            },

            // #endregion

            onDocumentLoad: function PDFM_handlerTrigger_onDocumentLoad(e)
            {
                var $this = p.PDFManager;
                $this.document = e;

                if ($this._handlerOnDocumentLoad != null && p.is.function($this._handlerOnDocumentLoad))
                {
                    $this._handlerOnDocumentLoad(e);
                }
            },
            onDocumentProgress: function PDFM_handlerTrigger_onDocumentProgress(e)
            {
                var $this = p.PDFManager;

                if (e && e["loaded"] && e["total"])
                {
                    if (e.loaded >= e.total)
                    {
                        $this._loading--;

                        if ($this._loading < 0)
                            $this._loading = 0;
                    }
                }

                if ($this._handlerOnDocumentProgress != null && p.is.function($this._handlerOnDocumentProgress))
                {
                    $this._handlerOnDocumentProgress(e);
                }
            },
            onPageLoad: function PDFM_handlerTrigger_onPageLoad(e)
            {
                var $this = p.PDFManager;
                $this.pages[e.pageNumber] = e;

                if ($this._handlerOnPageLoad != null && p.is.function($this._handlerOnPageLoad))
                {
                    $this._handlerOnPageLoad(e);
                }
            },
            onPageRender: function PDFM_handlerTrigger_onPageRender(e)
            {
                var $this = p.PDFManager;

                if ($this._handlerOnPageRender != null && p.is.function($this._handlerOnPageRender))
                {
                    $this._handlerOnPageRender(e);
                }
            }

            //#endregion
        }
    };

    exHelp.extend(module);
})(window);