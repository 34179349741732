(function initThis(window)
{
    let dependencies = [
        "exHelp",
        "exHelpExtend",
        "$"
    ],
        __dI = 0;
    for (; __dI < dependencies.length; __dI++) { if (window[dependencies[__dI]] == void 0) { return setTimeout(initThis, 100, window); } }

    // Create shorthand
    let p = exHelp;

    let module =
    {
        eggbasket:
        {
            g2048:
            {
                grid_html: [],
                grid_internal: [],
                Init: function ()
                {
                    p.common.showLoader();

                    $("#content").html("");

                    //Make Grid
                    var grid = $div({ "id": "gamegrid" });

                    for (var y = 0; y < 4; y++)
                    {
                        var row = $div({ "class": "row" });
                        this.grid_internal[y] = [];
                        this.grid_html[y] = [];

                        for (var x = 0; x < 4; x++)
                        {
                            var cell = $div({ "class": "cell" });
                            row.append(cell);

                            this.grid_html[y][x] = cell;
                            this.grid_internal[y][x] = 0;
                        }
                        grid.append(row);
                    }

                    $("#content").append(grid);

                    p.common.hideLoader();
                },

                tile: function (num)
                {
                    this.value = num;
                    this.html = $div({ "class": "tile", text: num });

                    this.setValue = function (n)
                    {
                        this.value = n;
                        this.html.html(n);
                        return this;
                    };

                    this.setPosition = function (x, y)
                    {

                    };
                }
            }
        }
    };

    exHelp.extend(module);
})(window);